import React from 'react';
import moment from 'moment';
import { displayDateFormatShort, pbhRangeCondition } from '../../../constants';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Table, TableHead, TableRow, TableCell, TableBody, Paper } from '@material-ui/core';
const VariableRents = ({rents}) => {
    return(
        <div style={{width:'520px'}}>
            <Table className='mui-table-format'>
                <TableHead>
                    <TableRow>
                        <TableCell>Range</TableCell>
                        <TableCell>Min</TableCell>
                        <TableCell>Max</TableCell>
                        <TableCell>Range Type</TableCell>
                        <TableCell>Rate</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rents.map((rent, index) => 
                        <TableRow>
                            <TableCell>
                                {pbhRangeCondition.filter(item => item.id === parseInt(rent.ratio_range)).length ? pbhRangeCondition.filter(item => item.id === parseInt(rent.ratio_range))[0].label : '---'}
                            </TableCell>
                            <TableCell>{rent.min}</TableCell>
                            <TableCell>{rent.max}</TableCell>
                            <TableCell>{rent.rental_rate_type === 2 ? 'Per Unit':'Fixed'}</TableCell>
                            <TableCell>{rent.rate}</TableCell>
                        </TableRow>
                    )}
                </TableBody>
            </Table>
        </div>
    )
}

const VariableRentModal = ({variableRentInfo, toggleModalFn}) => {
    let asset = variableRentInfo.asset;
    return( 
        <Dialog
            open={variableRentInfo.modal}
            onClose={() => toggleModalFn()}
            className='console-management-modal'
            maxWidth={'md'}
        >
            <DialogTitle id="scroll-dialog-title">
                    Variable Rent
            </DialogTitle>
            <DialogContent dividers={true}>
                {asset.basic_rent_type.value === 3 || asset.basic_rent_type.value === 4 ?
                    <div>
                        <>
                        {(()=> {
                            let hoursRents = asset.basic_rent.filter(variableRent => variableRent.type === 1)
                            if(hoursRents.length){
                                return(
                                    <Paper style={{marginBottom:'15px'}}>
                                        <p style={{ color: 'blue' }}>Flight Hours</p>
                                        <VariableRents 
                                            rents={hoursRents}
                                        />
                                    </Paper>
                                )
                            }else{
                                return null
                            }
                        })()}
                    </>
                    <>
                        {(()=> {
                            let cyclesRents = asset.basic_rent.filter(variableRent => variableRent.type === 2)
                            if(cyclesRents.length){
                                return(
                                    <Paper style={{marginBottom:'15px'}}>
                                        <p style={{ color: 'blue' }}>Flight Cycle</p>
                                        <VariableRents 
                                            rents={cyclesRents}
                                        />
                                    </Paper>
                                )
                            }else{
                                return null
                            }
                        })()}
                    </>
                    <>
                        {(()=> {
                            let blockHoursRents = asset.basic_rent.filter(variableRent => variableRent.type === 4)
                            if(blockHoursRents.length){
                                return(
                                    <Paper style={{marginBottom:'15px'}}>
                                        <p style={{ color: 'blue' }}>Block Hours</p>
                                        <VariableRents 
                                            rents={blockHoursRents}
                                        />
                                    </Paper>
                                )
                            }else{
                                return null
                            }
                        })()}
                    </>        
                    </div>:null
                }
                { asset.basic_rent_type.value === 2 ? 
                    <Paper style={{marginBottom:'15px'}}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell align="right" >Period</TableCell>
                                    <TableCell align="right" >Payment Date</TableCell>
                                    { asset.basic_rent_type.currency === "USD" ?
                                        <TableCell align="right" >Reference Value</TableCell>: null
                                    }
                                    <TableCell align="right" >{asset.basic_rent_type.currency === "EUR" ? " Euro Rent (EUR)" : "Rent"}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                { asset.basic_rent.map((rent, bindex) => {
                                        return (
                                            <TableRow style={{ textAlign: 'center' }}>
                                                <TableCell align="right" >{rent.period}</TableCell>
                                                <TableCell align="right" >{rent.payment_date ? moment(rent.payment_date).format(displayDateFormatShort) : ''}</TableCell>
                                                { asset.basic_rent_type.currency === "USD" ?
                                                    <TableCell align="right" >{rent.reference_value && "US$" + rent.reference_value + ".00"}</TableCell>: null
                                                }
                                                <TableCell align="right" >{asset.basic_rent_type.currency === "EUR" ? rent.rent && "€" + rent.rent + ".00" : rent.rent && "US$" + rent.rent + ".00"}</TableCell>
                                            </TableRow>
                                        )
                                    })
                                }
                            </TableBody>
                        </Table>
                    </Paper>:null
                }
            </DialogContent>
            <DialogActions>
                <Button onClick={toggleModalFn} color='primary' size='small' variant='outlined'>Close</Button>
            </DialogActions>
        </Dialog>
    )
}
export default VariableRentModal;