import React, { Fragment } from 'react';
import { withRouter } from 'react-router';
import { FormControlLabel, Checkbox, Grid, Paper, Radio } from "@material-ui/core";

const TechSpecsReport = ({onChangeExport, exportObj}) => {
    return(
        <Paper style={{marginBottom:'15px'}}>
            <Grid container spacing={1} alignItems='center'>
                <Grid item md={12}>
                    <div className='export-module-card'>
                        <h4 className='rpt-card-header'>Technical Specification</h4>
                        <div className='rpt-card-body'>
                            <Grid container spacing={1} alignItems='center'>
                                <Grid item md={6}>
                                    <FormControlLabel control={
                                        <Checkbox 
                                            checked={exportObj.tech_specs && exportObj.tech_specs.overview && exportObj.tech_specs.overview.export ? true:false} 
                                            onChange={(e, featureType) => onChangeExport(e.target.checked, 'overview')} 
                                            color="primary" 
                                            size="small" />} 
                                            label='Overview Summary' 
                                        />
                                </Grid>
                                <Grid item md={6}>
                                    <FormControlLabel value={1} control={<Radio checked={true} color="primary" size="small" />} label="PDF" />
                                </Grid>
                            </Grid>

                            <Grid container spacing={1} alignItems='center'>
                                <Grid item md={6}>
                                    <FormControlLabel control={
                                        <Checkbox 
                                            checked={exportObj.tech_specs && exportObj.tech_specs.marketing && exportObj.tech_specs.marketing.export ? true:false} 
                                            onChange={(e, featureType) => onChangeExport(e.target.checked, 'marketing')} 
                                            color="primary" 
                                            size="small" />} 
                                            label='Marketing Summary' 
                                        />
                                </Grid>
                                <Grid item md={6}>
                                    <FormControlLabel value={1} control={<Radio checked={true} color="primary" size="small" />} label="PDF" />
                                </Grid>
                            </Grid>
                        </div>
                    </div>
                </Grid>
            </Grid>
        </Paper>
    )
}
export default withRouter(TechSpecsReport);