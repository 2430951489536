import React, { useState, useEffect } from "react";
import { withRouter } from "react-router";
import { useSnackbar } from 'notistack';
import { Table, TableBody, TableCell, TableHead, TableRow, Paper, Grid, Button, TextField, Dialog, DialogContent, DialogTitle, DialogActions, Tooltip, CircularProgress } from '@material-ui/core';
import { Pagination, STableLoader, FilterComponent, PageLoader, EmptyCollection, DeletePopUp } from '../../shared_elements';
import AddInteriorItem from './AddInteriorItem'
import EditIcon from '@material-ui/icons/Edit';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import { globalGetService, globalPutService, globalPostService, globalDeleteService, globalExportService } from '../../../globalServices';
import { checkApiStatus, checkPermission } from '../../utils_v2';
import RemarksDynamicField from "../../../shared/components/RemarksDynamicField";
import { downloadFileType } from "../../../utils";
import { assetTypeValues, backendDateFormat } from "../../../constants";
import moment from "moment";
import { trackActivity } from "../../../utils/mixpanel";
const equipmentObj = {
    equipment: '',
    part_number: '',
    serial_number: '',
    manufacturer: '',
    equipment_type: ''
}
const EquipmentHd = () => {
    return (
        <TableHead>
            <TableRow>
                <TableCell>Equipment</TableCell>
                <TableCell>Part Number</TableCell>
                <TableCell>Serial Number</TableCell>
                <TableCell>Manufacturer</TableCell>
                <TableCell>Equipment Type</TableCell>
                <TableCell>Remarks</TableCell>
                <TableCell align="right">Action(s)</TableCell>
            </TableRow>
        </TableHead>
    )
}
const EquipmentList = ({ item, onEdit, onDelete }) => {
    const [showMore, setShowMore] = useState({});
    const toggleDescription = (id) => {
        setShowMore({
            ...showMore,
            [id]: !showMore[id],
        });
    };
    return (
        <TableRow>
            <TableCell>
                {item?.equipment && item?.equipment?.length > 30 ? (
                    <div style={{ maxWidth: '220px' }} >
                        {showMore[item?.id] && showMore[item?.id] ? (
                            <span> {item?.equipment}
                                <a href='#' style={{ color: '#0E7FE1', fontSize: '12px' }} onClick={(e) => { e.preventDefault(); toggleDescription(item.id); }}  >  less </a>
                            </span>
                        ) : (
                            <span> {item.equipment.substring(0, 25)}
                                <a href='#' style={{ color: '#0E7FE1', fontSize: '12px' }} onClick={(e) => { e.preventDefault(); toggleDescription(item.id); }}  > ..more </a>
                            </span>
                        )}
                    </div>
                ) :
                    <div>{item.equipment || '--'}</div>
                }
            </TableCell>
            <TableCell>
                <div style={{ maxWidth: '180px' }}>{item?.part_number || '--'}</div>
            </TableCell>
            <TableCell>
                <div style={{ maxWidth: '180px' }}>{item?.serial_number || '--'}</div>
            </TableCell>
            <TableCell>
                <div style={{ maxWidth: '180px' }}>{item?.manufacturer || '--'}</div>
            </TableCell>
            <TableCell>
                {item?.equipment_type && item?.equipment_type?.length > 30 ? (
                    <div style={{ maxWidth: '300px' }} >
                        {showMore[item?.field] && showMore[item?.field] ? (
                            <span> {item?.equipment_type}
                                <a href='#' style={{ color: '#0E7FE1', fontSize: '12px' }} onClick={(e) => { e.preventDefault(); toggleDescription(item.field); }}  >  less </a>
                            </span>
                        ) : (
                            <span> {item.equipment_type.substring(0, 25)}
                                <a href='#' style={{ color: '#0E7FE1', fontSize: '12px' }} onClick={(e) => { e.preventDefault(); toggleDescription(item.field); }}  > ..more </a>
                            </span>
                        )}
                    </div>
                ) :
                    <div>{item.equipment_type || '--'}</div>
                }
            </TableCell>
            <TableCell>
                <RemarksDynamicField
                    item={item}
                />
            </TableCell>
            <TableCell align="right">
                <ul className="list-inline" style={{ width: '80px', float: 'right' }}>
                    {
                        checkPermission('technical_specs', 'interior', 'U') ?
                            <li className="list-inline-item hover-inline" onClick={onEdit}>
                                <Tooltip title='Edit' arrow><EditIcon color="primary" fontSize="small" /></Tooltip>
                            </li>
                            : null}
                    {
                        checkPermission('technical_specs', 'interior', 'D') ?
                            <li className="list-inline-item hover-inline" onClick={onDelete}>
                                <Tooltip title='Delete' arrow><DeleteOutlineIcon color="secondary" fontSize="small" /></Tooltip>
                            </li>
                            : null}
                </ul>
            </TableCell>
        </TableRow>
    )
}
const AddEditEquipment = ({ params, addEditEquipment, toggleModalFn, getResponseBack, assetInfoData }) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [isLoading, setLoading] = useState(false);
    const [equipment, setEquipment] = useState(addEditEquipment.data);
    const [error, setError] = useState({});
    const onFieldChange = (key, value) => {
        setEquipment({ ...equipment, [key]: value });
    }
    const onAddEditEquipment = () => {
        let validationInputs = {
            equipment: equipment?.equipment?.trim()?.length ? '' : 'Please enter Equipment',
        }
        if (Object.keys(validationInputs).every((k) => { return validationInputs[k] === '' })) {
            setLoading(true);
            if (equipment.id) {
                globalPutService(`console/v2/${params.type}/${params.aircraft_slug}/interior/cabin-equipments/${equipment.id}/`, equipment)
                    .then(response => {
                        if (checkApiStatus(response)) {
                            enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { horizontal: 'right', vertical: 'top' } });
                            trackActivity('Item Added', { page_title: 'Equipment', asset_type: 'MSN', asset_name: assetInfoData?.serial_number, event_type: 'Edit Equipment', event_desc: 'Edited Equipment data from Edit Equipment form' })
                            toggleModalFn(); getResponseBack();
                        }
                        setLoading(false);
                    })
            } else {
                globalPostService(`console/v2/${params.type}/${params.aircraft_slug}/interior/cabin-equipments/`, equipment)
                    .then(response => {
                        if (checkApiStatus(response)) {
                            enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { horizontal: 'right', vertical: 'top' } });
                            trackActivity('Item Added', { page_title: 'Equipment', asset_type: 'MSN', asset_name: assetInfoData?.serial_number, event_type: 'Add Equipment', event_desc: 'Added Equipment data from Add Equipment form' })
                            toggleModalFn(); getResponseBack();
                        }
                        setLoading(false);
                    })
            }
        } else {
            setError(validationInputs);
        }
    }
    return (
        <Dialog
            open={addEditEquipment.modal}
            onClose={toggleModalFn}
            className='console-management-modal'
            maxWidth="md"
        >
            <DialogTitle id="scroll-dialog-title">
                {equipment.id ? 'Edit ' : 'Add '} Equipment
            </DialogTitle>
            <DialogContent dividers={true}>
                <Grid container spacing={1}>
                    <Grid item md={6}>
                        <TextField
                            required
                            name='equipment'
                            label='Equipment'
                            value={equipment?.equipment || ''}
                            fullWidth
                            margin="normal"
                            onChange={(e) => onFieldChange('equipment', e.target.value)}
                            InputLabelProps={{ shrink: true }}
                            inputProps={{ maxLength: 100 }}
                            error={error?.equipment}
                            helperText={error?.equipment || ''}
                            onFocus={() => setError({ ...error, 'equipment': '' })}
                            variant='outlined'
                        />
                    </Grid>
                    <Grid item md={6}>
                        <TextField
                            name='part_number'
                            label='Part Number'
                            value={equipment?.part_number || ''}
                            fullWidth
                            margin="normal"
                            onChange={(e) => onFieldChange('part_number', e.target.value)}
                            InputLabelProps={{ shrink: true }}
                            inputProps={{ maxLength: 25 }}
                            variant='outlined'
                        />
                    </Grid>
                    <Grid item md={6}>
                        <TextField
                            name='serial_number'
                            label='Serial Number'
                            value={equipment?.serial_number || ''}
                            fullWidth
                            margin="normal"
                            onChange={(e) => onFieldChange('serial_number', e.target.value)}
                            InputLabelProps={{ shrink: true }}
                            inputProps={{ maxLength: 25 }}
                            variant='outlined'
                        />
                    </Grid>
                    <Grid item md={6}>
                        <TextField
                            name='manufacturer'
                            label='Manufacturer'
                            value={equipment?.manufacturer || ''}
                            fullWidth
                            margin="normal"
                            onChange={(e) => onFieldChange('manufacturer', e.target.value)}
                            InputLabelProps={{ shrink: true }}
                            inputProps={{ maxLength: 50 }}
                            variant='outlined'
                        />
                    </Grid>
                    <Grid item md={12}>
                        <TextField
                            name='equipment_type'
                            label='Equipment Type'
                            value={equipment?.equipment_type || ''}
                            fullWidth
                            margin="normal"
                            onChange={(e) => onFieldChange('equipment_type', e.target.value)}
                            InputLabelProps={{ shrink: true }}
                            inputProps={{ maxLength: 250 }}
                            variant='outlined'
                        />
                    </Grid>
                    <Grid item md={12}>
                        <TextField
                            name='remarks'
                            label='Remarks'
                            value={equipment?.remarks || ''}
                            fullWidth
                            multiline
                            rows={3}
                            margin="normal"
                            onChange={(e) => onFieldChange('remarks', e.target.value)}
                            InputLabelProps={{ shrink: true }}
                            inputProps={{ maxLength: 250 }}
                            variant='outlined'
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button disabled={isLoading} onClick={toggleModalFn} color='primary' size='small' variant='outlined'>Cancel</Button>
                <Button disabled={isLoading} onClick={onAddEditEquipment} color='primary' size='small' variant='contained'>
                    {isLoading ? <CircularProgress size={24} /> : 'SAVE'}
                </Button>
            </DialogActions>
        </Dialog>
    )
}
const Equipments = ({ params, assetInfoData }) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [isLoading, setLoading] = useState(false);
    const [skeletonLoader, setSkeletonLoader] = useState(false);
    const [filter, setFilter] = useState({});
    const [equipmentsInfo, setEquipmentsInfo] = useState({ list: [], pagination: null });
    const [addEditEquipment, setAddEditEquipment] = useState({ modal: false, data: null });
    const [deleteEquipment, setDeleteEquipment] = useState({ modal: false, data: null });
    useEffect(() => {
        getEquipments({}, 'skeletonLoader');
    }, []);
    const getEquipments = (query = {}, loaderType) => {
        loaderType === 'skeletonLoader' ? setSkeletonLoader(true) : setLoading(true)
        globalGetService(`console/v2/${params.type}/${params.aircraft_slug}/interior/cabin-equipments/`, query)
            .then(response => {
                if (checkApiStatus(response)) {
                    setEquipmentsInfo(response.data.data)
                }
                loaderType === 'skeletonLoader' ? setSkeletonLoader(false) : setLoading(false)
            })
    }
    const onDeleteEquipment = () => {
        setLoading(true);
        globalDeleteService(`console/v2/${params.type}/${params.aircraft_slug}/interior/cabin-equipments/${deleteEquipment.data.id}/`)
            .then(response => {
                if (checkApiStatus(response)) {
                    enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { horizontal: 'right', vertical: 'top' } });
                    setDeleteEquipment({ modal: false, data: null })
                    getEquipments({}, 'pageLoader');
                }
                setLoading(false);
            })
    }
    const onExportEquipment = () => {
        setLoading(true);
        globalExportService(`console/v2/aircraft/${params.aircraft_slug}/interior/cabin-equipments/?download=xls`)
            .then(response => {
                downloadFileType(response, `${assetTypeValues[assetInfoData?.asset_type.type].label}-${assetInfoData?.serial_number}_Equipments ${moment().format(backendDateFormat)}`, 'xlsx')
                trackActivity('Export', { page_title: 'Equipment', asset_type: 'MSN', asset_name: `${assetInfoData?.serial_number}`, event_type: 'Equipment Exported', event_desc: ``, file_extension: 'xlsx' })
                setLoading(false)
            });
    }
    return (
        <div style={{ padding: '10px' }}>
            {skeletonLoader ? <STableLoader count={6} /> :
                <>
                    <AddInteriorItem
                        addPermission={checkPermission('technical_specs', 'interior', 'C')}
                        exportPermission={checkPermission('technical_specs', 'interior', 'EXP')}
                        onAddItem={() => setAddEditEquipment({ modal: true, data: equipmentObj })}
                        item={equipmentsInfo}
                        isExport
                        isImport
                        onExport={() => onExportEquipment()}
                        getResponseBack={() => getEquipments({}, 'skeletonLoader')}
                        trackActivity={trackActivity('Import', { page_title: 'Equipment', asset_type: 'MSN', asset_name: `${assetInfoData?.serial_number}`, event_type: 'Equipment Imported', event_desc: ``, file_extension: 'xlsx' })}
                        url={`/console/v2/aircraft/${params.aircraft_slug}/interior/cabin-equipments/import/`}
                    />
                    <Paper style={{ maxHeight: window.innerHeight - 340 + 'px', overflow: "scroll" }}>
                        <Table className='mui-table-format' stickyHeader>
                            <EquipmentHd />
                            <TableBody>
                                {equipmentsInfo.list.map((item, index) =>
                                    <EquipmentList
                                        key={index}
                                        item={item}
                                        onEdit={() => setAddEditEquipment({ modal: true, data: item })}
                                        onDelete={() => setDeleteEquipment({ modal: true, data: item })}
                                    />
                                )}
                            </TableBody>
                        </Table>
                    </Paper>
                    {equipmentsInfo?.pagination ?
                        <Paper>
                            <Pagination
                                pagination={equipmentsInfo.pagination}
                                onChangePage={(event, newPage) => getEquipments({ ...filter, page: newPage + 1, per_page: equipmentsInfo.pagination.per_page }, 'pageLoader')}
                                onChangeRowsPerPage={(event) => getEquipments({ ...filter, page: 1, per_page: event.target.value }, 'pageLoader')}
                            />
                        </Paper>
                        : null
                    }
                    <Paper>
                        {!equipmentsInfo?.list?.length ? <div style={{ textAlign: 'center' }}><EmptyCollection title='No records found' /></div> : null}
                    </Paper>
                </>
            }

            {addEditEquipment.modal ?
                <AddEditEquipment
                    assetInfoData={assetInfoData}
                    params={params}
                    addEditEquipment={addEditEquipment}
                    toggleModalFn={() => setAddEditEquipment({ modal: false, data: null })}
                    getResponseBack={() => getEquipments({}, 'pageLoader')}
                /> : null
            }
            {deleteEquipment.modal ?
                <DeletePopUp
                    modal={deleteEquipment.modal}
                    title='Delete Equipment'
                    content={`Are you sure, you want to Delete?`}
                    toggleModalFn={() => setDeleteEquipment({ modal: false, data: null })}
                    deleteRecordFn={onDeleteEquipment}
                /> : null
            }
            {isLoading ? <PageLoader /> : null}
        </div>
    )
}
export default withRouter(Equipments);
