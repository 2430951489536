import React, { useState } from "react";
import { useSnackbar } from 'notistack';
import axios from 'axios'
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from '@material-ui/core';
import { DeletePopUp, EmptyCollection, PageLoader } from '../../shared_elements'
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import VerticalAlignBottomIcon from '@material-ui/icons/VerticalAlignBottom';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { globalDeleteService } from '../../../globalServices';
import { checkApiStatus, checkPermission } from '../../utils_v2';
const InventoryAttachments = ({inventoryAttachments, toggleModalFn, getResponseBack}) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [isLoading, setLoading] = useState(false);
    const [deleteAttachment, setDeleteAttachment] = useState({modal:false, data:null})
    const onDeleteAttachment = () => {
        setLoading(true)
        globalDeleteService(`console/attachments/inventory/${inventoryAttachments.data.id}/${deleteAttachment.data.uuid}/`)
        .then(response => {
            if(checkApiStatus(response)){
                enqueueSnackbar(response.data.message, { variant: 'success',anchorOrigin:{horizontal:'right', vertical:'top'}});
                setDeleteAttachment({modal:false, data:null});
                getResponseBack(deleteAttachment.data.uuid);
            }
            setLoading(false)
        })
    }
    const onDownloadAttachment = (url, fileName) => {
        axios.get(url, { responseType: 'arraybuffer', })
        .then(response => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', fileName);
          document.body.appendChild(link);
          link.click()
        })
        .catch(error => {})
    }
    return(
        <>
            <Dialog
                open={inventoryAttachments.modal}
                onClose={toggleModalFn}
                className='console-management-modal'
                maxWidth={'md'}
            >
                <DialogTitle id="scroll-dialog-title">
                    Attachment(s)
                </DialogTitle>
                <DialogContent dividers={true}>
                    <div style={{width:'420px'}}>
                        {inventoryAttachments?.data?.attachments?.length ?
                            <>
                                { inventoryAttachments.data.attachments.map((item, index) => 
                                    <div className="flex-centered" style={{borderBottom:'1px solid #d7d7d7'}}>
                                        {(() => {
                                            let fileInfo = item.name.split('_');
                                            fileInfo.pop();
                                            let fileName= `${fileInfo.join('_')}.${item.extension}`
                                            return(
                                                <>
                                                    <p style={{width:'80%'}} className="singleline-clamp"> {fileName}</p>
                                                    <ul style={{marginLeft:'auto', cursor:'pointer'}} className="list-inline">
                                                        <li className="list-inline-item" style={{display:'none'}}>
                                                            <VisibilityIcon fontSize="small" color='primary' />
                                                        </li>
                                                        <li className="list-inline-item">
                                                            <a onClick={() => onDownloadAttachment(item.url, fileName)} download={fileName}>
                                                                <VerticalAlignBottomIcon  fontSize="small" color='primary' />
                                                            </a>
                                                        </li>
                                                        { checkPermission('technical_specs', 'inventory', 'D') ?
                                                            <li className="list-inline-item">
                                                                <DeleteOutlineIcon onClick={() => setDeleteAttachment({modal:true, data:item})} fontSize="small" color='secondary' />
                                                            </li>:null
                                                        }
                                                    </ul>
                                                </>        
                                            )
                                        })()}
                                    </div>
                                )}
                            </>:
                            <div style={{textAlign:'center', padding:'15px 0'}}>
                                <EmptyCollection title='No Attachment Available' />
                            </div>
                        }
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={toggleModalFn} color="primary" size='small'>Close</Button>
                </DialogActions>
            </Dialog>
            {deleteAttachment.modal ?
                <DeletePopUp
                    modal={deleteAttachment.modal}
                    title='Delete Attachment'
                    content={`Are you sure, you want to Delete?`}
                    toggleModalFn={() => setDeleteAttachment({modal:false, data:null})}
                    deleteRecordFn={onDeleteAttachment}
                />:null
            }
            { isLoading ? <PageLoader />:null }
        </>
    )
}
export default InventoryAttachments;