import React, { useState } from "react";
import { Button, Container, Grid, InputAdornment, TextField } from "@material-ui/core";
import { Link, browserHistory, withRouter } from 'react-router';
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import SolutionLogoConst from "./SolutionLogoCont";
import { regexConstants } from '../../../constants/regEx'
import { useSnackbar } from 'notistack';
import { checkApiStatus } from "../../utils_v2";
import { globalPostService } from "../../../globalServices";

const ForgotPwdForm = ({ getLoaderResponse,isLoading }) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [userInfo, setUserInfo] = useState({ email: '' });
    const [error, setError] = useState({});
    const onAddEditEmail = (e) => {
        e.preventDefault();
        let validationInputs = {
            email: userInfo?.email?.trim()?.length ? regexConstants.email.test(userInfo.email) ? '' : 'Please enter valid Email Address' : 'Please enter Valid Email Address'
        }
        if (Object.keys(validationInputs).every((k) => { return validationInputs[k] === '' })) {
            getLoaderResponse(true)
            globalPostService('api/password/reset/', userInfo)
                .then((response) => {
                    getLoaderResponse(false)
                    if (checkApiStatus(response)) {
                        enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { horizontal: 'right', vertical: 'top' }, });
                        browserHistory.push('/login');
                    } else {
                        if (response.data.statusCode === 1008 ){
                        enqueueSnackbar("Please Enter Valid Credentials", { variant: 'error', anchorOrigin: { horizontal: 'right', vertical: 'top' }, });
                    }else{
                        enqueueSnackbar(response.data.message, { variant: 'error', anchorOrigin: { horizontal: 'right', vertical: 'top' }, });

                    }}
                })
        } else {
            setError(validationInputs)
        }
    }

    return (
        <>
            <SolutionLogoConst />
            <form className="console-forms user-auth-form" onSubmit={onAddEditEmail} noValidate>
                <div>
                    <Link to="/login" classname="back-icon" style={{ color:"#3f51b5" }}><ArrowBackIcon style={{ color: "#3f51b5", verticalAlign: 'top' }} />Back To Login</Link>
                </div>
                <h2 className="form-title">Request Reset Password</h2>
                <Grid container spacing={4} alignItems="center" style={{display:'inline-flex'}}>
                    <Grid item md={12} container >
                        <TextField
                            style={{ bottom: '5px' }}
                            type="email"
                            id="email"
                            label="Registered Email Address"
                            placeholder="johndoe@doe.com"
                            fullWidth
                            margin="normal"
                            value={userInfo.email}
                            error={error.email ? true : false}
                            helperText={error?.email || ''}
                            InputLabelProps={{ shrink: true }}
                            variant='outlined'
                            onChange={(e) => {
                                setUserInfo({ ...userInfo, email: e.target.value });
                                setError({ ...error, email: '' });
                            }}
                            onFocus={() => setError({ email: '' })}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <MailOutlineIcon color='action' style={{fontSize:'18px'}} />
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Grid>
                    <Grid item md={12} container >
                        <Button fullWidth type='submit' color='primary' size='medium' variant='contained'>{ isLoading ? "Processing..." : "Send Link" }</Button>
                    </Grid>
                </Grid>
            </form>
        </>
    )
}
export default withRouter(ForgotPwdForm);