import React, { useState, useEffect } from "react";
import { useSnackbar } from 'notistack';
import { withRouter } from 'react-router';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import moment from 'moment';
import { backendDateFormat, fieldDateFormat, displayDateFormat } from '../../../constants';
import { Grid, CircularProgress, Button, TextField, Dialog, DialogContent, DialogTitle, DialogActions, Tooltip } from '@material-ui/core';
import { globalDeleteService, globalGetService, globalPostService } from '../../../globalServices';
import { checkApiStatus, replaceEmptyNumber } from '../../utils_v2';
import { regexConstants } from "../../../constants/regEx";
import { Autocomplete } from "@material-ui/lab";
const AddLLP = ({ params, toggleModalFn, addLlp, getResponseBack }) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [llp, setLlp] = useState({});
    const [error, setError] = useState({});
    const [assetInfoData, setAssetInfoData] = useState({});
    const modules= [{label:'Fan',value:1},{label:'HPC',value:2},{label:'HPT',value:4},{label:'LPT',value:5},{label:'Gearboxes',value:6},{label:'Combustion',value:3},{label:'IPC',value:8},{label:'IPT',value:9},{label:'Compressor',value:10},{label:'Cold Section',value:11},{label:'Hot Section',value:12},{label:'Power Turbine',value:13},{label:'Others',value:7}]
    useEffect(()=>{
        getAssetInfo()
        if(['hpc','hpt','fan','lpt'].includes(params?.type)){
            setLlp({...llp,
                module: modules?.find(item=> item?.label?.toLowerCase() == params.type)
            }) 
        }
    },[])
    const getAssetInfo = () => {
        globalGetService(`console/v2/asset/${params.type}/${params.aircraft_slug}/asset_details/`)
        .then(response => {
            if(checkApiStatus(response)){
                setAssetInfoData(response.data.data);
            }            
        })
    }
    const onAddLlp = () => {
        let validationInputs = {
            module: llp?.module?.value || llp?.module?.trim().length > 0 ? '' : 'Please enter Module' ,
            description: llp?.description?.trim().length > 0 ? '' : '  Please enter Description',
            part_number: llp?.part_number?.trim().length > 0 ? '' : 'Please enter Part Number',
            as_of_date: moment(llp.as_of_date).isValid() ? moment(llp?.as_of_date).isSameOrBefore(moment(), 'day') ? '' : "LLP as of Date can't be future Date":'',
            cycles_remaining: llp.cycles_remaining ? llp.cycles_remaining >= 0 ? '' :"Cycle Remaining can't be negative value":''
            
        }
        if (Object.keys(validationInputs).every((k) => { return validationInputs[k] === '' })) {
            if (llp) {
                let payload = Object.assign({},llp)
                  payload = {...payload, module:llp?.module?.label ? llp?.module?.label : llp?.module, part_number:payload?.part_number || '', serial_number: payload.serial_number ||'0', tsn:payload.tsn ||'0', tso:payload.tso||'0', csn:payload.csn||'0', cso:payload.cso||'0' , cycles_max_limit:payload.cycles_max_limit||'0', cycles_remaining:payload.cycles_remaining||'0'}
                  if( !['engine','hpc','hpt','fan','lpt'].includes(params?.type)){
                    globalPostService(`console/${params.type}/${params.aircraft_slug}/llp/`, payload)
                    .then(response => {
                        if (checkApiStatus(response)) {
                            enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { horizontal: 'right', vertical: 'top' }, });
                            toggleModalFn(); getResponseBack();
                        }
                    })
                  }
                  else{
                    globalPostService(`console/${params.type}/${assetInfoData.id}/llp/`, payload)
                    .then(response => {
                        if (checkApiStatus(response)) {
                            enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { horizontal: 'right', vertical: 'top' }, });
                            toggleModalFn(); getResponseBack();
                        }
                    })
                  }
            }
        } else {
            setError(validationInputs)
        }
    }
    const onFieldChange = (key, value) => {    
        if (['apu', 'lg', 'engine'].includes(params?.type)&&(key === 'csn' || key  === 'cycles_max_limit')) {
            let itemData =  Object.assign({}, llp);
            if(key === 'csn' ){
                itemData = {
                    ...itemData,
                    [key]:value,
                    cycles_remaining: itemData?.cycles_max_limit ? itemData?.cycles_max_limit-value:'' 
                }
            }else{
                itemData = {
                    ...itemData,
                    [key]:value,
                    cycles_remaining: itemData?.csn ? value-itemData.csn:value 
                }
            }
            setLlp(itemData);
        } else {
            setLlp({...llp, [key]: value });
        }
    }
    return (
        <Dialog
            open={addLlp.modal}
            className='console-management-modal'
            maxWidth="md"
        >
            <DialogTitle id="scroll-dialog-title">
                Add LLP
            </DialogTitle>
            <DialogContent dividers={true}>
                <div>
                    <Grid container spacing={1}>
                        <Grid item md={4}>
                            { ['engine','hpc','hpt','fan','lpt'].includes(params?.type)?
                                <Autocomplete
                                options={modules}
                                getOptionLabel={option => option.label}
                                id="engine-module"
                                value={llp?.module || null}
                                onChange={(e, value) => onFieldChange('module', value)}
                                renderInput={params => <TextField required {...params} onFocus={() => setError({ ...error,'module' : '' })} error={error?.module} helperText={error?.module || ''} label="Module" margin="normal" fullWidth InputLabelProps={{ shrink: true }} variant='outlined' />}
                                disabled={['hpc','hpt','fan','lpt'].includes(params?.type) ? true:false}
                                />
                            :
                            <TextField
                                required
                                name='module'
                                label='Module'
                                value={llp?.module || ''}
                                fullWidth
                                margin="normal"
                                error={error?.module}
                                helperText={error?.module || ''}
                                onChange={(e) => onFieldChange('module', e.target.value)}
                                onFocus={() => setError({ ...error,'module' : '' })}
                                inputProps={{ maxLength: 15}}
                                InputLabelProps={{ shrink: true }}
                                variant='outlined'
                            />
                        }
                        </Grid>
                        <Grid item md={4}>
                            <TextField
                                required
                                name='description'
                                label='Description'
                                value={llp?.description || ''}
                                fullWidth
                                margin="normal"
                                error={error?.description}
                                helperText={error?.description || ''}
                                inputProps={{ maxLength: 50}}
                                onChange={(e) => onFieldChange('description', e.target.value)}
                                onFocus={() => setError({ ...error, 'description': '' })}
                                InputLabelProps={{ shrink: true }}
                                variant='outlined'
                            />
                        </Grid>
                        <Grid item md={4}>
                            <TextField
                                required
                                name='part_number'
                                label='Part Number'
                                value={llp?.part_number || ''}
                                fullWidth
                                margin="normal"
                                error={error?.part_number}
                                helperText={error?.part_number || ''}
                                inputProps={{ maxLength: 15}}
                                onChange={(e) => onFieldChange('part_number', e.target.value)}
                                onFocus={() => setError({ ...error, 'part_number': '' })}
                                InputLabelProps={{ shrink: true }}
                                variant='outlined'
                            />
                        </Grid>
                        <Grid item md={4}>
                            <TextField
                                name='serial_number'
                                label='Serial Number'
                                value={llp?.serial_number || ''}
                                fullWidth
                                inputProps={{ maxLength: 15}}
                                margin="normal"
                                onChange={(e) => onFieldChange('serial_number', e.target.value)}
                                InputLabelProps={{ shrink: true }}
                                variant='outlined'
                            />
                        </Grid>
                        <Grid item md={4}>
                            <TextField
                                name='tsn'
                                label='TSN'
                                value={llp?.tsn || ''}
                                fullWidth
                                inputProps={{ maxLength: 10}}
                                margin="normal"
                                onChange={(e) => regexConstants.numberWithDot.test(e.target.value) ? onFieldChange('tsn', e.target.value) : e.preventDefault()}
                                InputLabelProps={{ shrink: true }}
                                variant='outlined'
                            />
                        </Grid>
                        {['engine','hpc','hpt','fan','lpt'].includes(params?.type)? null:
                            <Grid item md={4}>
                            <TextField
                                name='tso'
                                label='TSO'
                                value={llp?.tso || ''}
                                fullWidth
                                inputProps={{ maxLength: 10}}
                                margin="normal"
                                onChange={(e) => regexConstants.numberWithDot.test(e.target.value) ? onFieldChange('tso', e.target.value) : e.preventDefault()}
                                InputLabelProps={{ shrink: true }}
                                variant='outlined'
                                />
                        </Grid>
                            }
                        <Grid item md={4}>
                            <TextField
                                name='csn'
                                label='CSN'
                                inputProps={{ maxLength: 10}}
                                value={llp?.csn || ''}
                                fullWidth
                                margin="normal"
                                onChange={(e) => regexConstants.onlyNumeric.test(e.target.value) ? onFieldChange('csn', e.target.value) : e.preventDefault()}
                                InputLabelProps={{ shrink: true }}
                                variant='outlined'
                            />
                        </Grid>
                        {['engine','hpc','hpt','fan','lpt'].includes(params?.type)? null:
                        <Grid item md={4}>
                            <TextField
                                name='cso'
                                label='CSO'
                                value={llp?.cso || ''}
                                fullWidth
                                margin="normal"
                                inputProps={{ maxLength: 10}}
                                onChange={(e) => regexConstants.onlyNumeric.test(e.target.value) ? onFieldChange('cso', e.target.value) : e.preventDefault()}
                                InputLabelProps={{ shrink: true }}
                                variant='outlined'
                            />
                        </Grid>}
                        <Grid item md={4}>
                            <TextField
                                name='cycles_max_limit'
                                label={['propeller'].includes(params?.type)? 'Interval Hours':'Cycle Limit'}
                                value={llp?.cycles_max_limit || ''}
                                fullWidth
                                inputProps={{ maxLength: 10}}
                                margin="normal"
                                onChange={(e) =>(['propeller'].includes(params?.type)? regexConstants.numberWithDot.test(e.target.value): regexConstants.numberNoSpace.test(e.target.value)) ? onFieldChange('cycles_max_limit', e.target.value) : e.preventDefault()}
                                InputLabelProps={{ shrink: true }}
                                variant='outlined'
                            />
                        </Grid>
                        <Grid item md={4}>
                            <TextField
                                name='cycles_remaining'
                                label={['propeller'].includes(params?.type)? 'Remaining Hours' : 'Remaining Cycle'}
                                value={llp?.cycles_remaining || ''}
                                fullWidth
                                margin="normal"
                                inputProps={{ maxLength: 10}}
                                disabled={['engine'].includes(params?.type)}
                                error={error?.cycles_remaining}
                                helperText={error?.cycles_remaining || ''}
                                onChange={(e) => (['propeller'].includes(params?.type)? regexConstants.numberWithDot.test(e.target.value): regexConstants.numberNoSpace.test(e.target.value)) ? onFieldChange('cycles_remaining', e.target.value) : e.preventDefault()}
                                onFocus={() => setError({ ...error, 'cycles_remaining': '' })}
                                InputLabelProps={{ shrink: true }}
                                variant='outlined'
                            />
                        </Grid>
                        <Grid item md={4}>
                            <MuiPickersUtilsProvider utils={MomentUtils}>
                                <KeyboardDatePicker
                                    margin="normal"
                                    name="as_of_date"
                                    label="LLP as of Date"
                                    format={fieldDateFormat}
                                    fullWidth
                                    disableFuture
                                    InputLabelProps={{ shrink: true }}
                                    value={llp?.as_of_date || null}
                                    onChange={(data, value) => { onFieldChange('as_of_date', moment(data).isValid() ? moment(data).format(backendDateFormat) : null); setError({ ...error, 'sheet_date': '' }) }}
                                    inputVariant='outlined'
                                    error={error.as_of_date ? true : false}
                                    helperText={error.as_of_date || ''}
                                    onFocus={() => setError({ ...error, 'as_of_date': '' })}

                                />
                            </MuiPickersUtilsProvider>
                        </Grid>
                        {['engine','hpc','hpt','fan','lpt'].includes(params?.type)? 
                             <Grid item md={4}>
                             <TextField
                                 name='remarks'
                                 label='Remarks'
                                 value={llp?.remarks || ''}
                                 fullWidth
                                 margin="normal"
                                 onChange={(e) => onFieldChange('remarks', e.target.value)}
                                 inputProps={{ maxLength: 15}}
                                 InputLabelProps={{ shrink: true }}
                                 variant='outlined'
                             />
                         </Grid>
                         :null}
                    </Grid>
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={toggleModalFn} color='primary' size='small' variant='outlined'>Cancel</Button>
                <Button onClick={onAddLlp} color='primary' size='small' variant='contained'>Save</Button>
            </DialogActions>
        </Dialog>)
}
export default withRouter(AddLLP);