import React, { useEffect, useState } from 'react'
import { TableCell, TableHead, TableRow, Table, TableBody, Paper, Grid, Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import { EmptyCollection, FilterComponent, PageLoader, Pagination, STableLoader } from '../../shared_elements';
import { notificationFilter } from '..';
import { globalGetService, globalPostService } from '../../../globalServices';
import { checkApiStatus } from '../../utils_v2';
import moment from 'moment';
import { getNotificationBarDataAc } from '../../../shared/actionCreators';
import { connect } from 'react-redux';

function Notifications({ dispatch }) {
    const [filter, setFilter] = useState({});
    const [isLoading, setLoader] = useState(false);
    const [pageLoader, setPageLoader] = useState(false);
    const [detailsModal, setDetailsModal] = useState({ modal: false });
    const [notificationList, setNotificationList] = useState({});

    useEffect(() => {
        getNotificationList({ per_page: 20 }, 'skLoader')
    }, [])
    const getNotificationList = (query, loaderType) => {
        if (loaderType == 'pageLoader') {
            setPageLoader(true)
        }
        else if (loaderType == 'skLoader') {
            setLoader(true)
        }
        globalGetService(`notifications/`, query)
            .then(response => {
                setPageLoader(false)
                setLoader(false)
                if (checkApiStatus(response)) {
                    setNotificationList(response.data.data)
                }
                if (query) {
                    delete query.page
                    delete query.per_page
                    setFilter(query)
                }
            })
    }
    const openDetailsModal = (data) => {
        if (data?.status !== 1) {
            markNotificaitonRead(data)
        }
        setDetailsModal({ data: data, modal: true })
    }
    const markNotificaitonRead = (data) => {
        globalPostService(`/notifications/edit/`, { notification_id: data?.notification_id, status: 1 })
            .then(response => {
                if (checkApiStatus(response)) {
                    dispatch(getNotificationBarDataAc())
                    getNotificationList({ ...filter, page: 1, per_page: notificationList.pagination.per_page })
                }
            })
    }
    const markAllRead = () => {
        setPageLoader(true)
        globalPostService(`/notifications/edit/`, { mark_all_read: true })
            .then(response => {
                setPageLoader(false)
                if (checkApiStatus(response)) {
                    dispatch(getNotificationBarDataAc())
                    getNotificationList({ ...filter, page: 1, per_page: notificationList.pagination.per_page })
                }
            })
    }
    const toggleModal = () => {
        setDetailsModal({ modal: false })
    }
    let filterOptions = Object.assign({}, notificationFilter);
    let multiNotification = notificationList?.notifications?.filter(item => item.status != 1).length
    return (
        <section className='whats-new-container'>
            <div className='faq-header notification-header'>Notifications
            </div>
            <div className='notifications-body'>
                {
                    multiNotification ?
                        <div className='mark-all-read' onClick={markAllRead}>
                            Mark all as Read
                        </div>
                        : null}
                <Paper className='major-assemblies-list'>
                    <div style={{ height: multiNotification ? `${window.innerHeight - 220}px` : `${window.innerHeight - 190}px`, overflow: 'auto' }}>
                        <FilterComponent
                            filter={filter}
                            filterMenu={filterOptions}
                            getResponseBack={(applyFilter) => getNotificationList({ ...applyFilter, page: 1, per_page: notificationList.pagination.per_page }, 'pageLoader')}
                        />
                        <NotificationList
                            notificationList={notificationList}
                            openDetailsModal={openDetailsModal}
                        />

                        {
                            isLoading ?
                                <STableLoader count={4} />
                                :
                                !notificationList?.notifications?.length ?
                                    <Paper style={{ textAlign: 'center' }}>
                                        <EmptyCollection
                                            title={'No records found'}
                                        />
                                    </Paper>
                                    : null
                        }
                        {
                            pageLoader ? <PageLoader /> : null
                        }
                    </div>
                    <Pagination
                        pagination={notificationList.pagination}
                        onChangePage={(event, newPage) => getNotificationList({ ...filter, page: newPage + 1, per_page: notificationList.pagination.per_page, }, 'pageLoader')}
                        onChangeRowsPerPage={(event) => getNotificationList({ ...filter, page: 1, per_page: event.target.value }, 'pageLoader')}
                    />
                </Paper>
            </div>
            {
                detailsModal?.modal ?
                    <DetailsModal
                        detailsModal={detailsModal}
                        toggleModal={toggleModal}
                    /> : null
            }
        </section>
    )
}
const NotificationList = ({ notificationList, openDetailsModal }) => {
    return (
        <Table className='mui-table-format'>
            <TableHead>
                <TableRow>
                    <TableCell> Type </TableCell>
                    <TableCell> Subject </TableCell>
                    <TableCell> Date </TableCell>
                    <TableCell> Status </TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {
                    notificationList?.notifications?.map(listItem => (
                        <TableRow onClick={() => openDetailsModal(listItem)}>
                            <TableCell className={listItem?.status == 1 ? 'read' : 'unread'}>
                                <span> {listItem?.notification_type?.label || '--'}</span>
                            </TableCell>
                            <TableCell className={listItem?.status == 1 ? 'read' : 'unread'}>
                                <span>  {listItem?.subject || listItem?.notification_type?.label || '--'}</span>
                                <span className="read-more-link"> Read More</span>
                            </TableCell>
                            <TableCell className={listItem?.status == 1 ? 'read' : 'unread'}>
                                <span>{listItem?.created_at ? moment(listItem?.created_at).format('MMM DD, YYYY hh:mm A') : '--'}</span>
                            </TableCell>
                            <TableCell className={listItem?.status == 1 ? 'read' : 'read-more-link'}>
                                <span style={{ fontSize: '12px' }}> {listItem?.status == 1 ? 'Read' : 'Mark as Read' || '--'}</span>
                            </TableCell>
                        </TableRow>
                    ))
                }
            </TableBody>
        </Table>
    )
}
const DetailsModal = ({ detailsModal, toggleModal }) => {
    return (
        <Dialog maxWidth='md' open='true' onClose={() => toggleModal()} className='ntfcn-dtls-mdl'>
            <DialogTitle>  {detailsModal?.data?.notification_type?.label} </DialogTitle>
            <DialogContent dividers style={{ width: "700px" }}>
                <div dangerouslySetInnerHTML={{ __html: detailsModal?.data?.description }} ></div>
            </DialogContent>
            <DialogActions dividers >
                <Button variant='outlined' color='primary' size='small' onClick={() => toggleModal()} >Close</Button>
            </DialogActions>
        </Dialog>
    )
}
export default connect()(Notifications)