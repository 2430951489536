export const createUserObj = {
    description:'',
    email:'',
    contact_no:'',
    first_name:'',
    last_name:'',
    reason_for_creation:'',
    designation:'',
    organization:'',
    security_groups:[],
    user_role:1
}
export const userFilters = {
    'search':{
        'inputType': 'text',
        'title': 'By Name',
    },
    'filter_type':{
        'inputType': 'dropdown',
        'title': 'Status',
        'options':[{label:'Active',value:'active'},{label:'Deactivated',value:'inactive'},{label:'Invitation Sent',value:'invitation-sent'}],
        'labelKey':'label',
        'valueKey':'value'        
    }
}