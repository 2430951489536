import React, { useState, useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { Button, Dialog, DialogContent, DialogTitle, DialogActions, Grid, TextField, CircularProgress, Table, TableHead, TableBody, TableCell, TableRow } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { globalGetService, globalPostService, globalPutService } from '../../../globalServices';
import { checkApiStatus } from '../../utils_v2';
const AddEditRoleProfile = ({addEditRoleProfile, getResponseBack, toggleModalFn}) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [isLoading, setLoading] = useState(false);
    const [error, setError] = useState({});
    const [roleProfileCrud, setRoleProfileCrud] = useState(addEditRoleProfile.data ? addEditRoleProfile.data:{name:'', description:''})
    const onChangeField = (key, value) => {
        setRoleProfileCrud({...roleProfileCrud, [key]:value})
    }
    const onAddEditRoleProfile = () => {
        let validationInputs = {};
        validationInputs = {
            name: roleProfileCrud?.name?.trim().length ? '':'Please enter Profile Type'
        }
        if(Object.keys(validationInputs).every((k) => { return validationInputs[k] === ''})){
            setLoading(true);
            if(roleProfileCrud.id){
                globalPutService(`console/group_profile/${roleProfileCrud.id}/`, {name:roleProfileCrud.name, description:roleProfileCrud.description})
                .then(response => {
                    if(checkApiStatus(response)){
                        enqueueSnackbar(response.data.message, { variant: 'success',anchorOrigin:{horizontal:'right', vertical:'top'}});
                        toggleModalFn();
                        getResponseBack();
                    }else{
                        // enqueueSnackbar(response.data.message, { variant: 'error',anchorOrigin:{horizontal:'right', vertical:'top'}});
                    }
                    setLoading(false);
                })
            }else{
                globalPostService(`console/group_profile/`, roleProfileCrud)
                .then(response => {
                    if(checkApiStatus(response)){
                        enqueueSnackbar(response.data.message, { variant: 'success',anchorOrigin:{horizontal:'right', vertical:'top'}});
                        toggleModalFn();
                        getResponseBack();
                    }else{
                        // enqueueSnackbar(response.data.message, { variant: 'error',anchorOrigin:{horizontal:'right', vertical:'top'}});
                    }
                    setLoading(false);
                })
            }
        }else{
            setError(validationInputs);
        }
    }
    return(
        <Dialog
            open={addEditRoleProfile.modal}
            onClose={toggleModalFn}
            className='console-management-modal add-edit-role-modal'
            maxWidth="lg"
        >
            <DialogTitle id="scroll-dialog-title">
                {roleProfileCrud?.id ? addEditRoleProfile?.mode === 'view' ?'View ':'Edit ':'Add '} Profile Type
            </DialogTitle>
            <DialogContent dividers={true}>
                <div>
                    <Grid container spacing={1}>
                        <Grid item md={12}>
                            <TextField 
                                disabled={addEditRoleProfile.mode === 'view'}
                                required
                                id='title'
                                label='Profile Type'
                                value={roleProfileCrud?.name||''}
                                fullWidth
                                margin="normal"
                                error={error?.name} 
                                helperText={error?.name||''}
                                onChange={(e) => onChangeField('name', e.target.value)}
                                InputLabelProps={{shrink: true}}
                                onFocus={() => setError({...error, 'name':''})}
                                variant='outlined'
                            />
                        </Grid>
                        <Grid item md={12}>
                            <TextField 
                                disabled={addEditRoleProfile.mode === 'view'}
                                id='description'
                                label='Description'
                                value={roleProfileCrud?.description||''}
                                fullWidth
                                margin="normal"
                                onChange={(e) => onChangeField('description', e.target.value)}
                                InputLabelProps={{shrink: true}}
                                variant='outlined'
                            />
                        </Grid>
                    </Grid>
                    { addEditRoleProfile.mode === 'view' && addEditRoleProfile.data.sparta_groups.length?
                        <>
                            <p style={{margin:'5px 0'}}>Role(s) linked to this Profile Type:</p>
                            <Table className='mui-table-format'>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Role Name</TableCell>
                                        <TableCell>Application</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {addEditRoleProfile.data.sparta_groups.map((group) =>
                                        <TableRow>
                                            <TableCell>{group.name}</TableCell>
                                            <TableCell>{group?.application?.name||'--'}</TableCell>
                                        </TableRow>
                                    )}
                                    
                                </TableBody>
                            </Table>
                        </>:null
                    }
                    
                </div>
            </DialogContent>
            <DialogActions>
                <Button disabled={isLoading} onClick={toggleModalFn} color='primary' size='small' variant='outlined'>Cancel</Button>
                { addEditRoleProfile?.mode !== 'view' ?
                    <Button disabled={isLoading} onClick={onAddEditRoleProfile} color='primary' size='small' variant='contained'>
                        {isLoading ? <CircularProgress size={20} />:'Save'}
                    </Button>:null
                }
            </DialogActions>
        </Dialog>
    )
}
export default AddEditRoleProfile;