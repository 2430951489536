import React, { useState } from "react";
import { Box, TextField, Button, Grid, IconButton } from "@material-ui/core";
import { useSnackbar } from 'notistack';
import { globalPostService, globalGetService } from "../../../globalServices";
import { browserHistory } from "react-router";
import { eraseGlobalCookie } from "../../../utils";
import { checkApiStatus } from "../../utils_v2";
import { PageLoader } from "../../shared_elements";
import CloseIcon from '@material-ui/icons/Close';



const LoginAuth = ({ userResponse, getResponseBack, setOtpModal, authType }) => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [otp, setOtp] = useState(["", "", "", ""]);
  const [isLoading, setLoading] = useState(false);

  const handleClose = () => setOtpModal(false);

  const handleChange = (e, index) => {
    let value = e.target?.value;
    if (!/^\d*$/.test(value)) {
      return;
    }
    setOtp(prevOtp => {
      const newOtp = [...prevOtp];
      if (newOtp[index] && value) {
        return newOtp;
      }
      if (e.nativeEvent?.inputType === "deleteContentBackward" || e.nativeEvent?.inputType === "deleteContentForward") {
        newOtp[index] = "";
        const prevInput = document.getElementById(`otp-${index - 1}`);
        if (prevInput) {
          prevInput.focus();
        }
      } else {
        newOtp[index] = value;
        if (e.target?.value.length === 1 && index < otp.length - 1) {
          const nextInput = document.getElementById(`otp-${index + 1}`);
          nextInput.focus();
        }
      }
      return newOtp;
    });
    if (index === otp.length - 1 && e.target?.value.length === 1) {
      e.target.blur();
    }
  }

  const handlePaste = (e) => {
    e.preventDefault();
    const clipboardData = e.clipboardData || window?.clipboardData;
    const pastedData = clipboardData.getData('text').trim().slice(0, 4);
    const newOtpValues = [...otp];
    for (let i = 0; i < Math.min(pastedData.length, 4); i++) {
      newOtpValues[i] = pastedData[i];
    }
    setOtp(newOtpValues);
  };


  const submitOtp = (e,) => {
    e.preventDefault();
    const enteredOtp = otp.join("");
    if (enteredOtp === "") {
      enqueueSnackbar("Please enter the OTP", { variant: 'error', anchorOrigin: { horizontal: 'right', vertical: 'top' }, });
    } else {
      let apiUrl = authType === 'enabled' ? '/api/two-factor-auth/verify/' : '/api/two-factor-auth/verify_disable_otp/'
      globalPostService(apiUrl, { "two_f_auth_otp": enteredOtp, accessToken: userResponse.access })
        .then(response => {
          setLoading(false)
          if (checkApiStatus(response)) {
            setLoading(true)
            enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { horizontal: 'right', vertical: 'top' } });
            getResponseBack();
          } else {
            // enqueueSnackbar(response.data.message, { variant: 'error', anchorOrigin: { horizontal: 'right', vertical: 'top' }, });
            setOtp(["", "", "", ""]);
          }
        })
    }
  }

  const resendOtp = (e) => {
    e.preventDefault();
    globalGetService('/api/two-factor-auth/reset/', { accessToken: userResponse.access })
      .then(response => {
        if (checkApiStatus(response)) {
          enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { horizontal: 'right', vertical: 'top' }, });
        }
      })
  }

  return (
    <>
      <Box className="modal-style" >
        <Grid container direction="column" item xs align="right" >
          <Grid item >
            <IconButton style={{ left: '15px', bottom: '15px' }} onClick={handleClose}>{authType === 'disabled' && <CloseIcon />}</IconButton>
          </Grid>
        </Grid>

        <h4 className="form-title" style={{ textAlign: 'center', margin: '10px' }}>{userResponse?.user?.two_f_auth_enabled === true ? <span>You are signed in to SPARTA!</span> : <span>Disable Two-Factor Authentication</span>}</h4>
        <p style={{ top: '30px', textAlign: 'center', fontFamily: "Conv_IBMPlexSans-Regular", fontSize: '13px' }}>Please Enter the  OTP to Verify your Account</p>
        <div style={{ display: 'flex', justifyContent: 'center' }} >
          {otp.map((value, index) => (
            <TextField
              id={`otp-${index}`}
              key={index}
              variant="outlined"
              margin="normal"
              onChange={(e) => handleChange(e, index)}
              value={value}
              autoFocus={index === 0}
              style={{ padding: 4 }}
              className="otp-login-auth"
              onPaste={(e) => handlePaste(e)}
            />
          ))}
        </div>
        <p className="span-otp">
          OTP not received yet? <a onClick={resendOtp} style={{ color: "#2a2adc", cursor: 'pointer', fontSize: "11px", textDecoration: 'underline' }}>Resend OTP</a>
        </p>
        <div style={{ textAlign: 'center', paddingBottom: '30px' }}>
          <Button onClick={submitOtp} type='submit' color='primary' size='medium' variant='contained' style={{ width: '180px' }}> {isLoading ? "Verifying.." : "Verify"}</Button>
        </div>
      </Box>
      {isLoading ? <PageLoader /> : null}
    </>

  )
}
export default LoginAuth