import React from 'react';
import { TableCell, TableRow, Checkbox, Tooltip } from '@material-ui/core';
import { assetTypeLabel } from '../';
import { checkPermission } from '../../../console_management/utils_v2';
const AssetList = ({item, selectedAsset, onSelectAsset}) => {
    return(
        <TableRow>
            <TableCell>
                { checkPermission('reports','bulk_download','EXP') ?
                <Checkbox
                    checked={selectedAsset.find(asset => asset.asset_type == item.asset_type && asset.id == item.id) ? true : false} 
                    onChange={(e) => onSelectAsset(e, item)} 
                    color="primary" 
                    size="small" 
                /> : null }
            </TableCell>
            <TableCell>
                <div className='airframe-info'>
                    {item?.aircraft_type?.name ?<p>{item?.aircraft_type?.name||''} {item.model ? `/${item.model}`:''}</p>:null}
                    {item?.engine_type?.name ?<p>{item?.engine_type?.name||''}</p>:null}
                    {item?.apu_type?.name ?<p>{item?.apu_type?.name||''}</p>:null}
                    <h4>{`${assetTypeLabel[item.asset_type]} ${item.asset_name}`}</h4>
                </div>
            </TableCell>
            <TableCell>
                <div>
                    {item?.lessee?.logo ?
                        <img style={{ width: '60px' }} src={item?.lessee?.logo} alt="Lessee logo"></img> :
                        <p className='para-text'>{item?.lessee?.name || '--'}</p>
                    }
                </div>
            </TableCell>
            <TableCell>
                <div style={{width:'100px'}}>
                    {item?.aircraft_type ? <Tooltip title={item?.country ? item?.country : '--'} placement="top" ><p className='para-text'>{item?.current_registration_number || '--'}</p></Tooltip> : '--'}
                </div>
            </TableCell>
        </TableRow>
    )
}
export default AssetList;