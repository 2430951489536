import React from "react";
import { Link } from 'react-router';
import spartaLogo from '../../assets/images/sparta-logo.png';
import { Button, Grid, Container } from "@material-ui/core";

const SolutionLogoConst = ({ }) => {
    return (
        <div style={{padding:'15px'}}>
            <Grid container spacing={1}>
                <Grid item xs={6} alignItems="center">
                    <Link to='/'>
                        <img className="sparta-logo" src={spartaLogo} alt="sparta-logo" />
                    </Link>
                </Grid>
                <Grid item xs={6} style={{textAlign:'right'}}>
                    <span><Link to="/solutions"><Button className='solution-btn' color='primary' size='small' variant='outlined'>Solutions</Button></Link></span>
                </Grid>
            </Grid>
        </div>
        
    )
}
export default SolutionLogoConst;