import React, { useState, useEffect } from "react";
import { withRouter } from 'react-router';
import { useSnackbar } from 'notistack';
import { Button, TextField, Paper, Dialog, DialogContent, DialogTitle, DialogActions, FormControlLabel, Checkbox, Table, TableHead, TableRow, TableCell, TableBody, Tooltip, CircularProgress } from "@material-ui/core";
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import { DeletePopUp, STableLoader, PageLoader, EmptyCollection } from "../../shared_elements";
import { globalGetService, globalPostService, globalDeleteService } from '../../../globalServices';
import { checkApiStatus, checkPermission, checkPermissionHierarchy, getLocalStorageInfo } from '../../utils_v2';
const AddAssets = ({addAssets, onAssetSelection, onAssignUserAssets, toggleModalFn}) => {
    const [search, setSearch] = useState('')
    return(
        <Dialog
            open={addAssets.modal}
            onClose={toggleModalFn}
            className='console-management-modal'
            maxWidth="lg"
        >
            <DialogTitle id="scroll-dialog-title">
                Add Asset(s)
                <div className="console-forms-fields">
                    <TextField 
                        placeholder="Search for Asset(s)"
                        id='search'
                        value={search}
                        fullWidth
                        margin="normal"
                        onChange={(e) => setSearch(e.target.value)}
                        InputLabelProps={{shrink: true}}
                        variant='outlined'
                    />   
                </div>
            </DialogTitle>
            <DialogContent dividers={true}>
                <div style={{width:'380px'}}>
                    {(() => {
                        let assetList = search?.trim().length ? addAssets.data.filter(assetItem => assetItem.asset_name.toLowerCase().includes(search.toLowerCase())):addAssets.data;
                        return(
                            <>
                                {assetList.map((asset, index) => 
                                    <div key={index}>
                                        <FormControlLabel
                                            value="end"
                                            control={<Checkbox onChange={(e) => onAssetSelection(e.target.checked, asset)} checked={addAssets.selected.map(item => item.asset_name).includes(asset.asset_name)} size='small' color="primary" />}
                                            label={asset.asset_name}
                                            labelPlacement="end"
                                        />
                                    </div>
                                )}
                                {!assetList.length ? 
                                    <div style={{textAlign:'center'}}> 
                                        <EmptyCollection title="No records found" />
                                    </div>:null
                                }
                            </>
                        )
                    })()}       
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={toggleModalFn} color="primary" size="small" variant="outlined">Cancel</Button>
                {addAssets.selected.length ?
                    <Button onClick={onAssignUserAssets} color="primary" size="small" variant="contained">Save</Button>:null
                }
            </DialogActions>
        </Dialog>
    )
}
const UserAssets = ({params, userInfo}) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [isLoading, setLoading] = useState(false);
    const [skeletonLoader, setSkeletonLoader] = useState(false);
    const [isFetching, setFetching] = useState(false);
    const [addAssets, setAddAssets] = useState({modal:false, selected:[], data:[]});
    const [deleteAssets, setDeleteAssets] = useState({modal:false, data:null});
    const [userAssets, setUserAssets] = useState([]);
    useEffect(() => {
        getUserAssets('skeletonLoader');
    },[]);
    const onAssetSelection = (flag, asset) => {
        if(flag){
            setAddAssets({...addAssets, selected:[...addAssets.selected, asset]})
        }else{
            setAddAssets({...addAssets, selected:addAssets.selected.filter(item => item.asset_name !== asset.asset_name)})
        }
    }
    const getAllAssets = () => {
        setFetching(true);
        globalGetService(`console/asset-access-list/user/${params.slug}/add-asset/`)
        .then(response => {
            if(checkApiStatus(response)){
                setAddAssets({modal:true, selected:[],data:response.data.data})
            }
            setFetching(false);
        })
    }
    const getUserAssets = (loaderType) => {
        loaderType === 'skeletonLoader' ? setSkeletonLoader(true) :setLoading(true);
        globalGetService(`console/asset-access-list/user/${params.slug}/`)
        .then(response => {
            if(checkApiStatus(response)){
                setUserAssets(response.data.data);
            }
            loaderType === 'skeletonLoader' ? setSkeletonLoader(false) :setLoading(false);
        })
    }
    const onAssignUserAssets = () => {
        setLoading(true)
        globalPostService(`console/asset-access-list/user/${params.slug}/`, {asset_list:addAssets.selected})
        .then(response => {
            if(checkApiStatus(response)){
                setAddAssets({modal:false, selected:[],data:[]});
                enqueueSnackbar(response.data.message, { variant: 'success',anchorOrigin:{horizontal:'right', vertical:'top'}});
                getUserAssets('pageLoader');
            }else{
                // enqueueSnackbar(response.data.message, { variant: 'error',anchorOrigin:{horizontal:'right', vertical:'top'}});
            }
            setLoading(false)
        })
    }
    const onDeleteAssets = () => {
        setLoading(true)
        globalDeleteService(`console/asset-access-list/user/${params.slug}/`, {ids:[deleteAssets.data.id]})
        .then(response => {
            if(checkApiStatus(response)){
                setDeleteAssets({modal:false, data:null})
                enqueueSnackbar(response.data.message, { variant: 'success',anchorOrigin:{horizontal:'right', vertical:'top'}});
                getUserAssets('pageLoader');
            }else{
                // enqueueSnackbar(response.data.message, { variant: 'error',anchorOrigin:{horizontal:'right', vertical:'top'}});   
            }
            setLoading(false)
        })
    }
    const loggedUserId = getLocalStorageInfo()?.user?.id || null;
    return(
        <div>
            { (userInfo && Object.keys(userInfo).length && loggedUserId !== userInfo.id) && checkPermission('user_profile','allowed_asset','U') && checkPermissionHierarchy(userInfo) ?
                <Button disabled={isFetching} style={{marginBottom:'10px'}} onClick={() => getAllAssets()} color="primary" size="small" variant="contained">
                    { isFetching ? <CircularProgress size={20} />:'Add Asset(s)'}
                </Button>:null
            }
            
            <Paper>
                { skeletonLoader ? <STableLoader count={2} />:
                    <>
                        <Table className='mui-table-format margin-bottom40'>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Asset(s)</TableCell>
                                    {(userInfo && Object.keys(userInfo).length && loggedUserId !== userInfo.id) && checkPermission('user_profile','allowed_asset','D') && checkPermissionHierarchy(userInfo) ?
                                        <TableCell align="right">Action</TableCell>:null
                                    }
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {userAssets.map((asset, index) => 
                                    <TableRow key={index}>
                                        <TableCell>{asset.asset_name}</TableCell>
                                        {(userInfo && Object.keys(userInfo).length && loggedUserId !== userInfo.id) && checkPermission('user_profile','allowed_asset','D') && checkPermissionHierarchy(userInfo) ?
                                            <TableCell align="right">
                                                <Tooltip title='Remove Asset' arrow><DeleteOutlineIcon onClick={() => setDeleteAssets({modal:true, data:asset})} size='small' color="secondary" /></Tooltip>
                                            </TableCell>:null
                                        }    
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                        { !userAssets.length ? <div style={{textAlign:'center'}}><EmptyCollection title='No records found' /></div>:null}
                    </>
                }
            </Paper>
            { addAssets.modal ?
                <AddAssets 
                    addAssets={addAssets} 
                    onAssetSelection={onAssetSelection} 
                    onAssignUserAssets={onAssignUserAssets} 
                    toggleModalFn={() =>setAddAssets({modal:false, data:[]})}
                />:null
            }
            { deleteAssets.modal ?
                <DeletePopUp
                    modal={deleteAssets.modal}
                    title='Remove Asset'
                    content={`Are you sure, you want to Asset?`}
                    toggleModalFn={() => setDeleteAssets({modal:false, data:null})}
                    deleteRecordFn={onDeleteAssets}
                    confirmText="Remove"
                />:null
            }
            {isLoading ? <PageLoader />:null}
        </div>
    )
}
export default withRouter(UserAssets);