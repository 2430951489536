import React from "react";
import { Container, Grid } from "@material-ui/core";
import { StaticBanner } from '../components'
import config from '../../../config'
const DataPolicy= ({}) => {
    return(
        <section className="data-policy-section">
            <StaticBanner pageTitle='Data Privacy Policy' />
            <Container maxWidth='md'>
                <Grid container spacing={1}>
                    <Grid item md={12}>
                        <br/>
                        <br/>
                    <p>Acumen Aviation (“we”, “us”, “our”) is/are classified as a “Controller” under the General Data Protection Regulation ((EU) 2016/679) (“GDPR“) and any data protection legislation from time to time in force in Ireland including the Data Protection Acts 1988 & 2003 and/or any successor legislation in respect of certain personal data you furnish to us. This Privacy Policy is meant to help you understand what personal data we collect about you, why we collect it, and what we do with it. Please read the following carefully to understand our views and practices regarding your personal data and how we will treat it.</p>
                    <div>
                        <h3>What is personal data?</h3>
                        <p>“Personal data”is any information relating to an identified or identifiable natural person; an identifiable natural person is one who can be identified, directly or indirectly, in particular by reference to an identifier such as a name, an identification number, location data, an online identifier such as a user IP addresses or to one or more factors specific to the physical, physiological, genetic, mental, economic, cultural or social identity of that natural person.</p>
                    </div>
                    <div>
                        <h3>Personal data collected and processed</h3>
                        <p>
                        We may collect and process the following data:
                        </p>
                        <ul>
                            <li>Information that you provide by filling in forms on this site <a href={`${config.domain.subDomian}`}>sparta.aero</a> such as your name, email address, job title, company and location. This includes information provided at the time of registering to use any section of this website;</li>
                            <li>Details of your visits to this site and the resources that you access; and</li>
                            <li>Any personal data which you may send to Acumen Aviation via email (such as CV’s and other recruitment-related personal data).</li>
                        </ul>   
                    </div>  
                    <div>
                        <h3>Technical data collected</h3>
                        <p>When you visit this website, third parties engaged by us may gather statistical and other analytical information collected on an aggregate basis of all visitors to this website. We may receive this information on request. This “Non-Personal Data” comprises information that cannot be used to identify or contact you, such as demographic information regarding, for example, user IP addresses where they have been clipped or anonymised, browser types and other anonymous statistical data relating to the use of this website.</p>
                    </div>  
                    <div>
                        <h3>Usage information</h3>
                        <p>We collect information about how you use our SPARTA. This includes information such as the types of module that you view or engage with, the features you use, the actions you take, app crashes and other system activity, type of browser, and third-party sites you were using before interacting with our SPARTA.</p>
                    </div>
                    <div>
                        <h3>Device Information</h3>
                        <p>We might have access to devices and applications you use to access SPARTA, including the hardware models, device IP address, operating systems and versions, software, file names and versions, unique device identifiers, serial numbers, device motion information, and mobile network information. We may also infer your geographic location based on your IP address.</p>
                    </div>
                    <div>
                        <h3>Sources of personal data</h3>
                        <p>We collect data from you for example, if you register or sign up for our services on this website. We may ask you to provide information such as your name, email address, job title, company and location.</p>
                    </div>
                    <div>
                        <h3>Purposes for which we hold your personal data</h3>
                        <p>The personal data that is referred to above will be processed for the purposes of providing you with information that you have requested from us where you have consented to be contacted for such purposes.</p>
                        <p>We collect, use and share your personal data that we have in the ways described above:</p>
                        <ul>
                            <li>as necessary to fulfill our Terms of Use;</li>
                            <li>consistent with your consent, which you may withdraw at any time;</li>
                            <li>as necessary to comply with our legal obligations;</li>
                            <li>to protect your vital interests, or those of others;</li>
                            <li>as necessary in the public interest; and</li>
                            <li>as necessary for our or others legitimate interests, including our interests in providing services to our clients and partners unless those interests are overridden by your interests or fundamental rights and freedoms that require protection of personal data.</li>
                        </ul>
                        <p>Where you have provided consent for the use of your personal data, you can withdraw it at any time by contacting us at <a href="mailto:gdpr@acumen.aero">gdpr@acumen.aero</a> . You may also unsubscribe on this website or by clicking unsubscribe in the emails you receive, however, this will not affect the lawfulness of processing which was carried out based on your consent prior to its withdrawal.</p>
                    </div>
                    <div>
                        <h3>Disclosure of your personal data to third parties</h3>
                        <p>We disclose your personal data to:</p>
                        <ul>
                            <li>our trusted third party service providers who are responsible for managing this website and for providing services to you .We take all reasonable steps to ensure that our third party providers will process and store your data to the same high standard that we do.</li>
                            <li>our ultimate parent company Acumen Aviation Europe and its subsidiaries, the addresses of which can be found here https://www.acumen.aero/</li>
                        </ul>    
                            <p>We will disclose your personal data if we believe in good faith that we are required to access, use, preserve or disclose it in order to comply with any applicable law or regulation, a summons, a search warrant, a court or regulatory order, or other statutory requirement.<br>
                            </br>
                            We will not share your personal data with companies, organisations or individuals other than our trusted third party service providers.</p>
                    </div>
                    <div>
                        <h3>Transfers of your personal data outside of the European Union</h3>
                        <p>In connection with the above we may transfer and store your personal data outside the European Economic Area (“EEA“), including to a jurisdiction which is not recognised by the European Commission as providing for an equivalent level of protection for personal data as is provided for in the European Union. Your personal data may also be processed by staff operating outside the EEA who work for us or for one of our suppliers.</p>
                            <br/>
                        <p>If and to the extent that we do so, we will ensure that appropriate measures are in place to comply with our obligations under applicable law governing such transfers, which include entering into a contract governing the transfer which contains the ‘standard contractual clauses’ approved for this purpose by the European Commission. Further details of the measures that we have taken in this regard are available on request from <a href="mailto:gdpr@acumen.aero">gdpr@acumen.aero.</a></p>
                    </div>
                    <div>
                        <h3>How we secure your personal data</h3>
                        <p>Your personal data that we capture may be held at our offices and those of our group companies. This personal data may also be stored on our document storage platforms which are located in Bangalore , Dublin , Miami & Beijing .</p>
                        <br/>
                        <p>We understand the importance of data security and we want your user experience with us to be as safe as possible. We have implemented reasonable safeguards and precautions to protect Your Personal Information or Sensitive Personal Data or Information, including technical and organizational measures against unauthorized access, improper use, alteration, unlawful or accidental destruction, and accidental loss, in an online context.</p>
                        <br/>
                        <p>Unfortunately, the transmission of information via the internet is not completely secure. Although we will do our best to protect your personal data, we cannot guarantee the security of your data transmitted to this website; any transmission is at your own risk. Once we have received your information, we will use strict procedures and security features to try to prevent unauthorised access.</p>
    
                    </div>
                    <div>
                        <h3>Your rights</h3>
                        <p>You have the following rights, in certain circumstances and subject to certain restrictions, in relation to your personal data:</p>
                        <ul>
                            <li>The right to access your personal data;</li>
                            <li>The right to request the rectification and/or erasure of your personal data;</li>
                            <li>The right to restrict the use of your personal data;</li>
                            <li>The right to object to the processing of your personal data;</li>
                            <li>The right to be forgotten in certain circumstances; and</li>
                            <li>The right to receive your personal data, which you have provided to us, in a structured, commonly used and machine-readable format or to require us to transmit that data to another controller.</li>
                        </ul>
                    </div>
                    <div>
                        <h3>How you can exercise your rights</h3>
                        <p>In order to exercise any of the rights set out above, please contact us at <a href="mailto:gdpr@acumen.aero">gdpr@acumen.aero</a>.</p>
                    </div>
                    <div>
                        <h3>Children</h3>
                        <p>We do not knowingly collect personal data from children under the age of 16. If you are a parent or guardian and believe your child under the age of 16 years has provided the personal data without your</p>
                        <p>consent, you may contact us at <a href="mailto:gdpr@acumen.aero">gdpr@acumen.aero</a>, and we will take steps to delete such data from our system.</p>
                    </div>
                    <div>
                        <h3>How long we retain your personal data</h3>
                        <p>We will not hold your personal data for longer than is necessary. We retain your personal data for as long as we need it for the purposes described in this Privacy Policy, including but not limited to section 6 (Disclosure of your personal data to third parties), or to comply with any applicable law or regulation, a summons, a search warrant, a court or regulatory order, or other statutory requirement.</p>
                    </div>
                    <div>
                        <h3>Deleting Your Information</h3>
                        <p>If You wish to have the Personal Information or Sensitive Personal Data or Information that You have provided to us deleted, you can always do so by sending a request to us on the e-mail id <a href="mailto:support@spata.aero">support@sparta.aero</a>.</p>
                    </div>
                    <div>
                        <h3>Data Auditing and Censorship</h3>
                        <p>It is important to note that SPARTA is a professional system, created specifically for Aviation Asset Management activities. The professional intent of the system necessitates that the data or information being uploaded into the system is of professional nature and will also be subjected to applicable checks and balances. The user and/or license owner(s) are therefore fully responsible to ensure that the data or information being uploaded is having only professional content. If any illicit content (including but not limited to pornographic or explicit content, ransomware, malware, viruses and personal content such as photos, videos or other such media content) is detected in the system, Acumen Aviation reserves complete rights to remove such content from the system or deactivate the user account without any refund of license fees or apply both remedies. Such remedies may be implemented without any requirement of notification to the user and/or license owner(s) and will be solely at the discretion of Acumen Aviation.

    </p>
                    </div>
                    <div>
                        <h3>Links to other websites</h3>
                        <p>This Privacy Policy applies only to the Acumen website. The website may contain links to other web sites not operated or controlled by us (“Third Party Sites”). The policies and procedures we describe here do not apply to Third Party Sites and we are not responsible for Third Party Sites’ content, any use of Third Party Sites, or the privacy practices of Third Party Sites. Any links from this website do not imply that we endorse or have reviewed Third Party Sites. We suggest contacting those sites directly for information on their privacy policies.</p>
                    </div>
                    <div>
                        <h3>IP addresses and cookies</h3>
                        <p>We may engage third parties to collect information about your computer, including where available your IP address, operating system and browser type, for system administration which is available to us upon request. This is statistical data about our users’ browsing actions and patterns, and does not identify any individual.</p>
                        <br/>
                        <p>We may receive information about your general internet usage by using a cookie file which is stored on the hard drive of your computer. Cookies contain information that is transferred to your computer’s hard drive.</p>
                        <br/>
                        <p>You may refuse to accept cookies by activating the setting on your browser which allows you to refuse the setting of cookies. However, if you select this setting you may be unable to access certain parts of this site. Unless you have adjusted your browser setting so that it will refuse cookies, our system will issue cookies when you log on to this site</p>
                    </div>
                    <h3>Consent</h3>
                    <h3>By using our site, you consent to this Data Privacy Policy.</h3>
                    <div>
                        <h3>Changes to this privacy policy</h3>
                        <p>We reserve the right to make changes to this Privacy Policy at any time without prior consultation. Any changes to this Privacy Policy will be posted on this website so you are always aware of what information we collect, how we use it, and under what circumstances, if any, we disclose it. If at any time we decide to use your personal data in a manner significantly different from that stated in this Privacy Policy, or otherwise disclosed to you at the time it was collected, we will notify you at the last email you provided to us, and you will have a choice as to whether or not we use your personal data in the new manner.</p>
                    </div>
                    <div>
                        <h3>Questions and complaints</h3>
                        <p>Our Privacy Officer should be able to answer any queries or resolve any concerns you have regarding the use of your personal data, and is contactable at <a href="mailto:gdpr@acumen.aero">gdpr@acumen.aero</a>.</p>
                    </div>
                    </Grid>
                </Grid>
            </Container>
        </section>
    )
}
export default DataPolicy;