import React, { useState, useEffect } from 'react';
import { AlertsNotifications, UserProfile, UserRoles } from '../components'
import { Paper, Tab, Tabs } from '@material-ui/core';
import { MyProfileNav } from '../../elements'
import { getLocalStorageInfo } from '../../utils_v2';
const MyProfile = ({params}) => {
    const [tabIndex, setTabIndex] = useState(0)
    return(
        <section className='roles-management' style={{margin:'24px 0 0 276px'}}>
            <MyProfileNav />
            <Paper style={{marginBottom:'15px', padding:'15px'}}>
                <UserProfile 
                    params={{id:getLocalStorageInfo().user.id, slug:getLocalStorageInfo().user.slug}} 
                    profileType='selfProfile'
                />
            </Paper>
            <Paper style={{marginBottom:'15px'}}>
                <Tabs indicatorColor='primary' value={tabIndex} onChange={(event, newValue) => setTabIndex(newValue)}>
                    <Tab label="Role(s)"/>
                    <Tab label="Alerts & Notification"/>
                </Tabs>
            </Paper>
            { tabIndex === 0 ?
                <div>
                    <UserRoles 
                        params={{id:getLocalStorageInfo().user.id, slug:getLocalStorageInfo().user.slug}} 
                        profileType='selfProfile'
                    />
                </div>:null
            }
            { tabIndex === 1 ?
                <div>
                    <AlertsNotifications params={params} />
                </div>:null
            }
        </section>
    )
}
export default MyProfile;