import React from 'react';
import { TableCell, TableRow, Button } from '@material-ui/core';
import ChangeBankStatus from './ChangeBankStatus';
import EditIcon from '@material-ui/icons/Edit';
import VisibilityIcon from '@material-ui/icons/Visibility';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import { checkPermission } from '../../utils_v2';
const BankList = ({item, onEdit, onView, onDelete, onChangeStatus}) => {
    return(
        <TableRow>
            <TableCell>{item?.lessor?.name||'--'}</TableCell>
            <TableCell>{item?.name||'--'}</TableCell>
            <TableCell>{item?.account_name||'--'}</TableCell>
            <TableCell>{item?.account_number||'--'}</TableCell>
            <TableCell>{item?.swift_code||'--'}</TableCell>
            <TableCell>
                { item.status === 0 ? 
                    <>
                        {checkPermission('console','banking_details','APR') ?
                            <ChangeBankStatus onChangeStatus={onChangeStatus} label={'Pending'}/>:
                            <Button style={{border:'1px solid #ff0000'}} size='small' variant='outlined'>Pending</Button>
                        }
                    </>:
                    item.status === 1 ?
                     <ChangeBankStatus onChangeStatus={onChangeStatus} label={'Approved'}/> :
                     <ChangeBankStatus onChangeStatus={onChangeStatus} label={'Disabled'}/> 
                    // <Button style={{border:'1px solid #0a8b2e', color:'#0a8b2e'}} size='small' variant='outlined'>Approved</Button>
                }
            </TableCell>
            <TableCell align='right'>
                <ul className='list-inline'>
                    
                    <li onClick={onView} className='list-inline-item'>
                        <VisibilityIcon style={{cursor:'pointer'}} color='primary' fontSize='small' />
                    </li>
                    {checkPermission('console','banking_details','U') ?
                        <li onClick={onEdit} className='list-inline-item'>
                            <EditIcon style={{cursor:'pointer'}} color='primary' fontSize='small' />
                        </li>:null
                    }
                    {checkPermission('console','banking_details','D')? 
                        <li onClick={onDelete} className='list-inline-item'>
                            <DeleteOutlineIcon style={{cursor:'pointer'}} color='secondary' fontSize='small' />
                        </li>:null
                    }
                </ul>
            </TableCell>
        </TableRow>
    )
}
export default BankList;