import React, { Component, Fragment, useState } from 'react';
import { useSnackbar } from 'notistack';
import { Button, Drawer, IconButton, Tooltip } from '@material-ui/core';
import HistoryIcon from '@material-ui/icons/History';
import EmptyCollection from './EmptyCollection';
import PageLoader from './PageLoader';
import RevisionEditCard from './RevisionEditCard';
import RevisionAddCard from './RevisionAddCard';
import { assetTypeValues, imgStoragePath } from '../../constants';
import { globalExportService, globalGetService } from '../../globalServices';
import { checkApiStatus } from '../utils_v2';
import CloseIcon from '@material-ui/icons/Close';
import { SaveAlt } from '@material-ui/icons';
import moment from 'moment';
import { trackActivity } from '../../utils/mixpanel';
const RevisionHistory = ({ params, url, queryParams, buttonType, assetInfoData, isExp=true,id }) => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [isLoading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [revisionHistories, setRevisionHistories] = useState([]);
  const getRevisionHistories = () => {
    setLoading(true);
    globalGetService(url, queryParams)
      .then(response => {
        setLoading(false);
        if (checkApiStatus(response)) {
          setRevisionHistories(response.data.data)
          setOpen(true);
          trackActivity('Item Clicked', { page_title: 'Rivision History', asset_type: assetTypeValues[assetInfoData?.asset_type?.type].label, asset_name: assetInfoData?.serial_number, event_desc: 'Clicked on Rivision History Button' })
        } else {
         // enqueueSnackbar(response.data?.message, { variant: 'error', anchorOrigin: { horizontal: 'right', vertical: 'top' } })
        }
      })
  }
  const onExportRevision = () => {
    setLoading(true)
    let timezone = moment().format();
    let assetType =params.type === 'lg' ?'amlandinggear':['hpt', 'lpt', 'hpc', 'fan'].includes(params?.type) ? `amengine`:`am${params.type}`;
    globalGetService(`audit/console/${assetType?assetType:'amUtilization'}/?object_id=${queryParams?.object_id?queryParams?.object_id:id}&action=0,1&download=xls&timezone=${timezone}`)
    .then(response => {
        setLoading(false)
        if (checkApiStatus(response)) {
          enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { horizontal: 'right', vertical: 'top' }, });
          trackActivity('Export', { page_title:'Asset Revision History', asset_type: assetTypeValues[assetInfoData?.asset_type?.type].label, asset_name: assetInfoData?.serial_number, event_type: 'Asset Revison History Exported', event_desc: '',  });
        }
      })
  }
  return (
    <Fragment>
      {buttonType ?
        <Tooltip title="Revision History" arrow>
          <HistoryIcon onClick={() => getRevisionHistories(url, queryParams)} color="primary" fontSize="small" />
        </Tooltip> :
        <span onClick={() => getRevisionHistories(url, queryParams)}>Revision History</span>
      }
      <Drawer anchor="right" open={open} onClose={() => { setOpen(false); setRevisionHistories([]) }}>
        <div className='revision-history-drawer'>
          <div className="history-head">
            <ul className='list-inline right-cta flex-centered '>
              <li className='list-inline-item '>
                <h4>Revision History</h4>
              </li>
              { isExp ?
                <li className='list-inline-item '>
                  <Button color='primary' variant='outlined' size='small' onClick={onExportRevision}><SaveAlt style={{ fontSize: '1.0rem' }} />Export</Button>
                </li>:null
              }
            </ul>
            <span onClick={() => { setOpen(false); setRevisionHistories([]) }}><CloseIcon /></span>
          </div>
          <div className="edit-revision-history" style={{ width: '500px', height: window.innerHeight - 24 + 'px' }}>
            <div className='history-body'>
              <ul className="list-unstyled">
                {revisionHistories.map((item, index) =>
                  <li>
                    <span className="timeline-icon"><img src={`${imgStoragePath}${item.action === 1 ? 'edit_audit_icn.png' : 'add_audit_icn.png'}`} alt="icon" /></span>
                    {item.action === 0 ?
                      <RevisionAddCard item={item} /> : <RevisionEditCard item={item} />
                    }
                  </li>
                )}
              </ul>
            </div>
            {!revisionHistories.length ? <div style={{ textAlign: 'center' }}><EmptyCollection title="No records found" description="" /></div> : null}
          </div>
        </div>
      </Drawer>
      {isLoading ? <PageLoader /> : null}
    </Fragment>
  )
}
export default RevisionHistory;
