import React, { useState, useEffect } from 'react'
import { useSnackbar } from 'notistack';
import { Button, TextField, Grid, Paper, Table, TableBody, Dialog, DialogContent, DialogTitle, DialogActions, CircularProgress } from '@material-ui/core';
import BankForm from './BankForm';
import BankList from './BankList';
import BankHeader from './BankHeader';
import { DeletePopUp, PageLoader, STableLoader, FilterComponent, EmptyCollection } from '../../shared_elements'
import { globalGetService, globalPostService, globalPutService, globalDeleteService } from '../../../globalServices';
import { checkApiStatus, checkPermission } from '../../utils_v2';
import { bankObj, bankFilters } from '../'
import ConfigSideNav from './ConfigSideNav';
const RejectionBanking = ({ rejectBank, toggleModalFn, getResponseBack }) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [isLoading, setLoading] = useState(false);
    const [reason, setReason] = useState('');
    const [error, setError] = useState({});
    const onRejection = () => {
        let validationInputs = {
            reason: reason?.trim()?.length ? '' : 'Please enter Rejection Reason',
        };
        if (Object.keys(validationInputs).every((k) => { return validationInputs[k] === '' })) {
            setLoading(true);
            let payload = Object.assign({}, rejectBank.data)
            globalPutService(`console/bank-details/${rejectBank.data.id}/`, { ...payload, lessor: rejectBank.data.lessor.id, lessor_level: rejectBank.data.lessor.lessor_level, status: 2, rejection_reason: reason })
                .then(response => {
                    if (checkApiStatus(response)) {
                        enqueueSnackbar('Banking detail got rejected', { variant: 'success', anchorOrigin: { horizontal: 'right', vertical: 'top' } });
                        toggleModalFn(); getResponseBack();
                    }
                    setLoading(false);
                })
        } else {
            setError(validationInputs)
        }
    }
    return (
        <Dialog
            open={rejectBank.modal}
            onClose={toggleModalFn}
            className='console-management-modal add-edit-role-modal'
            maxWidth="md"
        >
            <DialogTitle id="scroll-dialog-title">
                Reason of Rejection
            </DialogTitle>
            <DialogContent dividers={true}>
                <div style={{ width: '320px' }}>
                    <TextField
                        required
                        id='reason'
                        label='Reason'
                        value={reason}
                        error={error.reason}
                        helperText={error?.reason || ''}
                        onFocus={() => setError({ ...error, 'reason': '' })}
                        fullWidth
                        margin="normal"
                        multiline
                        rows={4}
                        maxRows={4}
                        onChange={(e) => setReason(e.target.value)}
                        InputLabelProps={{ shrink: true }}
                        variant='outlined'
                    />
                </div>
            </DialogContent>
            <DialogActions>
                <Button disabled={isLoading} onClick={toggleModalFn} color='primary' size='small' variant='outlined'>Cancel</Button>
                <Button disabled={isLoading} onClick={onRejection} color='primary' size='small' variant='contained'>
                    {isLoading ? <CircularProgress size={24} /> : 'Reject'}
                </Button>
            </DialogActions>
        </Dialog>
    )
}
const AddEditBank = ({ owners, addEditBanking, getResponseBack, toggleModalFn }) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [isLoading, setLoading] = useState(false);
    const [bank, setBank] = useState(addEditBanking?.data || {})
    const [error, setError] = useState({});
    const onFieldChange = (key, value) => {
        setBank({ ...bank, [key]: value });
    }
    const onAddEditBank = () => {
        let validationInputs = {
            lessor: bank?.lessor ? '' : 'Please select Lessor',
            name: bank?.name?.trim()?.length ? '' : 'Please enter Bank Name',
            account_name: bank?.account_name?.trim()?.length ? '' : 'Please enter Account Holder Name',
            account_number: bank?.account_number?.trim()?.length ? '' : 'Please enter Account Number'
        };
        if (Object.keys(validationInputs).every((k) => { return validationInputs[k] === '' })) {
            setLoading(true);
            let payload = Object.assign({}, bank);
            payload = {
                ...payload,
                lessor: bank.lessor.id,
                lessor_level: bank.lessor.lessor_level
            }
            if (bank.id) {
                globalPutService(`console/bank-details/${bank.id}/`, payload)
                    .then(response => {
                        if (checkApiStatus(response)) {
                            toggleModalFn(); getResponseBack();
                            enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { horizontal: 'right', vertical: 'top' } });
                        }
                        setLoading(false);
                    })
            } else {
                globalPostService(`console/bank-details/`, payload)
                    .then(response => {
                        if (checkApiStatus(response)) {
                            toggleModalFn(); getResponseBack();
                            enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { horizontal: 'right', vertical: 'top' } });
                        }
                        setLoading(false);
                    })
            }
        } else {
            setError(validationInputs);
        }
    }
    return (
        <Dialog
            open={addEditBanking.modal}
            onClose={toggleModalFn}
            className='console-management-modal add-edit-role-modal'
            maxWidth="md"
        >
            <DialogTitle id="scroll-dialog-title">
                {addEditBanking.mode === 'add' ? 'Add' : addEditBanking.mode === 'edit' ? 'Edit' : 'View'}  Bank
            </DialogTitle>
            <DialogContent dividers={true}>
                <BankForm
                    addEditBanking={addEditBanking}
                    lessor={owners}
                    onFieldChange={onFieldChange}
                    bank={bank}
                    error={error}
                    onResetError={(key) => setError({ ...error, [key]: '' })}
                />
            </DialogContent>
            <DialogActions>
                <Button disabled={isLoading} onClick={toggleModalFn} color='primary' size='small' variant='outlined'> {addEditBanking.mode !== 'view' ? 'Cancel' : 'Close'}</Button>
                {addEditBanking.mode !== 'view' ?
                    <Button disabled={isLoading} onClick={onAddEditBank} color='primary' size='small' variant='contained'>
                        {isLoading ? <CircularProgress size={24} /> : 'Save'}
                    </Button> : null
                }

            </DialogActions>
        </Dialog>
    )
}
const Bankings = ({ }) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [isLoading, setLoading] = useState(false);
    const [skeletonLoader, setSkeletonLoader] = useState(false);
    const [filter, setFilter] = useState({});
    const [addEditBanking, setAddEditBanking] = useState({ modal: false, mode: '', data: null })
    const [bankingList, setBankingList] = useState([]);
    const [owners, setOwners] = useState([]);
    const [deleteBank, setDeleteBank] = useState({ modal: false, data: null })
    const [rejectBank, setRejectBank] = useState({ modal: false, data: null })
    useEffect(() => {
        getBankings(filter, 'skeletonLoader');
        getOwners()
    }, []);
    const getOwners = () => {
        globalGetService(`console/owner/`, { dropdown: true })
            .then(response => {
                if (checkApiStatus(response)) {
                    setOwners(response.data.data)
                }
            })
    }
    const getBankings = (query, loaderType) => {
        loaderType === 'skeletonLoader' ? setSkeletonLoader(true) : setLoading(true);
        let queryParams = Object.assign({}, query);
        if (queryParams?.lessor_id) {
            queryParams = {
                ...queryParams,
                lessor_level: owners.find(item => item.id === query.lessor_id).lessor_level
            }
        }
        globalGetService(`console/bank-details/`, queryParams)
            .then(response => {
                if (checkApiStatus(response)) {
                    setBankingList(response.data.data);
                    setFilter(query)
                }
                loaderType === 'skeletonLoader' ? setSkeletonLoader(false) : setLoading(false)
            })
    }
    const onDeleteBank = () => {
        setLoading(true)
        globalDeleteService(`console/bank-details/${deleteBank.data.id}/`)
            .then(response => {
                if (checkApiStatus(response)) {
                    setDeleteBank({ modal: false, data: null });
                    enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { horizontal: 'right', vertical: 'top' } });
                    getBankings(filter, 'pageLoader');
                }
                setLoading(false)
            })
    }
    const onChangeStatus = (value, bank) => {
        if (value === 2) {
            setRejectBank({ modal: true, data: bank })
        } else {
            setLoading(true);
            let payload = Object.assign({}, bank)
            globalPutService(`console/bank-details/${bank.id}/`, { ...payload, lessor: bank.lessor.id, lessor_level: bank.lessor.lessor_level, status: value })
                .then(response => {
                    if (checkApiStatus(response)) {
                        enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { horizontal: 'right', vertical: 'top' } });
                        getBankings(filter, 'pageLoader');
                    }
                    setLoading(false);
                })
        }
    }
    let filterOptions = Object.assign({}, bankFilters);
    filterOptions = {
        ...filterOptions,
        lessor_id: {
            ...filterOptions.lessor_id,
            options: owners
        }
    }
    return (
        <section className='config-container'>
            <Paper className="tech-specs-card" style={{ minHeight: `${window.innerHeight - 90}px` }}>
                <ConfigSideNav />
                <div className="tech-specs-content">
                    <Paper style={{ padding: '5px 10px' }}>
                        <Grid container spacing={1}>
                            <Grid item md={9}>
                                <FilterComponent
                                    filter={filter}
                                    filterMenu={filterOptions}
                                    getResponseBack={(applyFilter) => getBankings({ ...applyFilter }, 'pageLoader')}
                                />
                            </Grid>
                            <Grid item md={3}>
                                {checkPermission('console', 'banking_details', 'C') ?
                                    <ul className='list-inline' style={{ float: 'right' }}>
                                        <li className='list-inline-item'>
                                            <Button onClick={() => setAddEditBanking({ modal: true, mode: 'add', data: bankObj })} color='primary' size='small' variant='contained'>Add Bank</Button>
                                        </li>
                                    </ul> : null
                                }
                            </Grid>
                        </Grid>
                    </Paper>
                    {skeletonLoader ? <STableLoader count={7} /> :
                    <Paper style={{height:`${window.innerHeight - 135}px`, overflow:'scroll'}}>
                        <Table className='mui-table-format'>
                            <BankHeader />
                            <TableBody>
                                {bankingList.filter(bank => bank.status !== 2).map((item, index) =>
                                    <BankList
                                        item={item}
                                        onEdit={() => setAddEditBanking({ modal: true, mode: 'edit', data: item })}
                                        onDelete={() => setDeleteBank({ modal: true, data: item })}
                                        onView={() => setAddEditBanking({ modal: true, mode: 'view', data: item })}
                                        onChangeStatus={(value) => onChangeStatus(value, item)}
                                    />
                                )}
                            </TableBody>
                        </Table>
                        <Paper>
                        {!bankingList.length && !skeletonLoader ? <div style={{ textAlign: 'center' }}><EmptyCollection title={'No records found'} /></div> : null}
                        </Paper>
                        </Paper>
                    }
                    </div>
            </Paper>
            {addEditBanking.modal ?
                <AddEditBank
                    owners={owners}
                    addEditBanking={addEditBanking}
                    getResponseBack={() => getBankings(filter, 'pageLoader')}
                    toggleModalFn={() => setAddEditBanking({ modal: false, data: null })}
                /> : null
            }
            {deleteBank.modal ?
                <DeletePopUp
                    modal={deleteBank.modal}
                    title='Delete Bank'
                    content={`Are you sure, you want to Delete?`}
                    toggleModalFn={() => setDeleteBank({ modal: false, data: null })}
                    deleteRecordFn={onDeleteBank}
                /> : null
            }
            {rejectBank.modal ?
                <RejectionBanking
                    rejectBank={rejectBank}
                    toggleModalFn={() => setRejectBank({ modal: false, data: null })}
                    getResponseBack={() => getBankings(filter, 'pageLoader')}
                /> : null
            }
            {isLoading ? <PageLoader /> : null}
        </section>
    )
}
export default Bankings;