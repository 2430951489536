import React, { Component } from 'react';
import { Container } from '@material-ui/core';
import ToastNotify from './shared/containers/ToastNotify';
import './assets/styles/font.scss';
import './assets/styles/application.scss';
import './console_management/assets/styles/console_management.scss'
import { browserHistory } from 'react-router'
import { getLocalStorageInfo } from './utils';
import { trackLogOut } from './utils/mixpanel'
import { SideNav, Header } from './console_management/shared_elements';
class App extends Component {
  render() {
    const { children } = this.props;
    const userDetail = JSON.parse(localStorage.getItem('userInfo'));
    return (
      <Container maxWidth={false} style={(window.location.href.includes('login')  || window.location.href.includes('forgot-password') || window.location.href.includes('termscondition') || window.location.href.includes('data-policy') || window.location.href.includes('password/reset/') || window.location.href.includes('password/create/') )? { padding: '0px'} : {}}>
        <>
            {userDetail?.defaultLessor?.id ? <Header />:null }
            {userDetail?.defaultLessor?.id ? <SideNav />:null }
            {children}
            <ToastNotify />
        </>
      </Container>
    )
  }
}
export default App;
