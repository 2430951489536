import React, { useEffect, useState } from 'react'
import { Dialog, DialogContent, DialogTitle, DialogActions, Grid, TextField, ListItem, ListItemIcon, ListItemText, Checkbox, Paper, Breadcrumbs, Link, Typography, Tooltip, Button, CircularProgress } from '@material-ui/core';
import { checkApiStatus } from '../../utils_v2';
import { assetTypeValues } from '../../../constants';
import { globalGetService, globalPostService } from '../../../globalServices';
import { useSnackbar } from 'notistack';
import TemplateAddEdit from './TemplateAddEdit';
import { PageLoader, STableLoader } from '../../shared_elements';
import InfoIcon from '@material-ui/icons/Info';

const DataRoomList = ({ item, onSelectItem, onChangeNav }) => {
    return (
        <ListItem key={item.id} dense button>
            <ListItemText onClick={onChangeNav} id={item.id} primary={item.name} />
        </ListItem>
    )
}
export default function AddTemplate({ addTemplateModal, toggleModalFn, assetInfoData, submitWithTemplate, assemblyData, defaultTemplate, setDefaultTemplate, templatPayload, templateData }) {
    const [dataroom, setSourceDataRoom] = useState({})
    const [isLoading, setLoading] = useState(false);
    const [addNewFolder, setAddNewFolder] = useState({data:addTemplateModal?.data?.folderName || ''});
    const [error, setError] = useState(false);
    const [currentPath, setCurrentPath] = useState({});
    const [templateSelection, setTemplateSelection] = useState(false);
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    useEffect(() => {
        getUuid()
    }, [])

    const getUuid = (uuid) => {
        setLoading(true)
        globalGetService(`console/${assetTypeValues[assetInfoData?.asset_type.type]?.urlType}/${assetInfoData.slug}/records-detail/`)
            .then(response => {
                setLoading(false)
                if (checkApiStatus(response)) {
                    getSourceFolders(response.data.data.record_folders?.technical_records?.uuid)

                }
            })
    }
    const getSourceFolders = (uuid) => {
        setLoading(true)
        globalGetService(`records/v2/${assetTypeValues[assetInfoData?.asset_type.type]?.urlType}/${assetInfoData.slug}/data-room/${uuid}/`, { lite_move: true, fields: 'id,name,uuid,location' })
            .then(response => {
                setLoading(false)
                if (checkApiStatus(response)) {
                    let currentPath = response.data.data?.navigation[response.data.data?.navigation?.length -1]
                    setCurrentPath(currentPath)
                    setSourceDataRoom({
                        ...response.data.data,
                        list: response.data.data.list.map(listItem => ({
                            ...listItem,
                            parent: response.data.data.navigation[response.data.data.navigation.length - 1]
                        }))
                    });
                }
            })
    }
    const onSubmit =()=>{
        let payload = {}
        payload ={
            ...payload,
            parentFolderDetails:dataroom?.navigation[dataroom?.navigation?.length -1],
            newFolderName:addNewFolder?.data
        }
            setLoading(true)
            globalPostService(`records/v2/validate-folder-creation/`,payload)
            .then(response=>{
                setLoading(false)
                if(response?.data?.data?.validation_status !== false){
                 setTemplateSelection(true)
                }
                else{
                    enqueueSnackbar(response.data?.data?.validation_message, { variant: 'error', anchorOrigin: { horizontal: 'right', vertical: 'top' } });
                }
            })
    }
    return (
        <>

            <Dialog
                open={addTemplateModal?.flag}
                className='console-management-modal'
                maxWidth="md"
            >
                <DialogTitle id="scroll-dialog-title">
                    Select Dataroom Folder
                </DialogTitle>
                <DialogContent className='template-path-list' dividers={true} style={{ width: "550px", padding:'10px 0px' }}>
                    {isLoading ?
                        <PageLoader />
                        : null
                    }
                    <div style={{ padding: '0px 15px 10px', borderBottom:"1px solid #b2b2b2" }}>
                        <Breadcrumbs maxItems={3} aria-label="breadcrumb" >
                            {dataroom?.navigation?.length ?
                                dataroom.navigation.map((nav, index) =>
                                    dataroom.navigation.length - 1 === index ? <Typography>{nav.name}</Typography> :
                                        <Link style={{ color: "#3746a1" }} onClick={() => getSourceFolders(nav.uuid)}>{nav.name}</Link>
                                ) : null
                            }
                        </Breadcrumbs>
                    </div>
                    <div className='dataroom-nav-folders'>
                        <div style={{ height: '380px', overflow: "scroll" }}>
                            {
                                dataroom?.list?.length > 0 && dataroom?.list.map((item) =>
                                    <DataRoomList item={item} onChangeNav={() => getSourceFolders(item.uuid)} />)
                            }
                            {!dataroom?.list?.length && !isLoading ? <p className='no-folders-cls'>No more folders</p> : null}
                        </div>
                        
                                <div style={{marginLeft:"10px"}}>
                                    <TextField
                                        // label='Create New Folder'
                                        label={
                                            <div style={{ display: "flex" }}>Create New Folder
                                                <Tooltip title='Add Folder name in which you want to add all the folders.' arrow>
                                                    <span > <InfoIcon style={{fontSize:"19px", marginLeft:'4px'}} /></span>
                                                </Tooltip>
                                            </div>}
                                        id="tsn"
                                        fullWidth
                                        placeholder='Please Enter Folder Name'
                                        margin="normal"
                                        onChange={(e, value) => setAddNewFolder(e.target.value?.trim() ? { ...addNewFolder, data: e.target.value } : { ...addNewFolder, data: '' })}
                                        value={addNewFolder?.data ? addNewFolder?.data : ''}
                                        variant='outlined'
                                        // error={error}
                                        // helperText={error ? 'Please enter Folder name' : ''}
                                        // onFocus={()=>setError(false)}
                                        InputLabelProps={{ shrink: true, style: { pointerEvents: 'auto', display: 'flex' }  }}
                                        inputProps={{ maxLength: 100 }}
                                    />
                                </div>
                    </div>
                    <div style={{padding:'10px 24px', borderTop:"1px solid #b2b2b2"}}>
                       <div className='path-heading' > Current Selected Path</div>
                        <div style={{marginLeft:"10px"}}>
                            {
                                dataroom?.navigation?.map((item, index)=> {return ` ${index == 0 ? '':' / '} ${item?.name}` })
                            }
                            {addNewFolder?.data ? ' / ' +addNewFolder?.data : ''}
                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => toggleModalFn()} color='primary' size='small' variant='outlined'>
                        Cancel
                    </Button>
                    <Button onClick={() => onSubmit()} color='primary' size='small' variant='contained'>
                        Next
                    </Button>
                </DialogActions>

            </Dialog>
            {
                templateSelection ?
                    <TemplateAddEdit
                    templateData={templateData}
                    templatPayload={templatPayload}
                    setDefaultTemplate={setDefaultTemplate}
                    submitWithTemplate={submitWithTemplate}
                        mainassetInfoData={assetInfoData}
                        assetData={addTemplateModal}
                        defaultTemplate={defaultTemplate}
                        assemblyData={assemblyData}
                        currentPath={currentPath}
                        newFolderData = {addNewFolder?.data}
                        toggleModalFn={() => setTemplateSelection(false)}
                        templateSelection={templateSelection}
                    /> : null
            }
        </>
    )
}
