import axiosInstance from '../utils/httpInterceptors';
export const globalGetService = (url, params) => {
  return new Promise(
    function(resolve, reject){
      axiosInstance({
        method: 'GET',
        url: url,
        params: params && Object.keys(params).length ? {...params,timestamp: new Date().getTime()} : {timestamp: new Date().getTime()}
      })
      .then(response => {
        resolve(response);
      })
      .catch(error => {
        reject(error)
      })
    }
  )
}
export const globalPostService = (url, data) => {
  return new Promise(
    function(resolve, reject){
      axiosInstance({
        method: 'POST',
        url: url,
        data: data
      })
      .then(response => {
        resolve(response);
      })
      .catch(error => {
        reject(error)
      })
    }
  )
}
export const globalPutService = (url, data) => {
  return new Promise(
    function(resolve, reject){
      axiosInstance({
        method: 'PUT',
        url: url,
        data: data
      })
      .then(response => {
        resolve(response);
      })
      .catch(error => {
        reject(error)
      })
    }
  )
}
export const globalDeleteService = (url, data) => {
  return new Promise(
    function(resolve, reject){
      axiosInstance({
        method: 'DELETE',
        url: url,
        data: data
      })
      .then(response => {
        resolve(response);
      })
      .catch(error => {
        reject(error)
      })
    }
  )
}

export const globalExportService = (url, queryParams={}) => {
  return new Promise(function(resolve, reject){
    axiosInstance({
      method: 'GET',
      url: url,
      responseType: 'blob',
      params: queryParams
    })
    .then(response => {
      resolve(response.data);
    })
    .catch(error => {
      reject(error)
    })
  })
}

export const globalFileUploadService = (url, data, config) => {
  return new Promise(
    function(resolve, reject){
      axiosInstance.post(url, data, config)
      .then(function (response) {
        resolve(response);
      })
      .catch(error => {
        reject(error)
      })
    }
  )
}

