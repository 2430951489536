import React, { Fragment } from 'react';
import { Grid } from '@material-ui/core';
import LabelValueCard from './LabelValueCard';
import moment from 'moment';
import { backendDateFormat, displayDateTimeFormatShort } from '../../constants';
import { revisionInItem, revisionItemList } from '../../shared/components';
const RevisionEditCard = ({item}) => {
  return(
    <Fragment>
      <Grid container spacing={3}>
        <LabelValueCard md={6} label='Updated at' value={moment(item.timestamp).format(displayDateTimeFormatShort)} />
        <LabelValueCard md={6} label='Updated by' value={item.user && item.user.name ? item.user.name:'--'} />
        {
          item?.content_type && item?.object_repr ?
            <Grid md={6} className={'label-value-card'} style={{margin:'0px 0px 20px 13px'}}>
              <p>  Revision In</p>
              <h6>{`${revisionInItem[item?.content_type]} (${item?.object_repr})`}</h6>
            </Grid>
            : null
        }
      </Grid>
      {item?.content_type!=='amutilization'?
      <div className="edit-detail-table">
        <table>
          <thead>
            <tr>
              <th style={{width: '33%'}}>Label</th>
              <th style={{width: '33%'}}>Old Value</th>
              <th style={{width: '33%'}}>New Value</th>
            </tr>
          </thead>
          <tbody>
            { Object.keys(item.changes).map(label =>
              <tr>
                <td>{label}</td>
                <td>{item?.changes[label]?.[0]?item?.changes[label][0]:'--'}</td>
                <td>{item?.changes[label]?.[1]?item?.changes[label][1]:'--'}</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>:null}
    </Fragment>
  )
}
export default RevisionEditCard;
