import React, {useState} from 'react'

export default function RemarksDynamicField({item, minLength='50', trimLength='35', showLessText='less', showMoreText='..more'}) {
    const [showMore, setShowMore] = useState({});
    const toggleDescription = (id) => {
        setShowMore({
            ...showMore,
            [id]: !showMore[id],
        });
    };
  return (
    <div>
        {item?.remarks && item?.remarks?.length > minLength ? (
                <div style={{ width: '300px' }}>
                    {showMore[item?.id] ? (
                        <span> {item?.remarks}
                            <a href='#' style={{ color: '#0E7FE1', fontSize: '12px' }} onClick={(e) => { e.preventDefault(); toggleDescription(item.id); }}  > {showLessText} </a>
                        </span>
                    ) : (
                        <span> {item.remarks.substring(0, trimLength)}
                            <a href='#' style={{ color: '#0E7FE1', fontSize: '12px' }} onClick={(e) => { e.preventDefault(); toggleDescription(item.id); }}  > {showMoreText} </a>
                        </span>
                    )}
                </div>
            ) : (
             <div style={{maxWidth:'280px'}}>{item?.remarks||'--'}</div> 
            )}
    </div>
  )
}
