import React, { useState, useEffect } from "react";
import { withRouter } from "react-router";
import { useSnackbar } from 'notistack';
import moment from "moment";
import MomentUtils from '@date-io/moment';
import { Table, TableBody, TableCell, TableHead, TableRow, Paper, Grid, Button, TextField, Dialog, DialogContent, DialogTitle, DialogActions, Tooltip, CircularProgress } from '@material-ui/core';
import { Pagination, STableLoader, FilterComponent, PageLoader, EmptyCollection, DeletePopUp, GoogleLocationField } from '../../shared_elements';
import { AssetInfo } from "../components";
import { TechSpecsNav } from '../../elements'
import EditIcon from '@material-ui/icons/Edit';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import { globalGetService, globalPutService, globalPostService, globalDeleteService, globalExportService } from '../../../globalServices';
import { checkApiStatus, checkPermission } from '../../utils_v2';
import { assetTypeValues, backendDateFormat, displayDateFormat, displayDateFormatShort, fieldDateFormat } from "../../../constants";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { regexConstants } from "../../../constants/regEx";
import { downloadFileType } from "../../../utils";
import { getPermissionKey } from "..";
import { trackActivity } from "../../../utils/mixpanel";
const incidentObj = {
    start_date: null,
    end_date: null,
    tsn: null,
    csn: null,
    location: '',
    reason: ''
}
const IncidentHd = () => {
    return (
        <TableHead>
            <TableRow>
                <TableCell>From</TableCell>
                <TableCell>Till</TableCell>
                <TableCell>TSN</TableCell>
                <TableCell>CSN</TableCell>
                <TableCell>Location</TableCell>
                <TableCell style={{ width: '100px' }}>Reason</TableCell>
                <TableCell align="right">Action(s)</TableCell>
            </TableRow>
        </TableHead>
    )
}
const IncidentList = ({ params, item, onDelete, onEdit }) => {
    const [showMore, setShowMore] = useState({});
    const toggleDescription = (id) => {
        setShowMore({
            ...showMore,
            [id]: !showMore[id],
        });
    };
    return (
        <TableRow>
            <TableCell>{item?.start_date ? moment(item?.start_date).format(displayDateFormatShort) : '--'}</TableCell>
            <TableCell>{item?.end_date ? moment(item?.end_date).format(displayDateFormatShort) : '--'}</TableCell>
            <TableCell>{(item?.tsn).toFixed(2)}</TableCell>
            <TableCell>{item?.csn}</TableCell>
            <TableCell ><div style={{ maxWidth: '200px' }}>{item?.location}</div></TableCell>
            <TableCell>
                {item?.reason && item?.reason?.length > 50 ? (
                    <div style={{ width: '200px' }}>
                        {showMore[item?.id] ? (
                            <span> {item?.reason}
                                <a href='#' style={{ color: '#0E7FE1', fontSize: '12px' }} onClick={(e) => { e.preventDefault(); toggleDescription(item.id); }}  > less </a>
                            </span>
                        ) : (
                            <span> {item.reason.substring(0, 23)}
                                <a href='#' style={{ color: '#0E7FE1', fontSize: '12px' }} onClick={(e) => { e.preventDefault(); toggleDescription(item.id); }}  > ..more </a>
                            </span>
                        )}
                    </div>
                ) : (
                    <span>{item.reason}</span> || '--'
                )}
            </TableCell>
            <TableCell align="right">
                {checkPermission('technical_specs', getPermissionKey(params.type), 'U') ?
                    <ul className="list-inline">
                        <li className="list-inline-item hover-inline">
                            <Tooltip title='Edit' arrow onClick={onEdit}><EditIcon color="primary" fontSize="small" /></Tooltip>
                        </li>
                        <li className="list-inline-item hover-inline">
                            <Tooltip title='Delete' arrow onClick={onDelete}><DeleteOutlineIcon color="secondary" fontSize="small" /></Tooltip>
                        </li>
                    </ul> : null
                }

            </TableCell>
        </TableRow>
    )
}
const AddEditIncident = ({ params, addEditIncident, toggleModalFn, getResponseBack, assetInfoData }) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [isLoading, setLoading] = useState(false);
    const [showFullDescription, setShowFullDescription] = useState({});
    const [incident, setIncident] = useState(addEditIncident.data);
    const [error, setError] = useState({})

    const onAddEditIncident = () => {
        let validationInputs = {
            start_date: moment(incident?.start_date).isValid() ? moment(incident?.start_date).isSameOrBefore(moment(), 'day') ? '' : 'From Date cannot be future Date' : 'Please enter From Date',
            end_date: incident.end_date ? (moment(incident?.end_date).isSameOrAfter(incident?.start_date, 'day')) ? '' : 'Till Date should be greater then or equal to From Date' : '',
            tsn: incident.tsn === null ? 'Please enter TSN' : incident?.tsn?.toString()?.trim()?.length ? '' : 'Please enter TSN',
            csn: incident.csn === null ? 'Please enter CSN' : incident?.csn?.toString()?.trim()?.length ? '' : 'Please enter CSN',
            location: incident?.location.trim().length ? '' : 'Please enter Location',
            reason: incident.reason?.trim()?.length > 0 ? '' : 'Please enter Reason',
        }
        if (Object.keys(validationInputs).every((k) => { return validationInputs[k] === '' })) {
            setLoading(true)
            if (incident.slug) {
                globalPutService(`console/${params.type}/${params.aircraft_slug}/aog-incidents/${incident.slug}/`, incident)
                    .then(response => {
                        if (checkApiStatus(response)) {
                            enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { horizontal: 'right', vertical: 'top' }, });
                            trackActivity('Item Edited', { page_title: 'IncidentsAndAog', asset_type: assetTypeValues[assetInfoData?.asset_type.type].label, asset_name: assetInfoData?.serial_number, event_type: 'Edit IncidentsAndAog', event_desc: 'Edited IncidentsAndAog data from Edit IncidentsAndAog form' });
                            toggleModalFn(); getResponseBack();
                        } else {
                            // enqueueSnackbar(response.data?.message, { variant: 'error', anchorOrigin: { horizontal: 'right', vertical: 'top' }, });
                        }
                        setLoading(false)
                    })
            } else {
                globalPostService(`console/${params.type}/${params.aircraft_slug}/aog-incidents/`, incident)
                    .then(response => {
                        if (checkApiStatus(response)) {
                            enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { horizontal: 'right', vertical: 'top' }, });
                            trackActivity('Item Added', { page_title: 'IncidentsAndAog', asset_type: assetTypeValues[assetInfoData?.asset_type.type].label, asset_name: assetInfoData?.serial_number, event_type: 'Add IncidentsAndAog', event_desc: 'Added IncidentsAndAog data from Add IncidentsAndAog form' });
                            toggleModalFn(); getResponseBack();
                        }
                        setLoading(false)
                    })
            }
        } else {
            setError(validationInputs)
        }

    }
    const onFieldChange = (key, value) => {
        setIncident({ ...incident, [key]: value })
    }
    return (
        <Dialog
            open={addEditIncident.modal}
            onClose={toggleModalFn}
            className='console-management-modal'
            maxWidth="md"
        >
            <DialogTitle id="scroll-dialog-title">
                {addEditIncident?.data?.id ? 'Edit ' : 'Add '} Incident & AOG
            </DialogTitle>
            <DialogContent dividers={true}>
                <div>
                    <Grid container spacing={1}>
                        <Grid item md={4}>
                            <MuiPickersUtilsProvider utils={MomentUtils}>
                                <KeyboardDatePicker
                                    required
                                    margin="normal"
                                    name="start_date"
                                    label="From"
                                    format={fieldDateFormat}
                                    fullWidth
                                    disableFuture
                                    InputLabelProps={{ shrink: true }}
                                    value={incident?.start_date || null}
                                    onChange={(data, value) => { onFieldChange('start_date', moment(data).isValid() ? moment(data).format(backendDateFormat) : null); setError({ ...error, 'start_date': '' }) }}
                                    inputVariant='outlined'
                                    error={error.start_date ? true : false}
                                    helperText={error.start_date || ''}
                                />
                            </MuiPickersUtilsProvider>
                        </Grid>
                        <Grid item md={4}>
                            <MuiPickersUtilsProvider utils={MomentUtils}>
                                <KeyboardDatePicker
                                    disabled={!incident.start_date ? true : false}
                                    margin="normal"
                                    name="end_date"
                                    label="Till"
                                    format={fieldDateFormat}
                                    fullWidth
                                    minDate={incident.start_date}
                                    InputLabelProps={{ shrink: true }}
                                    value={incident?.end_date || null}
                                    onChange={(data, value) => { onFieldChange('end_date', moment(data).isValid() ? moment(data).format(backendDateFormat) : null); setError({ ...error, 'end_date': '' }) }}
                                    inputVariant='outlined'
                                    error={error.end_date ? true : false}
                                    helperText={error.end_date || ''}
                                />
                            </MuiPickersUtilsProvider>
                        </Grid>

                        <Grid item md={4}>
                            <TextField
                                required
                                name='tsn'
                                label='TSN'
                                fullWidth
                                margin="normal"
                                value={incident.tsn === null ? '' : incident.tsn}
                                inputProps={{ maxLength: 10 }}
                                onChange={(e) => regexConstants.decimalnodotstart.test(e.target.value) ? onFieldChange('tsn', e.target.value) : e.preventDefault()}
                                InputLabelProps={{ shrink: true }}
                                variant='outlined'
                                error={error.tsn ? true : false}
                                onFocus={() => setError({ ...error, 'tsn': '' })}
                                helperText={error.tsn || ''}
                            />
                        </Grid>
                        <Grid item md={4}>
                            <TextField
                                required
                                name='csn'
                                label='CSN'
                                value={incident.csn === null ? '' : incident.csn}
                                fullWidth
                                margin="normal"
                                inputProps={{ maxLength: 10 }}
                                onChange={(e) => regexConstants.onlyNumeric.test(e.target.value) ? onFieldChange('csn', e.target.value) : e.preventDefault()}
                                InputLabelProps={{ shrink: true }}
                                variant='outlined'
                                error={error.csn ? true : false}
                                onFocus={() => setError({ ...error, 'csn': '' })}
                                helperText={error.csn || ''}
                            />
                        </Grid>
                        <Grid item md={4}>
                            <GoogleLocationField
                                onFieldChange={(key, value) => { onFieldChange(key, value); setError({ ...error, 'location': '' }) }}
                                location={incident?.location || ''}
                                required={true}
                                error={error?.location ? true : false}
                                helperText={error?.location || ''}
                                onFocus={() => setError({ ...error, 'location': '' })}
                            />
                        </Grid>
                        <Grid item md={12}>
                            <TextField
                                required
                                name='reason'
                                label='Reason'
                                value={incident?.reason || ''}
                                fullWidth
                                margin="normal"
                                multiline
                                onChange={(e) => onFieldChange('reason', e.target.value)}
                                InputLabelProps={{ shrink: true }}
                                variant='outlined'
                                error={error.reason ? true : false}
                                onFocus={() => setError({ ...error, 'reason': '' })}
                                helperText={error.reason || ''}
                                inputProps={{ maxLength: 250 }}
                            />
                        </Grid>
                    </Grid>
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={toggleModalFn} color='primary' size='small' variant='outlined'>Cancel</Button>
                <Button onClick={onAddEditIncident} color='primary' size='small' variant='contained'>{isLoading ? <CircularProgress size={24} /> : 'Save'}</Button>
            </DialogActions>
        </Dialog>
    )
}
const IncidentsAndAog = ({ params }) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [isLoading, setLoading] = useState(false);
    const [skeletonLoader, setSkeletonLoader] = useState(false);
    const [filter, setFilter] = useState({});
    const [assetSkeletonLoader, setAssetSkeletonLoader] = useState(false);
    const [incidentsInfo, setIncidentsInfo] = useState({ list: [], pagination: {} });
    const [addEditIncident, setAddEditIncident] = useState({ modal: false, data: null });
    const [deleteIncident, setDeleteIncident] = useState({ modal: false, data: null });
    const [assetInfoData, setAssetInfoData] = useState();
    useEffect(() => {
        getIncidents({}, 'skeletonLoader');
        getAssetInfo('assetSkeletonLoader')
    }, []);
    const getAssetInfo = (loaderType) => {
        loaderType === 'setAssetSkeletonLoader' ? setAssetSkeletonLoader(true) : setLoading(true);
        globalGetService(`console/v2/asset/${params.type}/${params.aircraft_slug}/asset_details/`)
            .then(response => {
                if (checkApiStatus(response)) {
                    setAssetInfoData(response.data.data);
                }
                loaderType === 'setAssetSkeletonLoader' ? setAssetSkeletonLoader(false) : setLoading(false);
            })
    }
    const getIncidents = (query = {}, loaderType) => {
        loaderType === 'skeletonLoader' ? setSkeletonLoader(true) : setLoading(true)
        globalGetService(`console/${params.type}/${params.aircraft_slug}/aog-incidents`, query)
            .then(response => {
                if (checkApiStatus(response)) {
                    setIncidentsInfo(response.data.data)
                }
                loaderType === 'skeletonLoader' ? setSkeletonLoader(false) : setLoading(false)
            })
    }
    const onDeleteIncident = () => {
        setLoading(true);
        globalDeleteService(`console/${params.type}/${params.aircraft_slug}/aog-incidents/${deleteIncident.data.slug}/`)
            .then(response => {
                if (checkApiStatus(response)) {
                    setDeleteIncident({ modal: false, data: null })
                    enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { horizontal: 'right', vertical: 'top' }, });
                    getIncidents({}, 'pageLoader');
                }
                setLoading(false);
            })
    }
    const onExportIncident = () => {
        setLoading(true);
        globalExportService(`console/${params.type}/${params.aircraft_slug}/aog-incidents/?download=xlsx`)
            .then(response => {
                downloadFileType(response, `${assetTypeValues[assetInfoData?.asset_type.type].label} ${assetInfoData.serial_number} incident-aog ${moment().format(fieldDateFormat)}`, 'xlsx')
                trackActivity('Export', { page_title: 'IncidentAndAog', asset_type: assetTypeValues[assetInfoData?.asset_type.type].label, asset_name: assetInfoData?.serial_number, event_type: 'IncidentAndAog Exported', event_desc: '', file_extension: 'xlsx' });
                // enqueueSnackbar("Incedent-Aog Exported Successully", { variant: 'success', anchorOrigin: { horizontal: 'right', vertical: 'top' }, });
                setLoading(false);
            })
    }
    return (
        <section className="asset-tech-specs">
            <AssetInfo assetInfoData={assetInfoData} skeletonLoader={assetSkeletonLoader} getResponseBack={() => getAssetInfo()} />
            <Paper className="tech-specs-card" style={{ minHeight: `${window.innerHeight - 162}px` }}>
                <TechSpecsNav />
                <div className="tech-specs-content" >
                    <div style={{ padding: '10px' }}>
                        {skeletonLoader ? <STableLoader count={5} /> :
                            <>
                                <Paper square style={{ padding: '4px 8px' }}>
                                    <Grid container spacing={1} alignItems="center">
                                        <Grid item md={9}></Grid>
                                        <Grid item md={3}>
                                            <ul className="list-inline flex-centered" style={{ float: 'right' }}>
                                                {checkPermission('technical_specs', getPermissionKey(params.type), 'U') ?
                                                    <li className="list-inline-item">
                                                        <Button onClick={() => setAddEditIncident({ modal: true, mode: 'add', data: incidentObj })} color='primary' size='small' variant="contained">Add</Button>
                                                    </li> : null}
                                                {incidentsInfo?.list?.length && checkPermission('technical_specs', getPermissionKey(params.type), 'EXP') ?
                                                    <li className="list-inline-item">
                                                        <Button onClick={onExportIncident} color='primary' size='small' variant="outlined">Export</Button>
                                                    </li> : null}
                                            </ul>
                                        </Grid>
                                    </Grid>
                                </Paper>
                                <Paper style={{ maxHeight: `${window.innerHeight - 290}px`, overflow: 'auto' }}>
                                    <Table className='mui-table-format' stickyHeader>
                                        <IncidentHd />
                                        <TableBody>
                                            {incidentsInfo.list.map((item, index) =>
                                                <IncidentList
                                                    params={params}
                                                    key={index}
                                                    item={item}
                                                    index={index + 1}
                                                    onEdit={() => setAddEditIncident({ modal: true, mode: 'edit', data: item })}
                                                    onDelete={() => setDeleteIncident({ modal: true, data: item })}
                                                />
                                            )}
                                        </TableBody>
                                    </Table>
                                 </Paper>
                                 <Paper>   
                                    <Pagination
                                        pagination={incidentsInfo.pagination}
                                        onChangePage={(event, newPage) => getIncidents({ ...filter, page: newPage + 1, per_page: incidentsInfo.pagination.per_page }, 'pageLoader')}
                                        onChangeRowsPerPage={(event) => getIncidents({ ...filter, page: 1, per_page: event.target.value }, 'pageLoader')}
                                    />
                                 </Paper>
                                 <Paper>
                                    {!incidentsInfo?.list?.length ? <div style={{ textAlign: 'center' }}><EmptyCollection title='No records found' /></div> : null}
                                </Paper>
                            </>}
                            </div>
                            </div>
                        
                        {addEditIncident.modal ?
                            <AddEditIncident
                                params={params}
                                assetInfoData={assetInfoData}
                                addEditIncident={addEditIncident}
                                toggleModalFn={() => setAddEditIncident({ modal: false, data: null })}
                                getResponseBack={() => getIncidents({}, 'pageLoader')}
                            /> : null
                        }
                        {deleteIncident.modal ?
                            <DeletePopUp
                                modal={deleteIncident.modal}
                                title='Delete Incident & AOG'
                                content={`Are you sure, you want to Delete?`}
                                toggleModalFn={() => setDeleteIncident({ modal: false, data: null })}
                                deleteRecordFn={onDeleteIncident}
                            /> : null
                        }
                        {isLoading ? <PageLoader /> : null}
                    
            </Paper>
        </section>

    )
}
export default withRouter(IncidentsAndAog);