import React, { useState, useEffect } from "react";
import { useSnackbar } from "notistack";
import { CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Button } from "@material-ui/core";
import { DropzoneArea } from "material-ui-dropzone";
import { Link } from "react-router";
import { globalFileUploadService } from "../../../globalServices";
import { checkApiStatus } from "../../utils_v2";

const ImportInterior = ({ url, getResponseBack, onExport, trackActivity }) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [isLoading, setLoading] = useState(false);
    const [skeletonLoader, setSkeletonLoader] = useState(false);
    const [modal, setModal] = useState(false);
    const [files, setFiles] = useState([]);
    const onImport = () => {
        var formData = new FormData();
        formData.append('files', files[0]);
        setLoading(true);
        globalFileUploadService(url, formData)
            .then(response => {
                setLoading(false);
                if (checkApiStatus(response)) {
                    enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { horizontal: 'right', vertical: 'top' }, });
                    setModal(false); getResponseBack();
                    trackActivity()
                }
            })
    }
    return (
        <>
            <Button color="primary" variant='outlined' size='small' onClick={() => setModal(true)}>Import</Button>
            <Dialog
                open={modal}
                onClose={() => setModal(false)}
                className='console-management-modal'
                maxWidth={'md'}
            >
                <DialogTitle id="scroll-dialog-title">
                    Import
                </DialogTitle>
                <DialogContent style={{ width: "380px" }} dividers={true}>
                    <div >
                        <DropzoneArea
                            acceptedFiles={['.xls, .xlsx,']}
                            filesLimit={1}
                            maxFileSize={104857600}
                            showPreviewsInDropzone={false}
                            useChipsForPreview={true}
                            showPreviews={true}
                            dropzoneText={<p style={{ fontSize: '13px' }}>Drag & Drop Documents<br /> OR <br /> Click Here<br /> </p>}
                            dropzoneClass="drag-drop-cnt"
                            maxWidth="xl"
                            showAlerts={['info', 'error']}
                            alertSnackbarProps={{ anchorOrigin: { vertical: 'top', horizontal: 'right' } }}
                            clearOnUnmount={true}
                            onChange={(files) => setFiles(files)}
                        />
                    </div>
                    <span style={{ fontSize: '11px' }} className='files-upload-limit' > Note : Supported extension ('.xls, .xlsx').</span>
                    <Grid>
                        <Link style={{ color: 'blue', fontSize: '12px', textDecoration: 'underline' }} type="link" onClick={onExport}>Download Sample</Link>
                    </Grid>
                    <Grid>
                        <span style={{ backgroundColor: "#fff1b6", fontSize: '11px' }} >Note:Downloaded Sample(export file) can be used to import and create new records. Please check the note in downloded sample for more info.</span>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button disabled={isLoading} onClick={() => setModal(false)} color='primary' size='small' variant='outlined'>Cancel</Button>
                    <Button disabled={isLoading || files.length === 0} onClick={onImport} color='primary' size='small' variant='contained'>
                        {isLoading ? <CircularProgress size={24} /> : 'Save'}
                    </Button>
                </DialogActions>
            </Dialog>
        </>

    )
}
export default ImportInterior;