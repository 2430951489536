import React from 'react';
import { withRouter, Link } from 'react-router';
import { checkPermission } from '../utils_v2/'
const MyProfileNav = ({params}) => {
    return(
        <div className='console-sec-nav'>
            <div className='nav-link'>
                <Link activeClassName='active' to="/user-profile">My Profile</Link>
            </div>
            <div className='nav-link'>
                <Link activeClassName='active' to="/change-password">Change Password</Link>
            </div>
        </div>
    )
}
export default withRouter(MyProfileNav)