import React, { useEffect, useState } from 'react'
import { useSnackbar } from 'notistack';
import MomentUtils from '@date-io/moment';
import { Chip, Grid, TextField, Button, Paper, Table, TableBody, TableCell, TableHead, TableRow, Dialog, DialogContent, DialogTitle, DialogActions, CircularProgress, InputAdornment } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import Autocomplete from '@material-ui/lab/Autocomplete';
import { DeletePopUp, STableLoader, PageLoader, EmptyCollection, AutocompleteCreatable } from '../../shared_elements'
import { globalGetService, globalPostService, globalPutService, globalDeleteService } from '../../../globalServices';
import { checkApiStatus, checkPermission } from '../../utils_v2';
import { regexConstants } from '../../../constants/regEx';
import moment from 'moment';
const AddEditMainProgram = ({ addMainProgram, toggleModalFn, getResponseBack, lesseeSlug , lesseeList}) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [mainProgram, setMainProgram] = useState({})
    const [error, setError] = useState({})
    const [isLoading, setLoading] = useState(false);
    const [constants, setConstants] = useState([]);
    const [aircraftTypes, setAircraftTypes] = useState([]);
    useEffect(()=>{
        if(addMainProgram?.mode == 'edit'){
            let data = addMainProgram?.data
            setMainProgram(data)
        }
        if(!addMainProgram?.mode){
            const selectedLessee = [lesseeList.find(item => item?.slug === lesseeSlug)];
            setMainProgram(prevState => ({
                ...prevState,
                lessee: selectedLessee
            }));
    }
        getConstants()
        getAircraftTypes()
    },[])
    const onRestErrorKey =(key)=>{
        setError({...error, [key]:''})
    }
    const onFieldChange = (key, value)=>{
        if (key === 'lessee' && !value.find(item => item?.slug === lesseeSlug)) {
            return enqueueSnackbar('Default lessee cannot be removed.', { variant: 'error', anchorOrigin: { horizontal: 'right', vertical: 'top' } });   
        }
        setMainProgram({ ...mainProgram, [key]: value })
    }
    const getAircraftTypes = () => {
        globalGetService(`console/aircraft-types/`, { distinct: 1 })
            .then(response => {
                if (checkApiStatus(response)) {
                    setAircraftTypes(response.data.data.aircraftTypes);
                }
            })
    }

    const getConstants = () => {
        globalPostService('console/get-constants/', { constant_types: ['asset_components', 'maintenance_prog_interval'] })
            .then(response => {
                if (checkApiStatus(response)) {
                    setConstants(response.data.data)
                }
            })
    }
    const saveMainProgram =()=>{
        let validationInput ={}
        validationInput={
            program: mainProgram?.program ? '': "Please enter Program Name",
            aircraft_type: mainProgram?.aircraft_type ? '': "Please select Aircraft Type",
            lessee: mainProgram?.lessee?.length ? '': "Please select Lessee",
            component: mainProgram?.component ? '': "Please select Component",
            interval_type: mainProgram?.interval_type ? '': "Please select Interval Type",
            interval: mainProgram?.interval ? '': "Please select Interval",
        }
        if(Object.keys(validationInput).every(item => validationInput[item] === '')){
            let payload = Object.assign({}, mainProgram )
            payload={
                ...payload,
                interval: mainProgram?.interval ? parseFloat(mainProgram?.interval): null,
                cost: mainProgram?.cost ? parseFloat(mainProgram?.cost): null,
                min_cost: mainProgram?.min_cost ? parseFloat(mainProgram?.min_cost): null,
                cost_year: mainProgram?.cost_year ? parseFloat(mainProgram?.cost_year): null,
            }
            if(addMainProgram?.mode == 'edit'){
                globalPutService(`console/lessee-maintenance-events/${mainProgram?.slug}/`, payload)
                .then(response =>{
                    if(checkApiStatus(response)){
                        getResponseBack()
                        enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { horizontal: 'right', vertical: 'top' } });
                    }
                })
            }
            else{
                globalPostService(`console/lessee-maintenance-events/`, payload)
                .then(response =>{
                    if(checkApiStatus(response)){
                        getResponseBack()
                        enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { horizontal: 'right', vertical: 'top' } });
                    }
                })
            }
        }
        else{
            setError(validationInput)
        }
    }
    console.log('mainProgram', mainProgram);
    return (
        <Dialog
            open={addMainProgram.modal}
            onClose={toggleModalFn}
            className='console-management-modal'
            maxWidth="lg"
        >
            <DialogTitle id="scroll-dialog-title">
              {addMainProgram?.mode === 'edit' ?'Edit':"Add"}  Maintenace Program
            </DialogTitle>
            <DialogContent dividers={true} style={{width:"1000px"}}>
                <Grid container spacing={1}>
                <Grid item md={4}>
                        <TextField
                            className='lessee-profile-input'
                            id='name'
                            inputProps={{ maxLength: 255 }}
                            label='Program Name'
                            value={mainProgram?.program || ''}
                            fullWidth
                            required
                            error={error.program ? true : false} 
                            helperText={error.program ? error.program : ''} 
                            onFocus={() => onRestErrorKey('program')}
                            onChange={(e) => onFieldChange('program', e.target.value?.trim()? e.target.value : '' )}
                            margin="normal"
                            InputLabelProps={{ shrink: true }}
                            variant='outlined'
                        />
                    </Grid>
                    <Grid item md={4}>
                        <Autocomplete
                            options={aircraftTypes}
                            getOptionLabel={option => option.name}
                            id="aircraft_type"
                            value={mainProgram.aircraft_type}
                            onChange={(e, value) => onFieldChange('aircraft_type', value)}
                            renderInput={params => <TextField required={ true} error={error.aircraft_type ? true : false} helperText={error.aircraft_type ? error.aircraft_type : ''} onFocus={() => onRestErrorKey('aircraft_type')} {...params} label="Aircraft Type" margin="normal" fullWidth InputLabelProps={{ shrink: true }} variant='outlined' />}
                        />
                    </Grid>
                    <Grid item md={4}>
                        <Autocomplete
                            options={lesseeList}
                            multiple={true}
                            getOptionLabel={option => option.name}
                            id="lessee"
                            value={mainProgram.lessee}
                            onChange={(e, value) => onFieldChange('lessee', value)}
                            renderInput={params => <TextField required={ true} error={error.lessee ? true : false} helperText={error.lessee ? error.lessee : ''} onFocus={() => onRestErrorKey('lessee')} {...params} label="Lessee" margin="normal" fullWidth InputLabelProps={{ shrink: true }} variant='outlined' />}
                        />
                    </Grid>
                    <Grid item md={4}>
                        <Autocomplete
                            options={constants.filter(item => item?.type == 'asset_components')}
                            getOptionLabel={option => option.label}
                            id="component"
                            value={mainProgram.component}
                            onChange={(e, value) => onFieldChange('component', value)}
                            renderInput={params => <TextField required={ true} error={error.component ? true : false} helperText={error.component ? error.component : ''} onFocus={() => onRestErrorKey('component')} {...params} label="Component" margin="normal" fullWidth InputLabelProps={{ shrink: true }} variant='outlined' />}
                        />
                    </Grid>
                    <Grid item md={4}>
                        <Autocomplete
                            options={constants.filter(item => item?.type == 'maintenance_prog_interval')}
                            getOptionLabel={option => option.label}
                            id="interval_type"
                            value={mainProgram.interval_type}
                            onChange={(e, value) => onFieldChange('interval_type', value)}
                            renderInput={params => <TextField required={ true} error={error.interval_type ? true : false} helperText={error.interval_type ? error.interval_type : ''} onFocus={() => onRestErrorKey('interval_type')} {...params} label="Interval Type" margin="normal" fullWidth InputLabelProps={{ shrink: true }} variant='outlined' />}
                        />
                    </Grid>
                    <Grid item md={4}>
                        <TextField
                            className='lessee-profile-input'
                            id='interval'
                            required
                            label='Interval'
                            value={mainProgram?.interval || ''}
                            fullWidth
                            error={error.interval ? true : false} 
                            helperText={error.interval ? error.interval : ''} 
                            onFocus={() => onRestErrorKey('interval')}
                            onChange={(e) =>{regexConstants.numberWithDot.test(e.target.value) ? onFieldChange('interval', e.target.value): e.preventDefault()}}
                            margin="normal"
                            InputLabelProps={{ shrink: true }}
                            variant='outlined'
                        />
                    </Grid>
                    <Grid item md={4}>
                        <TextField
                            className='lessee-profile-input'
                            id='cost'
                            label='Cost'
                            value={mainProgram?.cost || ''}
                            fullWidth
                            error={error.cost ? true : false} 
                            helperText={error.cost ? error.cost : ''} 
                            onFocus={() => onRestErrorKey('cost')}
                            onChange={(e) =>{regexConstants.numberWithDot.test(e.target.value) ? onFieldChange('cost', e.target.value): e.preventDefault()}}
                            margin="normal"
                            InputLabelProps={{ shrink: true }}
                            variant='outlined'
                            InputProps={{
                                startAdornment: <InputAdornment position="start">US$</InputAdornment>,
                              }}
                        />
                    </Grid>
                    <Grid item md={4}>
                        <TextField
                            className='lessee-profile-input'
                            id='min_cost'
                            label='Min. Cost'
                            value={mainProgram?.min_cost || ''}
                            fullWidth
                            error={error.min_cost ? true : false} 
                            helperText={error.min_cost ? error.min_cost : ''} 
                            onFocus={() => onRestErrorKey('min_cost')}
                            onChange={(e) =>{regexConstants.numberWithDot.test(e.target.value) ? onFieldChange('min_cost', e.target.value): e.preventDefault()}}
                            margin="normal"
                            InputLabelProps={{ shrink: true }}
                            variant='outlined'
                            InputProps={{
                                startAdornment: <InputAdornment position="start">US$</InputAdornment>,
                              }}
                        />
                    </Grid>
                    <Grid item md={4}>
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                        <KeyboardDatePicker
                            views={['year']}
                            placeholder='YYYY'
                            style={{ position: "relative", top: '16px' }}
                            inputVariant="outlined"
                            variant='outlined'
                            margin="none"
                            id="date"
                            label="Cost Year"
                            value={mainProgram.cost_year ? mainProgram.cost_year : null}
                            format={'YYYY'}
                            fullWidth
                            InputLabelProps={{ shrink: true }}
                            onChange={(data, value) => { onFieldChange('cost_year', data ?moment(data).format('YYYY') : null) }}
                        />
                    </MuiPickersUtilsProvider>
                    </Grid>
                    
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button disabled={isLoading} onClick={toggleModalFn} color='primary' size='small' variant='outlined'>Cancel</Button>
                <Button disabled={isLoading} onClick={saveMainProgram} color='primary' size='small' variant='contained'>
                    {isLoading ? <CircularProgress size={24} /> : 'Save'}
                </Button>
            </DialogActions>
        </Dialog>
    )
}
const MaintenaceProgram = ({lesseeSlug}) => {
    const [maintenaceProgramList, setMaintenaceProgramList] = useState([])
    const [isLoading, setLoading] = useState(false);
    const [error, setError] = useState({});
    const [skeletonLoader, setSkeletonLoader] = useState(false);
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [addMainProgram, setAddMainProgram] = useState(false);
    const [deleteMainPrgrm, setDeleteMainPrgrm] = useState({});
    const [lesseeList, setLesseeList] = useState([]);
    useEffect(()=>{
        getMaintenaceProgram({}, 'skeletonLoader')
        getLesseeType()
    },[])
    const onAddMainPrgrm = () => {
        setAddMainProgram({modal:true})
    }
    const getMaintenaceProgram = (query = {}, loaderType) => {
        loaderType === 'skeletonLoader' ? setSkeletonLoader(true) : setLoading(true);
        globalGetService(`console/lessee-maintenance-events/`,{lessee:lesseeSlug})
            .then(response => {
                if (checkApiStatus(response)) {
                    setMaintenaceProgramList(response.data.data)
                }
                loaderType === 'skeletonLoader' ? setSkeletonLoader(false) : setLoading(false);
            })
    }
    const getLesseeType = () => {
        globalGetService('console/lessees/?distinct=1')
        .then(response => {
            if(checkApiStatus(response)){
                setLesseeList(response.data.data?.lessees);
            }
        })
    }
    const deleteMainPrgrmFn = () => {
        globalDeleteService(`console/lessee-maintenance-events/${deleteMainPrgrm?.data?.slug}`, )
        .then(response =>{
            if(checkApiStatus(response)){
                getMaintenaceProgram()
                setDeleteMainPrgrm({ modal: false, data: null })
                enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { horizontal: 'right', vertical: 'top' } });
            }
        })
    }

    return (
        <div>

            <div style={{ textAlign: 'right' }}>
                <Button style={{ margin: '15px 0px' }} onClick={onAddMainPrgrm} color='primary' size='small' variant='contained'>
                    Add Maintenance Program
                </Button>
            </div>
            {
                skeletonLoader ? <STableLoader count={7} />:
            <Paper style={{ height: `${window.innerHeight - 405}px`, overflow: 'scroll' }}>
                <Table className='mui-table-format'>
                    <TableHead style={{ position: 'sticky', top: '0' }}>
                        <TableRow>
                            <TableCell>Program Name</TableCell>
                            <TableCell>Aircraft Type</TableCell>
                            <TableCell>Lessee</TableCell>
                            <TableCell>Component</TableCell>
                            <TableCell>Interval Type</TableCell>
                            <TableCell>Interval</TableCell>
                            <TableCell align='right'>Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {maintenaceProgramList?.list?.map((item, index) =>
                            <TableRow key={index}>
                                <TableCell>{item.program}</TableCell>
                                <TableCell>{item.aircraft_type?.name}</TableCell>
                                <TableCell>{item.lessee.name}</TableCell>
                                <TableCell>{item.component.label}</TableCell>
                                <TableCell>{item.interval_type.label}</TableCell>
                                <TableCell>{item.interval}</TableCell>
                                <TableCell align='right'>
                                    <EditIcon style={{ cursor: 'pointer' }} onClick={() => setAddMainProgram({ modal: true, mode: 'edit', data: item })} color='primary' fontSize='small' />
                                    <DeleteOutlineIcon style={{ cursor: 'pointer' }} onClick={() => setDeleteMainPrgrm({ modal: true, data: item })} color='secondary' fontSize='small' />
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
                <Paper>
                    {!maintenaceProgramList?.list?.length ? <div style={{ textAlign: 'center' }}><EmptyCollection title={'No records found'} /></div> : null}
                </Paper>
            </Paper>
            }
                   {isLoading ? <PageLoader /> : null}
            {
                addMainProgram?.modal ?
                <AddEditMainProgram
                lesseeList={lesseeList}
                lesseeSlug={lesseeSlug}
                getResponseBack={()=>{getMaintenaceProgram(); setAddMainProgram({modal:false})}}
                addMainProgram={addMainProgram}
                toggleModalFn={()=>setAddMainProgram({modal:false})}
                />
            : null}
            {deleteMainPrgrm.modal ?
                <DeletePopUp
                    modal={deleteMainPrgrm.modal}
                    title='Delete Maintenance Program'
                    content={`Are you sure, you want to Delete?`}
                    toggleModalFn={() => setDeleteMainPrgrm({ modal: false, data: null })}
                    deleteRecordFn={deleteMainPrgrmFn}
                /> : null
            }
        </div>
    )
}
export default MaintenaceProgram