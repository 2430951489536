import React from 'react';
import { Container, Button } from '@material-ui/core';
 
const ContactUs = ({toggleRequestDemoModal}) => {
  return(
    <section className="contact-us-section">
      <Container maxWidth="xl">
        <div className="content-wrapper contact-us">
          <h4>Like what you see ?</h4>
          <p>Get in touch now to speak to our consultant</p>
          <Button onClick={() => toggleRequestDemoModal()} color="primary" className="btn-color" variant="contained" size="medium">Contact Us</Button>
        </div>
      </Container>
    </section>
  )
}
export default ContactUs;
