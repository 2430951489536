import React from 'react';
import { Link } from 'react-router';
import { TableCell, TableRow, Table, TableBody, Chip, Tooltip } from '@material-ui/core';
import FlightIcon from '@material-ui/icons/Flight';
import { castleAirInstance } from '../../../shared/components';
const MajorAssembliesList = ({item}) => {
    let cellWidth = castleAirInstance?'155px':'110px'
    return(
        <TableRow>
            <TableCell style={{ width: '160px', borderRight: '1px solid #d7d7d7' }}>
                <div className='airframe-info'>
                    <p>{item?.aircraft_type || ''}</p>
                    <h4>{item?.msn || ''}</h4>
                    {item?.current_registration_number ? <p><span>Reg:</span>{item?.current_registration_number || ''}</p> : null}
                </div>
            </TableCell>
            <TableCell colSpan={4} style={{ padding: '0', borderRight: '1px solid #d7d7d7' }}>
                <Table>
                    <TableRow>
                        <TableCell align='center' colSpan={1}>
                        <div style={{width: cellWidth, textAlign:'center'}}>
                                {item?.major_assembly_details?.engines?.length ?
                                item?.major_assembly_details?.engines?.filter((engitem)=> engitem?.is_assigned || engitem?.is_titled )?.length == 0 ? '--' :
                                    item?.major_assembly_details?.engines?.map((engine) =>
                                        engine?.is_fitted && !(engine?.is_titled || engine?.is_assigned) ? null :
                                            <div style={{ marginBottom: '3px' }}>
                                                <Tooltip title={<>{engine?.is_titled && engine?.is_assigned ?
                                                    'Titled & Assigned-' :
                                                    engine?.is_assigned && !engine?.is_titled ?
                                                        'Assigned-' : 'Titled-'} {engine.esn}</>} arrow>
                                                    <Chip style={{ width: '90px' }} size='small' label={engine.esn.length > 11 ? `${engine.esn.slice(0, 10)}...` : engine.esn} variant="outlined" />
                                                </Tooltip>
                                            </div>
                                    ) : '--'
                                }
                            </div>
                        </TableCell>
                        { castleAirInstance ? null:
                        <TableCell align='center' colSpan={1}>
                            <div style={{ width: '110px', textAlign: 'center' }}>
                                {item?.major_assembly_details?.propellers?.length ?
                                    item?.major_assembly_details?.propellers?.filter((propItem)=> propItem?.is_assigned || propItem?.is_titled )?.length == 0 ? '--' :
                                    item?.major_assembly_details?.propellers?.map((propeller) =>
                                        propeller?.is_fitted && !(propeller?.is_titled || propeller?.is_assigned) ? null :
                                            <div style={{ marginBottom: '3px' }}>
                                                <Tooltip title={<>{propeller?.is_titled && propeller?.is_assigned ?
                                                    'Titled & Assigned-' :
                                                    propeller?.is_assigned && !propeller?.is_titled ?
                                                        'Assigned-' : 'Titled-'} {propeller?.serial_number}</>} arrow>
                                                    <Chip style={{ width: '90px' }} size='small' label={propeller.serial_number.length > 11 ? `${propeller.serial_number.slice(0, 10)}...` : propeller.serial_number} variant="outlined" />
                                                </Tooltip>

                                            </div>
                                    ) : '--'
                                }
                            </div>
                        </TableCell>}
                        <TableCell align='center' colSpan={1}>
                        <div style={{width:cellWidth, textAlign:'center'}}>
                                {item?.major_assembly_details?.landing_gears?.length ?
                                item?.major_assembly_details?.landing_gears?.filter((lgItem)=> lgItem?.is_assigned || lgItem?.is_titled )?.length == 0 ? '--' :
                                    item?.major_assembly_details?.landing_gears?.map((lg) =>
                                        lg?.is_fitted && !(lg?.is_titled || lg?.is_assigned)? null :
                                            <div style={{ marginBottom: '3px' }}>
                                                <Tooltip title={<>{lg?.is_titled && lg?.is_assigned ?
                                                    'Titled & Assigned-' :
                                                    lg?.is_assigned && !lg?.is_titled ?
                                                        'Assigned-' : 'Titled-'} {lg.serial_number}</>} arrow>
                                                    <Chip style={{ width: '90px' }} size='small' label={lg.serial_number.length > 11 ? `${lg.serial_number.slice(0, 10)}...` : lg.serial_number} variant="outlined" />
                                                </Tooltip>
                                            </div>
                                    ) : '--'
                                }
                            </div>
                        </TableCell>
                        <TableCell align='center' colSpan={1}>
                        <div style={{width:cellWidth, textAlign:'center'}}>
                                {item?.major_assembly_details?.apus?.length ?
                                    item?.major_assembly_details?.apus?.filter((apuItem)=> apuItem?.is_assigned || apuItem?.is_titled )?.length == 0 ? '--' :
                                    item?.major_assembly_details?.apus?.map((apu) =>
                                        apu?.is_fitted && !(apu?.is_titled || apu?.is_assigned) ? null :
                                            <div style={{ marginBottom: '3px' }}>
                                                <Tooltip title={<>{apu?.is_titled && apu?.is_assigned ?
                                                    'Titled & Assigned-' :
                                                    apu?.is_assigned && !apu?.is_titled ?
                                                        'Assigned-' : 'Titled-'}  {apu.serial_number}</>} arrow>
                                                    <Chip style={{ width: '90px' }} size='small' label={apu.serial_number.length > 11 ? `${apu.serial_number.slice(0, 10)}...` : apu.serial_number} variant="outlined" />
                                                </Tooltip>
                                            </div>
                                    ) : '--'
                                }
                            </div>
                        </TableCell>
                    </TableRow>
                </Table>
            </TableCell>
            <TableCell colSpan={4} style={{ padding: '0' }}>
                <Table>
                    <TableRow>
                        <TableCell align='center' colSpan={1}>
                        <div style={{width:cellWidth, textAlign:'center'}}>
                                {item?.major_assembly_details?.engines?.length ?
                                    item?.major_assembly_details?.engines?.filter((propItem)=> propItem?.is_fitted)?.length == 0 ? '--':
                                    item?.major_assembly_details?.engines?.map((engine) =>
                                        engine?.is_fitted ?
                                            <div style={{ marginBottom: '3px' }}>
                                                <Tooltip title={engine.esn} arrow>
                                                    <Chip style={{ width: '90px' }} size='small' label={engine.esn.length > 11 ? `${engine.esn.slice(0, 10)}...` : engine.esn} variant="outlined" />
                                                </Tooltip>
                                            </div> : null
                                    ) : '--'
                                }
                            </div>
                        </TableCell>
                        { castleAirInstance ? null:
                        <TableCell align='center' colSpan={1}>
                            <div style={{width:cellWidth, textAlign:'center'}}>
                                {item?.major_assembly_details?.propellers?.length ?
                                 item?.major_assembly_details?.propellers?.filter((propItem)=> propItem?.is_fitted)?.length == 0 ? '--':
                                    item?.major_assembly_details?.propellers?.map((propeller) =>
                                        propeller?.is_fitted ?
                                            <div style={{ marginBottom: '3px' }}>
                                                <Tooltip title={propeller?.serial_number} arrow>
                                                    <Chip style={{ width: '90px' }} size='small' label={propeller.serial_number.length > 11 ? `${propeller.serial_number.slice(0, 10)}...` : propeller.serial_number} variant="outlined" />
                                                </Tooltip>
                                            </div> : null
                                    ) : '--'
                                }
                            </div>
                        </TableCell>}
                        <TableCell align='center' colSpan={1}>
                        <div style={{width:cellWidth, textAlign:'center'}}>
                                {item?.major_assembly_details?.landing_gears?.length ?
                                  item?.major_assembly_details?.landing_gears?.filter((lgItem)=> lgItem?.is_fitted)?.length == 0 ? '--':
                                    item?.major_assembly_details?.landing_gears?.map((lg) =>
                                        lg?.is_fitted ?
                                            <div style={{ marginBottom: '3px' }}>
                                                <Tooltip title={lg.serial_number} arrow>
                                                    <Chip style={{ width: '90px' }} size='small' label={lg.serial_number.length > 11 ? `${lg.serial_number.slice(0, 10)}...` : lg.serial_number} variant="outlined" />
                                                </Tooltip>
                                            </div> : null
                                    ) : '--'
                                }
                            </div>
                        </TableCell>
                        <TableCell align='center' colSpan={1}>
                        <div style={{width:cellWidth, textAlign:'center'}}>
                                {item?.major_assembly_details?.apus?.length ?
                                item?.major_assembly_details?.apus?.filter((apuItem)=> apuItem?.is_fitted)?.length == 0 ? '--':
                                    item?.major_assembly_details?.apus?.map((apu) =>
                                        apu?.is_fitted ?
                                            <div style={{ marginBottom: '3px' }}>
                                                <Tooltip title={apu.serial_number} arrow>
                                                    <Chip style={{ width: '90px' }} size='small' label={apu.serial_number.length > 11 ? `${apu.serial_number.slice(0, 10)}...` : apu.serial_number} variant="outlined" />
                                                </Tooltip>
                                            </div> : null
                                    ) : '--'
                                }
                            </div>
                        </TableCell>
                    </TableRow>
                </Table>
            </TableCell>
            <TableCell style={{ width: '60px', borderLeft: '1px solid #d7d7d7' }} align='right'>
                <Link to={`/technical-specification/aircraft/${item.slug}/basic-details`}>
                    <FlightIcon fontSize='small' color='primary' />
                </Link>
            </TableCell>
        </TableRow>
    )
}
export default MajorAssembliesList;
