import React from 'react';
import { TableCell, TableRow, Tooltip } from '@material-ui/core';
import VisibilityIcon from '@material-ui/icons/Visibility';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import { checkPermission } from '../../utils_v2'
const PendingList = ({item, onEdit}) => {
    return(
        <TableRow>
            <TableCell>{item?.first_name||''} {item?.last_name||''}</TableCell>
            <TableCell>{item?.email||'--'}</TableCell>
            <TableCell>{item?.contact_no||'--'}</TableCell>
            <TableCell>{item?.designation||'--'}</TableCell>
            <TableCell>{item?.description||'--'}</TableCell>
            <TableCell align='right' style={{cursor:'pointer'}}>
                { checkPermission('settings', 'manage_user','U') || checkPermission('settings', 'manage_user','C') ?
                    <ul className='list-inline'>
                        <li onClick={()=>onEdit('Approve')} className='list-inline-item'>
                            <Tooltip title='Approve' arrow><CheckCircleIcon color='primary' fontSize='small' /></Tooltip>
                        </li>
                        <li onClick={()=>onEdit('Reject')} className='list-inline-item'>
                            <Tooltip title='Reject' arrow><CancelIcon color='secondary' fontSize='small' /></Tooltip>
                        </li>
                    </ul>:null
                }
            </TableCell>
        </TableRow>
    )
}
export default PendingList;