export function checkApiStatus(response){
    return (response && response.data && response.data.statusCode >= 200 && response.data.statusCode < 300)
}
export function checkPermission(appKey, featureKey, indexKey){
    if(getLocalStorageInfo()?.user?.permission){
        if(featureKey){
            return getLocalStorageInfo()?.user?.permission?.[appKey]?.[featureKey]?.includes(indexKey)
        }else{

        }
    }else{
        return null
    }
}
export const checkPermissionHierarchy = (userInfo) => {
    if(getLocalStorageInfo()?.user?.group_profile_order?.id === 0){
        return true
    }else{
        if(Object.keys(userInfo).length){
            if(userInfo?.group_profile_order && Object.keys(userInfo.group_profile_order).length){
                if(userInfo?.group_profile_order?.id === 0){
                    return false
                }else{
                    return getLocalStorageInfo()?.user?.group_profile_order.order < userInfo?.group_profile_order.order
                }
            }else{
                return true
            }
        }else{
            return false
        }
    }
}
export const checkForAddAsset = () => {
    if(getLocalStorageInfo()?.user?.permission){
        let userInfo = getLocalStorageInfo().user.permission;
        if(userInfo?.technical_specs?.aircraft?.includes('C') || userInfo?.technical_specs?.engine?.includes('C') || userInfo?.technical_specs?.apu?.includes('C') || userInfo?.technical_specs?.landing_gears?.includes('C') || userInfo?.technical_specs?.propeller?.includes('C') || userInfo?.technical_specs?.component?.includes('C')){
            return true
        }else{
            return false
        }
    }else{
        return null
    }
}
export const getLocalStorageInfo = () => {
    return JSON.parse(localStorage.getItem('userInfo'))
}
export const replaceEmptyNumber = (obj, fields) => {
    let payload = Object.assign({}, obj);
    fields.map((field) => {
        if(!payload[field]){
            payload = {
                ...payload,
                [field]:null
            }
        }
    });
    return payload;
}

export const convertStringToNumber = (obj, fields) => {
    let payload = Object.assign({}, obj);
    fields.map((field) => {
        if(payload[field] && payload[field]?.toString()?.length ){
            payload = {
                ...payload,
                [field]:Number(obj[field])
            }
        }else{
            if(payload[field] === 0){
                payload = {
                    ...payload,
                    [field]:0
                }
            }else{
                payload = {
                    ...payload,
                    [field]:null
                }
            }
        }
    })
    return payload;
}
export const replaceEmptyNumberZero = (obj, fields) => {
    let payload = Object.assign({}, obj);
    fields.map((field) => {
        if(!payload[field]){
            payload = {
                ...payload,
                [field]:0
            }
        }
    });
    return payload;
}

