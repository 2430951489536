export const rolesFilters = {
    'application_id':{
        'inputType': 'dropdown',
        'title': 'Application',
        'options':[],
        'labelKey':'name',
        'valueKey':'id'        
    },
    'group_profile_id':{
        'inputType': 'dropdown',
        'title': 'Profile Type',
        'options':[],
        'labelKey':'name',
        'valueKey':'id'        
    },
    
};
export const roleProfilesFilters = {
    'name':{
        'inputType': 'text',
        'title': 'Profile Type',
    },
    'created_by':{
        'inputType': 'text',
        'title': 'Created By',
    }
};