import React from "react";
import { Button, Dialog, DialogContent, DialogTitle, DialogActions, Tooltip, Chip } from '@material-ui/core';
const ServerErrorModal = ({ serverError, basicDetails, toggleModalFn }) => {
    return (
        <>
            <Dialog
                open={serverError.modal}
                onClose={toggleModalFn}
                className='console-management-modal'
                maxWidth="md"
            >
                <DialogTitle id="scroll-dialog-title">
                    {serverError?.data?.data?.formatted_message?.title || 'Error Occured'}
                </DialogTitle>
                <DialogContent dividers={true}>
                    <div style={{ textAlign: 'left', width: '440px' }} >
                        <p dangerouslySetInnerHTML={{__html: serverError?.data?.data?.formatted_message?.body}}>
                            {/* {serverError?.data?.data?.formatted_message?.body} */}
                        </p>
                    </div>

                </DialogContent>
                <DialogActions>
                    <Button onClick={toggleModalFn} color='primary' size='small' variant='outlined'>Close</Button>
                </DialogActions>
            </Dialog>
        </>
    )
}
export default ServerErrorModal;