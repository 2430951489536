export const numberRegx = /^[+-]?((\.\d+)|(\d+(\.+)?(\d+)?)|(\d))$/;
export const float2DecRegx = /^[0-9]*(\.[0-9]{0,2})?$/;
export const floatMRDecRegx = /^[0-9]*(\.[0-9]{0,8})?$/;
export const onlyNumeric = /^(\s*|\d+)$/;
export const intRegx = /^[0-9]?\d+$/;
export const emailRegx = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const onlyCharsWithSpaceRegex = /^([A-Za-z]( )[A-Za-z]+)/
export const fieldDateFormat = 'DD-MM-YYYY';
export const backendDateFormat = 'YYYY-MM-DD';
export const displayDateFormat = 'MMMM DD, YYYY';
export const displayDateFormatCard = 'MMM DD';
export const displayDateFormatCardYear = 'YYYY';
export const displayDateTimeFormat = "MMM DD, YYYY";
export const displayDateFormatShort = 'MMM DD, YYYY';
export const displayDateTimeFormatShort = 'MMM DD, YYYY, HH:mm';

// Constants Currency Format
export const currencyFormat = {cssClass:["pretty-number-override-css"], justification:'L', precision: 2, currency: true, commafy: true};

//qa urls
export const mediaBaseUrl = "https://qa.beta.sparta.aero:8223";
export const unlimitedBaseUrl = "https://qa.beta.sparta.aero:8223";
export const signUpBaseUrl = "https://qa.beta.sparta.aero:8223";
export const imgStoragePath = 'https://sparta-aims.ams3.digitaloceanspaces.com/others/assets/img/';



export const assetsType = {
  1: 'aircraft',
  2: 'engine',
  3: 'apu',
  4: 'lg',
  5: 'propeller',
  6: 'fan',
  7: 'hpc',
  8: 'hpt',
  9: 'lpt'
}
export const currencyType = {
  'USD': 'USD',
  'EUR': 'EUR'
}

export const securityGroups = {
  lessor_admin: 29,
  technical_admin: 24
};
export const pbhRangeCondition = [
  {id:1, label: 'Less Than Equal To'},
  {id: 6, label: 'Greater Than Equal To'},
  {id: 4, label: 'In Between Equal To',}
]
export const assetTypeValues = {
  1: {label:'MSN', urlType:'aircraft'},
  2: {label:'ESN', urlType:'engine'},
  3: {label:'APU', urlType:'apu'},
  4: {label:'LG', urlType:'lg'},
  5: {label:'Propeller', urlType:'propeller'},
  6: {label:'Fan',urlType:'fan'},
  7: {label:'HPC', urlType:'hpc'},
  8: {label:'HPT', urlType:'hpt'},
  9: {label:'LPT', urlType:'lpt'},
  10: {label:'COMPONENT', urlType:'component'}
};
