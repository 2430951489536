import React from 'react';
import { Paper, Grid, Button } from '@material-ui/core';
import { checkPermission } from '../../utils_v2';
import ImportInterior from './ImportInterior';
import { trackActivity } from '../../../utils/mixpanel';
const AddInteriorItem = ({ onAddItem, onExport, isExport, isImport, addPermission, onImport, getResponseBack, url, item, exportPermission }) => {
    return (
        <Paper square style={{ padding: '4px 8px' }}>
            <Grid container spacing={1} alignItems="center">
                <Grid item md={9}></Grid>
                <Grid item md={3}>
                    <ul className="list-inline" style={{ float: 'right' }}>
                        {addPermission ?
                            <li className="list-inline-item">
                                <Button onClick={onAddItem} color="primary" size='small' variant="contained">ADD</Button>
                            </li> : null
                        }
                        {exportPermission && isExport && item?.list?.length ?
                            <li className="list-inline-item">
                                <Button isExport={isExport} color='primary' variant='outlined' size='small' onClick={onExport} >Export</Button>
                            </li> : null
                        }
                        {addPermission && isImport ?
                            <li className="list-inline-item">
                                <ImportInterior isImport={isImport} getResponseBack={getResponseBack} trackActivity={trackActivity} url={url} onExport={onExport} />
                            </li> : null
                        }
                    </ul>
                </Grid>
            </Grid>
        </Paper>
    )
}
export default AddInteriorItem;