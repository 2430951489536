import React, { useState, useEffect } from "react";
import { withRouter } from 'react-router';
import { useSnackbar } from 'notistack';
import { Paper, Button, Grid, TextField, FormControlLabel, Checkbox, Tooltip } from "@material-ui/core";
import Autocomplete from '@material-ui/lab/Autocomplete';
import MomentUtils from '@date-io/moment';
import LeaseStatusError from './LeaseStatusError';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import moment from 'moment';
import { assetTypeValues, backendDateFormat, fieldDateFormat } from '../../../constants';
import MSNOnOffWing from "./MSNOnOffWing";
import ErrorTitled from "./ErrorTitled";
import { GoogleLocationField, PageLoader, LesseeCreate, STableLoader } from "../../shared_elements";
import { globalGetService, globalPostService, globalPutService } from "../../../globalServices";
import { checkApiStatus, checkPermission, convertStringToNumber, getLocalStorageInfo, replaceEmptyNumber } from '../../utils_v2';
import { getPermissionKey, offWingObj } from "..";
import EditIcon from '@material-ui/icons/Edit';
import InfoIcon from '@material-ui/icons/Info';
import { regexConstants } from '../../../constants/regEx';
import { trackActivity } from "../../../utils/mixpanel";
import ServerErrorModal from "./ServerErrorModal";
import { jetStreamInstance } from "../../../shared/components";
import config from "../../../config";
import { leaseStatus, subStatus } from "../../fleet";

const ComponentDetails = ({ params, getResponseBack, assetInfoData }) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [isLoading, setLoading] = useState(false);
    const [skeletonLoader, setSkeletonLoader] = useState(false);
    const [isEdit, setEdit] = useState(false);
    const [error, setError] = useState({});
    const [CompBasicDetails, setCompCompBasicDetails] = useState({});
    const [lgSubAssembly, setLgSubAssembly] = useState([]);
    const [regions, setRegions] = useState([]);
    const [assets, setAssets] = useState([]);
    const [apuTypes, setApuTypes] = useState([]);
    const [operators, setOperators] = useState([])
    const [engineTypes, setEngineTypes] = useState([]);
    const [lessees, setLessees] = useState([]);
    const [lessors, setLessors] = useState([])
    const [pltConstants, setPltConstants] = useState([])
    const [serverError, setServerError] = useState({ modal: false, data: null })
    const [serverErrorMdl, setErrorModal] = useState({ modal: false });
    const [activeLeaseModal, setActiveLeaseModal] = useState({ modal: false, error: null });

    useEffect(() => {
        getCompBasicDetails('skeletonLoader');
    }, []);
    const getDropdownData = () => {
        getRegionOfOperations();
        getLessees();
        getLessors();
        getPltConstants();
        getApuTypes();
        getEngineTypes();
        getAssets();
        getOperators();
    }
    const getCompBasicDetails = (loaderType) => {
        loaderType === 'skeletonLoader' ? setSkeletonLoader(true) : setLoading(true);
        globalGetService(`console/v2/asset/${params.type}/${params.aircraft_slug}/basic_details/`)
            .then(response => {
                if (checkApiStatus(response)) {
                    setCompCompBasicDetails(response.data.data);
                    //getResponseBack()
                }
                loaderType === 'skeletonLoader' ? setSkeletonLoader(false) : setLoading(false);
            })
    }
    const getAssets = () => {
        globalGetService('console/list/?asset_type=1&dropdown=true')
            .then(response => {
                if (checkApiStatus(response)) {
                    setAssets(response.data.data.asset)
                }
            })
    }
    const getEngineTypes = () => {
        globalGetService(`console/engine-types/`, { lite: true, aircraft_type: CompBasicDetails?.stand_alone ? null : assetInfoData?.msn?.aircraft_type?.id || null })
            .then(response => {
                if (checkApiStatus(response)) {
                    setEngineTypes(response.data.data.engineTypes)
                }
            })
    }
    const getLgSubAssembly = (position) => {
        globalGetService(`console/lg_sub_assemblies_position/`, { position: position, aircraft_type_id: assetInfoData?.msn?.aircraft_type?.id })
            .then(response => {
                if (checkApiStatus(response)) {
                    setLgSubAssembly(response.data.data);
                }
            })
    }
    const getApuTypes = () => {
        globalGetService(`console/apu-types/`, { aircraft_type: CompBasicDetails?.stand_alone ? null : assetInfoData?.msn?.aircraft_type?.id || null })
            .then(response => {
                if (checkApiStatus(response)) {
                    setApuTypes(response.data.data.apu_types);
                }
            })
    }
    const getRegionOfOperations = () => {
        globalGetService(`console/regions/`)
            .then(response => {
                if (checkApiStatus(response)) {
                    setRegions(response.data.data.region_list)
                }
            })
    }
    const getLessees = () => {
        globalGetService(`console/lessees/`)
            .then(response => {
                if (checkApiStatus(response)) {
                    setLessees(response.data.data.lessees)
                }
            })
    }
    const getOperators = () => {
        globalGetService(`console/operators/`)
            .then(response => {
                if (checkApiStatus(response)) {
                    setOperators(response.data.data.operators)
                }
            })
    }
    const getLessors = () => {
        globalGetService(`console/owner/?dropdown=true&lessor_use=true`)
            .then(response => {
                if (checkApiStatus(response)) {
                    setLessors(response.data.data)
                }
            })
    }
    const getPltConstants = () => {
        globalPostService('console/get-constants/', { constant_types: ['asset_status', 'asset_sub_status', 'lg_position', 'engine_position', 'asset_ownership_type', 'off_wing_status', 'shop_visit_type', 'workscope_status', 'sv_report_status', 'sv_requirement'] })
            .then(response => {
                if (checkApiStatus(response)) {
                    setPltConstants(response.data.data)
                }
            })
    }
    const onFieldChange = (key, value) => {
        const updatedDetails = { ...CompBasicDetails, [key]: value };  
        if (key === 'status') {
            updatedDetails.sub_status = null;
            updatedDetails.lessee = null;
            updatedDetails.operator = null;
        } else if (key === 'ownership_type') {
            updatedDetails.status = null;
            updatedDetails.sub_status = null;
        }
    
        setCompCompBasicDetails(updatedDetails);
    }

    const onEditCompBasicDetails = () => {
        let validationInputs = {
            ownership_type: CompBasicDetails?.ownership_type?'' : 'Please select OwnerShip Type',
            sold_out_date: CompBasicDetails?.sold_out_date ? moment(CompBasicDetails.sold_out_date).isSameOrAfter(CompBasicDetails?.date_of_manufacture, 'day') ? '' : "Date of Sale cannot be lesser than Date Of Manufacture" : '',
            purchase_date: moment(CompBasicDetails?.purchase_date).isValid() ? moment(CompBasicDetails?.purchase_date).isSameOrAfter(CompBasicDetails?.date_of_manufacture, 'day') ? moment(CompBasicDetails?.purchase_date).isSameOrBefore(moment(), 'day') ? '' : "Date of Purchase cant't be future Date" :"Date of Purchase cant't be lesser than Date Of Manufacture" : '' ,
            country: CompBasicDetails?.country?.trim()?.length ? '' : 'Please enter Country',
            lessor_name: CompBasicDetails?.lessor_name ? '' : `Please select ${jetStreamInstance?"Lessor/Holdco":"Lessor"}`,
            owner: CompBasicDetails?.owner ? '' : `Please select ${jetStreamInstance?"Owner/SPV":"Owner"}`,
            status: CompBasicDetails?.status ? '' : 'Please select Lease Status',
            lessee: CompBasicDetails?.status?.value === 1 ? CompBasicDetails?.lessee ? '' : 'Please select Lessee' : '',
            operator: CompBasicDetails?.status?.value === 1 && CompBasicDetails.same_operator_lessee === false ? CompBasicDetails?.operator ? '' : 'Please select Operator' : '',
            date_of_manufacture:  moment(CompBasicDetails?.date_of_manufacture).isValid() ? moment(CompBasicDetails.date_of_manufacture).isValid() && moment(CompBasicDetails?.date_of_manufacture).isSameOrBefore(moment(), 'day') ? '' : "Date of Manufacture cannot be future date" : 'Please enter Date of Manufacture',
            component_name: CompBasicDetails?.component_name?.trim()?.length ? '' : 'Please enter Component Name ' ,
            serial_number: CompBasicDetails?.serial_number?.trim()?.length ? '' : 'Please enter Component Serial Number',
        }
        if (CompBasicDetails.sold_out_date) {
            const soldOutDate = moment(CompBasicDetails.sold_out_date);
        
            if (!soldOutDate.isValid()) {
                validationInputs = {
                    ...validationInputs,
                    sold_out_date: 'Enter valid Date of Sale'
                };
            } else if (soldOutDate.isAfter(moment())) {
                validationInputs = {
                    ...validationInputs,
                    sold_out_date: 'Date of Sale cannot be a future date'
                };
            }
        }
        if (CompBasicDetails.date_of_manufacture && !moment(CompBasicDetails.date_of_manufacture).isValid()) {
            validationInputs = {
                ...validationInputs,
                date_of_manufacture: 'Enter valid Date of Manufacture'
            }
        }
        if (CompBasicDetails?.status?.value === 1 && CompBasicDetails?.lessee && CompBasicDetails.same_operator_lessee === false && CompBasicDetails?.operator) {
            if (CompBasicDetails.lessee.name === CompBasicDetails.operator.name) {
                validationInputs = {
                    ...validationInputs,
                    operator: 'Operator Should be different from Lessee'
                }
            }
        }
        if (Object.keys(validationInputs).every((k) => { return validationInputs[k] === '' })) {
            let payload = Object.assign({}, CompBasicDetails);
            payload = {
                ...payload,
                lessee:CompBasicDetails?.status?.value === 1 ?{'id':CompBasicDetails.lessee?.id,'name':CompBasicDetails.lessee?.name}:null ,
                operator:CompBasicDetails?.status?.value === 1 ? {'id':CompBasicDetails.operator?.id,'name':CompBasicDetails.operator?.name}:null,
            }
            setLoading(true);
            setError({})
            globalPutService(`console/v2/asset/${params.type}/${params.aircraft_slug}/basic_details/`, convertStringToNumber(payload,['tsn_at_sold_date','csn_at_sold_date']))
                .then(response => {
                    setLoading(false);
                    if (checkApiStatus(response)) {
                        enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { horizontal: 'right', vertical: 'top' } });
                        trackActivity('Item Edited', { page_title: 'Basic Details', asset_type: assetTypeValues[assetInfoData?.asset_type?.type]?.label, asset_name: assetInfoData?.serial_number, event_type: 'Basic Details Edited', event_desc: 'Edited Basic Details data from Edit Basic Details form' });
                        setEdit(false);
                        getResponseBack()
                    } else if (response.data.statusCode === 422) {
                        setErrorModal({ modal: true, data: response })
                    } else {
                        if (response.data?.statusCode === 406) {
                            setServerError({ modal: true, data: response.data });
                        }
                        if (response.data?.statusCode === 409) {
                            setActiveLeaseModal({ modal: true, error: response.data })
                        }
                    }
                })
        } else {
            setError(validationInputs);
            enqueueSnackbar('Mandatory field(s) are missing', { variant: 'error', anchorOrigin: { horizontal: 'right', vertical: 'top' } });

        }
    }
    let jetstreamOption;
    if (CompBasicDetails?.status?.value == 1) {
        jetstreamOption = subStatus.onLease;
    } else if (CompBasicDetails?.status?.value == 2) {
        if (CompBasicDetails.ownership_type == 2) {
            jetstreamOption = subStatus.offLease.filter(item => item.value !== '25');
        }else{
            jetstreamOption = subStatus.offLease
        }
    } else if (CompBasicDetails?.status?.value == 17) {
        jetstreamOption = subStatus.sold;
    } else if (CompBasicDetails?.status?.value == 14) {
        jetstreamOption = subStatus.partOut;
    } else if (CompBasicDetails?.status?.value == 15) {
        jetstreamOption = subStatus.installedOnly;
    } else if (CompBasicDetails?.status?.value == 16) {
        jetstreamOption = subStatus.writtenOff;
    } else {
        jetstreamOption = pltConstants.filter(item => item.type === 'asset_sub_status');
    }
    const castleAirIns = (config.env.key === 'api') ? '479' :(config.env.key === 'uat')?'472': '537'

    return (
        <div style={{ position: 'relative' }}>
            <div className="console-forms-fields" style={{ height: `${window.innerHeight - 220}px`, padding: '10px', overflow: 'auto' }}>
                <p style={{ textAlign: 'right', height: '20px' }}>
                    {checkPermission('technical_specs', getPermissionKey('component'), 'U') && !isEdit ?
                        <span style={{ cursor: 'pointer' }}>
                            <EditIcon onClick={() => { getDropdownData(); setEdit(true); }} fontSize="small" color='primary' />
                        </span> :null
                    }
                </p>
                {skeletonLoader ? < STableLoader count={4} /> : <>
                    <Grid container spacing={1}>
                                <Grid item xs={12} md={3}>
                                    <TextField
                                        disabled={!isEdit}
                                        name='manufacturer'
                                        label='Manufacturer'
                                        value={CompBasicDetails?.manufacturer || ''}
                                        fullWidth
                                        margin="normal"
                                        onChange={(e) => onFieldChange('manufacturer', e.target.value)}
                                        InputLabelProps={{ shrink: true }}
                                        inputProps={{ maxLength: 50 }}
                                        variant='outlined'
                                    />
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <TextField
                                        required
                                        disabled={!isEdit}
                                        name='component_name'
                                        label='Component Name'
                                        value={CompBasicDetails?.component_name || ''}
                                        fullWidth
                                        margin="normal"
                                        onChange={(e) => onFieldChange('component_name', e.target.value)}
                                        error={error?.component_name}
                                        helperText={error?.component_name || ''}
                                        onFocus={() => setError({ ...error, 'component_name': '' })}
                                        InputLabelProps={{ shrink: true }}
                                        inputProps={{ maxLength: 25 }}
                                        variant='outlined'
                                    />
                                </Grid>
                                <Grid item xs={12} md={3}>
                                <TextField
                                    required
                                    id="serial_number"
                                    label="Component Serial No."
                                    disabled={!isEdit}
                                    fullWidth
                                    margin="normal"
                                    value={CompBasicDetails.serial_number}
                                    error={error.serial_number ? true : false}
                                    helperText={error.serial_number ? error.serial_number : ''}
                                    onChange={(e, value) => onFieldChange('serial_number', e.target.value)}
                                    onFocus={() => setError({ ...error, 'serial_number': '' })}
                                    InputLabelProps={{ shrink: true }}
                                    inputProps={{ maxLength: 20 }}
                                    variant='outlined'
                                />
                                </Grid>
                                <Grid item md={3}>
                                    <MuiPickersUtilsProvider utils={MomentUtils}>
                                        <KeyboardDatePicker
                                            required
                                            disabled={!isEdit}
                                            margin="normal"
                                            name="date_of_manufacture"
                                            label="Date of Manufacture"
                                            format={fieldDateFormat}
                                            fullWidth
                                            disableFuture
                                            InputLabelProps={{ shrink: true }}
                                            minDate={moment().subtract(40, 'years')}
                                            value={CompBasicDetails?.date_of_manufacture || null}
                                            onChange={(data, value) => { onFieldChange('date_of_manufacture', moment(data).isValid() ? moment(data).format(backendDateFormat) : null); setError({ ...error, 'date_of_manufacture': '' }) }}
                                            error={error?.date_of_manufacture}
                                            helperText={error?.date_of_manufacture || ''}
                                            onFocus={() => setError({ ...error, 'date_of_manufacture': '' })}
                                            inputVariant='outlined'
                                        />
                                    </MuiPickersUtilsProvider>
                                </Grid>
                                <Grid item xs={12} md={3}>
                                <TextField
                                    id="part_number"
                                    label="Part Number"
                                    fullWidth
                                    margin="normal"
                                    disabled={!isEdit}
                                    value={CompBasicDetails.part_number}
                                    onChange={(e, value) => onFieldChange('part_number', e.target.value)}
                                    InputLabelProps={{ shrink: true }}
                                    inputProps={{ maxLength: 20 }}
                                    variant='outlined'
                                />
                            </Grid>
                        <Grid item md={3}>
                            <TextField
                                required
                                disabled={!isEdit}
                                name='country'
                                label='Country'
                                value={CompBasicDetails?.country || ''}
                                fullWidth
                                margin="normal"
                                onChange={(e) => onFieldChange('country', e.target.value)}
                                error={error?.country}
                                helperText={error?.country || ''}
                                onFocus={() => setError({ ...error, 'country': '' })}
                                InputLabelProps={{ shrink: true }}
                                inputProps={{ maxLength: 100 }}
                                variant='outlined'
                            />
                        </Grid>
                            <Grid item md={3}>
                                <Autocomplete
                                    disabled={!isEdit}
                                    options={regions}
                                    getOptionLabel={option => option.name}
                                    name="region"
                                    value={CompBasicDetails?.region || null}
                                    onChange={(e, value) => onFieldChange('region', value)}
                                    renderInput={params => <TextField {...params} label="Region of Operation" margin="normal" fullWidth InputLabelProps={{ shrink: true }} variant='outlined' />}
                                />
                            </Grid>
                        <Grid item md={3}>
                            {!isEdit ?
                                <TextField
                                    disabled={true}
                                    name='location'
                                    label={
                                        <span>
                                            Location
                                            <Tooltip title='Input country of operation' placement='right' ><InfoIcon style={{ marginLeft: '5px', fontSize: '1rem', color: 'grey' }} /></Tooltip>
                                        </span>
                                    }
                                    value={CompBasicDetails?.location || ''}
                                    fullWidth
                                    margin="normal"
                                    InputLabelProps={{ shrink: true, style: { pointerEvents: 'auto' } }}
                                    variant='outlined'
                                /> :

                                <GoogleLocationField
                                    onFieldChange={onFieldChange}
                                    location={CompBasicDetails?.location || ''}
                                    label={
                                        <span>
                                            Location
                                            <Tooltip title='Input country of operation' placement='right' ><InfoIcon style={{ marginLeft: '5px', fontSize: '1rem', color: 'grey' }} /></Tooltip>
                                        </span>
                                    }
                                />}
                        </Grid>

                        <Grid item md={3}>
                            <Autocomplete
                                disabled={!isEdit}
                                options={lessors}
                                getOptionLabel={option => option.name}
                                name="lessor_name"
                                value={CompBasicDetails?.lessor_name || null}
                                onChange={(e, value) => { onFieldChange('lessor_name', value); setError({ ...error, 'lessor_name': '' }) }}
                                renderInput={params => <TextField required error={error?.lessor_name} helperText={error?.lessor_name || ''} {...params} label={jetStreamInstance?'Lessor/Holdco':'Lessor'} margin="normal" fullWidth InputLabelProps={{ shrink: true }} variant='outlined' />}
                            />
                        </Grid>
                        <Grid item md={3}>
                            <Autocomplete
                                disabled={!isEdit}
                                options={lessors}
                                getOptionLabel={option => option.name}
                                name="owner"
                                value={CompBasicDetails?.owner || null}
                                onChange={(e, value) => { onFieldChange('owner', value); setError({ ...error, 'owner': '' }) }}
                                renderInput={params => <TextField required error={error?.owner} helperText={error?.owner || ''} {...params} label={jetStreamInstance?"Owner/SPV":"Owner"} margin="normal" fullWidth InputLabelProps={{ shrink: true }} variant='outlined' />}
                            />
                        </Grid>
                        <Grid item md={3}>
                            {(() => {
                                let options = [{ value: 1, label: 'Owned' }, { value: 2, label: 'Managed' }, { value: 3, label: 'Third Party' }];
                                return (
                                    <Autocomplete
                                        disableClearable
                                        disabled={!isEdit}
                                        options={!jetStreamInstance?options.filter(option=>option.value !==3):options}
                                        getOptionLabel={option => option.label}
                                        name="ownership_type"
                                        value={CompBasicDetails?.ownership_type || null}
                                        onChange={(e, value) =>{ onFieldChange('ownership_type', value);setError({ ...error, 'ownership_type': '' })}}
                                        renderInput={params => <TextField required error={error?.ownership_type} helperText={error?.ownership_type || ''} {...params} label="Ownership" margin="normal" fullWidth InputLabelProps={{ shrink: true }} variant='outlined' />}
                                    />
                                )
                            })()}
                        </Grid>
                        <Grid item md={3}>
                            <Autocomplete
                                disabled={(!isEdit || CompBasicDetails?.status?.value === 5 || assetInfoData?.status?.value === 5) ? true : checkPermission('technical_specs', 'component', 'STS') ? false : true}
                                options={getLocalStorageInfo()?.defaultLessor?.id !== 442 ? (pltConstants.filter(item => item?.type === 'asset_status').filter(item => item?.remarks === null).filter(item => item?.value !== 5 && item?.value !== 0)) : pltConstants.filter(item => item?.type === 'asset_status').filter(item => ![0,3,4,5,6,7,8,9,10,11].includes(item?.value) && item.remarks!== castleAirIns && (CompBasicDetails.ownership_type?.value !== 3?item.value !==15:item.value ===15) )}
                                getOptionLabel={option => option.label}
                                name="status"
                                value={assetInfoData?.status?.value === 5 ? assetInfoData?.status : CompBasicDetails?.status || null}
                                onChange={(e, value) => { onFieldChange('status', value); setError({ ...error, 'status': '' }) }}
                                renderInput={params => <TextField required error={error?.status} helperText={error?.status || ''} {...params} label="Lease Status" margin="normal" fullWidth InputLabelProps={{ shrink: true }} variant='outlined' />}
                            />
                        </Grid>
                        <Grid item md={3}>
                            <Autocomplete
                                disabled={!isEdit}
                                options={jetStreamInstance ? jetstreamOption : pltConstants.filter(item => item?.type === 'asset_sub_status')}
                                getOptionLabel={option => option.label}
                                name="sub_status"
                                value={CompBasicDetails?.sub_status || null}
                                onChange={(e, value) => { onFieldChange('sub_status', value); setError({ ...error, 'sub_status': '' }) }}
                                renderInput={params => <TextField required={getLocalStorageInfo()?.defaultLessor?.id === 242} error={error?.sub_status} helperText={error?.sub_status || ''} {...params} label="Sub Status" margin="normal" fullWidth InputLabelProps={{ shrink: true }} variant='outlined' />}
                            />
                        </Grid>
                        <Grid item md={3}>
                                <MuiPickersUtilsProvider utils={MomentUtils}>
                                    <KeyboardDatePicker
                                        disabled={!isEdit}
                                        margin="normal"
                                        name="purchase_date"
                                        label="Date of Purchase"
                                        format={fieldDateFormat}
                                        fullWidth
                                        disableFuture
                                        InputLabelProps={{ shrink: true }}
                                        minDate={(CompBasicDetails?.date_of_manufacture)}
                                        value={CompBasicDetails?.purchase_date || null}
                                        onChange={(data, value) => { onFieldChange('purchase_date', moment(data).isValid() ? moment(data).format(backendDateFormat) : null); setError({ ...error, 'purchase_date': '' }) }}
                                        inputVariant='outlined'
                                        error={error?.purchase_date}
                                        helperText={error?.purchase_date || ''}
                                    //onFocus={() => setError({ ...error, 'purchase_date': '' })}
                                    />
                                </MuiPickersUtilsProvider>
                            </Grid>
                        {CompBasicDetails?.status?.value === 17 ?
                            <>
                                <Grid item xs={12} md={3}>
                                    <MuiPickersUtilsProvider utils={MomentUtils}>
                                        <KeyboardDatePicker
                                            margin="normal"
                                            id="sold_out_date"
                                            label="Date of Sale"
                                            format={fieldDateFormat}
                                            fullWidth
                                            disableFuture
                                            InputLabelProps={{ shrink: true }}
                                            value={CompBasicDetails.sold_out_date ? CompBasicDetails.sold_out_date : null}
                                            onChange={(data, value) => onFieldChange('sold_out_date', data ? moment(data).format(backendDateFormat) : null)}
                                            minDate={(moment(CompBasicDetails.date_of_manufacture))}
                                            maxDate={moment()}
                                            error={error?.sold_out_date}
                                            helperText={error?.sold_out_date || ''}
                                            onFocus={() => setError({ ...error, 'sold_out_date': '' })}
                                            inputVariant='outlined'
                                            disabled={!isEdit}
                                        />
                                    </MuiPickersUtilsProvider>
                                </Grid> 
                                <Grid item md={3}>
                                    <TextField
                                        disabled={!isEdit}
                                        name='tsn_at_sold_date'
                                        label='TSN at Sale'
                                        value={CompBasicDetails?.tsn_at_sold_date?CompBasicDetails?.tsn_at_sold_date:''}
                                        fullWidth
                                        margin="normal"
                                        onChange={(e) => regexConstants.decimalnodotstart.test(e.target.value) ? onFieldChange('tsn_at_sold_date', e.target.value) : e.preventDefault()}
                                        InputLabelProps={{ shrink: true }}
                                        inputProps={{ maxLength: 10 }}
                                        variant='outlined'
                                    />
                                </Grid>
                                <Grid item md={3}>
                                    <TextField
                                        disabled={!isEdit}
                                        name='csn_at_sold_date'
                                        label='CSN at Sale'
                                        value={CompBasicDetails?.csn_at_sold_date?CompBasicDetails?.csn_at_sold_date:''}
                                        fullWidth
                                        margin="normal"
                                        onChange={(e) => regexConstants.decimalnodotstart.test(e.target.value) ? onFieldChange('csn_at_sold_date', e.target.value) : e.preventDefault()}
                                        InputLabelProps={{ shrink: true }}
                                        inputProps={{ maxLength: 10 }}
                                        variant='outlined'
                                    />
                                </Grid>
                            </> : null
                        }
                        {CompBasicDetails?.status?.value === 1 ?
                            <>
                                <Grid item md={3}>
                                    <LesseeCreate
                                        options={lessees}
                                        required={true}
                                        paramsKey='lessee'
                                        optionKey='name'
                                        label='Lessee'
                                        value={CompBasicDetails?.lessee || null}
                                        onFieldChange={(e, paramsKey, newValue) => onFieldChange('lessee', newValue)}
                                        error={error.lessee}
                                        resetErrorKey={() => setError({ ...error, 'lessee': '' })}
                                        disabled={!isEdit}
                                    />
                                </Grid>
                                {(() => {
                                    let options = [{ label: 'Yes', value: 1 }, { label: 'No', value: 2 }];
                                    let optionValue = CompBasicDetails?.same_operator_lessee ? { label: 'Yes', value: 1 } : { label: 'No', value: 2 }
                                    return (
                                        <Grid item md={3}>
                                            <Autocomplete
                                                disableClearable={true}
                                                disabled={!isEdit}
                                                options={options}
                                                getOptionLabel={option => option.label}
                                                name="same_operator_lessee"
                                                value={optionValue}
                                                onChange={(e, value) => onFieldChange('same_operator_lessee', value?.value === 1 ? true : false)}
                                                renderInput={params => <TextField {...params} label="Operator is Same as Lessee" margin="normal" fullWidth InputLabelProps={{ shrink: true }} variant='outlined' />}
                                            />
                                        </Grid>
                                    )
                                })()}
                                <Grid item md={3}>
                                    <Autocomplete
                                        disabled={!isEdit || CompBasicDetails?.same_operator_lessee}
                                        options={operators}
                                        getOptionLabel={option => option.name}
                                        name="operator"
                                        value={CompBasicDetails?.same_operator_lessee ? CompBasicDetails?.lessee : CompBasicDetails?.operator || null}
                                        onChange={(e, value) => { onFieldChange('operator', value); setError({ ...error, 'operator': '' }) }}
                                        renderInput={params => <TextField required={CompBasicDetails?.same_operator_lessee === false} error={error?.operator} helperText={error?.operator || ''} {...params} label="Operator" margin="normal" fullWidth InputLabelProps={{ shrink: true }} variant='outlined' />}
                                    />
                                </Grid>
                            </> : null
                        }
                        <Grid item md={12}>
                            <TextField
                                disabled={!isEdit}
                                name='technical_disclaimer'
                                label='Technical Disclaimer'
                                value={CompBasicDetails?.technical_disclaimer || ''}
                                fullWidth
                                multiline
                                rows={3}
                                margin="normal"
                                onChange={(e) => onFieldChange('technical_disclaimer', e.target.value)}
                                InputLabelProps={{ shrink: true }}
                                inputProps={{ maxLength: 255 }}
                                variant='outlined'
                            />
                        </Grid>
                    </Grid>
                </>}
                {isEdit ? <div className="divider" style={{ height: '51px' }}></div> : null}
            </div>
            {isEdit ?
                <Paper square style={{ padding: '10px', borderTop: '1px solid #d7d7d7', position: 'absolute', bottom: '0', left: '0', width: '100%', background: '#fff', zIndex: 9 }}>
                    <ul className="list-inline">
                        <li className="list-inline-item">
                            <Button onClick={() => { setError(''); setEdit(false); getCompBasicDetails() }} color="primary" size='small' variant="outlined">Cancel</Button>
                        </li>
                        <li className="list-inline-item">
                            <Button onClick={onEditCompBasicDetails} color="primary" size='small' variant="contained">SAVE</Button>
                        </li>
                    </ul>
                </Paper> : null
            }
            {serverError.modal ?
                <ErrorTitled
                    CompBasicDetails={CompBasicDetails}
                    serverError={serverError}
                    toggleModalFn={() => setServerError({ modal: false, data: null })}
                /> : null
            }
            {activeLeaseModal.modal ?
                <LeaseStatusError
                    activeLeaseModal={activeLeaseModal}
                    toggleModalFn={() => setActiveLeaseModal({ modal: false, error: null })}
                /> : null
            }
            {isLoading ? <PageLoader /> : null}
            {
                serverErrorMdl?.modal ?
                    <ServerErrorModal
                        serverError={serverErrorMdl}
                        toggleModalFn={() => setErrorModal({ modal: false })}
                    /> : null
            }
        </div>

    )
}
export default withRouter(ComponentDetails);