import React from 'react'
import { Button, Grid, Paper, Dialog, DialogContent, DialogTitle, DialogActions, CircularProgress, FormControlLabel, Checkbox, Table, TableBody, TableCell, TableHead, TableRow, } from '@material-ui/core';
export default function LinkedAssetInfoPopUp({ linkedAssetInfo, toggleModalFn, basicDetails }) {
    return (
        <div>
            <Dialog
                open={linkedAssetInfo.modal}
                onClose={toggleModalFn}
                className='console-management-modal'
                maxWidth="md"
            >
                <DialogTitle id="scroll-dialog-title">
                    Linked With Aircraft
                </DialogTitle>
                <DialogContent dividers={true} style={{ width: '400px' }}>
                    <Paper>
                    <Table className='mui-table-format' style={{cursor:'pointer'}}>
                        <TableBody>
                            {
                                basicDetails?.titled_aircraft?.asset_name  && basicDetails?.titled_aircraft?.slug?
                                    <TableRow style={{background:'#f7f7f7'}} onClick={() => window.open(`/technical-specification/aircraft/${basicDetails?.titled_aircraft?.slug}/basic-details`, '_blank')}>
                                        <TableCell>Titled MSN</TableCell>
                                        <TableCell style={{color:"#007bff"}}>{basicDetails?.titled_aircraft?.asset_name}</TableCell>
                                    </TableRow> : null
                            }
                            {
                                basicDetails?.assigned_aircraft?.asset_name && basicDetails?.assigned_aircraft?.slug ?
                                    <TableRow style={{background:"#f1f4fd"}} onClick={() => window.open(`/technical-specification/aircraft/${basicDetails?.assigned_aircraft?.slug}/basic-details`, '_blank')}>
                                        <TableCell>Assigned MSN</TableCell>
                                        <TableCell style={{color:"#007bff"}}>{basicDetails?.assigned_aircraft?.asset_name}</TableCell>
                                    </TableRow> : null
                            }
                            {
                                basicDetails?.on_wing_aircraft?.name && basicDetails?.on_wing_aircraft?.slug?
                                    <TableRow style={{background:'#f7f7f7'}} onClick={() => window.open(`/technical-specification/aircraft/${basicDetails?.on_wing_aircraft?.slug}/basic-details`, '_blank')}>
                                        <TableCell>On Wing MSN</TableCell>
                                        <TableCell style={{color:"#007bff"}}>{basicDetails?.on_wing_aircraft?.name}</TableCell>
                                    </TableRow> : null
                            }

                        </TableBody>
                    </Table>
                    </Paper>
                </DialogContent>
                <DialogActions>
                    <Button onClick={toggleModalFn} color='primary' size='small' variant='contained'>
                        Okay
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}
