import React, { useState } from "react";
import { Paper, Tabs, Tab } from "@material-ui/core";
import { TechSpecsNav } from '../../elements'
import AircraftConfig from './AircraftConfig';
import AircraftOverview from './AircraftOverview';
import AircraftWeight from './AircraftWeight';
import MajorAssemblies from './MajorAssemblies';
const Aircraft = ({assetInfoData, getResponseBack}) => {
    const [tabIndex, setTabIndex] = useState('details');
    return(
        <div>
            <Paper style={{margin:'0 10px 0px 10px'}}>
                <Tabs indicatorColor="primary" value={tabIndex} onChange={(event, newValue) => setTabIndex(newValue)} variant='fullWidth'>
                    <Tab label="Basic Details" value={'details'} />
                    <Tab label="Acquisition & Others Details" value={'configuration'} />
                    <Tab label="Weight Variants" value={'weight-variants'}/>
                    <Tab label="Major Assemblies" value={'major-assemblies'}/>
                </Tabs>
            </Paper>   
            { tabIndex === 'details' ? <AircraftOverview assetInfoData={assetInfoData} getResponseBack={getResponseBack}/>:null}        
            { tabIndex === 'configuration' ? <AircraftConfig assetInfoData={assetInfoData} getResponseBack={getResponseBack}/>:null}        
            { tabIndex === 'weight-variants' ? <AircraftWeight assetInfoData={assetInfoData}/>:null}        
            { tabIndex === 'major-assemblies' ? <MajorAssemblies assetInfoData={assetInfoData}/>:null}        
        </div>
    )
}
export default Aircraft;