import React, { useState, useEffect } from 'react'
import { useSnackbar } from 'notistack';
import { Grid, Button, TextField, Paper, Table, TableBody, TableCell, TableHead, TableRow, Dialog, DialogContent, DialogTitle, DialogActions, CircularProgress, Tooltip, Checkbox, Radio, RadioGroup } from '@material-ui/core';
import { DeletePopUp, PageLoader, STableLoader } from '../../shared_elements'
import EditIcon from '@material-ui/icons/Edit';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import { globalGetService, globalPostService, globalPutService, globalDeleteService } from '../../../globalServices';
import { checkApiStatus, checkPermission } from '../../utils_v2';
import { regexConstants } from '../../../constants/regEx';
import InfoIcon from '@material-ui/icons/Info';
import ConfigSideNav from './ConfigSideNav';
import { jetStreamInstance } from '../../../shared/components';
const AddEditOwner = ({ addEditOwner, toggleModalFn, getResponseBack }) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [isLoading, setLoading] = useState(false);
    const [error, setError] = useState({});
    const [owner, setOwner] = useState(addEditOwner.data)
    const onFieldChange = (key, value) => {
        setOwner({ ...owner, [key]: value })
    }
    const onAddEditOwner = () => {
        let validationInputs = {
            name: owner?.name?.trim()?.length ? '' : 'Please enter Name',
            contact: owner?.contact?.trim()?.length ? regexConstants.phoneNumber.test(owner.contact) ? '' : 'Please enter valid Contact Number' : ''
        }
        if (Object.keys(validationInputs).every((k) => { return validationInputs[k] === '' })) {
            setLoading(true);
            if (owner.id) {
                globalPutService(`console/owner/${owner.id}/`, owner)
                    .then(response => {
                        if (checkApiStatus(response)) {
                            toggleModalFn(); getResponseBack();
                            enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { horizontal: 'right', vertical: 'top' } });
                        }
                        setLoading(false);
                    })
            } else {
                globalPostService(`console/owner/`, owner)
                    .then(response => {
                        if (checkApiStatus(response)) {
                            toggleModalFn(); getResponseBack();
                            enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { horizontal: 'right', vertical: 'top' } });
                        }
                        setLoading(false);
                    })
            }
        } else {
            setError(validationInputs)
        }
    }
    return (
        <Dialog
            open={addEditOwner.modal}
            onClose={toggleModalFn}
            className='console-management-modal'
            maxWidth="md"
        >
            <DialogTitle id="scroll-dialog-title">
                {owner.id ? 'Edit' : 'Add'} {jetStreamInstance?'Owner/SPV':'Owner'} & {jetStreamInstance?'Lessor/Holdco':'Lessor'}
            </DialogTitle>
            <DialogContent dividers={true}>
                <Grid container spacing={1}>
                    <Grid item md={6}>
                        <TextField
                            required
                            id='name'
                            label='Name'
                            value={owner?.name || ''}
                            fullWidth
                            error={error.name}
                            helperText={error?.name || ''}
                            margin="normal"
                            onFocus={() => setError({ ...error, name: '' })}
                            onChange={(e) => onFieldChange('name', e.target.value)}
                            InputLabelProps={{ shrink: true }}
                            variant='outlined'
                        />
                    </Grid>
                    <Grid item md={6}>
                        <TextField
                            id='name'
                            label={
                                <div style={{ display: 'flex', alignItems: 'center' }}>Reference Name
                                    <Tooltip title={`If entered, Reference Name will appear in all invoices instead of Lessor/Owner Name.`} placement="top" arrow >
                                        <InfoIcon fontSize="small" style={{ verticalAlign: 'bottom', marginLeft: '3px' }} />
                                    </Tooltip>
                                </div>
                            }
                            value={owner?.reference_name || ''}
                            fullWidth
                            margin="normal"
                            onChange={(e) => onFieldChange('reference_name', e.target.value)}
                            InputLabelProps={{ shrink: true, style: { pointerEvents: "auto" } }}
                            variant='outlined'
                        />
                    </Grid>

                    <Grid item md={6}>
                        <TextField
                            id='address'
                            label='Address'
                            value={owner?.address || ''}
                            fullWidth
                            margin="normal"
                            onChange={(e) => onFieldChange('address', e.target.value)}
                            InputLabelProps={{ shrink: true }}
                            variant='outlined'
                        />
                    </Grid>
                    <Grid item md={6}>
                        <TextField
                            id='contact'
                            label='Contact Number'
                            value={owner?.contact || ''}
                            fullWidth
                            error={error.contact}
                            helperText={error?.contact || ''}
                            onFocus={() => setError({ ...error, contact: '' })}
                            margin="normal"
                            onChange={(e) => onFieldChange('contact', e.target.value)}
                            InputLabelProps={{ shrink: true }}
                            variant='outlined'
                        />
                    </Grid>
                    <Grid item md={6}>
                        <TextField
                            id='jurisdiction'
                            label='Jurisdiction'
                            value={owner?.jurisdiction || ''}
                            fullWidth
                            margin="normal"
                            onChange={(e) => onFieldChange('jurisdiction', e.target.value)}
                            InputLabelProps={{ shrink: true }}
                            variant='outlined'
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button disabled={isLoading} onClick={toggleModalFn} color='primary' size='small' variant='outlined'>Cancel</Button>
                <Button disabled={isLoading} onClick={onAddEditOwner} color='primary' size='small' variant='contained'>
                    {isLoading ? <CircularProgress size={24} /> : 'Save'}
                </Button>
            </DialogActions>
        </Dialog>
    )
}
const OwnerLessor = ({ }) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [isLoading, setLoading] = useState(false);
    const [skeletonLoader, setSkeletonLoader] = useState(false);
    const [ownerList, setOwnerList] = useState([]);
    const [addEditOwner, setAddEditOwner] = useState({ modal: false, mode: '', data: null })
    const [deleteOwner, setDeleteOwner] = useState({ modal: false, data: null })
    useEffect(() => {
        getOwners('skeletonLoader');
    }, []);
    const getOwners = (loaderType) => {
        loaderType === 'skeletonLoader' ? setSkeletonLoader(true) : setLoading(true)
        globalGetService(`console/owner/`)
            .then(response => {
                if (checkApiStatus(response)) {
                    setOwnerList(response.data.data);
                }
                loaderType === 'skeletonLoader' ? setSkeletonLoader(false) : setLoading(false)
            })
    }
    const onDeleteOwner = () => {
        setLoading(true);
        globalDeleteService(`console/owner/${deleteOwner.data.id}/`)
            .then(response => {
                if (checkApiStatus(response)) {
                    setDeleteOwner({ modal: false, data: null })
                    getOwners('pageLoader');
                    enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { horizontal: 'right', vertical: 'top' } });
                }
                setLoading(false)
            })
    }
    return (
        <section className="config-container">
            <Paper className="tech-specs-card" style={{ minHeight: `${window.innerHeight - 90}px` }}>
                <ConfigSideNav />
                <div className="tech-specs-content">
                    <div style={{ textAlign: 'right' }}>
                        {checkPermission('console', 'owner_details', 'C') ?
                            <Button style={{ marginBottom: '15px' }} onClick={() => setAddEditOwner({ modal: true, mode: 'add', data: { name: '', address: '', contact: '', jurisdiction: '', reference_name: null } })} color='primary' size='small' variant='contained'>Add {jetStreamInstance?"Owner/SPV":"Owner"} & {jetStreamInstance?"Lessor/Holdco":"Lessor"}</Button> : null
                        }
                    </div>
                    <Paper>
                        {skeletonLoader ? <STableLoader count={5} /> :
                        <Paper style={{height:`${window.innerHeight - 145}px`, overflow:'scroll'}}>
                            <Table className='mui-table-format'>
                                <TableHead style={{position:'sticky', top:'0'}}>
                                    <TableRow>
                                        <TableCell>Name</TableCell>
                                        <TableCell>Contact Number</TableCell>
                                        <TableCell>Address</TableCell>
                                        <TableCell>Jurisdiction</TableCell>
                                        <TableCell align='right'>Actions</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {ownerList.map((item, index) =>
                                        <TableRow key={index}>
                                            <TableCell>{item?.name || '--'}</TableCell>
                                            <TableCell>{item?.contact || '--'}</TableCell>
                                            <TableCell>{item?.address || '--'}</TableCell>
                                            <TableCell>{item?.jurisdiction || '--'}</TableCell>
                                            <TableCell align='right'>
                                                {checkPermission('console', 'owner_details', 'U') ?
                                                    <EditIcon style={{cursor:'pointer'}} onClick={() => setAddEditOwner({ modal: true, mode: 'edit', data: item })} color='primary' fontSize='small' /> : null
                                                }
                                                {checkPermission('console', 'owner_details', 'D') ?
                                                    <DeleteOutlineIcon style={{cursor:'pointer'}} onClick={() => setDeleteOwner({ modal: true, data: item })} color='secondary' fontSize='small' /> : null
                                                }
                                            </TableCell>
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                            </Paper>
                        }

                    </Paper>
                </div>
            </Paper>
            {addEditOwner.modal ?
                <AddEditOwner
                    addEditOwner={addEditOwner}
                    toggleModalFn={() => setAddEditOwner({ modal: false, mode: '', data: null })}
                    getResponseBack={() => getOwners('pageLoader')}
                /> : null
            }
            {deleteOwner.modal ?
                <DeletePopUp
                    modal={deleteOwner.modal}
                    title={`Delete ${jetStreamInstance?"Owner/SPV":"Owner"} & ${jetStreamInstance?"Lessor/Holdco":"Lessor"}`}
                    content={`Are you sure, you want to Delete?`}
                    toggleModalFn={() => setDeleteOwner({ modal: false, data: null })}
                    deleteRecordFn={onDeleteOwner}
                /> : null
            }
            {isLoading ? <PageLoader /> : null}
        </section>
    )
}
export default OwnerLessor;