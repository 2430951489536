import React from 'react';
import { withRouter, Link } from 'react-router'
import { Button, Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import { isMobile } from 'react-device-detect';
import config from '../../../config'
const RedirectionPopUp = ({ params, activeLeaseModal, toggleModalFn }) => {
  return (
    <div >
      <Dialog
         open={activeLeaseModal.modal}
         onClose={toggleModalFn}
         aria-labelledby="scroll-dialog-title"
      >
        <DialogTitle id="scroll-dialog-title">
            Redirect to Lease
        </DialogTitle>
        <DialogContent dividers={true} style={{padding:'24px 16px 0px' }}>
          <div style={isMobile ? { width: 'auto' } : { minWidth: '400px',height:'138px',maxWidth:'500px' }}>
            <div className="">
                {activeLeaseModal?.error?.message||''}
                <p>{activeLeaseModal?.error?.data?.lease_slug ? <a href={`${config.domain.lease}${params.type}/${params.aircraft_slug}/contract/list/${activeLeaseModal.error.data.lease_slug}/`} target='_blank'>Click here to Redirect to Lease</a>:<a href={`${config.domain.lease}${params.type}/${params.aircraft_slug}/contracts`} target='_blank'>Click here to Redirect to Lease</a>}</p>
            </div>
            <div >
              <Button style={{float:'inline-end',marginTop:'20px'}} size='small' variant="outlined" onClick={toggleModalFn} color={'primary'}>Cancel</Button>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  )
}
export default withRouter(RedirectionPopUp);
