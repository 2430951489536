import React, { useState, useEffect } from 'react';
import { Paper, Button, Dialog, Grid, TextField, DialogActions, DialogContent, DialogTitle, Table, TableHead, TableBody, TableCell, CircularProgress, TableRow, Tabs, Tab, FormControlLabel, Checkbox } from '@material-ui/core';
import { checkApiStatus } from '../../utils_v2';
import { globalGetService, globalPostService } from '../../../globalServices';
import { useSnackbar } from 'notistack';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { PageLoader } from '../../shared_elements';
import { assetTypeValues } from '../../../constants';

export default function TemplateSelection({ open, toggleModalFn, onSubmitData, assetData, addTemplateModal, templateInitialData, templatPayload, setDefaultTemplate, templateData }) {
    const [templateList, setTemplates] = useState([])
    const [error, setError] = useState(false)
    const [selectedTemplate, setSelectedTemplate] = useState({})
    const [currentTemplate, setCurrentTemplate] = useState(null)
    const [currDefaultTemp, setCurrDefaultTemp] = useState(templateData)
    const [isLoading, setLoading] = useState(false);
    const [maxObj, setMaxObj] = useState(0);
    const [checkedFolders, setCheckedFolders] = useState([]);
    useEffect(() => {
        getTemplateList()
    }, [])
    useEffect(() => {
        if (selectedTemplate.length > 0) {
            setMaxObj(findMaxDepth(selectedTemplate));
        }
    }, [selectedTemplate]);
    const getTemplateList = () => {
        // let query =  assetData?.aircraft_type ?{aircraft_types:assetData?.aircraft_type.id} : assetData?.apu_type ? {apu_types:assetData?.apu_type?.id}: assetData?.engine_type_id? {engine_types:assetData?.engine_type_id?.id}: null
        let query = { }
        setLoading(true)
        globalGetService(`records/v2/lessor-folder-template`, {...query,asset_type:assetData?.asset_type, dropdown: true})
        .then(response => {
            if (checkApiStatus(response)) {
                    setLoading(false)
                    setTemplates(response.data.data.list)
                    if (templateInitialData?.templateDetails?.id) {
                        if(templateData?.checkedFolders?.length > 1){
                            setSelectedTemplate(templateData?.selectedTemplate);
                            setCheckedFolders(templateData?.checkedFolders)
                            setCurrentTemplate(response.data.data.list?.find(item => item?.id == templateData?.templateDetails?.id))
                        }
                        else{
                            onChangeTemplate(templateInitialData?.templateDetails, 'apicall')
                        }
                    }
                }
            });
    }
    const onChangeTemplate = (template, apiCall) => {
        setCurrentTemplate(template)
        if (template?.id) {
            setLoading(true)
            globalPostService(`records/v2/get-lessor-folder-template/`, { ...templatPayload, template_id: template?.id })
                .then(response => {
                    setLoading(false)
                    if (checkApiStatus(response)) {
                        let responseData = response.data?.data?.folderDetails || []
                        setSelectedTemplate(responseData);
                        setCurrDefaultTemp(response.data.data)
                        responseData.map(item => onFieldChange(item, item?.is_checked, apiCall))
                    }
                })
        }
        else {
            setSelectedTemplate([])
        }
    }
    const onFieldChange = (folder, checked, apiState) => {
        let initialData = [...checkedFolders]
        if(apiState == 'dropdown'){
            initialData =[]
        }
        const updatedCheckedFolders = initialData;
        // const updatedCheckedFolders = [...checkedFolders];
        const toggleCheck = (folder, isChecked) => {
            const folderIndex = updatedCheckedFolders.findIndex(item => item.folder_name === folder.folder_name && item?.parent_folder[0] == folder?.parent_folder[0]);
            const folderData = {
                is_checked: isChecked,
                level: folder?.level,
                parent_folder: folder?.parent_folder,
                naming_conventions: folder?.naming_conventions,
                folder_name: folder?.folder_name,
                childrens: folder?.childrens
            };

            if (folderIndex === -1) {
                updatedCheckedFolders.push(folderData);
            } else {
                updatedCheckedFolders[folderIndex] = folderData;
            }
            if(apiState == 'checkbox'){
                folder.childrens.forEach(child => toggleCheck(child, isChecked));
            }
            else{
                folder.childrens.forEach(child => toggleCheck(child, child?.is_checked));
            }
        };
        toggleCheck(folder, checked);
        // setCheckedFolders(updatedCheckedFolders);
        if ( apiState == 'apicall' || apiState =='dropdown' || (checkedFolders?.length < 1 && addTemplateModal?.apiCall == 'apicall' && apiState != 'checkbox')) {
            setCheckedFolders(prevState => ([...prevState, ...updatedCheckedFolders]))
        }
        else {
            setCheckedFolders(updatedCheckedFolders);
        }
    };

    const saveData = () => {
        let payload = {
            checkedFolders,
            selectedTemplate:selectedTemplate,
            templateDetails: currentTemplate,
            majorAssembly: false,
            majorAssemblyAsset: null,
            majorAssemblyAssetType: null,
            asset: { serial_number: assetData?.msn ? assetData?.msn : assetData?.esn ? assetData?.esn : assetData?.serial_number, id: assetData?.id }
        }
        setDefaultTemplate(currDefaultTemp)
        onSubmitData(payload, selectedTemplate)
    };
    const findMaxDepth = (folders) => {
        let maxDepth = 0;
        const traverse = (folders, depth) => {
            folders.forEach((folder) => {
                if (folder.childrens.length > 0) {
                    traverse(folder.childrens, depth + 1);
                } else {
                    maxDepth = Math.max(maxDepth, depth);
                }
            });
        };
        traverse(folders, 1);
        return maxDepth;
    };

    const renderRows = (folders, depth = 1) => {
        const maxDepth = findMaxDepth(selectedTemplate);
        return folders.flatMap((folder) => {
            const rows = [];
            const indent = Array.from({ length: depth - 1 }).map(() => (
                <TableCell key={Math.random()} />
            ));
            rows.push(
                <TableRow key={folder.folder_name}>
                    {indent}
                    <TableCell>
                        {/* {item?.is_checked} */}
                        <FormControlLabel control={<Checkbox size="small" color='primary' checked={checkedFolders.find(item => (item.folder_name === folder.folder_name) && item?.is_checked == true && (item.parent_folder?.length ? item.parent_folder[0] === folder.parent_folder[0] : true))?.is_checked || false} onChange={(e) => onFieldChange(folder, e.target.checked, 'checkbox')} name="sub_folder" />} label={<p style={{ fontSize: '13px' }}>{folder.folder_name}</p>} />
                    </TableCell>
                    {Array.from({ length: maxDepth - depth }).map((_, i) => (
                        <TableCell key={`empty-${i}`} />
                    ))}
                    <TableCell>{folder.naming_conventions}</TableCell>
                </TableRow>
            );
            if (folder.childrens.length > 0) {
                rows.push(...renderRows(folder.childrens, depth + 1));
            }
            return rows;
        });
    };
    const changeTemplateFn = (value) => {
        setSelectedTemplate([])
        setCheckedFolders([])
        if (value?.id) {
            setLoading(true)
            setTimeout(() => { onChangeTemplate(value, 'dropdown') }, 400);
        }
        else { onChangeTemplate(value) }
    }
    return (
        <div>
            {isLoading ? <PageLoader /> : null}
            <Dialog
                open={open}
                onClose={() => { toggleModalFn(false) }}
                className='console-management-modal'
                maxWidth={false}
            >
                <DialogTitle id="scroll-dialog-title">
                    Template
                </DialogTitle>
                <DialogContent dividers={true} >

                    <div style={{ minHeight: '700px', minWidth: '800px' }}>
                        <Grid container spacing={1}>
                            <Grid item xs={12} sm={6}>
                                <Autocomplete
                                    style={{ marginBottom: '20px' }}
                                    options={templateList}
                                    getOptionLabel={option => option.name}
                                    id="template"
                                    value={currentTemplate || null}
                                    onChange={(e, value) => changeTemplateFn(value)}
                                    renderInput={params => <TextField required error={error ? true : false} helperText={error ? 'Please select Template to continue' : ''} {...params} onFocus={() => setError(false)} label="Select Template" margin="normal" fullWidth InputLabelProps={{ shrink: true }} variant='outlined' />}
                                />
                            </Grid>
                            {/* <Grid item xs={12} sm={6} style={{ display: 'flex' }}>
                            {currentTemplate ?
                                <FormControlLabel control={<Checkbox size="small" color='primary' checked={includeFolder} onChange={(e) => setIncludeFolder(e.target.checked)} name="sub_folder" />} label={<p style={{ fontSize: '13px' }}>Include selected Folder</p>} />
                                : null}
                        </Grid> */}
                        </Grid>
                        <Grid item md={12}>
                            {selectedTemplate?.length ?
                                <div style={{ overflow: "scroll", width: `${(40) + (maxObj * 20)}%`, minWidth: "100%" }}>
                                    <Table className='mui-table-format'>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell >Main Folders</TableCell>
                                                {
                                                    Array.from({ length: maxObj - 1 }).map((item, index) => (
                                                        <TableCell >Sub Folder {index + 1} </TableCell>
                                                    ))
                                                }
                                                <TableCell width='280px'>Naming Conventions</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {selectedTemplate?.length ?
                                                renderRows(selectedTemplate)
                                                : null
                                            }
                                        </TableBody>
                                    </Table>
                                </div>
                                :
                                <Paper style={{ textAlign: 'center', padding: '20px' }}>
                                    {isLoading ?
                                        <CircularProgress size={24} /> :
                                        'Choose template to you wish to select.'
                                    }
                                </Paper>
                            }
                        </Grid>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={toggleModalFn} color='primary' size='small' variant='outlined'>Cancel</Button>
                    <Button disabled={(checkedFolders.filter(item => item?.is_checked == true)?.length == 0) || isLoading} onClick={() => saveData()} color='primary' size='small' variant='contained'>Save</Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}
