import React, { useState, useEffect, Fragment } from 'react';
import { useSnackbar } from 'notistack';
import { withRouter, browserHistory } from 'react-router';
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField, Radio, RadioGroup, FormControl, FormLabel, FormControlLabel, Tooltip, Checkbox, Chip } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import Autocomplete from '@material-ui/lab/Autocomplete';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import moment from 'moment';
import { assetTypeValues, backendDateFormat, fieldDateFormat } from '../../../constants';
import { getLocalStorageInfo } from '../../../utils';
import { globalGetService, globalPostService } from '../../../globalServices';
import { checkApiStatus } from '../../utils_v2';
import { leaseStatus, subStatus } from '../';
import { DeletePopUp, GoogleLocationField, LesseeCreate, PageLoader } from '../../shared_elements';
import { trackActivity } from '../../../utils/mixpanel';
import { regexConstants } from '../../../constants/regEx';
import { jetStreamInstance } from '../../../shared/components';
import TemplateSelection from './TemplateSelection';
import { Edit, Info } from '@material-ui/icons';
const AddALP = ({ portfolios, regions, lessees, operators, pltConstants, addAsset, toggleModalFn, ownersList, location, getResponseBack, permission }) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [isLoading, setLoading] = useState(false);
    const [formData, setFormData] = useState({ ownership_type: 1 ,same_operator_lessee:false,status:null,sub_status:null});
    const [error, setError] = useState({});
    const [apuTypes, setApuTypes] = useState([]);
    const [subAssemblyList, setSubAssemblyList] = useState([])
    const [addTemplateModal, setAddTemplateModal] = useState(false);
    const [defaultTemplate, setDefaultTemplate] = useState({});
    const [noRecordsPermission, setNoRecordsPermission] = useState({ modal: false });
    const [templateData, setTemplateData] = useState(null);
    const [templatPayload, setTemplatPayload] = useState(null);
    const [pageLoader, setPageLoader] = useState(null);

    useEffect(() => {
        getApuTypes();

    }, []);
    const getLgSubAssembly = (position) => {
        globalGetService(`console/lg_sub_assemblies_position/?position=${position.value}`)
            .then((response) => {
                if (checkApiStatus(response)) {
                    setSubAssemblyList(response.data.data)
                }
            });
    }
    const getApuTypes = () => {
        globalGetService('console/apu-types/')
            .then(response => {
                if (checkApiStatus(response)) {
                    setApuTypes(response.data.data.apu_types);
                }
            })
    }
    // const onFieldChange = (keyParam, value) => {
    //     setFormData({...formData, [keyParam]:value})
    // }
    const onFieldChangeLessee = (e, keyParam, value) => {
        setFormData({ ...formData, [keyParam]: value })
    }
    const onFieldChange = (keyParam, value) => {
        if (keyParam === "position") {
            setFormData({ ...formData, [keyParam]: value?.value })
            if (value) {
                getLgSubAssembly(value)
            }
        }if (keyParam === 'ownership_type'&& jetStreamInstance){          
            setFormData({...formData,[keyParam]:value, 'status':null,'sub_status':null})
        }else if(keyParam === 'status' && jetStreamInstance){
            setFormData({...formData,[keyParam]:value, 'sub_status':null})
        } else {
            setFormData({ ...formData, [keyParam]: value })
        }
    }

    const onRestErrorKey = (keyParam) => {
        setError({ ...error, [keyParam]: '' })
    }
    const getUuid = (data) => {
        setLoading(true)
        globalGetService(`console/${assetTypeValues[addAsset.asset_type]?.urlType}/${data.slug}/records-detail/`)
            .then(response => {
                if (checkApiStatus(response)) {
                    addTemplateonSubmit(templateData?.templateDetails ? templateData : defaultTemplate, data, response.data.data)

                }
                setLoading(false)
            })
    }
    const addTemplateonSubmit = (templateData, assetData, recordsData) => {
        let payload = Object.assign({}, templateData)
        payload = {
            ...payload,
            asset_type: addAsset?.asset_type,
            folderDetails: payload?.checkedFolders ? [...payload?.checkedFolders] : templateData?.folderDetails,
            asset: payload?.asset ? { ...payload?.asset, id: assetData.id } : { id: assetData.id },
            recordsParentFolder: { ...recordsData?.record_folders?.technical_records, name: "technical" },
            majorAssembly: false,
            majorAssemblyAsset: null,
            majorAssemblyAssetType: null,
            initial_template:true,
            templateDetails: templateData?.templateDetails
        }
        delete payload['checkedFolders']
        delete payload['selectedTemplate']
        setLoading(true)
        globalPostService(`records/v2/lessor-folder-template/apply-template/${templateData.templateDetails?.id}/`, payload)
            .then(response => {
                if (checkApiStatus(response)) {
                    setLoading(false)
                    setTimeout(() => {
                        window.location.reload()
                    }, 200)
                    enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { horizontal: 'right', vertical: 'top' } });
                }
            });
    }
    const onAddNewAsset = (data) => {
        let validationInputs = {};
        validationInputs = {
            ...validationInputs,
            operator_id: formData?.status?.value == 1 && formData.same_operator_lessee === false ? formData?.operator_id ? '' : 'Please select Operator' : '',
            serial_number: formData.serial_number ? '' : 'Please enter Serial Number',
            part_number: formData.part_number ? '' : 'Please enter Part Number',
            status: formData.status ? '' : 'Please select Lease status',
        }
        if (addAsset.asset_type == 5) {
            validationInputs = {
                ...validationInputs,
                part_number: formData.part_number = '',
            }
        }
        if (formData.ownership_type === 2 && !jetStreamInstance) {
            validationInputs = {
                ...validationInputs,
                portfolio: formData.owner_portfolio ? '' : 'Please select Portfolio',
            }
        }
        if (addAsset.asset_type === 3) {
            validationInputs = {
                ...validationInputs,
                apu_type: formData.apu_type ? '' : 'Please select APU Type',
            }
        }
        if (addAsset.asset_type === 4) {
            validationInputs = {
                ...validationInputs,
                position: formData.position ? '' : 'Please select Position',
            }
        }
        if (getLocalStorageInfo()?.defaultLessor?.id === 242) {
            validationInputs = {
                ...validationInputs,
                sub_status: formData.sub_status ? '' : 'Please select Sub Status',
            }
        }
        if (addAsset.asset_type === 5 || addAsset.asset_type === 3) {
            validationInputs = {
                ...validationInputs,
                date_of_manufacture: formData.date_of_manufacture ? moment(formData.date_of_manufacture, backendDateFormat, true).isValid() ? '' : 'Please enter valid Date' : 'Please enter Date of Manufacture',
            }
        }
        if (formData?.status?.value == 1) {
            validationInputs = {
                ...validationInputs,
                lessee_id: formData.lessee_id ? '' : 'Please select Lessee'
            }
            // if (!formData.same_operator_lessee) {
            //     validationInputs = {
            //         ...validationInputs,
            //         operator_id: ''
            //     }
            // }
        }
        if (formData?.status?.value == 1 && formData?.lessee_id && formData.same_operator_lessee === false && formData?.operator_id) {
            if (formData.lessee_id.name == formData.operator_id.name) {
                validationInputs = {
                    ...validationInputs,
                    operator_id: 'Operator Should be different from Lessee'
                }
            }
        }
        if (getLocalStorageInfo()?.defaultLessor?.id === 242 && formData?.status?.value == 5) {
            validationInputs = {
                ...validationInputs,
                sub_status: ''
            }
        }
        if (Object.keys(validationInputs).every((k) => { return validationInputs[k] === '' })) {
            if (!permission?.settings?.records_template?.includes('C') && getLocalStorageInfo()?.is_records && (noRecordsPermission?.modal == false)) {
                setNoRecordsPermission({ modal: true })
            }
            else {
                setLoading(true)
                let url = addAsset.asset_type === 3 ? 'console/add-apu/' : addAsset.asset_type === 4 ? 'console/add-landing-gear/' : 'console/add-propeller/';
                let payload = Object.assign({}, formData);
                payload = {
                    ...payload,
                    apu_type: payload.apu_type && payload.apu_type.id ? payload.apu_type.id : null,
                    position: payload?.position?.value ? payload?.position?.value : payload?.position ? payload?.position : 0,
                    region: payload.region && payload.region.id ? payload.region.id : null,
                    credit_classification: payload.credit_classification && payload.credit_classification.value ? payload.credit_classification.value : null,
                    status: payload.status.value,
                    sub_status: payload?.sub_status?.value || null,
                    // lessee_id: payload.lessee_id && payload.lessee_id.id ? payload.lessee_id.id : null,
                    operator_id: payload.operator_id && payload.operator_id.id ? payload.operator_id.id : null,
                    archive_status: payload.status.value == 5 ? true : false,
                    tsn: payload.tsn ? payload.tsn : 0,
                    csn: payload.csn ? payload.csn : 0,
                    tslsv: payload.tslsv ? payload.tslsv : 0,
                    cslsv: payload.cslsv ? payload.cslsv : 0,
                    csn_at_acquisition: payload.csn_at_acquisition ? payload.csn_at_acquisition : 0,
                    average_monthly_cycles: payload.average_monthly_cycles ? payload.average_monthly_cycles : 0,
                    average_monthly_hours: payload.average_monthly_hours ? payload.average_monthly_hours : 0,
                    sub_assembly: payload.sub_assembly ? payload.sub_assembly : null,
                    location: payload?.location ? payload?.location : null,
                    tsn_at_acquisition: payload?.tsn_at_acquisition ? payload?.tsn_at_acquisition : 0,
                    tslsv_at_acquisition: payload?.tslsv_at_acquisition ? payload?.tslsv_at_acquisition : 0,
                    cslsv_at_acquisition: payload?.cslsv_at_acquisition ? payload?.cslsv_at_acquisition : 0

                }
                if (payload.ownership_type === 2 && !jetStreamInstance) {
                    if (payload.owner_portfolio.id) {
                        payload = {
                            ...payload,
                            owner_portfolio: payload?.owner_portfolio?.id
                        }
                    }
                }
                if (addAsset.asset_type === 5) {
                    payload = {
                        ...payload,
                        installed_position: payload.installed_position && payload.installed_position.value ? payload.installed_position.value : 0,
                    }
                }
                if (formData?.status?.value == 4) {
                    payload = {
                        ...payload,
                        csn_at_sold_date: payload?.csn_at_sold_date ? payload?.csn_at_sold_date : 0,
                        tsn_at_sold_date: payload?.tsn_at_sold_date ? payload?.tsn_at_sold_date : 0
                    }
                }
                let dataCopy = Object.assign({}, formData)
                if (data == 'notify_email') {
                    payload = {
                        ...payload,
                        notify_email: true
                    }
                }
                globalPostService(url, payload)
                    .then(response => {
                        if (checkApiStatus(response)) {
                            if (dataCopy?.add_template === true) {
                                getUuid(response.data.data)
                            }
                            else {
                                toggleModalFn();
                            }
                            setLoading(false)
                            enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
                            setTimeout(() => {
                                if (payload.status == 5) {
                                    browserHistory.push('/archived-assets')
                                } else if (addAsset.asset_type === 3) {
                                    if (location.query.apps && location.query.asset_type) {
                                        getResponseBack()
                                    } else {
                                        browserHistory.push(`/technical-specification/apu/${response.data.data.slug}/basic-details`)
                                    }
                                } else if (addAsset.asset_type === 4) {
                                    if (location.query.apps && location.query.asset_type) {
                                        getResponseBack()
                                    } else {
                                        browserHistory.push(`/technical-specification/lg/${response.data.data.slug}/basic-details`)
                                    }
                                } else if (addAsset.asset_type === 5) {
                                    if (location.query.apps && location.query.asset_type) {
                                        getResponseBack()
                                    } else {
                                        browserHistory.push(`/technical-specification/propeller/${response.data.data.slug}/basic-details`)
                                    }
                                } else {
                                    window.location.reload(false)
                                }

                            }, 800);
                            trackActivity('Item Added', { page_title: 'Asset Listing', asset_type: `${addAsset.asset_type}`, serial_no: formData.serial_number, event_type: `Add ${addAsset.asset_type}`, event_desc: `Added ${addAsset.asset_type} data from Add ${addAsset.asset_type} form` })
                        } else {
                            setLoading(false)
                            // enqueueSnackbar(response.data.message, { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
                        }
                    })
            }
        } else {
            setError(validationInputs)
        }
    }
    const fetchTemplate = (flag) => {
        if (flag) {
            let payload = {}
            payload = {
                ...payload,
                asset_type: addAsset.asset_type,
                asset: formData.apu_type || {},
                asset_model_type: formData.apu_type || null,
                majorAssemblyAssetType: null,
                majorAssemblyAsset: {},
                position: formData.position || null,
                is_major_assembly: false,
                serial_number: formData?.serial_number
            }
            setPageLoader(true)
            globalPostService(`records/v2/get-lessor-folder-template/`, payload)
                .then(response => {
                    setPageLoader(false)
                    setTemplatPayload(payload)
                    if (checkApiStatus(response)) {
                        if (response.data.data) {
                            setDefaultTemplate(response.data.data)
                        }
                    }
                    else {
                        setAddTemplateModal({ modal: true, data: {}, apiCall: 'apicall' })
                    }
                })
        }
        else {
            setDefaultTemplate({})
        }
    }
    const getTemplateDetailsFn = () => {
        setAddTemplateModal({ modal: true, data: defaultTemplate })
    }
    const saveTemplateData = (data) => {
        setTemplateData(data)
    }
    const checkTemplateData =(value) =>{
        if(!value){
            setTemplateData({}) 
        }
    }
    const checkForDefaultTemp =()=>{
        if( !defaultTemplate?.templateDetails){
            onFieldChange('add_template',false)
        }
    }
    const { jacLeaseStatus, defaultLeaseStatus, airAsiaLeaseStatus,thirdParty } = leaseStatus;
    const LgPositionValues = [1, 2, 3];
    const LgPositionValue = [1, 2, 3, 4, 5, 6];
    const lgPosition = pltConstants.filter(item => item.type === "lg_position" && LgPositionValues.includes(item.value));
    const lgPositionforAll = pltConstants.filter(item => item.type === "lg_position" && LgPositionValue.includes(item.value));
    let jetstreamOption;
    if (formData?.status?.value == 1) {
        jetstreamOption = subStatus.onLease;
    } else if (formData?.status?.value == 2) {
        if (formData.ownership_type == 2) {
            jetstreamOption = subStatus.offLease.filter(item => item.value !== '25');
        } else {
            jetstreamOption = subStatus.offLease;
        }
    } else if (formData?.status?.value == 17) {
        jetstreamOption = subStatus.sold;
    } else if (formData?.status?.value == 14) {
        jetstreamOption = subStatus.partOut;
    } else if (formData?.status?.value == 15) {
        jetstreamOption = subStatus.installedOnly;
    } else if (formData?.status?.value == 16) {
        jetstreamOption = subStatus.writtenOff;
    } else {
        jetstreamOption = pltConstants.filter(item => item.type === 'asset_sub_status');
    }

    return (
        <Fragment>
            {pageLoader ? <PageLoader /> : null}
            <Dialog
                open={true}
                onClose={() => toggleModalFn()}
                className='console-management-modal'
                maxWidth={'md'}
            >
                <DialogTitle id="scroll-dialog-title">
                    Add {addAsset.asset_type === 3 ? 'APU' : addAsset.asset_type === 4 ? 'Landing Gear' : 'Propeller'}
                </DialogTitle>
                <DialogContent dividers={true} className="data-upload-modal">
                    <form>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    required
                                    id="serial_number"
                                    label="Serial Number"
                                    fullWidth
                                    margin="normal"
                                    value={formData.serial_number}
                                    error={error.serial_number ? true : false}
                                    helperText={error.serial_number ? error.serial_number : ''}
                                    onFocus={() => onRestErrorKey('serial_number')}
                                    onChange={(e, value) => onFieldChange('serial_number', e.target.value)}
                                    InputLabelProps={{ shrink: true }}
                                    inputProps={{ maxLength: 20 }}
                                    variant='outlined'
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <MuiPickersUtilsProvider utils={MomentUtils}>
                                    <KeyboardDatePicker
                                        required={addAsset.asset_type === 5 || addAsset.asset_type === 3}
                                        margin="normal"
                                        id="date_of_manufacture"
                                        label="Date of Manufacture"
                                        format={fieldDateFormat}
                                        fullWidth
                                        disableFuture
                                        InputLabelProps={{ shrink: true }}
                                        minDate={moment().subtract(40, 'years')}
                                        value={formData.date_of_manufacture ? formData.date_of_manufacture : null}
                                        error={error.date_of_manufacture ? true : false}
                                        helperText={error.date_of_manufacture ? error.date_of_manufacture : ''}
                                        onFocus={() => onRestErrorKey('date_of_manufacture')}
                                        onChange={(data, value) => { onFieldChange('date_of_manufacture', data ? moment(data).format(backendDateFormat) : null); onRestErrorKey('manufacturing_date') }}
                                        inputVariant='outlined'
                                    />
                                </MuiPickersUtilsProvider>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    required={addAsset.asset_type == 5 ? false : true}
                                    id="part_number"
                                    label="Part Number"
                                    fullWidth
                                    margin="normal"
                                    value={formData.part_number}
                                    error={error.part_number ? true : false}
                                    helperText={error.part_number ? error.part_number : ''}
                                    onFocus={() => onRestErrorKey('part_number')}
                                    onChange={(e, value) => onFieldChange('part_number', e.target.value)}
                                    InputLabelProps={{ shrink: true }}
                                    inputProps={{ maxLength: 20 }}
                                    variant='outlined'
                                />
                            </Grid>
                            {addAsset.asset_type === 5 ?
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        id="model"
                                        label="Model"
                                        fullWidth
                                        margin="normal"
                                        value={formData.model}
                                        onChange={(e, value) => onFieldChange('model', e.target.value)}
                                        InputLabelProps={{ shrink: true }}
                                        inputProps={{ maxLength: 50 }}
                                        variant='outlined'
                                    />
                                </Grid> : null}
                            {addAsset.asset_type === 5 ?
                                <Grid item xs={12} md={6}>
                                    <Autocomplete
                                        options={pltConstants.filter(item => item.type == "engine_position")}
                                        getOptionLabel={option => option.label}
                                        id="installed_position"
                                        value={formData.installed_position}
                                        onChange={(e, value) => onFieldChange('installed_position', value)}
                                        renderInput={params => <TextField {...params} label="Installed Position" margin="normal" fullWidth InputLabelProps={{ shrink: true }} variant='outlined' />}
                                    />
                                </Grid> : null
                            }
                            <Grid item xs={12} md={6}>
                                <TextField
                                    id="manufacturer"
                                    label="Manufacturer"
                                    fullWidth
                                    margin="normal"
                                    value={formData.manufacturer}
                                    onChange={(e, value) => onFieldChange('manufacturer', e.target.value)}
                                    InputLabelProps={{ shrink: true }}
                                    inputProps={{ maxLength: 50 }}
                                    variant='outlined'
                                />
                            </Grid>
                            {addAsset.asset_type === 3 ?
                                <Grid item xs={12} md={6}>
                                    <Autocomplete
                                        options={apuTypes}
                                        getOptionLabel={option => option.name}
                                        id="apu_type"
                                        value={formData.apu_type}
                                        onChange={(e, value) => onFieldChange('apu_type', value)}
                                        renderInput={params => <TextField required error={error.apu_type ? true : false} helperText={error.apu_type ? error.apu_type : ''} onFocus={() => onRestErrorKey('apu_type')} {...params} label="APU Type" margin="normal" fullWidth InputLabelProps={{ shrink: true }} variant='outlined' />}
                                    />
                                </Grid> : null
                            }
                            {addAsset.asset_type === 4 ?
                                <Grid item xs={12} md={6}>
                                    <Autocomplete
                                        options={getLocalStorageInfo()?.defaultLessor?.id === 442 ? lgPosition : lgPositionforAll}
                                        getOptionLabel={option => option.label}
                                        id="position"
                                        value={formData.position}
                                        onChange={(e, value) => onFieldChange('position', value)}
                                        renderInput={params => <TextField required error={error.position ? true : false} helperText={error.position ? error.position : ''} onFocus={() => onRestErrorKey('position')} {...params} label="Position" margin="normal" fullWidth InputLabelProps={{ shrink: true }} variant='outlined' />}
                                    />
                                </Grid> : null
                            }
                            <Grid item xs={12} md={6}>
                                <Autocomplete
                                    options={regions}
                                    getOptionLabel={option => option.name}
                                    id="region"
                                    value={formData.region}
                                    onChange={(e, value) => onFieldChange('region', value)}
                                    renderInput={params => <TextField {...params} label="Region of Operation" margin="normal" fullWidth InputLabelProps={{ shrink: true }} variant='outlined' />}
                                />
                            </Grid>
                            {addAsset.asset_type === 4 ?
                                <Grid item xs={12} md={6}>
                                    <Autocomplete
                                        options={subAssemblyList}
                                        getOptionLabel={option => option.name}
                                        id="sub_assembly"
                                        value={formData.sub_assembly ? formData.sub_assembly : null}
                                        onChange={(e, value) => onFieldChange('sub_assembly', value)}
                                        renderInput={params => <TextField {...params} label="Sub Assembly" margin="normal" fullWidth InputLabelProps={{ shrink: true }} variant='outlined' />}
                                    />
                                </Grid> : null}
                            {addAsset.asset_type === 3 ?
                                <Grid item xs={12} md={6}>
                                    <MuiPickersUtilsProvider utils={MomentUtils}>
                                        <KeyboardDatePicker
                                            margin="normal"
                                            id="cta_exp_date"
                                            label={
                                                <div>
                                                    CTA Date
                                                    <Tooltip title="Certificate of Technical Acceptance" placement='top' arrow>
                                                        <InfoIcon fontSize="small" style={{ verticalAlign: 'bottom', marginLeft: '3px' }} />
                                                    </Tooltip>
                                                </div>
                                            }
                                            format={fieldDateFormat}
                                            fullWidth
                                            disableFuture
                                            InputLabelProps={{ shrink: true, style: { pointerEvents: "auto" } }}
                                            maxDate={moment()}
                                            value={formData.cta_exp_date ? formData.cta_exp_date : null}
                                            onFocus={() => onRestErrorKey('cta_exp_date')}
                                            onChange={(data, value) => onFieldChange('cta_exp_date', data ? moment(data).format(backendDateFormat) : null)}
                                            inputVariant='outlined'
                                        />
                                    </MuiPickersUtilsProvider>
                                </Grid> : null}
                            {addAsset.asset_type === 3 || addAsset.asset_type === 5 ?
                                <Grid item xs={12} md={6}>
                                    <MuiPickersUtilsProvider utils={MomentUtils}>
                                        <KeyboardDatePicker
                                            margin="normal"
                                            id="delivery_date_to_current_operator"
                                            label="Delivery Date to Current Operator"
                                            format={fieldDateFormat}
                                            fullWidth
                                            disableFuture
                                            InputLabelProps={{ shrink: true }}
                                            minDate={moment(formData.date_of_manufacture)}
                                            maxDate={moment()}
                                            value={formData.delivery_date_to_current_operator ? formData.delivery_date_to_current_operator : null}
                                            onFocus={() => onRestErrorKey('delivery_date_to_current_operator')}
                                            onChange={(data, value) => onFieldChange('delivery_date_to_current_operator', data ? moment(data).format(backendDateFormat) : null)}
                                            inputVariant='outlined'
                                        />
                                    </MuiPickersUtilsProvider>
                                </Grid> : null}
                            {addAsset.asset_type === 3 ?
                                <Grid item xs={12} md={6}>
                                    <MuiPickersUtilsProvider utils={MomentUtils}>
                                        <KeyboardDatePicker
                                            margin="normal"
                                            id="next_delivery_date"
                                            label="Next Delivery Date"
                                            format={fieldDateFormat}
                                            fullWidth
                                            InputLabelProps={{ shrink: true }}
                                            minDate={moment()}
                                            value={formData.next_delivery_date ? formData.next_delivery_date : null}
                                            onFocus={() => onRestErrorKey('next_delivery_date')}
                                            onChange={(data, value) => onFieldChange('next_delivery_date', data ? moment(data).format(backendDateFormat) : null)}
                                            inputVariant='outlined'
                                        />
                                    </MuiPickersUtilsProvider>
                                </Grid> : null}
                            {addAsset.asset_type === 3 || addAsset.asset_type === 5 ?
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        id="next_use"
                                        label="Next Use"
                                        fullWidth
                                        margin="normal"
                                        value={formData.next_use}
                                        onChange={(e, value) => onFieldChange('next_use', e.target.value)}
                                        InputLabelProps={{ shrink: true }}
                                        inputProps={{ maxLength: 50 }}
                                        variant='outlined'
                                    />
                                </Grid> : null}
                            {addAsset.asset_type === 3 ?
                                <Grid item xs={12}>
                                    <Grid container spacing={1}>
                                        <Grid item xs={12}>
                                            <h6>Acquisition Details</h6>
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <MuiPickersUtilsProvider utils={MomentUtils}>
                                                <KeyboardDatePicker
                                                    margin="normal"
                                                    id="acquisition_date"
                                                    label="Acquisition Date"
                                                    format={fieldDateFormat}
                                                    fullWidth
                                                    disableFuture
                                                    InputLabelProps={{ shrink: true }}
                                                    maxDate={moment()}
                                                    minDate={moment(formData.date_of_manufacture)}
                                                    value={formData.acquisition_date ? formData.acquisition_date : null}
                                                    onFocus={() => onRestErrorKey('acquisition_date')}
                                                    onChange={(data, value) => onFieldChange('acquisition_date', data ? moment(data).format(backendDateFormat) : null)}
                                                    inputVariant='outlined'
                                                />
                                            </MuiPickersUtilsProvider>
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <TextField
                                                id="tsn_at_acquisition"
                                                label="TSN at Acquisition"
                                                fullWidth
                                                margin="normal"
                                                value={formData.tsn_at_acquisition ? formData.tsn_at_acquisition : ''}
                                                onChange={(e, value) => regexConstants.numberWithDot.test(e.target.value) ? onFieldChange('tsn_at_acquisition', e.target.value) : e.preventDefault()}
                                                InputLabelProps={{ shrink: true }}
                                                inputProps={{ maxLength: 50 }}
                                                variant='outlined'
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <TextField
                                                id="csn_at_acquisition"
                                                label="CSN at Acquisition "
                                                fullWidth
                                                margin="normal"
                                                value={formData.csn_at_acquisition ? formData.csn_at_acquisition : ''}
                                                onChange={(e, value) => regexConstants.numberWithDot.test(e.target.value) ? onFieldChange('csn_at_acquisition', e.target.value) : e.preventDefault()}
                                                InputLabelProps={{ shrink: true }}
                                                inputProps={{ maxLength: 50 }}
                                                variant='outlined'
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <TextField
                                                id="acquired_from"
                                                label="Acquired from"
                                                fullWidth
                                                margin="normal"
                                                value={formData.acquired_from}
                                                onChange={(e, value) => onFieldChange('acquired_from', e.target.value)}
                                                InputLabelProps={{ shrink: true }}
                                                inputProps={{ maxLength: 50 }}
                                                variant='outlined'
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <TextField
                                                id="tslsv_at_acquisition"
                                                label="TSLSV at Acquisition"
                                                fullWidth
                                                margin="normal"
                                                value={formData.tslsv_at_acquisition ? formData.tslsv_at_acquisition : ''}
                                                onChange={(e, value) => regexConstants.numberWithDot.test(e.target.value) ? onFieldChange('tslsv_at_acquisition', e.target.value) : e.preventDefault()}
                                                InputLabelProps={{ shrink: true }}
                                                inputProps={{ maxLength: 50 }}
                                                variant='outlined'
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <TextField
                                                id="cslsv_at_acquisition"
                                                label="CSLSV at Acquisition"
                                                fullWidth
                                                margin="normal"
                                                value={formData.cslsv_at_acquisition ? formData.cslsv_at_acquisition : ''}
                                                onChange={(e, value) => regexConstants.numberWithDot.test(e.target.value) ? onFieldChange('cslsv_at_acquisition', e.target.value) : e.preventDefault()}
                                                InputLabelProps={{ shrink: true }}
                                                inputProps={{ maxLength: 50 }}
                                                variant='outlined'
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid> : null}
                            <Grid item xs={12} md={6}>
                                <GoogleLocationField
                                    onFieldChange={onFieldChange}
                                    location={formData.location ? formData.location : ''}
                                />
                            </Grid>
                            <Grid item md={12} xs={12}>
                                <FormControl component="fieldset">
                                    <FormLabel className="MuiInputLabel-shrink" component="label">Ownership Status</FormLabel>
                                    <RadioGroup row aria-label="position" name="ownership_type" defaultValue="top">
                                        <FormControlLabel value={1} control={<Radio checked={formData.ownership_type === 1} onChange={() => onFieldChange('ownership_type', 1)} color="primary" size="small" />} label="Owned" />
                                        <FormControlLabel value={2} control={<Radio checked={formData.ownership_type === 2} onChange={() => onFieldChange('ownership_type', 2)} color="primary" size="small" />} label="Managed" />
                                        {jetStreamInstance?<FormControlLabel value={3} control={<Radio checked={formData.ownership_type === 3} onChange={() => onFieldChange('ownership_type', 3)} color="primary" size="small" />} label="Third party"/>:null}  
                                    </RadioGroup>
                                </FormControl>
                            </Grid>
                            {formData.ownership_type === 2 && !jetStreamInstance ?
                                <Grid item xs={12}>
                                    <Autocomplete
                                        options={ownersList}
                                        getOptionLabel={option => option.name}
                                        id="owner_portfolio"
                                        value={formData.owner_portfolio}
                                        onChange={(e, value) => onFieldChange('owner_portfolio', value)}
                                        renderInput={params => <TextField required error={error.portfolio ? true : false} helperText={error.portfolio ? error.portfolio : ''} onFocus={() => onRestErrorKey('portfolio')} {...params} label="Portfolio" margin="normal" fullWidth InputLabelProps={{ shrink: true }} variant='outlined' />}
                                    />
                                </Grid> : null
                            }
                            {/* <Grid item xs={12} md={6}>
                                <Autocomplete
                                    options={pltConstants.filter(item => item.type === 'credit_classification')}
                                    getOptionLabel={option => option.label}
                                    id="credit_classification"
                                    value={formData.credit_classification}
                                    onChange={(e, value) => onFieldChange('credit_classification', value)}
                                    renderInput={params => <TextField {...params} label="Credit Classification" margin="normal" fullWidth InputLabelProps={{ shrink: true }} variant='outlined' />}
                                />
                            </Grid> */}
                            <Grid item xs={12} md={6}>
                                <Autocomplete
                                    options={getLocalStorageInfo().defaultLessor.id === 348 ? airAsiaLeaseStatus : getLocalStorageInfo().defaultLessor.id === 442 &&formData.ownership_type === 3 ?thirdParty :getLocalStorageInfo().defaultLessor.id === 442 && formData.ownership_type !== 3?jacLeaseStatus: defaultLeaseStatus}
                                    getOptionLabel={option => option.label}
                                    id="status"
                                    value={formData.status}
                                    onChange={(e, value) => onFieldChange('status', value)}
                                    renderInput={params => <TextField required error={error.status ? true : false} helperText={error.status ? error.status : ''} onFocus={() => onRestErrorKey('status')} {...params} label="Lease Status" margin="normal" fullWidth InputLabelProps={{ shrink: true }} variant='outlined' />}
                                />
                            </Grid>
                            {formData?.status?.value == 4 ?
                                <Grid item xs={12} md={6}>
                                    <MuiPickersUtilsProvider utils={MomentUtils}>
                                        <KeyboardDatePicker
                                            margin="normal"
                                            id="sold_out_date"
                                            label="Date of Sale"
                                            format={fieldDateFormat}
                                            fullWidth
                                            disableFuture
                                            InputLabelProps={{ shrink: true }}
                                            value={formData.sold_out_date ? formData.sold_out_date : null}
                                            onChange={(data, value) => onFieldChange('sold_out_date', data ? moment(data).format(backendDateFormat) : null)}
                                            minDate={(moment(formData.date_of_manufacture))}
                                            maxDate={moment()}
                                            inputVariant='outlined'
                                        />
                                    </MuiPickersUtilsProvider>
                                </Grid> : null
                            }
                            {formData?.status?.value == 4 ?
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        id="tsn_at_sold_date"
                                        label="TSN at Sale"
                                        fullWidth
                                        margin="normal"
                                        value={formData.tsn_at_sold_date ? formData.tsn_at_sold_date : ''}
                                        onChange={(e, value) => regexConstants.numberWithDot.test(e.target.value) ? onFieldChange('tsn_at_sold_date', e.target.value) : e.preventDefault()}
                                        InputLabelProps={{ shrink: true }}
                                        inputProps={{ maxLength: 50 }}
                                        variant='outlined'
                                    />
                                </Grid> : null
                            }
                            {formData?.status?.value == 4 ?
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        id="csn_at_sold_date"
                                        label="CSN at Sale"
                                        fullWidth
                                        margin="normal"
                                        value={formData.csn_at_sold_date ? formData.csn_at_sold_date : ''}
                                        onChange={(e, value) => regexConstants.numberWithDot.test(e.target.value) ? onFieldChange('csn_at_sold_date', e.target.value) : e.preventDefault()}
                                        InputLabelProps={{ shrink: true }}
                                        inputProps={{ maxLength: 50 }}
                                        variant='outlined'
                                    />
                                </Grid> : null
                            }
                            {pltConstants.filter(item => item.type === 'asset_sub_status').length ? formData?.status?.value == 5 ? null :
                                <Grid item xs={12} md={6}>
                                    <Autocomplete
                                        options = {jetStreamInstance?jetstreamOption:pltConstants.filter(item => item.type === 'asset_sub_status') }
                                        getOptionLabel={option => option.label}
                                        id="sub_status"
                                        value={formData.sub_status}
                                        onChange={(e, value) => onFieldChange('sub_status', value)}
                                        renderInput={params => <TextField required={getLocalStorageInfo()?.defaultLessor?.id === 242 ? true : false} error={error.sub_status ? true : false} helperText={error.sub_status ? error.sub_status : ''} onFocus={() => onRestErrorKey('sub_status')} {...params} label="Sub Status" margin="normal" fullWidth InputLabelProps={{ shrink: true }} variant='outlined' />}
                                    />
                                </Grid> : null
                            }
                            {formData?.status?.value == 1 ?
                                <>
                                    <Grid item xs={12} md={6}>
                                        <LesseeCreate
                                            options={lessees}
                                            required={formData?.status?.value == 1 ? true : false}
                                            paramsKey='lessee_id'
                                            optionKey='name'
                                            label='Lessee'
                                            value={formData.lessee_id ? formData.lessee_id : null}
                                            onFieldChange={(e, paramsKey, newValue) => onFieldChangeLessee(e, 'lessee_id', newValue)}
                                            error={error.lessee_id}
                                            resetErrorKey={() => onRestErrorKey('lessee_id')}
                                            disabled={formData?.status?.value != 1 ? true : false}
                                        />
                                        {/* <Autocomplete
                                        options={lessees}
                                        getOptionLabel={option => option.name}
                                        id="lessee_id"
                                        value={formData.lessee_id}
                                        onChange={(e, value) => onFieldChange('lessee_id', value)}
                                        renderInput={params => <TextField error={error.lessee_id ? true : false} required={formData?.status?.value == 1 ? true : false} helperText={error.lessee_id ? error.lessee_id : ''} onFocus={() => onRestErrorKey('lessee_id')} {...params} label="Lessee" margin="normal" fullWidth InputLabelProps={{ shrink: true }} variant='outlined' />}
                                        disabled={formData?.status?.value != 1 ? true : false}
                                    /> */}
                                    </Grid>

                                    <Grid item md={12} xs={12}>
                                        <FormControl component="fieldset">
                                            <FormLabel className="MuiInputLabel-shrink" component="label">Operator is same as Lessee ?</FormLabel>
                                            <RadioGroup row aria-label="position" name="same_operator_lessee" >
                                                <FormControlLabel value={true} control={<Radio onChange={() => onFieldChange('same_operator_lessee', true)} checked={formData.same_operator_lessee === true} color="primary" size="small" />} label="Yes" />
                                                <FormControlLabel value={false} control={<Radio onChange={() => onFieldChange('same_operator_lessee', false)} checked={formData.same_operator_lessee === false} color="primary" size="small" />} label="No" />
                                            </RadioGroup>
                                        </FormControl>
                                    </Grid>
                                    {formData.same_operator_lessee === false ?
                                        <Grid item xs={12}>
                                            <Autocomplete
                                                options={operators}
                                                getOptionLabel={option => option.name}
                                                id="operator_id"
                                                value={formData.operator_id}
                                                onChange={(e, value) => onFieldChange('operator_id', value)}
                                                renderInput={params => <TextField error={formData.same_operator_lessee === false ? error.operator_id ? true : false : false} required={formData.same_operator_lessee === false ? true : false} helperText={formData.same_operator_lessee === false ? error.operator_id : ''} onFocus={() => onRestErrorKey('operator_id')} {...params} label="Operator" margin="normal" fullWidth InputLabelProps={{ shrink: true }} variant='outlined' />}
                                            />
                                        </Grid> : null
                                    }
                                </> : null}
                            <Grid item xs={12}>
                                <Grid container spacing={1}>
                                    <Grid item xs={12} md={12}>
                                        <MuiPickersUtilsProvider utils={MomentUtils}>
                                            <KeyboardDatePicker
                                                margin="normal"
                                                id="last_utilization_updated"
                                                label="Utilization as of Date"
                                                format={fieldDateFormat}
                                                fullWidth
                                                disableFuture
                                                InputLabelProps={{ shrink: true }}
                                                maxDate={moment()}
                                                value={formData.last_utilization_updated ? formData.last_utilization_updated : null}
                                                onChange={(data, value) => onFieldChange('last_utilization_updated', data ? moment(data).format(backendDateFormat) : null)}
                                                inputVariant='outlined'
                                            />
                                        </MuiPickersUtilsProvider>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            id="tsn"
                                            label="TSN"
                                            fullWidth
                                            margin="normal"
                                            value={formData.tsn ? formData.tsn : ''}
                                            onChange={(e) => { regexConstants.numberWithDot.test(e.target.value) ? onFieldChange('tsn', e.target.value) : e.preventDefault() }}
                                            InputLabelProps={{ shrink: true }}
                                            inputProps={{ maxLength: 10 }}
                                            variant='outlined'
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            id="csn"
                                            label="CSN"
                                            fullWidth
                                            margin="normal"
                                            value={formData.csn ? formData.csn : ''}
                                            onChange={(e) => { regexConstants.numberWithDot.test(e.target.value) ? onFieldChange('csn', e.target.value) : e.preventDefault() }}
                                            InputLabelProps={{ shrink: true }}
                                            inputProps={{ maxLength: 10 }}
                                            variant='outlined'
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            id="average_monthly_hours"
                                            label="Avg. Monthly Hours"
                                            fullWidth
                                            margin="normal"
                                            value={formData.average_monthly_hours ? formData.average_monthly_hours : ''}
                                            onChange={(e) => { regexConstants.numberWithDot.test(e.target.value) ? onFieldChange('average_monthly_hours', e.target.value) : e.preventDefault() }}
                                            InputLabelProps={{ shrink: true }}
                                            inputProps={{ maxLength: 10 }}
                                            variant='outlined'
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            id="average_monthly_cycles"
                                            label="Avg. Monthly Cycles"
                                            fullWidth
                                            margin="normal"
                                            value={formData.average_monthly_cycles ? formData.average_monthly_cycles : ''}
                                            onChange={(e) => { regexConstants.numberWithDot.test(e.target.value) ? onFieldChange('average_monthly_cycles', e.target.value) : e.preventDefault() }}
                                            InputLabelProps={{ shrink: true }}
                                            inputProps={{ maxLength: 10 }}
                                            variant='outlined'
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            {
                                getLocalStorageInfo()?.is_records ?
                                    <Grid item md={12}>
                                        <FormControlLabel control={<Checkbox disabled={formData.serial_number && permission?.settings?.records_template?.includes('C') ? false : true} size="small" color='primary' checked={formData?.add_template ? true : false} onChange={(e) => { onFieldChange('add_template', e.target.checked); fetchTemplate(e.target.checked);checkTemplateData(e.target.checked) }} name="add_template" />} label={<p style={{ fontSize: '13px' }}>
                                            <span>Add Dataroom Template</span>
                                            <Tooltip title='Please click on “Add Dataroom Template” to choose a template and apply it to this asset’s Dataroom.' arrow>
                                                <Info style={{ fontSize: '13px', marginLeft: '3px' }} />
                                            </Tooltip>
                                        </p>} />
                                        {
                                            defaultTemplate?.templateDetails && formData?.add_template ?
                                                <Chip onDelete={getTemplateDetailsFn} deleteIcon={<Edit/>} variant="outlined" color="primary" label={defaultTemplate?.templateDetails?.name} size='small' onClick={() => getTemplateDetailsFn()} /> : null
                                        }
                                        <br/>
                                          <span className='notes-yellow'>
                                            {
                                                permission?.settings?.records_template?.includes('C') ?
                                                'Add Serial Number to enable Dataroom Template': 
                                                'You do not have the necessary permissions to enable the Add Dataroom Template.'
                                            }
                                        </span>
                                    </Grid>
                                    : null}
                        </Grid>
                    </form>
                </DialogContent>
                <DialogActions>
                    <Button color="primary" onClick={() => { toggleModalFn(); trackActivity('Item Clicked', { page_title: 'Asset List', item_type: 'Cancel', event_desc: `Clicked on Cancel Button form Add ${addAsset.asset_type} form` }) }}>Cancel</Button>
                    <Button onClick={() => { onAddNewAsset(); trackActivity('Item Clicked', { page_title: 'Asset List', item_type: 'Save ', event_desc: `Clicked on Save Button form Add ${addAsset.asset_type} form` }) }} color="primary" variant="contained">
                        {isLoading ? <CircularProgress color="#ffffff" size={24} /> : 'Save'}
                    </Button>
                </DialogActions>
            </Dialog>
            {
                addTemplateModal?.modal ?
                    <TemplateSelection
                        setDefaultTemplate={setDefaultTemplate}
                        templatPayload={templatPayload}
                        addTemplateModal={addTemplateModal}
                        templateData={templateData}
                        assetData={{ ...formData, asset_type: addAsset.asset_type }}
                        templateInitialData={addTemplateModal?.data}
                        open={addTemplateModal?.modal}
                        onSubmitData={(data, tempId) => { saveTemplateData(data); setAddTemplateModal(false) }}
                        toggleModalFn={() => { setAddTemplateModal(false);  checkForDefaultTemp() }}
                    />
                    : null
            }
            {
                noRecordsPermission.modal ?
                    <DeletePopUp
                        modal={noRecordsPermission.modal}
                        title='Add Asset'
                        confirmColor='primary'
                        confirmText={isLoading ? <CircularProgress color="#ffffff" size={24} />:'Save' }
                        content={
                            <span>
                                Asset Creation Recquires Records Action, You do not have a Records Application Permission to Perform.
                                <div style={{ marginTop: '15px' }}>
                                    Are you sure, you want to Continue?
                                </div>
                            </span>}
                        toggleModalFn={() => toggleModalFn({ modal: false, data: null })}
                        deleteRecordFn={()=>onAddNewAsset('notify_email')}
                    /> : null
            }
        </Fragment>
    )
}

export default withRouter(AddALP);
