import React, { useState } from 'react';
import { Grid, Container } from '@material-ui/core';
import selection from '../../../shared/assets/img/selection_icon.png';
import implementation from '../../../shared/assets/img/implementation_icon.png';
import changeManagement from '../../../shared/assets/img/management_icon.png';
import maintenance from '../../../shared/assets/img/maintenance_icon.png';
 
const DigitalSuccess = ({dsa}) => {
  const [tabIndex, setTabIndex] = useState(0)
  return(
    <section className="digital-success-section" id="digi-success" ref={dsa}>
      <Container maxWidth="xl">
        <div className="module-header">
          <h2>DIGITAL SUCCESS ADVISORY</h2>
          <p>(Technology Consulting)</p>
        </div>
        <div className="slider-wrapper">
          <Grid container spacing={0}  style={{placeContent:'center'}}>
            <Grid item lg={2} md={3} sm={4} xs={6} >
              <ul className='list-inline'style={{ textAlign: 'center' }} >
                <li className={tabIndex === 0 ? "active" : " "} onClick={() => setTabIndex(0)}>Selection</li>
                <li className={tabIndex === 1 ? "active" : " "} onClick={() => setTabIndex(1)}>Implementation</li>
                <li className={tabIndex === 2 ? "active" : " "} onClick={() => setTabIndex(2)}>Change <br /> Management</li>
                <li className={tabIndex === 3 ? "active" : " "} onClick={() => setTabIndex(3)}>Maintenance</li>
              </ul>
            </Grid>
            {tabIndex === 0 &&
              <Grid item lg={10} md={8} sm={8} xs={12}>
                <Grid container spacing={2} alignItems="center">
                  <Grid item lg={4} md={9} sm={4} xs={12} style={{ textAlign: 'center' }}>
                    <img className="slider-img" src={selection} alt="Selection Icon" />
                  </Grid>
                  <Grid item lg={8} md={12} sm={12} xs={12} className='mobile-align' >
                    <div className='dsa-content-wrapper' >
                      <h4>Selection</h4>
                      <p>
                        Our team of industry veterans and technology experts help organisations select the right Digital Solutions and Services that align with their business needs and goals.
                      </p>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            }
            {
              tabIndex === 1 &&
              <Grid item lg={10} md={9} sm={8} xs={12}>
                <Grid container spacing={2} alignItems="center">
                  <Grid item lg={4} md={9} sm={12} xs={12} style={{ textAlign: 'center' }}>
                    <img className="slider-img" src={implementation} alt="Implementation Icon" />
                  </Grid>
                  <Grid item lg={8} md={12} sm={12} xs={12} className='mobile-align'>
                    <div className='dsa-content-wrapper'>
                      <h4>Implementation</h4>
                      <p>
                        Our team of technical and functional experts help in end-to-end implementation of selected solutions and services. We further ensure user training and adoption monitoring.
                      </p>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            }
            {
              tabIndex === 2 &&
              <Grid item lg={10} md={9} sm={8} xs={12}>
                <Grid container spacing={2} alignItems="center">
                  <Grid item lg={4} md={9} sm={4} xs={12} style={{ textAlign: 'center' }}>
                    <img className="slider-img" src={changeManagement} alt="Change Management Icon" />
                  </Grid>
                  <Grid item lg={8} md={12} sm={12} xs={12} className='mobile-align'>
                    <div className='dsa-content-wrapper'>
                      <h4>Change Management</h4>
                      <p>
                        Our team of Delivery Experts help overcome workforce resistance and positively influence Digital Transformation while aligning stakeholders with the corporate vision.                             </p>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            }
            {
              tabIndex === 3 &&
              <Grid item lg={10} md={9} sm={8} xs={12}>
                <Grid container spacing={2} alignItems="center">
                  <Grid lg={4} md={9} sm={4} xs={12} style={{ textAlign: 'center' }}>
                    <img className="slider-img" src={maintenance} alt="Maintenance Icon" />
                  </Grid>
                  <Grid item lg={8} md={12} sm={12} xs={12} className='mobile-align'>
                    <div className='dsa-content-wrapper'>
                      <h4>Maintenance</h4>
                      <p>
                        Our team of Product Managers, Developers and Support Executives help organisations in maintaining existing digital platforms, allowing customers to focus on business goals.
                      </p>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            }
          </Grid>
        </div>
      </Container>
    </section>
  )
}
export default DigitalSuccess;
