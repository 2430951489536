import React from 'react';
import { withRouter, Link } from 'react-router';
import { checkPermission } from '../utils_v2/'
const SettingsNavWrapper = ({params}) => {
    return(
        <div className='console-sec-nav'>
            { checkPermission('settings', 'manage_user','R') ?
                <>
                    <div className='nav-link'>
                        <Link activeClassName='active' to="/users/manage">User(s)</Link>
                    </div>
                    <div className='nav-link'>
                        <Link activeClassName='active' to="/users/invites/">Pending User(s)</Link>
                    </div>
                </>:null
            }
            { checkPermission('settings', 'custom_roles','R') ?
                <div className='nav-link'>
                    <Link activeClassName='active' to="/settings/roles">Role(s)</Link>
                </div>:null
            }
            { checkPermission('settings', 'profile_order','R') ?
                <div className='nav-link'>
                    <Link activeClassName='active' to="/settings/profile">Profile(s)</Link>
                </div>:null
            }
        </div>
    )
}
export default withRouter(SettingsNavWrapper)