import { TableCell, TableHead, TableRow, TableSortLabel } from '@material-ui/core';
import React from 'react';
const ActiveUserHeader = ({createSortHandler, sortInfo}) => {
    return(
        <TableHead>
            <TableRow>
                <TableCell>
                    <TableSortLabel
                        active={sortInfo.sort === 'name' ? true:false}
                        direction={sortInfo.sort === 'name' ? sortInfo.sort_by :'asc'}
                        onClick={()=>createSortHandler('name')}
                    >
                        Name
                    </TableSortLabel>
                </TableCell>
                <TableCell>
                    <TableSortLabel
                        active={sortInfo.sort === 'email' ? true:false}
                        direction={sortInfo.sort === 'email' ? sortInfo.sort_by :'asc'}
                        onClick={()=>createSortHandler('email')}
                    >
                        Email
                    </TableSortLabel>
                </TableCell>
                <TableCell>Designation</TableCell>
                <TableCell>Status</TableCell>
            </TableRow>
        </TableHead>
    )
}
export default ActiveUserHeader;