import React, { useState } from 'react';
import { Button, Dialog, DialogContent, DialogTitle, TextField } from '@material-ui/core';
import { isMobile } from 'react-device-detect';
const ActiveLeasePopUp = ({ modal, title, content, toggleModalFn, submitFn, confirmText, cancelText, cancelColor, confirmColor, isLoading }) => {
    const [emptyReason, setEmptyReason]= useState('')
    return (
        <div className='delete-dialoge-section'>
            <Dialog
                open={modal}
                // onClose={toggleModalFn}
                aria-labelledby="scroll-dialog-title"
            >
                <DialogTitle id="scroll-dialog-title">
                    {title ? title : 'Delete'}
                </DialogTitle>
                <DialogContent dividers={true}>
                    <div className="delete-record-modal" style={{ minWidth: '400px',padding:'0px', textAlign:"left" }}>
                        <div className="">
                            {content}
                        </div>
                        <div>
                            <TextField
                                id='empty_utilization_reason'
                                name='empty_utilization_reason'
                                label='Reason'
                                value={emptyReason || ''}
                                fullWidth
                                inputProps={{ maxLength: 250 }}
                                onChange={(e) => setEmptyReason(e.target.value)}
                                margin="normal"
                                InputLabelProps={{ shrink: true, style: { pointerEvents: 'auto' } }}
                                variant='outlined'
                            />
                        </div>
                        <div className="actions-cta" style={{textAlign:"right"}}>
                            <Button variant="outlined" onClick={toggleModalFn} color={cancelColor ? cancelColor : 'primary'}>{cancelText ? cancelText : 'Cancel'}</Button>
                            <Button variant="contained" onClick={()=>submitFn(emptyReason)} color={confirmColor ? confirmColor : "secondary"} >
                                {confirmText ? confirmText : 'Delete'}
                            </Button>
                        </div>
                    </div>
                </DialogContent>
            </Dialog>
        </div>
    )
}
export default ActiveLeasePopUp;
