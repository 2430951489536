import React, { Fragment, useState} from 'react';
import { withRouter } from 'react-router';
import { Drawer, Button } from "@material-ui/core";
import { TechSpecsReport, UtilizationReport } from '../components';

const ExportReportSidebar = ({direction='right', isOpen, setOpen, selectedAsset, exportObj, onChangeExportTechSpec, onChangeExportUtils, generateBulkReportFn, onChangeExtensionFn, onChangeDateFn, onChangeDropdownFn}) => {
    return(
        <Fragment>
            <Drawer
                anchor={direction}
                open={isOpen}
                className='export-report-drawer'
            >
                <div className='export-report-drawer-hd'>
                    <h4>Export Module Reports</h4>
                </div>
                <div className='export-report-drawer-body'>
                    <TechSpecsReport exportObj={exportObj} onChangeExport={onChangeExportTechSpec} />
                    <UtilizationReport exportObj={exportObj} onChangeExport={onChangeExportUtils} onChangeExtensionFn={onChangeExtensionFn} onChangeDateFn={onChangeDateFn} onChangeDropdownFn={onChangeDropdownFn} />
                </div>
                <div className='export-report-drawer-footer'>
                    <ul className='list-inline'>
                        <li className='list-inline-item'>
                            <Button variant='outlined' color='primary' size='small' onClick={() => setOpen(false, { selectedAsset: [] })} >Cancel</Button> 
                        </li>
                        <li className='list-inline-item'>
                            <Button variant='contained' color='primary' size='small' onClick={() => generateBulkReportFn()} >Generate Report</Button> 
                        </li>
                    </ul>
                </div>
            </Drawer>
        </Fragment>
    )
}
export default withRouter(ExportReportSidebar);