import React, { Component } from 'react';
import { connect } from 'react-redux';
import { browserHistory, Link } from 'react-router';
import { getLocalStorageInfo } from './';
function RoleBasedAccess(ComposedComponent, extraInfo) {
  class authorizedUser extends Component {
    constructor(props) {
      super(props);
    }
    componentWillMount(){
      let userDetail = JSON.parse(localStorage.getItem('userInfo'));
      if(userDetail && userDetail.defaultLessor == undefined){
        browserHistory.push( "/login" );
      }
    }
    render() {
      let permissionInfo1 = getLocalStorageInfo()?.user?.permission || {};
      if(permissionInfo1 && permissionInfo1[extraInfo[0]] && permissionInfo1[extraInfo[0]][extraInfo[1]] && permissionInfo1[extraInfo[0]][extraInfo[1]].indexOf(extraInfo[2]) != -1) {
        return(<ComposedComponent {...this.props}/>)
      }else {
        let sideBar;
        return (
          <div style={{width:'100wh', height:'100vh', position:'relative'}}>
            <div style={{position: 'fixed', top: '50%', left: '58%', transform: 'translate(-50%, -58%)', color: '#000000', textAlign: 'center'}}>
              <br/><br/>
                Sorry, You do not have access to the Page.<br/>
                Please contact to Acumen for access <Link to="/assets-listing">Go Back</Link>
            </div>
          </div>
        )
      }
    }
  }
  return connect(null, null)(authorizedUser);
}
export default RoleBasedAccess;
