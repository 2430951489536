import React from 'react';
import { TableCell, TableHead, TableRow } from '@material-ui/core';
const ReportsHD = ({fleetType}) => {
    return(
        <TableHead>
            <TableRow>
                <TableCell width={50}>
                </TableCell>
                <TableCell>
                    Assets(Select to Export)
                </TableCell>
                <TableCell>
                    Lessee
                </TableCell>
                <TableCell>
                    Registration No
                </TableCell>
            </TableRow>
        </TableHead>
    )
}
export default ReportsHD;