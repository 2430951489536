import React, { useState, useEffect } from "react";
import { withRouter, browserHistory } from 'react-router';
import { useSnackbar } from 'notistack';
import { Button, IconButton, Avatar, Dialog, DialogContent, DialogTitle, DialogActions, Grid, TextField, CircularProgress, Modal, Backdrop, Fade } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import EditIcon from '@material-ui/icons/Edit';
import PhotoCamera from '@material-ui/icons/PhotoCamera';
import { globalGetService, globalPostService, globalPutService } from '../../../globalServices';
import { checkApiStatus, checkPermission, checkPermissionHierarchy, getLocalStorageInfo } from '../../utils_v2';
import { DeletePopUp, PageLoader } from "../../shared_elements";
import { regexConstants } from '../../../constants/regEx';
import { LoginAuth } from "../../auth/components";
import { getGlobalCookie } from "../../../utils";
const useStyles = makeStyles((theme) => ({
    root: {
        '& > *': {
            margin: theme.spacing(1),
        },
    },
    input: {
        display: 'none',
    },
}));
const EditProfile = ({ params, editProfile, toggleModalFn, getResponseBack }) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [isLoading, setLoading] = useState(false)
    const [userProfile, setUserProfile] = useState(editProfile.data)
    const [error, setError] = useState({})
    const onFieldChange = (key, value) => {
        setUserProfile({ ...userProfile, [key]: value })
    }
    const onEditProfile = () => {
        let validationInputs = {}
        validationInputs = {
            first_name: userProfile?.first_name?.trim().length ? '' : 'Please enter First Name',
            contact_no: userProfile?.contact_no?.trim().length ? regexConstants.phoneNumber.test(userProfile.contact_no) ? '' : 'Please enter valid Contact Number' : '',
            alternative_contact_no: userProfile?.alternative_contact_no?.trim().length ? regexConstants.phoneNumber.test(userProfile.alternative_contact_no) ? '' : 'Please enter valid Alternate Contact Number' : '',
        }
        if (Object.keys(validationInputs).every((k) => { return validationInputs[k] === '' })) {
            let formData = new FormData();
            delete userProfile.profile_pic
            Object.keys(userProfile).map(item => {
                formData.append(item, userProfile[item] ? userProfile[item] : '')
            });
            setLoading(true);
            globalPutService(`console/get-user-details/${params.slug}/`, formData)
                .then(response => {
                    if (checkApiStatus(response)) {
                        enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { horizontal: 'right', vertical: 'top' } });
                        getResponseBack()
                    } else {
                        // enqueueSnackbar(response.data.message, { variant: 'error',anchorOrigin:{horizontal:'right', vertical:'top'}});
                    }
                    setLoading(false);
                })
        } else {
            setError(validationInputs);
        }
    }
    return (
        <Dialog
            open={editProfile.modal}
            onClose={toggleModalFn}
            className='console-management-modal'
            maxWidth="md"
        >
            <DialogTitle id="scroll-dialog-title">
                Edit Profile
            </DialogTitle>
            <DialogContent dividers={true}>
                <Grid container spacing={1}>
                    <Grid item md={4}>
                        <TextField
                            required
                            id='first_name'
                            label='First Name'
                            value={userProfile.first_name}
                            fullWidth
                            margin="normal"
                            error={error.first_name}
                            helperText={error?.first_name || ''}
                            onChange={(e) => onFieldChange('first_name', e.target.value)}
                            onFocus={() => setError({ ...error, 'first_name': '' })}
                            InputLabelProps={{ shrink: true }}
                            variant='outlined'
                        />
                    </Grid>
                    <Grid item md={4}>
                        <TextField
                            id='last_name'
                            label='Last Name'
                            value={userProfile.last_name}
                            fullWidth
                            margin="normal"
                            onChange={(e) => onFieldChange('last_name', e.target.value)}
                            onFocus={() => setError({ ...error, 'last_name': '' })}
                            InputLabelProps={{ shrink: true }}
                            variant='outlined'
                        />
                    </Grid>
                    <Grid item md={4}>
                        <TextField
                            id='designation'
                            label='Designation'
                            value={userProfile.designation}
                            fullWidth
                            margin="normal"
                            onChange={(e) => onFieldChange('designation', e.target.value)}
                            onFocus={() => setError({ ...error, 'designation': '' })}
                            InputLabelProps={{ shrink: true }}
                            variant='outlined'
                        />
                    </Grid>
                    <Grid item md={4}>
                        <TextField
                            id='contact_no'
                            label='Contact Number'
                            value={userProfile.contact_no}
                            fullWidth
                            margin="normal"
                            error={error.contact_no}
                            helperText={error?.contact_no || ''}
                            onChange={(e) => onFieldChange('contact_no', e.target.value)}
                            onFocus={() => setError({ ...error, 'contact_no': '' })}
                            InputLabelProps={{ shrink: true }}
                            variant='outlined'
                        />
                    </Grid>
                    <Grid item md={4}>
                        <TextField
                            id='alternative_contact_no'
                            label='Alternate Contact Number'
                            value={userProfile.alternative_contact_no}
                            fullWidth
                            margin="normal"
                            error={error.alternative_contact_no}
                            helperText={error?.alternative_contact_no || ''}
                            onChange={(e) => onFieldChange('alternative_contact_no', e.target.value)}
                            onFocus={() => setError({ ...error, 'alternative_contact_no': '' })}
                            InputLabelProps={{ shrink: true }}
                            variant='outlined'
                        />
                    </Grid>
                    <Grid item md={4}>
                        <TextField
                            id='experience'
                            label='Overall Work Experience'
                            value={userProfile.experience}
                            fullWidth
                            margin="normal"
                            onChange={(e) => onFieldChange('experience', e.target.value)}
                            onFocus={() => setError({ ...error, 'experience': '' })}
                            InputLabelProps={{ shrink: true }}
                            variant='outlined'
                        />
                    </Grid>
                    <Grid item md={4}>
                        <TextField
                            id='country'
                            label='Country'
                            value={userProfile.base_location}
                            fullWidth
                            margin="normal"
                            onChange={(e) => onFieldChange('base_location', e.target.value)}
                            onFocus={() => setError({ ...error, 'base_location': '' })}
                            InputLabelProps={{ shrink: true }}
                            variant='outlined'
                        />
                    </Grid>
                    <Grid item md={4}>
                        <TextField
                            id='city'
                            label='City'
                            value={userProfile.city}
                            fullWidth
                            margin="normal"
                            onChange={(e) => onFieldChange('city', e.target.value)}
                            onFocus={() => setError({ ...error, 'city': '' })}
                            InputLabelProps={{ shrink: true }}
                            variant='outlined'
                        />
                    </Grid>
                    <Grid item md={12}>
                        <TextField
                            id='remarks'
                            label='Additional Information'
                            value={userProfile.additional_remarks}
                            fullWidth
                            margin="normal"
                            onChange={(e) => onFieldChange('additional_remarks', e.target.value)}
                            onFocus={() => setError({ ...error, 'additional_remarks': '' })}
                            InputLabelProps={{ shrink: true }}
                            variant='outlined'
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button disabled={isLoading} onClick={toggleModalFn} color="primary" size="small" variant="outlined">Cancel</Button>
                <Button disabled={isLoading} onClick={onEditProfile} color="primary" size="small" variant="contained">
                    {isLoading ? <CircularProgress size={24} /> : 'Save'}
                </Button>
            </DialogActions>
        </Dialog>
    )
}
const UserProfile = ({ params, profileType }) => {
    const classes = useStyles();
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [isLoading, setLoading] = useState(false);
    const [editProfile, setEditProfile] = useState({ modal: false, user: null })
    const [userInfo, setUserInfo] = useState({});
    const [error, setError] = useState({});
    const [userStatus, setUserStatus] = useState({ modal: false, type: '' })
    const [otp, setOtp] = useState(false);

    useEffect(() => {
        getUserDetail();
    }, []);
    const getUserDetail = () => {
        globalGetService(`console/get-user-details/${params.slug}/`, { lite: true })
            .then(response => {
                if (checkApiStatus(response)) {
                    setUserInfo(response.data.data)
                } else {
                    browserHistory.push('/users/manage')
                }
            })
    }
    const onUploadProfile = (files) => {
        let formData = new FormData();
        Object.keys(userInfo).map(item => {
            if (typeof userInfo[item] == 'object') {
                formData.append(item, JSON.stringify(userInfo[item] ? userInfo[item] : ''))
            } else {
                formData.append(item, userInfo[item] ? userInfo[item] : '')
            }
        })
        formData.append('profile_pic', files[0]);
        setLoading(true);
        globalPutService(`console/get-user-details/${params.slug}/`, formData)
            .then(response => {
                if (checkApiStatus(response)) {
                    enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { horizontal: 'right', vertical: 'top' } });
                    getUserDetail();
                }
                setLoading(false);
            })
    }
    const onResendInvite = () => {
        setLoading(true);
        globalGetService(`console/resend-user-invite/${params.slug}/`)
            .then(response => {
                if (checkApiStatus(response)) {
                    enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { horizontal: 'right', vertical: 'top' } });
                    setUserStatus({ modal: false, type: '' })
                    getUserDetail();
                } else {
                    enqueueSnackbar(response.data.message, { variant: 'error', anchorOrigin: { horizontal: 'right', vertical: 'top' } });
                }
                setLoading(false);
            })
    }
    const onRemoveUser = () => {
        setLoading(true);
        globalGetService(`console/lessor-unlink-user/${params.slug}/`)
            .then(response => {
                if (checkApiStatus(response)) {
                    enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { horizontal: 'right', vertical: 'top' } });
                    browserHistory.push('/users/manage')
                } else {
                    enqueueSnackbar(response.data.message, { variant: 'error', anchorOrigin: { horizontal: 'right', vertical: 'top' } });
                }
                setLoading(false);
            })
    }
    const onChangeUserStatus = (user) => {
        if (userStatus.type === 'Resend Invite') {
            onResendInvite();
        } else if (userStatus.type === 'Remove User') {
            onRemoveUser();
        } else {
            let payload = {
                user: {
                    id: userInfo.id,
                    name: `${userInfo.first_name} ${userInfo.last_name}`,
                    email: userInfo.email
                },
                is_active: !userInfo.is_active
            };
            setLoading(true);
            globalPostService(`console/user-activate-deactivate/`, payload)
                .then(response => {
                    if (checkApiStatus(response)) {
                        enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { horizontal: 'right', vertical: 'top' } });
                        setUserStatus({ modal: false, type: '' })
                        getUserDetail();
                    } else {
                        enqueueSnackbar(response.data.message, { variant: 'error', anchorOrigin: { horizontal: 'right', vertical: 'top' } });
                    }
                    setLoading(false);
                })
        }

    }
    const enableTwoFactorAuth = (e) => {
        globalPostService('/api/two-factor-auth/action/', { two_f_auth_enabled: true })
            .then(response => {
                if (checkApiStatus(response)) {
                    enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { horizontal: 'right', vertical: 'top' }, });
                    getUserDetail();
                }
            });
    }
    const disableTwoFactorAuth = (e) => {
        globalGetService('/api/two-factor-auth/disable_otp/')
            .then(response => {
                setOtp(true);
                if (checkApiStatus(response)) {
                    enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { horizontal: 'right', vertical: 'top' }, });
                }else{
                    setLoading(true)
                }
            });
    }
    const loggedUserId = getLocalStorageInfo()?.user?.id || null;
    return (
        <div className="console-forms-fields" style={{ position: 'relative' }}>
            {(userInfo && Object.keys(userInfo).length && loggedUserId === userInfo.id) || (checkPermission('settings', 'manage_user', 'U') && checkPermissionHierarchy(userInfo)) ?
                <span onClick={() => setEditProfile({ modal: true, data: userInfo })} style={{ position: 'absolute', top: '0px', right: '0px', zIndex: 10, cursor: 'pointer' }}>
                    <EditIcon color="primary" fontSize="small" />
                </span> : null
            }
            <Grid container spacing={1}>
                <Grid item md={2}>
                    <div style={{ textAlign: 'center' }}>
                        <span style={{ position: 'relative' }}>
                            <Avatar src={userInfo?.profile_pic || ''} alt={userInfo?.first_name} style={{ width: '100px', height: '100px', margin: '0 auto' }} />
                            {userInfo && Object.keys(userInfo).length && (loggedUserId === userInfo.id || checkPermission('settings', 'manage_user', 'U')) ?
                                <span style={{ position: 'absolute', bottom: '0', textAlign: 'center', cursor: 'pointer' }}>
                                    <input onChange={(e) => onUploadProfile(e.target.files)} accept="image/*" className={classes.input} id="icon-button-file" type="file" />
                                    <label htmlFor="icon-button-file">
                                        <IconButton color="primary" aria-label="upload picture" component="span">
                                            <PhotoCamera color='primary' fontSize="small" />
                                        </IconButton>
                                    </label>
                                </span> : null
                            }
                        </span>
                        <h4 style={{ fontSize: '16px', marginTop: '10px' }}>{userInfo?.first_name || ''} {userInfo?.last_name || ''}</h4>
                        <p style={{ fontSize: '13px' }}>{userInfo?.designation || ''}</p>
                        {profileType === 'selfProfile' && (
                            userInfo?.two_f_auth_enabled === false ?
                                <Button size="small" color="primary" style={{ fontSize: '9px', textDecoration: 'underline', top: '15px' }} onClick={enableTwoFactorAuth} >
                                    Enable Two-Factor Authentication
                                </Button>
                                :
                                <Button size="small" color="primary" style={{ fontSize: '9px', textDecoration: 'underline', top: '15px' }} onClick={disableTwoFactorAuth} >
                                    Disable Two-Factor Authentication
                                </Button>

                        )}
                    </div>
                </Grid>
                <Grid item md={10}>
                    <Grid container spacing={1}>
                        <Grid item md={4}>
                            <TextField
                                disabled
                                id='email'
                                label='Email'
                                value={userInfo?.email || ''}
                                fullWidth
                                margin="normal"
                                InputLabelProps={{ shrink: true }}
                                variant='outlined'
                            />
                        </Grid>
                        <Grid item md={4}>
                            <TextField
                                disabled
                                id='contact_no'
                                label='Contact Number'
                                value={userInfo?.contact_no || ''}
                                fullWidth
                                margin="normal"
                                InputLabelProps={{ shrink: true }}
                                variant='outlined'
                            />
                        </Grid>
                        <Grid item md={4}>
                            <TextField
                                disabled
                                id='alternative_contact_no'
                                label='Alternate Contact Number'
                                value={userInfo?.alternative_contact_no || ''}
                                fullWidth
                                margin="normal"
                                InputLabelProps={{ shrink: true }}
                                variant='outlined'
                            />
                        </Grid>
                        <Grid item md={4}>
                            <TextField
                                disabled
                                id='experience'
                                label='Overall Work Experience'
                                value={userInfo?.experience || ''}
                                fullWidth
                                margin="normal"
                                InputLabelProps={{ shrink: true }}
                                variant='outlined'
                            />
                        </Grid>
                        <Grid item md={4}>
                            <TextField
                                disabled
                                id='country'
                                label='Country'
                                value={userInfo?.base_location || ''}
                                fullWidth
                                margin="normal"
                                InputLabelProps={{ shrink: true }}
                                variant='outlined'
                            />
                        </Grid>
                        <Grid item md={4}>
                            <TextField
                                disabled
                                id='city'
                                label='City'
                                value={userInfo?.city || ''}
                                fullWidth
                                margin="normal"
                                InputLabelProps={{ shrink: true }}
                                variant='outlined'
                            />
                        </Grid>
                        <Grid item md={12}>
                            <TextField
                                disabled
                                id='remarks'
                                label='Additional Information'
                                value={userInfo?.additional_remarks || ''}
                                fullWidth
                                margin="normal"
                                InputLabelProps={{ shrink: true }}
                                variant='outlined'
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            {(userInfo && Object.keys(userInfo).length && getLocalStorageInfo()?.user?.id !== userInfo.id) && checkPermission('settings', 'manage_user', 'U') && checkPermissionHierarchy(userInfo) ?
                <div style={{ background: '#f1f1f1', padding: '10px', marginTop: '10px', textAlign: 'right' }}>
                    <ul className="list-inline">
                        {userInfo?.is_new_user ?
                            <>
                                <li className="list-inline-item">
                                    <Button onClick={() => setUserStatus({ modal: true, type: 'Resend Invite' })} color='primary' size='small' variant="outlined">Resend Invite</Button>
                                </li>
                                <li className="list-inline-item">
                                    <Button onClick={() => setUserStatus({ modal: true, type: 'Remove User' })} color='secondary' size='small' variant="outlined">Remove User</Button>
                                </li>
                            </> : null
                        }
                        {userInfo?.is_new_user === false ?
                            <>
                                {!userInfo?.is_active ?
                                    <li className="list-inline-item">
                                        <Button onClick={() => setUserStatus({ modal: true, type: 'Activate' })} color='primary' size='small' variant="contained">Activate</Button>
                                    </li> : null
                                }
                                {userInfo?.is_active ?
                                    <li className="list-inline-item">
                                        <Button onClick={() => setUserStatus({ modal: true, type: 'Deactivate' })} color='primary' size='small' variant="contained">Deactivate</Button>
                                    </li> : null
                                }
                            </> : null
                        }
                    </ul>
                </div> : null
            }
            {editProfile.modal ?
                <EditProfile
                    params={params}
                    editProfile={editProfile}
                    getResponseBack={() => { setEditProfile({ modal: false, user: null }); getUserDetail() }}
                    toggleModalFn={() => setEditProfile({ modal: false, user: null })}
                /> : null
            }
            <DeletePopUp
                modal={userStatus.modal}
                title={userStatus.type}
                content={`Are you sure, you want to ${userStatus.type}?`}
                toggleModalFn={() => setUserStatus({ modal: false, type: '' })}
                deleteRecordFn={onChangeUserStatus}
                confirmText="Yes"
            />
            {isLoading ? <PageLoader /> : null}
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={otp}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}>
                <Fade>
                    <LoginAuth
                        authType='disabled'
                        setOtpModal={() => setOtp(false)}
                        userResponse={{ access: JSON.parse(getGlobalCookie('lessorAccess')).access }}
                        getResponseBack={() => { setOtp(false); getUserDetail() }}
                    />
                </Fade>
            </Modal>
        </div>

    )
}
export default UserProfile;