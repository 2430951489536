import { Chip, Grid, TextField, Button, Paper, Table, TableBody, TableCell, TableHead, TableRow, Dialog, DialogContent, DialogTitle, DialogActions, CircularProgress, FormControl, FormControlLabel, Switch, Tooltip, FormLabel } from '@material-ui/core';
import React, { useEffect, useState } from 'react'
import { globalGetService, globalPostService, globalPutService } from '../../../globalServices'
import { EmptyCollection, STableLoader } from '../../shared_elements';
import AddEditThirdParty from './AddEditThirdParty';
import EditIcon from '@material-ui/icons/Edit';
import { checkApiStatus, checkPermission } from '../../utils_v2';
import { toastFlashMessage } from '../../../utils';
import ConfigSideNav from './ConfigSideNav';
import ReadMoreInline from '../../../shared/components/ReadMoreInline';

export default function ThirdPartyIntegration() {
    const [isLoading, setLoading] = useState(false)
    const [thirdPartyList, setListing] = useState([])
    const [addEditList, setAddEditList] = useState(false)
    const [thirdPartyData, setThirdPartyData] = useState({})
    useEffect(() => {
        getThirdPartyListing()
    }, [])
    const getThirdPartyListing = () => {
        setLoading(true)
        globalGetService(`console/third-party-apps/`)
            .then(response => {
                setLoading(false)
                setListing(response.data.list?.data)
            })
    }
    const statusChangeFn = (item, value) => {
        if (!item?.client_id && !item?.client_secret) {
            setAddEditList({ modal: true })
            setThirdPartyData({ ...item, status: 1 })
        }
        else {
            if (item?.modified_by?.id) {
                globalPutService(`console/third-party-apps/${item?.app_slug}/`, { status: value == false ? 0 : 1 })
                    .then(response => {
                        if (checkApiStatus(response)) {
                            getThirdPartyListing()
                            toastFlashMessage(response.data.message, 'success')
                        }
                        else {
                            toastFlashMessage(response.data.message, 'error')
                        }
                    })
            }
            else {
                globalPostService(`console/third-party-apps/`, { status: value == false ? 0 : 1 })
                    .then(response => {
                        if (checkApiStatus(response)) {
                            getThirdPartyListing()
                            toastFlashMessage(response.data.message, 'success')
                        }
                        else {
                            toastFlashMessage(response.data.message, 'error')
                        }
                    })
            }
        }
    }
    return (
        <section className='config-container' style={{ margin: '24px 0 0 75px' }}>
            <Paper className="tech-specs-card" style={{ minHeight: `${window.innerHeight - 90}px` }}>
                <ConfigSideNav />
                <div className="tech-specs-content">
                    <div>
                        <AddEditThirdParty
                            thirdPartyData={thirdPartyData}
                            addEditList={addEditList}
                            setThirdPartyData={setThirdPartyData}
                            setAddEditList={setAddEditList}
                            getThirdPartyListing={getThirdPartyListing}
                        />
                        <Paper>
                            <Table className='mui-table-format'>
                                <TableHead>
                                    <TableRow>
                                        <TableCell width='150px'>Status</TableCell>
                                        <TableCell>Name</TableCell>
                                        <TableCell>Description</TableCell>
                                        <TableCell>Client Id</TableCell>
                                        <TableCell>Client Secret</TableCell>
                                        <TableCell>Created/Modified By</TableCell>
                                        <TableCell>Action</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        thirdPartyList?.map((item, index) =>
                                            <TableRow>
                                                <TableCell >{
                                                    <FormControl component="fieldset">
                                                        <FormControlLabel
                                                            control={<Switch color='primary' checked={item?.status} onChange={(e, value) => statusChangeFn(item, value)} name="gilad" />}
                                                            label={['false', 0, '0', false].includes(item?.status) ? 'Disabled' : 'Enabled'}
                                                        />
                                                    </FormControl>
                                                }</TableCell>
                                                <TableCell>{item?.name || '--'}</TableCell>
                                                <TableCell style={{ maxWidth: "350px"}}> <ReadMoreInline text={item?.description}/></TableCell> 
                                                <TableCell>{item?.client_id || '--'}</TableCell>
                                                <TableCell>{item?.client_secret || '--'}</TableCell>
                                                <TableCell>{item?.modified_by?.name || '--'}</TableCell>
                                                <TableCell>
                                                    {
                                                        checkPermission('console', '3rd_party_integration', 'U') ?
                                                            <EditIcon onClick={() => { setAddEditList({ modal: true, mode: 'edit', data: item }); setThirdPartyData({ status: item?.status == false ? 0 : 1, app_slug: item?.app_slug, modified_by: item?.modified_by }) }} color='primary' fontSize='small' style={{ cursor: 'pointer' }} /> : null
                                                    }
                                                </TableCell>
                                            </TableRow>
                                        )
                                    }
                                </TableBody>
                            </Table>
                            {isLoading ? <STableLoader count={3} /> : null}
                            <Paper>
                            {!thirdPartyList?.length && !isLoading ? <div style={{ textAlign: 'center' }}><EmptyCollection title={'No records found'} /></div> : null}
                            </Paper>
                        </Paper>
                    </div>
                </div>
            </Paper>
        </section>
    )
}
