import React, { useState, useEffect } from "react";
import { withRouter } from "react-router";
import { useSnackbar } from 'notistack';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Table, TableBody, TableCell, TableHead, TableRow, Paper, Grid, Button, TextField, Dialog, DialogContent, DialogTitle, DialogActions, Tooltip, CircularProgress } from '@material-ui/core';
import { Pagination, STableLoader, FilterComponent, PageLoader, EmptyCollection, DeletePopUp } from '../../shared_elements';
import AddInteriorItem from './AddInteriorItem'
import EditIcon from '@material-ui/icons/Edit';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import { globalGetService, globalPutService, globalPostService, globalDeleteService, globalExportService } from '../../../globalServices';
import { checkApiStatus, checkPermission } from '../../utils_v2';
import RemarksDynamicField from "../../../shared/components/RemarksDynamicField";
import { regexConstants } from "../../../constants/regEx";
import { assetTypeValues, backendDateFormat } from "../../../constants";
import { downloadFileType } from "../../../utils";
import { trackActivity } from "../../../utils/mixpanel";
import moment from "moment";
const galleyObj = {
    name: '',
    part_number: '',
    manufacturer: '',
    quantity: '',
    configuration: null,
    galley_type: null,
    location: null,
    remarks: ''
}
const GalleyHd = () => {
    return (
        <TableHead>
            <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Part Number</TableCell>
                <TableCell>Manufacturer</TableCell>
                <TableCell>Quantity</TableCell>
                <TableCell>Configuration</TableCell>
                <TableCell>Type</TableCell>
                <TableCell>Location</TableCell>
                <TableCell>Remarks</TableCell>
                <TableCell align="right">Action(s)</TableCell>
            </TableRow>
        </TableHead>
    )
}
const GalleyList = ({ item, onEdit, onDelete }) => {
    return (
        <TableRow>
            <TableCell>
                <div style={{ maxWidth: '180px' }}>{item?.name || '--'}</div>
            </TableCell>
            <TableCell>
                <div style={{ maxWidth: '120px' }}>{item?.part_number || '--'}</div>
            </TableCell>
            <TableCell>
                <div style={{ maxWidth: '180px' }}>{item?.manufacturer || '--'}</div>
            </TableCell>
            <TableCell>
                <div style={{ maxWidth: '100px' }}>{item?.quantity || '--'}</div>
            </TableCell>
            <TableCell>
                <div style={{ maxWidth: '120px' }}>{item?.configuration?.label || '--'}</div>
            </TableCell>
            <TableCell>
                <div style={{ maxWidth: '100px' }}>{item?.galley_type?.label || '--'}</div>
            </TableCell>
            <TableCell>
                <div style={{ maxWidth: '120px' }}>{item?.location?.label || '--'}</div>
            </TableCell>
            <TableCell>
                <RemarksDynamicField
                    item={item}
                />
            </TableCell>
            <TableCell align="right">
                <ul className="list-inline" style={{ width: '80px', float: 'right' }}>
                    {
                        checkPermission('technical_specs', 'interior', 'U') ?
                            <li className="list-inline-item hover-inline" onClick={onEdit}>
                                <Tooltip title='Edit' arrow><EditIcon color="primary" fontSize="small" /></Tooltip>
                            </li>
                            : null}
                    {
                        checkPermission('technical_specs', 'interior', 'D') ?
                            <li className="list-inline-item hover-inline" onClick={onDelete}>
                                <Tooltip title='Delete' arrow><DeleteOutlineIcon color="secondary" fontSize="small" /></Tooltip>
                            </li>
                            : null}
                </ul>
            </TableCell>
        </TableRow>
    )
}
const AddEditGalley = ({ params, addEditGalley, toggleModalFn, getResponseBack, assetInfoData }) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [isLoading, setLoading] = useState(false);
    const [galley, setGalley] = useState(addEditGalley.data);
    const [pltConstants, setPltConstants] = useState([]);
    const [error, setError] = useState({});
    useEffect(() => {
        globalPostService('console/get-constants/', { constant_types: ['galley_configuration', 'galley_type', 'interior_location'] })
            .then(response => {
                if (checkApiStatus(response)) {
                    setPltConstants(response.data.data)
                }
            })
    }, [])
    const onFieldChange = (key, value) => {
        setGalley({ ...galley, [key]: value })
    }
    const onAddEditGalley = () => {
        let validationInputs = {
            name: galley?.name?.trim()?.length ? '' : 'Please enter Name',
        }
        if (Object.keys(validationInputs).every((k) => { return validationInputs[k] === '' })) {
            setLoading(true);
            if (galley.id) {
                globalPutService(`console/v2/${params.type}/${params.aircraft_slug}/interior/galley/${galley.id}/`, galley)
                    .then(response => {
                        if (checkApiStatus(response)) {
                            enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { horizontal: 'right', vertical: 'top' } });
                            trackActivity('Item Edited', { page_title: 'Galley', asset_type: 'MSN', asset_name: assetInfoData?.serial_number, event_type: 'Edit Galley', event_desc: 'Edited Galley data from Edit Galley form' })
                            toggleModalFn(); getResponseBack();

                        }
                        setLoading(false);
                    })
            } else {
                globalPostService(`console/v2/${params.type}/${params.aircraft_slug}/interior/galley/`, galley)
                    .then(response => {
                        if (checkApiStatus(response)) {
                            enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { horizontal: 'right', vertical: 'top' } });
                            trackActivity('Item Added', { page_title: 'Galley', asset_type: 'MSN', asset_name: assetInfoData?.serial_number, event_type: 'Add Galley', event_desc: 'Added Galley data from Add Galley form' })
                            toggleModalFn(); getResponseBack();
                        }
                        setLoading(false);
                    })
            }
        } else {
            setError(validationInputs)
        }
    }
    return (
        <Dialog
            open={addEditGalley.modal}
            onClose={toggleModalFn}
            className='console-management-modal'
            maxWidth="md"
        >
            <DialogTitle id="scroll-dialog-title">
                {galley.id ? 'Edit ' : 'Add '} Galley
            </DialogTitle>
            <DialogContent dividers={true}>
                <Grid container spacing={1}>
                    <Grid item md={6}>
                        <TextField
                            required
                            name='name'
                            label='Name'
                            value={galley?.name || ''}
                            fullWidth
                            margin="normal"
                            onChange={(e) => onFieldChange('name', e.target.value)}
                            InputLabelProps={{ shrink: true }}
                            inputProps={{ maxLength: 50 }}
                            error={error?.name}
                            helperText={error?.name || ''}
                            onFocus={() => setError({ ...error, 'name': '' })}
                            variant='outlined'
                        />
                    </Grid>
                    <Grid item md={6}>
                        <TextField
                            name='part_number'
                            label='Part Number'
                            value={galley?.part_number || ''}
                            fullWidth
                            margin="normal"
                            onChange={(e) => onFieldChange('part_number', e.target.value)}
                            InputLabelProps={{ shrink: true }}
                            inputProps={{ maxLength: 25 }}
                            variant='outlined'
                        />
                    </Grid>
                    <Grid item md={6}>
                        <TextField
                            name='quantity'
                            label='Quantity'
                            value={galley?.quantity || ''}
                            fullWidth
                            margin="normal"
                            onChange={(e) => regexConstants.numberNoSpace.test(e.target.value) ? onFieldChange('quantity', e.target.value) : null}
                            InputLabelProps={{ shrink: true }}
                            inputProps={{ maxLength: 3 }}
                            variant='outlined'
                        />
                    </Grid>
                    <Grid item md={6}>
                        <Autocomplete
                            options={pltConstants.filter(optionItem => optionItem.type === 'galley_configuration')}
                            getOptionLabel={option => option.label}
                            name="configuration"
                            value={galley?.configuration || null}
                            onChange={(e, value) => onFieldChange('configuration', value)}
                            renderInput={params => <TextField {...params} label="Configuration" margin="normal" fullWidth InputLabelProps={{ shrink: true }} variant='outlined' />}
                        />
                    </Grid>
                    <Grid item md={4}>
                        <Autocomplete
                            options={pltConstants.filter(optionItem => optionItem.type === 'galley_type')}
                            getOptionLabel={option => option.label}
                            name="galley_type"
                            value={galley?.galley_type || null}
                            onChange={(e, value) => onFieldChange('galley_type', value)}
                            renderInput={params => <TextField {...params} label="Type" margin="normal" fullWidth InputLabelProps={{ shrink: true }} variant='outlined' />}
                        />
                    </Grid>
                    <Grid item md={4}>
                        <Autocomplete
                            options={pltConstants.filter(optionItem => optionItem.type === 'interior_location')}
                            getOptionLabel={option => option.label}
                            name="location"
                            value={galley?.location || null}
                            onChange={(e, value) => onFieldChange('location', value)}
                            renderInput={params => <TextField {...params} label="Location" margin="normal" fullWidth InputLabelProps={{ shrink: true }} variant='outlined' />}
                        />
                    </Grid>
                    <Grid item md={4}>
                        <TextField
                            name='manufacturer'
                            label='Manufacturer'
                            value={galley?.manufacturer || ''}
                            fullWidth
                            margin="normal"
                            onChange={(e) => onFieldChange('manufacturer', e.target.value)}
                            InputLabelProps={{ shrink: true }}
                            inputProps={{ maxLength: 50 }}
                            variant='outlined'
                        />
                    </Grid>
                    <Grid item md={12}>
                        <TextField
                            name='remarks'
                            label='Remarks'
                            value={galley?.remarks || ''}
                            fullWidth
                            margin="normal"
                            multiline
                            rows={3}
                            inputProps={{ maxLength: 250 }}
                            onChange={(e) => onFieldChange('remarks', e.target.value)}
                            InputLabelProps={{ shrink: true }}
                            variant='outlined'
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button disabled={isLoading} onClick={toggleModalFn} color='primary' size='small' variant='outlined'>Cancel</Button>
                <Button disabled={isLoading} onClick={onAddEditGalley} color='primary' size='small' variant='contained'>
                    {isLoading ? <CircularProgress size={24} /> : 'Save'}
                </Button>
            </DialogActions>
        </Dialog>
    )
}
const Galley = ({ params, assetInfoData }) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [isLoading, setLoading] = useState(false);
    const [skeletonLoader, setSkeletonLoader] = useState(false);
    const [filter, setFilter] = useState({});
    const [galleysInfo, setGalleysInfo] = useState({ list: [], pagination: null });
    const [addEditGalley, setAddEditGalley] = useState({ modal: false, data: null });
    const [deleteGalley, setDeleteGalley] = useState({ modal: false, data: null });
    useEffect(() => {
        getGalleys({}, 'skeletonLoader');
    }, []);
    const getGalleys = (query = {}, loaderType) => {
        loaderType === 'skeletonLoader' ? setSkeletonLoader(true) : setLoading(true)
        globalGetService(`console/v2/${params.type}/${params.aircraft_slug}/interior/galley/`, query)
            .then(response => {
                if (checkApiStatus(response)) {
                    setGalleysInfo(response.data.data)
                }
                loaderType === 'skeletonLoader' ? setSkeletonLoader(false) : setLoading(false)
            })
    }
    const onDeleteGalley = () => {
        setLoading(true);
        globalDeleteService(`console/v2/${params.type}/${params.aircraft_slug}/interior/galley/${deleteGalley.data.id}/`)
            .then(response => {
                if (checkApiStatus(response)) {
                    enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { horizontal: 'right', vertical: 'top' } });
                    setDeleteGalley({ modal: false, data: null })
                    getGalleys({}, 'pageLoader');
                }
                setLoading(false);
            })
    }
    const onExportGallery = () => {
        setLoading(true);
        globalExportService(`console/v2/aircraft/${params.aircraft_slug}/interior/galley/?download=xlsx`)
            .then(response => {
                downloadFileType(response, `${assetTypeValues[assetInfoData?.asset_type.type].label}-${assetInfoData?.serial_number}_galley ${moment().format(backendDateFormat)}`, 'xlsx')
                trackActivity('Export', { page_title: 'Galley', asset_type: 'MSN', asset_name: `${assetInfoData?.serial_number}`, event_type: 'Galley Exported', event_desc: ``, file_extension: 'xlsx' })
                setLoading(false)
            });
    }
    return (
        <div style={{ padding: '10px' }}>
            {skeletonLoader ? <STableLoader count={8} /> :
                <>
                    <AddInteriorItem
                        addPermission={checkPermission('technical_specs', 'interior', 'C')}
                        exportPermission={checkPermission('technical_specs', 'interior', 'EXP')}
                        onAddItem={() => setAddEditGalley({ modal: true, data: galleyObj })}
                        item={galleysInfo}
                        isExport
                        isImport
                        onExport={() => onExportGallery()}
                        getResponseBack={() => getGalleys({}, 'skeletonLoader')}
                        expotrPermission={checkPermission('technical_specs', 'interior', 'EXP')}
                        trackActivity={trackActivity('Import', { page_title: 'Galley', event_type: 'Galley Imported', asset_type: 'MSN', asset_name: `${assetInfoData?.serial_number}`, event_desc: ``, file_extension: 'xlsx' })}
                        url={`/console/v2/aircraft/${params.aircraft_slug}/interior/galley/import/`}
                    />
                    <Paper style={{ maxHeight: window.innerHeight - 340 + 'px', overflow: "scroll" }}>
                        <Table className='mui-table-format' stickyHeader>
                            <GalleyHd />
                            <TableBody>
                                {galleysInfo.list.map((item, index) =>
                                    <GalleyList
                                        key={index}
                                        item={item}
                                        onEdit={() => setAddEditGalley({ modal: true, data: item })}
                                        onDelete={() => setDeleteGalley({ modal: true, data: item })}
                                    />
                                )}
                            </TableBody>
                        </Table>
                    </Paper>
                    {galleysInfo?.pagination ?
                        <Paper>
                            <Pagination
                                pagination={galleysInfo.pagination}
                                onChangePage={(event, newPage) => getGalleys({ ...filter, page: newPage + 1, per_page: galleysInfo.pagination.per_page }, 'pageLoader')}
                                onChangeRowsPerPage={(event) => getGalleys({ ...filter, page: 1, per_page: event.target.value }, 'pageLoader')}
                            /> </Paper>
                        : null
                    }
                    <Paper>
                        {!galleysInfo?.list?.length ? <div style={{ textAlign: 'center' }}><EmptyCollection title='No records found' /></div> : null}
                    </Paper>
                </>
            }
            {addEditGalley.modal ?
                <AddEditGalley
                    params={params}
                    assetInfoData={assetInfoData}
                    addEditGalley={addEditGalley}
                    toggleModalFn={() => setAddEditGalley({ modal: false, data: null })}
                    getResponseBack={() => getGalleys({}, 'pageLoader')}
                /> : null
            }
            {deleteGalley.modal ?
                <DeletePopUp
                    modal={deleteGalley.modal}
                    title='Delete Galley'
                    content={`Are you sure, you want to Delete?`}
                    toggleModalFn={() => setDeleteGalley({ modal: false, data: null })}
                    deleteRecordFn={onDeleteGalley}
                /> : null
            }
            {isLoading ? <PageLoader /> : null}
        </div>
    )
}
export default withRouter(Galley);