import React, {Fragment} from 'react';
import Autocomplete from "@material-ui/lab/Autocomplete"
import { TextField } from "@material-ui/core"
const DropdownFilter = ({filterOption, filterMenu, keyParam, onFieldChange, placeHolder}) => {
    const getMultiOutput = (value) => {
        const options =  filterMenu[keyParam].options;
        const valueKey = filterMenu[keyParam].valueKey;
        const selectedValues = filterOption[keyParam].split(',');
        return options.filter(item => selectedValues.includes(item[valueKey].toString()));
    }
    return(
        <Fragment>
            {filterMenu[keyParam].multiple ?
                <Autocomplete
                    options={filterMenu[keyParam].options}
                    getOptionLabel={(option) =>
                    option[filterMenu[keyParam].labelKey] }
                    id={keyParam}
                    value={ filterMenu[keyParam].options && filterMenu[keyParam].options.length && filterOption[keyParam] ? getMultiOutput() : [] }
                    onChange={(e, value) => onFieldChange(keyParam, value.map(item => item[filterMenu[keyParam].valueKey]).join(',').toString())}
                    renderInput={(params) => ( <TextField {...params} label={filterMenu[keyParam].title} margin="normal" fullWidth InputLabelProps={{ shrink: true }} placeholder={placeHolder} variant="outlined" /> )}
                    multiple={true}
                />:
                <Autocomplete
                    options={filterMenu[keyParam].options}
                    getOptionLabel={(option) =>
                    option[filterMenu[keyParam].labelKey] }
                    id={keyParam}
                    value={ filterMenu[keyParam].options && filterMenu[keyParam].options.length && filterOption[keyParam] ? filterMenu[keyParam].options.find(item => item[filterMenu[keyParam].valueKey] === filterOption[keyParam]) : null }
                    onChange={(e, value) => onFieldChange(keyParam, value ? value[filterMenu[keyParam].valueKey]:null)}
                    renderInput={(params) => ( <TextField {...params} label={filterMenu[keyParam].title} margin="normal" fullWidth InputLabelProps={{ shrink: true }} placeholder={placeHolder} variant="outlined" /> )}
                    multiple={false}
                />
            }
        </Fragment>
    )
}
export default DropdownFilter;