import React, { useState } from 'react';
import { Chip, TableCell, TableRow, IconButton, Tooltip } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import VisibilityIcon from '@material-ui/icons/Visibility';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import GetAppIcon from '@material-ui/icons/GetApp';
import { checkPermission } from '../../utils_v2';
import { globalExportService } from '../../../globalServices';
import { downloadFileType } from '../../../utils';
import { PageLoader } from '../../shared_elements';
import moment from 'moment';
import { fieldDateFormat } from '../../../constants';
const Role = ({item, onDelete, onEdit, onView}) => {
    const [isLoading, setLoading] = useState(false);
    const exportRoles = (role) => {
        setLoading(true);
        globalExportService(`console/role/${item.application?.id}/${item?.id}/`, {download:'xlsx'})
        .then(response => {
            setLoading(false);
            downloadFileType(response, `${item?.name}__${moment().format(fieldDateFormat)}`, 'xlsx')
        });
    }
    return(
        <TableRow>
            <TableCell style={{width:'320px'}}>
                {item?.name||''}<br/>
                <Chip label={item.custom_group ? 'Custom':'Default'} size='small' />
            </TableCell>
            <TableCell>{item?.group_profile_order?.name||''}</TableCell>
            <TableCell>{item?.application?.name||''}</TableCell>
            <TableCell>{item.users.length}</TableCell>
            <TableCell align='right' style={{cursor:'pointer'}}>
                <Tooltip title='View' arrow><VisibilityIcon onClick={onView} color='primary' fontSize='small' /></Tooltip>
                { checkPermission('settings', 'custom_roles', 'EXP')  ?
                            <Tooltip title='Export' arrow>
                                <GetAppIcon onClick={exportRoles}  color='primary' fontSize='small' />
                            </Tooltip>
                            :null
                }
                { item.custom_group ?
                    <>
                        { checkPermission('settings','custom_roles','U') ?
                            <Tooltip title='Edit' arrow>
                                <EditIcon onClick={onEdit} color='primary' fontSize='small' />
                            </Tooltip>
                            :null
                        }
                        { checkPermission('settings','custom_roles','D') ?
                            <Tooltip title='Delete' arrow>
                                <DeleteOutlineIcon onClick={onDelete} color='secondary' fontSize='small' />
                            </Tooltip>
                            :null
                        }
                    </>:null
                }
                {isLoading ? <PageLoader /> : null}
            </TableCell>
        </TableRow>
    )
}
export default Role;