import React from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Button, Dialog, DialogContent, DialogTitle, Grid, TextField } from '@material-ui/core';
const DeleteRoleProfile = ({modal, deleteRoleProfile, alterOptions, onChangeRoleProfile, onDeleteProfileRole, toggleModalFn}) => {
    return(
        <Dialog
            open={deleteRoleProfile.modal}
            onClose={toggleModalFn}
            aria-labelledby="scroll-dialog-title"
            className='console-management-modal'
        >
            <DialogTitle id="scroll-dialog-title">
                Delete "{deleteRoleProfile.item.name}" Profile Type
            </DialogTitle>
            <DialogContent dividers={true}>
                <div className='delete-role-modal'>
                    <h4>Are you sure you want to delete this Profile Type?</h4>
                    { deleteRoleProfile.item.sparta_groups.length ?
                        <>
                            <p>There are {deleteRoleProfile.item.sparta_groups.length} roles(s) linked to this profile type, please assign the alternate profile type</p>
                            <Grid container spacing={1}>
                                <Grid item md={12}>
                                    {(() => {
                                        let options = alterOptions.filter(item => item.id !== deleteRoleProfile.item.id)
                                        return(
                                            <Autocomplete
                                                options = {options}
                                                getOptionLabel={option => option.name}
                                                id="role"
                                                value={deleteRoleProfile.profile}
                                                onChange={(e, value) => onChangeRoleProfile(value)}
                                                renderInput={params => <TextField {...params} label="Profile Type" margin="normal" fullWidth InputLabelProps={{shrink: true}} variant='outlined' />}
                                            />
                                        )
                                    })()}
                                    
                                </Grid>
                            </Grid>
                        </>:null
                    }    
                </div>
                <div style={{textAlign:'center', marginTop:'20px'}}>
                    <ul className='list-inline'>
                        <li className='list-inline-item'>
                            <Button onClick={toggleModalFn} color='primary' size='small' variant='outlined'>Cancel</Button>
                        </li>
                        <li className='list-inline-item'>
                            <Button onClick={onDeleteProfileRole} color='secondary' size='small' variant='contained'>Confirm</Button>
                        </li>
                    </ul>
                </div>
            </DialogContent>
        </Dialog>
    )
}
export default DeleteRoleProfile;