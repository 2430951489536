import React from 'react';
import ReactDOM from 'react-dom';
import configureStore from './store/configureStore';
import { Provider } from 'react-redux';
import { SnackbarProvider } from "notistack"
import { Router, Route, IndexRoute, browserHistory } from 'react-router';
import { PageNotFound } from './shared';
import App from './App';
import requireAuth from './utils/requireAuth';
import LoggedUserRed from './utils/LoggedUserRed';
import { getLocalStorageInfo, getGlobalCookie } from './utils'
import {consoleRoutes} from './console_management/routes';
import ApplicationsAccess from './console_management/fleet/pages/ApplicationsAccess'
import SpartaSolutions from './console_management/solutions/pages';
const store = configureStore();
let userInfo = getLocalStorageInfo()?.user !== undefined;
ReactDOM.render(
	<div>
		<Provider store={store}>
			<SnackbarProvider maxSnack={3}>
				<Router history={browserHistory}>
					<Route path="/" component={App}>
						<IndexRoute component={(userInfo || getGlobalCookie('lessorAccess')) ? requireAuth(ApplicationsAccess) : LoggedUserRed(SpartaSolutions)} />
						<Route path="/" component={(userInfo || getGlobalCookie('lessorAccess')) ? requireAuth(ApplicationsAccess) : LoggedUserRed(SpartaSolutions)} />
						{ consoleRoutes.map((route, index) => 
							<Route key={index} path={route.path} component={route.component} />
						)}
					</Route>
					<Route path="/solutions" component={SpartaSolutions} />
					<Route path='*' exact={true} component={PageNotFound} />
				</Router>
			</SnackbarProvider>
		</Provider></div>,
	document.querySelector('.root')
);