import React from 'react';
import { Grid, Container, Button } from '@material-ui/core';
import lease from '../../../shared/assets/img/lease_management_icon.png';
import records from '../../../shared/assets/img/records_dataroom_icon.png';
import project from '../../../shared/assets/img/projects_management_icon.png';
import downArrow from '../../../shared/assets/img/arrow_down_icon.svg';
import camoIcon from '../../../console_management/assets/images/CAMO_Banner.png'
import { trackFeatureActivity } from '../../../utils/mixpanel';
import { isMobile } from 'react-device-detect';
import { slideInLeft, slideInUp, slideInDown, fadeIn } from 'react-animations'
import Radium, { StyleRoot } from 'radium';
 
const styles = {
  fadeIn: {
    animation: 'x 1s',
    animationName: Radium.keyframes(fadeIn, 'fadeIn')
  },
  slideInLeft: {
    animation: 'x 1s',
    animationName: Radium.keyframes(slideInLeft, 'slideInLeft')
  },
  slideInUp: {
    animation: 'x 1.5s',
    animationName: Radium.keyframes(slideInUp, 'slideInUp')
  },
  slideInDown: {
    animation: 'x 1.5s',
    animationName: Radium.keyframes(slideInDown, 'slideInDown')
  },
}
const ModulesFeatures = ({scrollToDsa, scrollToAt, tabIndex, changeTab, toggleRequestDemoModal}) => {
  return(
    <StyleRoot>
      <section className="solutions-features  module-features-section" id="module-features">
        <Container maxWidth="xl">
          <Grid container spacing={0} alignItems="center" className="tab-wrapper-mobile">
          <Grid item lg={2} md={2} sm={4} xs={6} className="tab-blk" style={tabIndex === 0 ? { color: '#3f51b5' } : {}} onClick={() => changeTab(0, 'Lease Management')}>
              <p className={tabIndex === 0 ? "active" : "tab-head"}>Lease  <br /> Management</p>
            </Grid>
            <Grid item lg={2} md={2} sm={4} xs={6} className="tab-blk" style={tabIndex === 1 ? { color: '#3f51b5' } : {}} onClick={() => changeTab(1, 'Airworthiness Management')}>
              <p className={tabIndex === 1 ? "active" : "tab-head"}>Airworthiness  <br /> Management</p>
            </Grid>
            <Grid item lg={2} md={2} sm={4} xs={6} className="tab-blk" style={tabIndex === 2 ? { color: '#3f51b5' } : {}} onClick={() => changeTab(2, 'Projects Management')}>
              <p className={tabIndex === 2 ? "active" : "tab-head"}>Projects <br /> Management</p>
            </Grid>
            <Grid item lg={2} md={2} sm={4} xs={6} className="tab-blk" style={tabIndex === 3 ? { color: '#3f51b5' } : {}} onClick={() => changeTab(3, 'Records Dataroom')}>
              <p className={tabIndex === 3 ? "active" : "tab-head"}>Records <br /> Dataroom</p>
            </Grid>
            <Grid item lg={2} md={2} sm={4} xs={6} className="tab-blk" onClick={scrollToAt}>
              <p className={tabIndex === 4 ? "active" : "tab-head"}>Automation <br /> Tools</p>
            </Grid>
            <Grid item lg={2} md={2} sm={4} xs={6} className="tab-blk" onClick={scrollToDsa} >
              <p className={tabIndex === 5 ? "active" : "last-tab"}>Technology <br /> Consulting</p>
            </Grid>
          </Grid>
          {
            (tabIndex === 0 ) &&
            <div className="slider-wrapper">
              <Grid container spacing={2} alignItems="center">
                <Grid item md={1}></Grid>
                <Grid item md={10}>
                  <Grid container spacing={2} alignItems="center">
                    <Grid item lg={5} md={12} xs={12} style={{ textAlign: 'center' }}>
                      <img className="slider-img" src={lease} alt="Maintenance" />
                    </Grid>
                    <Grid item lg={7} md={12} xs={12} className='mobile-align'>
                      <h4>Lease Management</h4>
                      <p>
                        Manage multiple assets under leases, forecast maintenance & rental cashflow. Track asset utilisation, raise and manage invoices. Integrate seamlessly with external systems.
                      </p>
                      <ul className="list-inline btn-cta">
                        <li className="list-inline-item">
                          <Button color="primary" className="btn-color" variant="contained" size="large" onClick={() => { toggleRequestDemoModal(); trackFeatureActivity('Item Clicked ', { page_title: 'Solutions', page_section: 'Our Digital Solutions - Lease', item_type: 'Event', item_name: 'Request a Demo' }) }}>Request a Demo</Button>
                        </li>
                      </ul>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item md={1}></Grid>
              </Grid>
            </div>
          }
          {
            (tabIndex === 1 ) &&
            <div className="slider-wrapper">
              <Grid container spacing={2} alignItems="center">
                <Grid item md={1}></Grid>
                <Grid item md={10}>
                  <Grid container spacing={2} alignItems="center">
                    <Grid item lg={5} md={12} xs={12} style={{ textAlign: 'center' }}>
                      <img className="slider-img" style={{width:'235px'}} src={camoIcon} alt="Airworthiness Management" />
                    </Grid>
                    <Grid item lg={7} md={12} xs={12} className='mobile-align'>
                      <h4>Airworthiness Management</h4>
                      <p>
                        Track ADs, SBs, utilisation and SVs for enhanced safety, compliance, and operational efficiency—essential asset management
                      </p>
                      <ul className="list-inline btn-cta">
                        <li className="list-inline-item">
                          <Button color="primary" className="btn-color" variant="contained" size="large" onClick={() => { toggleRequestDemoModal(); trackFeatureActivity('Item Clicked ', { page_title: 'Solutions', page_section: 'Our Digital Solutions - Airworthiness Management', item_type: 'Event', item_name: 'Request a Demo' }) }}>Request a Demo</Button>
                        </li>
                      </ul>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item md={1}></Grid>
              </Grid>
            </div>
          }
          {
            (tabIndex === 2 ) &&
            <div className="slider-wrapper">
              <Grid container spacing={2} alignItems="center">
                <Grid item md={1}></Grid>
                <Grid item md={10}>
                  <Grid container spacing={2} alignItems="center">
                    <Grid item lg={5} md={12} xs={12} style={{ textAlign: 'center' }}>
                      <img className="slider-img" src={project} alt="Project" />
                    </Grid>
                    <Grid item lg={7} md={12} xs={12} className='mobile-align'>
                      <h4>Projects Management</h4>
                      <p>
                        Plan, execute, and monitor fleet wide due-diligence. Perform diverse projects ranging from asset acquisition, mid lease inspections and end of lease.
                      </p>
                      <ul className="list-inline btn-cta">
                        <li className="list-inline-item">
                          <Button color="primary" className="btn-color" variant="contained" size="large" onClick={() => { toggleRequestDemoModal(); trackFeatureActivity('Item Clicked ', { page_title: 'Solutions', page_section: 'Our Digital Solutions - Projects', item_type: 'Event', item_name: 'Request a Demo' }) }}>Request a Demo</Button>
                        </li>
                      </ul>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item md={1}></Grid>
              </Grid>
            </div>
          }
          {
            (tabIndex === 3 ) &&
            <div className="slider-wrapper">
              <Grid container spacing={2} alignItems="center">
                <Grid item md={1}></Grid>
                <Grid item md={10}>
                  <Grid container spacing={2} alignItems="center">
                    <Grid item lg={5} md={12} xs={12} style={{ textAlign: 'center' }}>
                      <img className="slider-img" src={records} alt="Records" />
                    </Grid>
                    <Grid item lg={7} md={12} xs={12} className='mobile-align'>
                      <h4>Records Dataroom</h4>
                      <p>
                        Industry compliant cloud based Documents Management System for aviation records. Harness the power of digital data with our  specialised digitisation services.
                      </p>
                      <ul className="list-inline btn-cta">
                        <li className="list-inline-item">
                          <Button color="primary" className="btn-color" variant="contained" size="large" onClick={() => { toggleRequestDemoModal(); trackFeatureActivity('Item Clicked ', { page_title: 'Solutions', page_section: 'Our Digital Solutions - Records', item_type: 'Event', item_name: 'Request a Demo' }) }}>Request a Demo</Button>
                        </li>
                      </ul>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item md={1}></Grid>
              </Grid>
            </div>
          }
          <div className="down-arrow">
            <img src={downArrow} alt="Arrow Icon" onClick={scrollToAt} />
          </div>
        </Container>
      </section>
    </StyleRoot>
  )
}
export default ModulesFeatures;
