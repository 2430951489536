import React from 'react';
import { Chip, TableBody, TableCell, TableRow } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import VisibilityIcon from '@material-ui/icons/Visibility';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import { checkPermission } from '../../utils_v2';
import {SortableContainer, SortableElement, sortableHandle} from 'react-sortable-hoc';
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
const DragHandle = sortableHandle(() => <span style={{paddingRight:'10px', display:'inline-block', cursor:'move'}}><DragIndicatorIcon /></span>);
const SortableItem = SortableElement(({item, onEdit, onDelete, onView}) => 
    <TableRow>
        <TableCell style={{width:'10%'}}>
            {checkPermission('settings', 'profile_order', 'U') ?
                <DragHandle />:null
            }
            {item.order}
        </TableCell>
        <TableCell style={{width:'20%'}}>
            {item.name}<br/>
            <Chip label={item.custom_profile ? 'Custom':'Default'} size='small' />
        </TableCell>
        <TableCell style={{width:'10%'}}>{item.sparta_groups.length}</TableCell>
        <TableCell style={{width:'30%'}}>{item.description}</TableCell>
        <TableCell style={{width:'20%'}}>{item?.created_by?.name||'--'}</TableCell>
        <TableCell align='right' style={{cursor:'pointer', width:'10%'}}>
            <VisibilityIcon onClick={onView} color='primary' fontSize='small' />
            { item.custom_profile ?
                <>
                    {checkPermission('settings', 'profile_order', 'U')?
                        <EditIcon onClick={onEdit} color='primary' fontSize='small' />:null
                    }
                    { checkPermission('settings', 'profile_order', 'D') ?
                        <DeleteOutlineIcon onClick={onDelete} color='secondary' fontSize='small' />:null
                    }
                </>:null
            }
        </TableCell>
    </TableRow>
);
const RoleProfile = SortableContainer(({items, onEdit, onDelete, onView}) => {
    return (
        <TableBody>
            {items.map((item, index) => (
                <SortableItem onView={() => onView(item)} onEdit={() => onEdit(item)} onDelete={() => onDelete(item)} key={`item-${index}`} sortIndex={index} index={index} item={item} />
            ))}
        </TableBody>
    );
});
export default RoleProfile;