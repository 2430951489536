import React from "react";
import { Container, Grid } from "@material-ui/core";
import { Link } from "react-router";
import { getGlobalCookie } from '../../../utils'
const StaticBanner = ({ pageTitle }) => {
    return (
        <div className="static-banner">
            <Container maxWidth='md'>
                <Grid container spacing={1}>
                    <Grid item xs={6}>
                        <Link to='/'>
                            <img src={'https://sparta-aims.ams3.digitaloceanspaces.com/others/assets/img/phase-2/sparta_logo.svg'} alt="Logo" />
                        </Link>
                    </Grid>
                    <Grid item xs={6}>
                        {(() => {
                            let lessorAccess = JSON.parse(getGlobalCookie('lessorAccess'));
                            if (!lessorAccess) {
                                return (
                                    <Link to='/login' className='login-cta'>
                                        Login
                                    </Link>
                                )
                            } else {
                                return null
                            }
                        })()}
                    </Grid>
                    <Grid item md={12}>
                        <h2>{pageTitle}</h2>
                    </Grid>
                </Grid>
            </Container>
        </div>
    )
}
export default StaticBanner;