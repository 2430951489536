import React from 'react';
import PageLoaderImg from '../../assets/img/page-loader.gif'
const PageLoader = () => {
  return(
    <div className="page-loader">
      <span>
        <img src={PageLoaderImg} alt="" />
      </span>
    </div>
  )
}
export default PageLoader;
