import React, { useState } from "react";
import { Paper, Grid, Container } from "@material-ui/core";
import { AppSlider, SetResetPwdForm } from '../components'
import { isMobile } from 'react-device-detect';
import {PageLoader} from "../../shared_elements";

const PasswordAuth = ({}) => {
    const [ isLoading, setLoading ] = useState(false)
    return (
        <section className="login-auth-section" style={{height: `${window.innerHeight}px`}}>
            <Container maxWidth='lg' className="centre-blk">
                <Paper className="password-section">
                    <Grid container spacing={0}>
                        <Grid item md={isMobile ? 12: 6} style={{position:'relative'}}>
                            <SetResetPwdForm
                            isLoading={isLoading}
                            getLoaderResponse={(flag) => setLoading(flag)}
                            />
                        </Grid>
                        {isMobile ? null : <Grid item md={6}>
                            <div className="img-slider-wrapper">
                                <AppSlider />
                            </div>
                        </Grid>}
                    </Grid>
                </Paper>
            </Container>
            {isLoading ? <PageLoader /> : null}
        </section>
    )
}
export default PasswordAuth;