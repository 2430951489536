import React from 'react';
import { Grid, Button, Container } from '@material-ui/core';
import { browserHistory } from 'react-router'
import spartaLogo from '../../../shared/assets/img/sparta_logo_new.png';
import { isMobile } from 'react-device-detect';
 
const FeaturesHeader = ({scrollToDsa, scrollToNextSec}) => {
  return(
    <section className="solutions-header header-section">
      <Container maxWidth="xl">
        <Grid container spacing={3} alignItems="center">
          <Grid item md={4} xs={5}>
            <img width="50" src={spartaLogo} alt="Sparta Logo" />
          </Grid>
          <Grid item md={8} xs={7}>
            <ul className="list-inline header-cta">
              {isMobile ? null : <li className="list-inline-item" onClick={scrollToNextSec}>Solutions</li>}
              {isMobile ? null : <li className="list-inline-item" style={{ cursor: 'pointer' }} onClick={scrollToDsa}>Technology Consulting</li>}
              <li className="list-inline-item">
                <Button onClick={() => browserHistory.push(`/login`)} color="primary" variant="outlined">Login</Button>
              </li>
            </ul>
          </Grid>
        </Grid>
      </Container>
    </section>
  )
}
export default FeaturesHeader;
