import React, { useState, useEffect } from "react";
import { withRouter } from "react-router";
import { useSnackbar } from 'notistack';
import { DropzoneArea } from "material-ui-dropzone";
import { Table, TableBody, TableCell, TableHead, TableRow, Paper, Grid, Button, TextField, Dialog, DialogContent, DialogTitle, DialogActions, Tooltip, CircularProgress, Chip } from '@material-ui/core';
import { Pagination, STableLoader, FilterComponent, PageLoader, EmptyCollection, DeletePopUp } from '../../shared_elements';
import AddInteriorItem from './AddInteriorItem'
import EditIcon from '@material-ui/icons/Edit';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import GetAppIcon from '@material-ui/icons/GetApp';
import { globalGetService, globalPutService, globalPostService, globalDeleteService } from '../../../globalServices';
import { checkApiStatus, checkPermission } from '../../utils_v2';
import { downloadFile } from "../../../utils";
import RemarksDynamicField from "../../../shared/components/RemarksDynamicField";
import { trackActivity } from "../../../utils/mixpanel";
const lopaObj = {
    files: [],
    remarks: ''
}
const LOPAHd = () => {
    return (
        <TableHead>
            <TableRow>
                <TableCell>File Name</TableCell>
                <TableCell>Remarks</TableCell>
                <TableCell align="right">Action(s)</TableCell>
            </TableRow>
        </TableHead>
    )
}
const LOPAList = ({ item, onEdit, onDelete }) => {
    return (
        <TableRow>
            <TableCell style={{ maxWidth: '380px' }}>{item?.file_name || '--'}</TableCell>
            <TableCell>
                <RemarksDynamicField
                    item={item}
                />
            </TableCell>
            <TableCell align="right">
                <ul className="list-inline" style={{ width: '80px', float: 'right' }}>
                    {item?.image?.length ?
                        <li className="list-inline-item hover-inline" onClick={() => downloadFile(item)}>
                            <Tooltip title='Download LOPA' arrow><GetAppIcon color="primary" fontSize="small" /></Tooltip>
                        </li> : null
                    }
                    {
                        checkPermission('technical_specs', 'interior', 'U') ?
                            <li className="list-inline-item hover-inline" onClick={onEdit}>
                                <Tooltip title='Edit' arrow><EditIcon color="primary" fontSize="small" /></Tooltip>
                            </li>
                            : null}
                    {
                        checkPermission('technical_specs', 'interior', 'D') ?
                            <li className="list-inline-item hover-inline" onClick={onDelete}>
                                <Tooltip title='Delete' arrow><DeleteOutlineIcon color="secondary" fontSize="small" /></Tooltip>
                            </li>
                            : null}
                </ul>
            </TableCell>
        </TableRow>
    )
}
const AddEditLOPA = ({ params, addEditLOPA, toggleModalFn, getResponseBack, assetInfoData }) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [isLoading, setLoading] = useState(false);
    const [lopa, setLOPA] = useState(addEditLOPA.data);
    const [error, setError] = useState({});
    const onAddEditLOPA = () => {
        let validationInputs = {
            files: lopa?.id ? '' : lopa?.files?.length ? '' : 'Please upload LOPA',
        }
        if (Object.keys(validationInputs).every((k) => { return validationInputs[k] === '' })) {
            setLoading(true);
            const payload = new FormData();
            payload.append('remarks', lopa?.remarks || '');
            if (lopa?.files?.length) {
                payload.append('image', lopa?.files[0]);
            }
            if (lopa.id) {
                globalPutService(`console/v2/${params.type}/${params.aircraft_slug}/interior/lopa/${lopa.id}/`, payload)
                    .then(response => {
                        if (checkApiStatus(response)) {
                            enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { horizontal: 'right', vertical: 'top' } });
                            trackActivity('Item Edited', { page_title: 'LOPA', asset_type: 'MSN', asset_name: assetInfoData?.serial_number, event_type: 'Edit LOPA', event_desc: 'Edited LOPA data from Edit LOPA form' });
                            toggleModalFn(); getResponseBack();
                        }
                        setLoading(false);
                    })
            } else {

                globalPostService(`console/v2/${params.type}/${params.aircraft_slug}/interior/lopa/`, payload)
                    .then(response => {
                        if (checkApiStatus(response)) {
                            enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { horizontal: 'right', vertical: 'top' } });
                            trackActivity('Item Added', { page_title: 'LOPA', asset_type: 'MSN', asset_name: assetInfoData?.serial_number, event_type: 'Add LOPA', event_desc: 'Added LOPA data from Add LOPA form' });
                            toggleModalFn(); getResponseBack();
                        }
                        setLoading(false);
                    })
            }
        } else {
            setError(validationInputs);
        }
    }
    return (
        <Dialog
            open={addEditLOPA.modal}
            onClose={toggleModalFn}
            className='console-management-modal'
            maxWidth="md"
        >
            <DialogTitle id="scroll-dialog-title">
                {lopa?.id ? 'Edit ' : 'Add '} LOPA
            </DialogTitle>
            <DialogContent dividers={true}>
                <Grid container spacing={1}>
                    <Grid item md={12}>
                        <div>
                            <DropzoneArea
                                acceptedFiles={['image/jpeg', 'image/jpg', 'image/png', 'image/gif', 'image/bmp', 'application/pdf']}
                                filesLimit={1}
                                maxFileSize={104857600}
                                showPreviewsInDropzone={false}
                                useChipsForPreview={true}
                                showPreviews={true}
                                dropzoneText={<p style={{ fontSize: '13px' }}>Drag & Drop Documents<br /> OR <br /> Click Here<br /> </p>}
                                dropzoneClass="drag-drop-cnt"
                                showAlerts={['info', 'error']}
                                alertSnackbarProps={{ anchorOrigin: { vertical: 'top', horizontal: 'right' } }}
                                clearOnUnmount={true}
                                onDrop={(files) => { setLOPA({ ...lopa, 'files': files }); setError({ ...error, 'files': '' }) }}
                            />
                        </div>
                        <span style={{ fontSize: '11px' }} className='files-upload-limit' > Note : Supported extension ('.pdf, images/').</span>
                        {error?.files ? <p className="error-msg">{error?.files}</p> : null}
                        {lopa?.id && lopa?.file_name ?
                            <p>
                                <Chip label={lopa?.file_name} size='small' />
                            </p> : null
                        }

                    </Grid>
                    <Grid item md={12}>
                        <TextField
                            id='remarks'
                            label='Remarks'
                            value={lopa?.remarks || ''}
                            fullWidth
                            margin="normal"
                            multiline
                            rows={3}
                            inputProps={{ maxLength: 250 }}
                            onChange={(e) => setLOPA({ ...lopa, 'remarks': e.target.value })}
                            InputLabelProps={{ shrink: true }}
                            variant='outlined'
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button disabled={isLoading} onClick={toggleModalFn} color='primary' size='small' variant='outlined'>Cancel</Button>
                <Button disabled={isLoading} onClick={onAddEditLOPA} color='primary' size='small' variant='contained'>
                    {isLoading ? <CircularProgress size={24} /> : 'Save'}
                </Button>
            </DialogActions>
        </Dialog>
    )
}
const LOPA = ({ params, assetInfoData }) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [isLoading, setLoading] = useState(false);
    const [skeletonLoader, setSkeletonLoader] = useState(false);
    const [filter, setFilter] = useState({});
    const [lopasInfo, setLOPAsInfo] = useState({ list: [], pagination: null });
    const [addEditLOPA, setAddEditLOPA] = useState({ modal: false, data: null });
    const [deleteLOPA, setDeleteLOPA] = useState({ modal: false, data: null });
    useEffect(() => {
        getLOPAs({}, 'skeletonLoader');
    }, []);
    const getLOPAs = (query = {}, loaderType) => {
        loaderType === 'skeletonLoader' ? setSkeletonLoader(true) : setLoading(true)
        globalGetService(`console/v2/${params.type}/${params.aircraft_slug}/interior/lopa/`, query)
            .then(response => {
                if (checkApiStatus(response)) {
                    setLOPAsInfo(response.data.data)
                }
                loaderType === 'skeletonLoader' ? setSkeletonLoader(false) : setLoading(false)
            })
    }
    const onDeleteLOPA = () => {
        setLoading(true);
        globalDeleteService(`console/v2/${params.type}/${params.aircraft_slug}/interior/lopa/${deleteLOPA.data.id}`)
            .then(response => {
                if (checkApiStatus(response)) {
                    enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { horizontal: 'right', vertical: 'top' } });
                    setDeleteLOPA({ modal: false, data: null })
                    getLOPAs({}, 'pageLoader');
                }
                setLoading(false);
            })
    }
    return (
        <div style={{ padding: '10px' }}>
            {skeletonLoader ? <STableLoader count={4} /> :
                <>
                    <AddInteriorItem addPermission={checkPermission('technical_specs', 'interior', 'C')} onAddItem={() => setAddEditLOPA({ modal: true, data: lopaObj })} />
                    <Paper style={{ maxHeight: window.innerHeight - 340 + 'px', overflow: "scroll" }}>
                        <Table className='mui-table-format' stickyHeader>
                            <LOPAHd />
                            <TableBody>
                                {lopasInfo.list.map((item, index) =>
                                    <LOPAList
                                        key={index}
                                        item={item}
                                        onEdit={() => setAddEditLOPA({ modal: true, data: { ...item, files: [] } })}
                                        onDelete={() => setDeleteLOPA({ modal: true, data: item })}
                                    />
                                )}
                            </TableBody>
                        </Table>
                    </Paper>
                    {lopasInfo?.pagination ?
                        <Paper>
                            <Pagination
                                pagination={lopasInfo.pagination}
                                onChangePage={(event, newPage) => getLOPAs({ ...filter, page: newPage + 1, per_page: lopasInfo.pagination.per_page }, 'pageLoader')}
                                onChangeRowsPerPage={(event) => getLOPAs({ ...filter, page: 1, per_page: event.target.value }, 'pageLoader')}
                            />
                        </Paper>
                        : null
                    }
                    <Paper>
                        {!lopasInfo?.list?.length ? <div style={{ textAlign: 'center' }}><EmptyCollection title='No records found' /></div> : null}
                    </Paper>
                </>
            }
            {addEditLOPA.modal ?
                <AddEditLOPA
                    params={params}
                    assetInfoData={assetInfoData}
                    addEditLOPA={addEditLOPA}
                    toggleModalFn={() => setAddEditLOPA({ modal: false, data: null })}
                    getResponseBack={() => getLOPAs({}, 'pageLoader')}
                /> : null
            }
            {deleteLOPA.modal ?
                <DeletePopUp
                    modal={deleteLOPA.modal}
                    title='Delete LOPA'
                    content={`Are you sure, you want to Delete?`}
                    toggleModalFn={() => setDeleteLOPA({ modal: false, data: null })}
                    deleteRecordFn={onDeleteLOPA}
                /> : null
            }
            {isLoading ? <PageLoader /> : null}
        </div>
    )
}
export default withRouter(LOPA);