import React, { useState, useEffect } from "react";
import { useSnackbar } from 'notistack';
import { Table, TableBody, TableCell, TableHead, TableRow, Paper, Grid, Button } from '@material-ui/core';
import { Pagination, STableLoader, FilterComponent, PageLoader, EmptyCollection } from '../../shared_elements';
import { TechSpecsNav } from '../../elements'
import { AssetInfo } from "../components";
import { globalExportService, globalGetService } from '../../../globalServices';
import { checkApiStatus, checkPermission } from '../../utils_v2';
import moment from "moment";
import { assetTypeValues, backendDateFormat, displayDateFormat, fieldDateFormat } from "../../../constants";
import { downloadFileType } from "../../../utils";
import { getPermissionKey } from "..";
import { trackActivity } from "../../../utils/mixpanel";
const MovementTrackerHd = () => {
    return (
        <TableHead>
            <TableRow>
                <TableCell>Asset</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Performed By</TableCell>
                <TableCell>Performed At</TableCell>
                <TableCell>Position</TableCell>
                <TableCell style={{ width: '150px' }}>Reason</TableCell>
                <TableCell style={{ width: '145px' }}>Records Movement</TableCell>
            </TableRow>
        </TableHead>
    )
}
const MovementTrackerList = ({ item, assetInfoData }) => {
    const [showMore, setShowMore] = useState({});
    const toggleDescription = (id) => {
        setShowMore({
            ...showMore,
            [id]: !showMore[id],
        });
    };
    return (
        <TableRow>
            <TableCell>{ (assetInfoData?.asset_type?.type === 1 ? item?.major_assembly?.asset_name :item?.aircraft?.asset_name ) || '--'}</TableCell>
            <TableCell>{item?.status?.label || '--'} {item?.installed_type ? `(${item?.installed_type})` : null}</TableCell>
            <TableCell>{item?.user?.name || ''}</TableCell>
            <TableCell>{moment(item.created_at).format(displayDateFormat) || '--'}</TableCell>
            <TableCell>{item?.position?.label || '--'}</TableCell>
            <TableCell>
                {item?.remarks && item?.remarks?.length > 50 ? (
                    <div style={{ width: '300px' }}>
                        {showMore[item?.id] ? (
                            <span> {item?.remarks}
                                <a href='#' style={{ color: '#0E7FE1', fontSize: '12px' }} onClick={(e) => { e.preventDefault(); toggleDescription(item.id); }}  >less </a>
                            </span>
                        ) : (
                            <span> {item.remarks.substring(0, 35)}
                                <a href='#' style={{ color: '#0E7FE1', fontSize: '12px' }} onClick={(e) => { e.preventDefault(); toggleDescription(item.id); }}  > ..more </a>
                            </span>
                        )}
                    </div>
                ) : (
                    <span>{item.remarks || '--'}</span>
                )}
            </TableCell>
            <TableCell >{item?.is_data_moved ? 'Yes': 'No'}</TableCell>
        </TableRow>
    )
}
const MovementTracker = ({ params }) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [isLoading, setLoading] = useState(false);
    const [skeletonLoader, setSkeletonLoader] = useState(false);
    const [movementTrackerInfo, setMovementTrackerInfo] = useState({ list: [], pagination: {} });
    const [assetInfoData, setAssetInfoData] = useState();
    const [filter, setFilter] = useState({});
    const [assetSkeletonLoader, setAssetSkeletonLoader] = useState(false);
    useEffect(() => {
        getMovementTracker({}, 'skeletonLoader');
        getAssetInfo('assetSkeletonLoader')
    }, []);
    const getAssetInfo = (loaderType) => {
        loaderType === 'setAssetSkeletonLoader' ? setAssetSkeletonLoader(true) : setLoading(true);
        globalGetService(`console/v2/asset/${params.type}/${params.aircraft_slug}/asset_details/`)
            .then(response => {
                if (checkApiStatus(response)) {
                    setAssetInfoData(response.data.data);
                }
                loaderType === 'setAssetSkeletonLoader' ? setAssetSkeletonLoader(false) : setLoading(false);
            })
    }
    const getMovementTracker = (query = {}, loaderType) => {
        loaderType === 'skeletonLoader' ? setSkeletonLoader(true) : setLoading(true)
        globalGetService(`console/get-major-assembly-history/${params.type}/${params.aircraft_slug}/?page=1`, query)
            .then(response => {
                if (checkApiStatus(response)) {
                    setMovementTrackerInfo(response.data.data)
                }
                loaderType === 'skeletonLoader' ? setSkeletonLoader(false) : setLoading(false)
            })
    }
    const onMOmentExport = () => {
        setLoading(true);
        globalExportService(`console/get-major-assembly-history/${params.type}/${params.aircraft_slug}/?download=xlsx`)
            .then(response => {
                downloadFileType(response, `${assetTypeValues[assetInfoData?.asset_type.type].label} ${assetInfoData.serial_number} Moment-Tracker ${moment().format(fieldDateFormat)}`, 'xlsx')
                trackActivity('Export', { page_title: 'MomentTracker', asset_type: assetTypeValues[assetInfoData?.asset_type.type].label, asset_name: assetInfoData?.serial_number, event_type: 'MomentTracker Exported', event_desc: '', file_extension: 'xlsx' });
                // enqueueSnackbar("MomentTracker Exported Successully", { variant: 'success', anchorOrigin: { horizontal: 'right', vertical: 'top' }, });
                setLoading(false);
            })
    }
    return (
        <section className="asset-tech-specs">
            <AssetInfo assetInfoData={assetInfoData} skeletonLoader={assetSkeletonLoader} getResponseBack={() => getAssetInfo()} />
            <Paper className="tech-specs-card" style={{ minHeight: `${window.innerHeight - 162}px` }}>
                <TechSpecsNav />
                <div className="tech-specs-content" >
                    <div style={{ padding: '10px' }}>
                        {skeletonLoader ? <STableLoader count={5} /> :
                            <>
                                <Paper square style={{ padding: '4px 8px' }}>
                                    <Grid container spacing={1} alignItems="center">
                                        <Grid item md={9}></Grid>
                                        <Grid item md={3}>
                                            <ul className="list-inline flex-centered" style={{ float: 'right' }}>
                                                {movementTrackerInfo?.list?.length && checkPermission('technical_specs', getPermissionKey(params.type), 'EXP') ?
                                                    <li className="list-inline-item">
                                                        <Button onClick={onMOmentExport} color='primary' size='small' variant="outlined">Export</Button>
                                                    </li> : null}
                                            </ul>
                                        </Grid>
                                    </Grid>
                                </Paper>
                                <Paper style={{ maxHeight: `${window.innerHeight - 290}px`, overflow: 'auto' }}>
                                    <Table className='mui-table-format' stickyHeader>
                                        <MovementTrackerHd />
                                        <TableBody>
                                            {movementTrackerInfo?.list?.map((item, index) =>
                                                <MovementTrackerList
                                                assetInfoData={assetInfoData}
                                                    key={index}
                                                    item={item}
                                                />
                                            )}
                                        </TableBody>
                                    </Table>
                                </Paper>
                                <Paper>
                                    <Pagination
                                        pagination={movementTrackerInfo.pagination}
                                        onChangePage={(event, newPage) => getMovementTracker({ ...filter, page: newPage + 1, per_page: movementTrackerInfo.pagination.per_page }, 'pageLoader')}
                                        onChangeRowsPerPage={(event) => getMovementTracker({ ...filter, page: 1, per_page: event.target.value }, 'pageLoader')}
                                    />
                                </Paper>
                                <Paper>
                                    {!movementTrackerInfo?.list?.length ? <div style={{ textAlign: 'center' }}><EmptyCollection title='No records found' /></div> : null}
                                </Paper>
                            </>
                        }
                        {isLoading ? <PageLoader /> : null}
                    </div>
                </div>
            </Paper>
        </section>
    )
}
export default MovementTracker;