
export const CLEAR_TOAST = 'CLEAR_TOAST';
export const SHOW_TOAST = 'SHOW_TOAST';
export const HIDE_TOAST = 'HIDE_TOAST';

export const S_CLOSE_TOAST_NOTIFY = 'S_CLOSE_TOAST_NOTIFY';

export const NOTIFICATIONS_COUNT = 'NOTIFICATIONS_COUNT'
export const NOTIFICATIONS_COUNT_GIF='NOTIFICATIONS_COUNT_GIF';
export const NOTIFICATIONS_COUNT_UPDATE='NOTIFICATIONS_COUNT_UPDATE';

export const NOTIFICATION_FLAG = 'NOTIFICATION_FLAG';

