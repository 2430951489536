import { TableCell, TableHead, TableRow, TableSortLabel } from '@material-ui/core';
import React from 'react';
const RoleHeader = ({item, createSortHandler, sortInfo}) => {
    return(
        <TableHead>
            <TableRow>
                <TableCell>
                    <TableSortLabel
                        active={sortInfo.sort === 'name' ? true : false}
                        direction={sortInfo.sort === 'name' ? sortInfo.sort_by : 'asc'}
                        onClick={() => createSortHandler('name')}
                    >
                      Role Name
                    </TableSortLabel>
                </TableCell>
                <TableCell>
                    <TableSortLabel
                        active={sortInfo.sort === 'group_profile' ? true : false}
                        direction={sortInfo.sort === 'group_profile' ? sortInfo.sort_by : 'asc'}
                        onClick={() => createSortHandler('group_profile')}
                    >
                      Profile Type
                    </TableSortLabel>
                </TableCell>
                <TableCell>
                    <TableSortLabel
                        active={sortInfo.sort === 'application' ? true : false}
                        direction={sortInfo.sort === 'application' ? sortInfo.sort_by : 'asc'}
                        onClick={() => createSortHandler('application')}
                    >
                    Application
                    </TableSortLabel>
                </TableCell>
                <TableCell>User(s)</TableCell>
                <TableCell align='right'> Action(s) </TableCell>
            </TableRow>
        </TableHead>
    )
}
export default RoleHeader