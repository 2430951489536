import { jetStreamInstance } from "../../shared/components"
export const contactFilterOps = {
    name: {
      inputType: 'text',
      placeholder: 'Search by Name',
      title:'Name'
    },
    email: {
      inputType: 'text',
      placeholder: 'Search by Email',
      title:'Email'
    },
    lessee_id: {
      inputType: 'dropdown',
      placeholder: 'Search by Lessee name',
      title:'Lessee',
      labelKey:'name',
      valueKey:'id',
      options:[]
    }
}
export const bankObj = {
	lessor_level: null,
	lessor: null,
	name: "",
	account_name: "",
	account_number: "",
	code: "",
	hub_number: "",
	vat_number: "",
	iban: "",
	aba_number: "",
	sort_code: "",
	swift_code: "",
	reference: "",
	note: "",
	footer_note: "",
	jurisdiction: "",
	spv: ""
}
export const bankFilters = {
	'bank_name':{
        'inputType': 'text',
        'title': 'Bank Name',
    },
    'status':{
        'inputType': 'dropdown',
        'title': 'Status',
        'options':[{label:'Pending',value:'0'},{label:'Approved',value:'1'}],
        'labelKey':'label',
        'valueKey':'value'        
    },
    'lessor_id':{
        'inputType': 'dropdown',
        'title': `${jetStreamInstance?"Lessor/Holdco":"Lessor"}`,
        'options':[],
        'labelKey':'name',
        'valueKey':'id'        
    },
}
