import React from 'react';
import { TableCell, TableHead, TableRow, Table } from '@material-ui/core';
import { castleAirInstance } from '../../../shared/components';
const MajorAssembliesHD = ({item}) => {
    return(
        <TableHead>
            <TableRow>
                <TableCell style={{width:'160px', borderRight:'1px solid #d7d7d7'}}>Aircraft</TableCell>
                <TableCell colSpan={8} style={{padding:'0'}}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell style={{borderBottom:'1px solid #d7d7d7'}} align='center' colSpan={8}>Major Assemblies</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell style={{borderRight:'1px solid #d7d7d7'}} align='center' colSpan={4}>Titled/Assigned</TableCell>
                                <TableCell  align='center' colSpan={4}>On Wing</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell colSpan={4} style={{padding:'0'}}>
                                    <Table>
                                        <TableRow>
                                            <TableCell style={{width:'110px', borderRight:'1px solid #d7d7d7'}} align='center' colSpan={1}>Engine</TableCell>
                                            {
                                                castleAirInstance ? null:<TableCell style={{width:'110px', borderRight:'1px solid #d7d7d7'}} align='center' colSpan={1}>Propeller</TableCell>
                                            }
                                            <TableCell style={{width:'110px', borderRight:'1px solid #d7d7d7'}} align='center' colSpan={1}>LG</TableCell>
                                            <TableCell style={{width:'110px', borderRight:'1px solid #d7d7d7'}} align='center' colSpan={1}>APU</TableCell>
                                        </TableRow>
                                    </Table>
                                </TableCell>
                                <TableCell colSpan={4} style={{padding:'0'}}>
                                    <Table>
                                        <TableRow>
                                            <TableCell style={{width:'110px', borderRight:'1px solid #d7d7d7'}} align='center' colSpan={1}>Engine</TableCell>
                                            {
                                                castleAirInstance ? null: <TableCell style={{width:'110px', borderRight:'1px solid #d7d7d7'}} align='center' colSpan={1}>Propeller</TableCell>
                                            }
                                            <TableCell style={{width:'110px', borderRight:'1px solid #d7d7d7'}} align='center' colSpan={1}>LG</TableCell>
                                            <TableCell style={{width:'110px', borderRight:'1px solid #d7d7d7'}} align='center' colSpan={1}>APU</TableCell>
                                        </TableRow>
                                    </Table>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                    </Table>
                </TableCell>
                <TableCell style={{borderLeft:'1px solid #d7d7d7'}} align='right'>Action</TableCell>
            </TableRow>
        </TableHead>
    )
}
export default MajorAssembliesHD