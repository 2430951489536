import React, { useState } from "react";
import { Button, Menu, MenuItem } from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
const ChangeBankStatus = ({ label, onChangeStatus }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = (event, value) => {
        setAnchorEl(null);
    };
    return (
        ['Approved', 'Disabled'].includes(label) ?
            <>
                <Button style={label === 'Approved' ? { border: '1px solid #0a8b2e', color: '#0a8b2e' } : { border: '1px solid #949494', color: '#949494' }} variant="outlined" size="small" aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick} endIcon={<ArrowDropDownIcon fontSize="small" />}>
                    {label}
                </Button>
                <Menu
                    id="simple-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                >
                    {
                        label === 'Approved' ?
                            <MenuItem onClick={() => { handleClose(); onChangeStatus(3) }}>Disable</MenuItem>
                            :
                            <MenuItem onClick={() => { handleClose(); onChangeStatus(1) }}>Approve</MenuItem>
                    }
                </Menu>
            </> :
            <>
                <Button style={{ border: '1px solid #b29b0d', color: '#b29b0d' }} variant="outlined" size="small" aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick} endIcon={<ArrowDropDownIcon fontSize="small" />}>
                    {label}
                </Button>
                <Menu
                    id="simple-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                >
                    <MenuItem onClick={() => { handleClose(); onChangeStatus(1) }}>Approve</MenuItem>
                    <MenuItem onClick={() => { handleClose(); onChangeStatus(2) }}>Reject</MenuItem>
                </Menu>
            </>
    )
}
export default ChangeBankStatus;