import React, {useState} from 'react';
import { withRouter } from 'react-router';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import moment from 'moment';
import { backendDateFormat, fieldDateFormat } from '../../../constants';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { FormControlLabel, Checkbox, Grid, Radio, TextField } from "@material-ui/core";

const InvoiceReport = ({onChangeExport, exportObj, onChangeExtensionFn, onChangeDateFn, onChangeDropdownFn}) => {
    return(
        <div>
            <Grid container spacing={1} alignItems='center'>
                <Grid item md={12}>
                    <div className='export-module-card'>
                        <h4 className='rpt-card-header'>Invoice</h4>
                        <div className='rpt-card-body'>
                            <Grid container spacing={3} alignItems='center'>
                                <Grid item md={6}>
                                    <FormControlLabel control={
                                        <Checkbox 
                                            checked={exportObj.contracts && exportObj.contracts.invoice && exportObj.contracts.invoice.export ? true:false} 
                                            onChange={(e) => onChangeExport(e.target.checked, 'invoice')} 
                                            color="primary" 
                                            size="small" />} 
                                            label='Invoice Detail' 
                                        />
                                </Grid>
                                <Grid item md={6}>
                                    <FormControlLabel value={1} control={<Radio checked={(exportObj.contracts && exportObj.contracts.invoice && exportObj.contracts.invoice.extension == 'pdf')} onChange={() => onChangeExtensionFn('pdf', 'invoice')} color="primary" size="small" />} label="PDF" />
                                </Grid>
                            </Grid>
                            <Grid container spacing={3} alignItems='center'>
                                <Grid item md={6}>
                                    <MuiPickersUtilsProvider utils={MomentUtils}>
                                        <KeyboardDatePicker
                                            margin="normal"
                                            id="start_date"
                                            label="Invoice Start Date"
                                            format={fieldDateFormat}
                                            fullWidth
                                            InputLabelProps={{ shrink: true }}
                                            maxDate={moment()}
                                            value={exportObj.contracts.invoice.filter.startDate ? moment(exportObj.contracts.invoice.filter.startDate):null}
                                            onChange={(data) => onChangeDateFn(moment(data).format(backendDateFormat), 'startDate', 'invoice')}
                                            inputVariant='outlined'
                                        />
                                    </MuiPickersUtilsProvider>
                                </Grid>
                                <Grid item md={6}>
                                    <MuiPickersUtilsProvider utils={MomentUtils}>
                                        <KeyboardDatePicker
                                            margin="normal"
                                            id="end_date"
                                            label="Invoice End Date"
                                            format={fieldDateFormat}
                                            fullWidth
                                            InputLabelProps={{ shrink: true }}
                                            maxDate={moment()}
                                            value={exportObj.contracts.invoice.filter.endDate ? moment(exportObj.contracts.invoice.filter.endDate):null}
                                            onChange={(data) => onChangeDateFn(moment(data).format(backendDateFormat), 'endDate', 'invoice')}
                                            inputVariant='outlined'
                                        />
                                    </MuiPickersUtilsProvider>
                                </Grid>
                            </Grid>
                            <Grid container spacing={3} alignItems='center'>
                                <Grid item md={6}>
                                    <Autocomplete
                                        options={[{label: 'Reserve Invoice', value: '1'}, {label: 'Rental Invoice', value: '2' }, {label: 'Ad Hoc Invoice', value: '9'}, {label: 'Penalty Invoice', value: '3'}, {label: 'Debit Note Invoice', value: '4'},
                                                    {label: 'Credit Note Invoice', value: '5'}, {label: 'Security Deposit Invoice', value: '6'}, {label: 'Insurance Invoice', value: '7'}, {label: 'Insurance Claim Invoice', value: '8'}]}
                                        getOptionLabel={option => option.label}
                                        id="invoice_type"
                                        value={exportObj.contracts.invoice.filter.invoice_type}
                                        onChange={(e, data) => onChangeDropdownFn(data, 'invoice_type', 'invoice')}
                                        renderInput={params => <TextField  {...params} label="Invoice Type" margin="normal" fullWidth InputLabelProps={{ shrink: true }} placeholder='Select Invoice Type' variant='outlined' />}
                                    />
                                </Grid>
                                <Grid item md={6}>
                                    <Autocomplete
                                        options={[{label: 'Draft', value: '1'}, {label: 'Sent', value: '2' }, {label: 'Partially-Paid', value: '5'}, {label: 'Paid', value: '3'}, {label: 'Cancelled', value: '4'}]}
                                        getOptionLabel={option => option.label}
                                        id="invoice_status"
                                        value={exportObj.contracts.invoice.filter.status}
                                        onChange={(e, data) => onChangeDropdownFn(data, 'status', 'invoice')}
                                        renderInput={params => <TextField {...params} label="Invoice Status" margin="normal" fullWidth InputLabelProps={{ shrink: true }} placeholder='Select Invoice Status' variant='outlined' />}
                                    />
                                </Grid>
                            </Grid>
                        </div>
                    </div>
                </Grid>
            </Grid>
        </div>
    )
}
export default withRouter(InvoiceReport);