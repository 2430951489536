import React, { Fragment } from 'react';
import { withRouter } from 'react-router';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import moment from 'moment';
import { backendDateFormat, fieldDateFormat } from '../../../constants';
import { FormControlLabel, Checkbox, Grid, Paper, Radio } from "@material-ui/core";
import { InvoiceReport, CashflowReport } from '../components';
import { getLocalStorageInfo } from '../../../utils';

const UtilizationReport = ({onChangeExport, exportObj, onChangeExtensionFn, onChangeDateFn, onChangeDropdownFn}) => {
    return(
        <Paper>
            {getLocalStorageInfo().user.permission.contracts ?
            <Grid container spacing={1} alignItems='center'>
                <Grid item md={12}>
                    <div className='export-module-card'>
                        <h4 className='rpt-card-header'>Contracts</h4>
                        <div className='rpt-card-body'>
                            <Grid container spacing={1} alignItems='center'>
                                <Grid item md={6}>
                                    <FormControlLabel control={
                                        <Checkbox 
                                            checked={exportObj.contracts && exportObj.contracts.utilization_trends && exportObj.contracts.utilization_trends.export ? true:false} 
                                            onChange={(e) => onChangeExport(e.target.checked, 'utilization_trends')} 
                                            color="primary" 
                                            size="small" />} 
                                            label='Utilization Trends' 
                                        />
                                </Grid>
                                <Grid item md={6}>
                                    <FormControlLabel value={1} control={<Radio checked={(exportObj.contracts && exportObj.contracts.utilization_trends && exportObj.contracts.utilization_trends.extension == 'pdf')} onChange={() => onChangeExtensionFn('pdf', 'utilization_trends')} color="primary" size="small" />} label="PDF" />
                                    <FormControlLabel value={2} control={<Radio checked={(exportObj.contracts && exportObj.contracts.utilization_trends && exportObj.contracts.utilization_trends.extension == 'xls')} onChange={() => onChangeExtensionFn('xls', 'utilization_trends')} color="primary" size="small" />} label="XLS" />
                                </Grid>
                            </Grid>

                            <Grid container spacing={1} alignItems='center'>
                                <Grid item md={6}>
                                    <FormControlLabel control={
                                        <Checkbox 
                                            checked={exportObj.contracts && exportObj.contracts.utilization_history && exportObj.contracts.utilization_history.export ? true:false} 
                                            onChange={(e) => onChangeExport(e.target.checked, 'utilization_history')} 
                                            color="primary" 
                                            size="small" />} 
                                            label='Utilization History' 
                                        />
                                </Grid>
                                <Grid item md={6}>
                                    <FormControlLabel value={1} control={<Radio checked={(exportObj.contracts && exportObj.contracts.utilization_history && exportObj.contracts.utilization_history.extension == 'pdf')} onChange={() => onChangeExtensionFn('pdf', 'utilization_history')} color="primary" size="small" />} label="PDF" />
                                    <FormControlLabel value={2} control={<Radio checked={(exportObj.contracts && exportObj.contracts.utilization_history && exportObj.contracts.utilization_history.extension == 'xls')} onChange={() => onChangeExtensionFn('xls', 'utilization_history')} color="primary" size="small" />} label="XLS" />
                                </Grid>
                            </Grid>
                            <Grid container spacing={1} alignItems='center'>
                                <Grid item md={6}>
                                    <MuiPickersUtilsProvider utils={MomentUtils}>
                                        <KeyboardDatePicker
                                            margin="normal"
                                            id="start_date"
                                            label="Start Date"
                                            format={fieldDateFormat}
                                            fullWidth
                                            InputLabelProps={{ shrink: true }}
                                            maxDate={moment()}
                                            value={exportObj.contracts.utilization_history.filter.startDate ? moment(exportObj.contracts.utilization_history.filter.startDate):null}
                                            onChange={(data) => onChangeDateFn(moment(data).format(backendDateFormat), 'startDate', 'utilization_history')}
                                            inputVariant='outlined'
                                        />
                                    </MuiPickersUtilsProvider>
                                </Grid>
                                <Grid item md={6}>
                                    <MuiPickersUtilsProvider utils={MomentUtils}>
                                        <KeyboardDatePicker
                                            margin="normal"
                                            id="end_date"
                                            label="End Date"
                                            format={fieldDateFormat}
                                            fullWidth
                                            InputLabelProps={{ shrink: true }}
                                            maxDate={moment()}
                                            value={exportObj.contracts.utilization_history.filter.endDate ? moment(exportObj.contracts.utilization_history.filter.endDate):null}
                                            onChange={(data) => onChangeDateFn(moment(data).format(backendDateFormat), 'endDate', 'utilization_history')}
                                            inputVariant='outlined'
                                        />
                                    </MuiPickersUtilsProvider>
                                </Grid>
                            </Grid>
                        </div>
                    </div>
                </Grid>
            </Grid> : null }
            {getLocalStorageInfo()?.user?.permission?.contracts?.invoices ? <InvoiceReport onChangeExport={onChangeExport} exportObj={exportObj} onChangeDropdownFn={onChangeDropdownFn} onChangeExtensionFn={onChangeExtensionFn} onChangeDateFn={onChangeDateFn} /> : null}
            {getLocalStorageInfo()?.user?.permission?.contracts?.cash_flow ? <CashflowReport onChangeExport={onChangeExport} exportObj={exportObj} onChangeDropdownFn={onChangeDropdownFn} onChangeExtensionFn={onChangeExtensionFn} onChangeDateFn={onChangeDateFn} /> : null }
        </Paper>
    )
}
export default withRouter(UtilizationReport);