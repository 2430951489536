import React, { useEffect, useState } from 'react'
import { Chip, Grid, TextField, Button, Paper, Table, TableBody, TableCell, TableHead, TableRow, Dialog, DialogContent, DialogTitle, DialogActions, CircularProgress, FormControl, FormControlLabel, Switch, Tooltip, FormLabel } from '@material-ui/core';
import { checkApiStatus, checkPermission } from '../../utils_v2';
import { globalPostService, globalPutService } from '../../../globalServices';
import { toastFlashMessage } from '../../../utils';

export default function AddEditThirdParty({ setAddEditList, getThirdPartyListing, setThirdPartyData, thirdPartyData, addEditList }) {
    const [error, setError] = useState({});
    const toggleModalFn = () => {
        setError({})
        setAddEditList(false)
    }
    const onFieldChange = (keyParam, value) => {
        setThirdPartyData({ ...thirdPartyData, [keyParam]: value })
    }
    const addEditThirdPartyFn = () => {
        let validationInputs = {
            client_id: thirdPartyData?.client_id ? '' : 'Please Enter Client ID',
            client_secret: thirdPartyData?.client_secret ? '' : 'Please Enter Client Secret'
        }
        if (Object.keys(validationInputs).every(item => { return validationInputs[item] === '' })) {
            if (thirdPartyData?.modified_by?.id) {
                globalPutService(`console/third-party-apps/${thirdPartyData?.app_slug}/`, thirdPartyData)
                    .then(response => {
                        if (checkApiStatus(response)) {
                            setAddEditList(false)
                            getThirdPartyListing()
                            toastFlashMessage(response.data.message, 'success')
                        }
                        else {
                            toastFlashMessage(response.data.message, 'error')
                        }
                    })
            }
            else {
                globalPostService(`console/third-party-apps/`, thirdPartyData)
                    .then(response => {
                        if (checkApiStatus(response)) {
                            setAddEditList(false)
                            getThirdPartyListing()
                            toastFlashMessage(response.data.message, 'success')
                        }
                        else {
                            toastFlashMessage(response.data.message, 'error')
                        }
                    })
            }
        }
        else {
            setError(validationInputs)
        }
    }
    return (
        <div>
            {/* <div style={{ textAlign: 'right' }}>
                {checkPermission('console', '3rd_party_integration', 'C') ?
                    <Button style={{ marginBottom: '15px' }} onClick={() => { setAddEditList({ modal: true, mode: 'add', data: null }); setThirdPartyData({});setError({}) }} color='primary' size='small' variant='contained'>Add Thirdparty </Button>
                    : null}
            </div> */}
            <Dialog
                open={addEditList.modal}
                onClose={toggleModalFn}
                className='console-management-modal'
                maxWidth="md"
            >
                <DialogTitle id="scroll-dialog-title">
                    Add Third Party
                </DialogTitle>
                <DialogContent dividers={true}>
                    <div style={{ width: '380px' }}>
                        <Grid container spacing={1}>
                            <Grid item md={12}>
                                <TextField
                                    required
                                    id='clientId'
                                    label=' Client ID'
                                    value={thirdPartyData?.client_id || ''}
                                    error={error.client_id}
                                    helperText={error?.client_id || ''}
                                    onFocus={() => setError({ ...error, 'client_id': '' })}
                                    fullWidth
                                    margin="normal"
                                    multiline
                                    onChange={(e) => onFieldChange('client_id', e.target.value)}
                                    InputLabelProps={{ shrink: true }}
                                    variant='outlined'
                                />
                            </Grid>
                            <Grid item md={12}>
                                <TextField
                                    required
                                    id='clientId'
                                    label=' Client Secret'
                                    value={thirdPartyData?.client_secret || ''}
                                    error={error.client_secret}
                                    helperText={error?.client_secret || ''}
                                    onFocus={() => setError({ ...error, 'client_secret': '' })}
                                    fullWidth
                                    margin="normal"
                                    multiline
                                    onChange={(e) => onFieldChange('client_secret', e.target.value)}
                                    InputLabelProps={{ shrink: true }}
                                    variant='outlined'
                                />
                            </Grid>
                            {/* <Grid item md={12}>
                                <FormControl component="fieldset">
                                    <FormControlLabel
                                        control={<Switch color='primary' checked={thirdPartyData?.status} onChange={(e, value) => setThirdPartyData({ ...thirdPartyData, status: value == false ? 0 : 1 })} name="gilad" />}
                                        label={'Enabled'}
                                    />
                                </FormControl>
                            </Grid> */}
                            <Grid item md={12} style={{ textAlign: "right" }}>
                                <Button color='primary' size='small' variant='outlined' style={{ marginRight: "10px" }} onClick={() => {setAddEditList(false); setError({})}}>
                                    Cancel
                                </Button>
                                <Button color='primary' size='small' variant='contained' onClick={addEditThirdPartyFn}>
                                    Save
                                </Button>
                            </Grid>
                        </Grid>
                    </div>
                </DialogContent>
            </Dialog>
        </div>
    )
}
