import React, {Fragment} from 'react';
import { TextField } from "@material-ui/core"
import { regexConstants } from '../../../constants/regEx'
const NumberFilter = ({filter, filterOption, filterMenu, keyParam, onFieldChange, placeHolder}) => {
    return(
        <Fragment>
            <TextField
                id={keyParam}
                label={filterMenu[keyParam].title}
                fullWidth
                margin="normal"
                value={ filterOption[keyParam] ? filterOption[keyParam] : ""}
                onChange={(e) => {regexConstants.numberWithDot.test(e.target.value) ? onFieldChange(keyParam, e.target.value):e.preventDefault()}}
                InputLabelProps={{ shrink: true }}
                variant="outlined"
                placeHolder={placeHolder}
            />
        </Fragment>
    )
}
export default NumberFilter;