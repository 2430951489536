import React from 'react';
import { Button } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
const EmptyCollection = ({icon, title, description, buttonText, onAddClick}) => {
  return(
    <div className="empty-collection-ui">
      
      { title ? <h4>{title}</h4>:null }
      { description ? <p>{description}</p> : null }
      { buttonText ?
        <Button variant="contained" color="primary" startIcon={<AddIcon />} onClick={onAddClick}>{buttonText}</Button>:null
      }
    </div>
  )
}
export default EmptyCollection;
