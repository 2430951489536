import React, { useState } from 'react';
import { useSnackbar } from 'notistack';
import {DropzoneArea} from 'material-ui-dropzone';
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField } from '@material-ui/core';
import { globalPostService, globalExportService } from '../../../globalServices';
import { checkApiStatus } from '../../utils_v2';
import { downloadFileType } from '../../../utils'
import { trackActivity } from '../../../utils/mixpanel';
const ImportInventory = ({getResponseBack}) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [isLoading, setLoading] = useState(false);
    const [modal, setModal] = useState(false);
    const [files, setFiles] = useState([]);
    const onImport = () => {
        var formData = new FormData();
        formData.append('files', files[0]);
        setLoading(true)
        globalPostService(`console/inventory/import/`, formData)
        .then(response => {
            if(checkApiStatus(response)){
                enqueueSnackbar(response.data.message, { variant: 'success',anchorOrigin:{horizontal:'right', vertical:'top'}});
                setModal(false);getResponseBack();
            }
            setLoading(false)
        })
    }
    const exportInventorySample = () => {
        setLoading(true);
        globalExportService(`console/inventory/export/`)
        .then(response => {
            setLoading(false);
            downloadFileType(response, 'Inventory Sample.', 'xlsx')
        });
    }
    return(
        <>
            <Button onClick={() => setModal(true)} color='primary' size='small' variant='outlined'>Import</Button>
            { modal ?
                <Dialog
                    open={modal}
                    onClose={() => setModal(false)}
                    className='console-management-modal'
                    maxWidth={'md'}
                >
                    <DialogTitle id="scroll-dialog-title">
                        Import Inventory
                    </DialogTitle>
                    <DialogContent dividers={true}>
                        <div style={{width:'380px'}}>
                            <p>Please refer the sample sheet, <a style={{color:'#3f51b5'}} onClick={exportInventorySample}>Download</a></p>
                            <div>
                                <DropzoneArea
                                    filesLimit={1}
                                    maxFileSize={104857600}
                                    acceptedFiles={['.xls, .xlsx']}
                                    showPreviewsInDropzone={false}
                                    useChipsForPreview={true}
                                    showPreviews={ true }
                                    dropzoneText={<p>Drag & Drop file Or Click Here </p>}
                                    dropzoneClass="drag-drop-cnt"
                                    maxWidth="sm"
                                    showAlerts={['info','error']}
                                    alertSnackbarProps={{anchorOrigin: {vertical:'top', horizontal:'right'}}}
                                    clearOnUnmount={true}
                                    onChange={(files) => setFiles(files)} 
                                />
                            </div>
                        </div>
                        
                    </DialogContent>
                    <DialogActions>
                        <Button disabled={isLoading} color="primary" size='small' onClick={() => { setModal(false); trackActivity('Item Clicked', { page_title: 'Inventory List', item_type: 'Cancel', event_desc: 'Clicked on Cancel Button form Import Inventory form' }) }}>Cancel</Button>
                        <Button size='small' disabled={isLoading || !files.length} color="primary"  variant="contained" onClick={() => { onImport(); trackActivity('Item Clicked', { page_title: 'Inventory List', item_type: 'Save ', event_desc: 'Clicked on Save Button form Import Inventory form' }) }}>
                            {isLoading ? <CircularProgress color="#ffffff" size={24} /> : 'Save'}
                        </Button>
                    </DialogActions>
                </Dialog>:null
            }
            
        </>
    )
}
export default ImportInventory;