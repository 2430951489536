import React from "react";
import { Button, Dialog, DialogContent, DialogTitle, DialogActions, Tooltip, Chip } from '@material-ui/core';
const ErrorTitled = ({serverError, basicDetails, toggleModalFn}) => {
    return(
        <>
            <Dialog
                open={serverError.modal}
                onClose={toggleModalFn}
                className='console-management-modal'
                maxWidth="md"
            >
                <DialogTitle id="scroll-dialog-title">
                    Unable to update Titled Assembly
                </DialogTitle>
                <DialogContent dividers={true}>
                    <div style={{textAlign:'left', width:'440px'}} >
                        <p>
                            {serverError.data.message}
                        </p>
                        <a target="_blank" href={`/technical-specification/aircraft/${basicDetails?.titled_aircraft?.slug}/`}>Click here, {basicDetails?.titled_aircraft?.asset_name||''}</a>
                    </div>
                    
                </DialogContent>
                <DialogActions>
                    <Button onClick={toggleModalFn} color='primary' size='small' variant='outlined'>Close</Button>
                </DialogActions>
            </Dialog>
        </>
    )
}
export default ErrorTitled;