
import * as actions from '../actions';
import { browserHistory } from 'react-router';
import produce from 'immer';
export default function( state = {
  toastLists: [],
  notificationsGif:false,
  notificationsGifClick:false,
  notifications:{
    card_count: 0,
    notification_count: 0,
  },
  notificationOpen: false
}, action){
  switch (action.type) {
    case actions.NOTIFICATIONS_COUNT:
      return {
        ...state,
        notifications: action.payload,
      }
    case actions.NOTIFICATION_FLAG:
      return {
        ...state,
        notificationOpen: action.payload,
      }
    case actions.NOTIFICATIONS_COUNT_GIF:
      return {
        ...state,
        notificationsGif:state.notificationsGifClick?true:true
      }
    case actions.NOTIFICATIONS_COUNT_UPDATE:
      return {
        ...state,
        notificationsGif:false,
        notificationsGifClick:true
     }
    
    case actions.CLEAR_TOAST:
      return {
        ...state,
        toastLists: []
      }
    case actions.SHOW_TOAST:
      if(browserHistory.getCurrentLocation().pathname.includes('/user/activate/')
        || browserHistory.getCurrentLocation().pathname.includes('/forgot-password')){
        return state;
      }
      if(action.payload.toastType == 'error'){
        if(state.toastLists.filter(toast => toast.toastType == 'error').length < 1){
          return {
            ...state,
            toastLists: produce(state.toastLists, draft => {
              draft.push(action.payload)
            })
          }
        }else{
          return state;
        }
      }else{
        return {
          ...state,
          toastLists: produce(state.toastLists, draft => {
            draft.push(action.payload)
          })
        }
      }

    case actions.HIDE_TOAST:

      if(state.toastLists){
        return {
          ...state,
          toastLists: produce(state.toastLists, draft => {
            draft.splice(0,1);
          })
        }
      }else{
        return state;
      }

    case actions.S_CLOSE_TOAST_NOTIFY:
      return {
        ...state,
        toastLists: produce(state.toastLists, draft => {
          draft.splice(0,action.payload);
        })
      }
    default:
      return state;
    }
}
