import React, { Fragment } from "react"
import {
  Button,
  ClickAwayListener,
  Grow,
  Paper,
  Popper,
  MenuItem,
  MenuList,
} from "@material-ui/core"
import SaveAltIcon from "@material-ui/icons/SaveAlt"
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf"
import DescriptionIcon from "@material-ui/icons/Description"
import InsertDriveFileIcon from "@material-ui/icons/InsertDriveFile"
const ExportMenu = ({ disabled, exportReportFn, files, title }) => {
  const [open, setOpen] = React.useState(false)
  const anchorRef = React.useRef(null)
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen)
  }
  const handleClose = (event, file, triggerExport) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return
    }
    setOpen(false)
    if (triggerExport) {
      exportReportFn(file)
    }
  }

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault()
      setOpen(false)
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open)
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus()
    }
    prevOpen.current = open
  }, [open])
  return (
    <Fragment>
      <Button
        variant="outlined"
        size="small"
        ref={anchorRef}
        aria-controls={open ? "menu-list-grow" : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
        color="primary"
        disabled={disabled}
      >
        <SaveAltIcon />
        {title ? title : "Export"}
      </Button>
      <Popper
        placement="bottom-end"
        style={{ zIndex: "9" }}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom" ? "center top" : "center bottom",
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  autoFocusItem={open}
                  id="menu-list-grow"
                  onKeyDown={handleListKeyDown}
                >
                  {files.map((file, index) => (
                    <MenuItem
                      onClick={(event) =>
                        handleClose(event, file, "triggerExport")
                      }
                    >
                      {file.extension === "pdf" ? (
                        <PictureAsPdfIcon />
                      ) : file.extension === "docx" ? (
                        <InsertDriveFileIcon />
                      ) : (
                        <DescriptionIcon />
                      )}
                      {file.title}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </Fragment>
  )
}

export default ExportMenu
