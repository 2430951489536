import React from 'react';
import { browserHistory } from 'react-router'
import { TableCell, TableRow } from '@material-ui/core';
const ActiveList = ({item, onDelete, onEdit, onView}) => {
    return(
        <TableRow onClick={() => browserHistory.push(`/users/manage/${item.slug}`)}>
            <TableCell>{item?.first_name||''} {item?.last_name||''}</TableCell>
            <TableCell>{item?.email||'--'}</TableCell>
            <TableCell>{item?.designation||'--'}</TableCell>
            <TableCell>
                <span className="deactivate" style={{color: '#000',fontSize: '14px'}}>{item.is_active ? item.is_new_user ? 'Invitation Sent': 'Active' :'Deactivated'}</span>
            </TableCell>
        </TableRow>
    )
}
export default ActiveList;