import React, { Fragment, useState, useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { Table, TableBody, Paper, Grid, Button, Tabs, Tab } from '@material-ui/core';
import { ExportReportSidebar, ReportsHD, ReportsList, AircraftCustomReport, EngineCustomReport, AssetLogReport, AssetTrackerReport, CustAcStatement, CustomerAcCashflow, PortfolioReport, ValuationPortfolio, UtilsInvReport, LLPLimiterReport } from '../components';
import { globalGetService, globalPostService } from '../../../globalServices';
import { checkApiStatus } from '../../utils_v2';
import { getLocalStorageInfo, removeEmptyKey } from '../../../utils';
import { checkPermission } from '../../../console_management/utils_v2';
import { trackActivity } from '../../../utils/mixpanel';
import { Pagination, FilterComponent, STableLoader, PageLoader, EmptyCollection } from '../../shared_elements';
import { reportsFilterOptions, bulkExportObj, reportsFilterOptionsSMBC, smbcInstance } from '../';
import { Alert } from '@material-ui/lab';
import EngineSummaryReport from '../components/EngineSummaryReport';
import DeploymentMessage from '../../../shared/components/DeploymentMessage';
import EngineSwapReport from '../components/EngineSwapReport';
const Reports = ({}) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [isLoading, setLoading] = useState(false);
    const [skeletonLoader, setSkeletonLoader] = useState(false);
    const [filter, setFilter] = useState({});
    const [engineTypes, setEngineTypes] = useState([]);
    const [lessee, setLessee] = useState([]);
    const [ownerList, setOwnerList] = useState([]);
    const [aircraftTypes, setAircraftTypes] = useState([]);
    const [assetsInfo, setAssetsInfo] = useState({asset:[], pagination:{}});
    const [selectedAsset, setSelectedAsset] = useState([]);
    const [exportObj, setExportObj] = useState(bulkExportObj)
    const [isOpen, setOpen] = useState(false);
    useEffect(() => {
        getAssets({...filter, per_page:50}, 'skeletonLoader');
        getAircraftTypes();
        getEngineTypes();
        getLessee();
        getOwnerList();
    },[]);

    const getAssets = (query, loaderType) => {
        let queryParams = Object.assign({}, query);
        loaderType === 'skeletonLoader' ? setSkeletonLoader(true):setLoading(true);
        globalGetService(`console/list/?lite=1&exclude_columns=owner,lessor,lessor_name,owner_portfolio,ownership_type,record_folders,status,sub_status`, {...queryParams,  all_assets: true })
        .then(response => {
            if(checkApiStatus(response)){
                setAssetsInfo(response.data.data);
                delete query.page
                delete query.per_page
                setFilter(query);
            }else{

            }
            loaderType === 'skeletonLoader' ? setSkeletonLoader(false):setLoading(false);
        });
    }
    const getAircraftTypes = () => {
        globalGetService(`console/aircraft-types/`)
        .then(response => {
            if(checkApiStatus(response)){
                setAircraftTypes(response.data.data.aircraftTypes);
            }
        })
    }
    const getEngineTypes = () => {
        globalGetService(`console/engine-types/`)
        .then(response => {
            if(checkApiStatus(response)){
                setEngineTypes(response.data.data.engineTypes)
            }
        })
    }
    const getLessee = () => {
        globalGetService(`console/lessees/`)
        .then(response => {
            if(checkApiStatus(response)){
                setLessee(response.data.data.lessees)
            }
        })
    }
    const getOwnerList = () => {
        globalGetService(`console/owner/?dropdown=true`)
        .then(response => {
            if(checkApiStatus(response)){
                setOwnerList(response.data.data)
            }
        })
    }

    const onSelectAsset = (e, asset) => {
        if (e.target.checked) {
          if (selectedAsset.length < 10) {
            setSelectedAsset([...selectedAsset, { asset_type: asset.asset_type, id: asset.id, slug: asset.slug }])
          } else {
            // do nothing
          }
        } else {
          setSelectedAsset(selectedAsset.filter(item => (item.asset_type != asset.asset_type || asset.id != item.id)))
        }
    }

    const onChangeExportFn = (value, featureType, application) => {
        setExportObj({
            ...exportObj,
            [application]: {
                ...exportObj[application],
                [featureType]: {
                  ...exportObj[application][featureType],
                  export: value
                }
              }
        })
    }

    const onChangeExtensionFn = (value, featureType, application) => {
        setExportObj({
            ...exportObj,
            [application]: {
                ...exportObj[application],
                [featureType]: {
                  ...exportObj[application][featureType],
                  extension: value
                }
              }
        })
    }

    const onChangeDropdownFn = (data, keyParam, featureType, application) => {
        setExportObj({
            ...exportObj,
            [application]: {
                ...exportObj[application],
                [featureType]: {
                  ...exportObj[application][featureType],
                  filter:{
                    ...exportObj[application][featureType].filter,
                    [keyParam]: data
                  }
                }
              }
        })
    }

    const onChangeDateFn = (value, keyParam, featureType, application) => {
        setExportObj({
            ...exportObj,
            [application]: {
                ...exportObj[application],
                [featureType]: {
                  ...exportObj[application][featureType],
                  filter:{
                    ...exportObj[application][featureType].filter,
                    [keyParam]: value == 'Invalid date' ? '' : value
                  }
                }
              }
        })
    }

    const generateBulkReportFn = () => {
        let assetSlugInfo = {
          aircraft_slug: selectedAsset.filter(asset => asset.asset_type == 1).map(item => item.slug),
          engine_slug: selectedAsset.filter(asset => asset.asset_type == 2).map(item => item.slug),
          apu_slug: selectedAsset.filter(asset => asset.asset_type == 3).map(item => item.slug),
          lg_slug: selectedAsset.filter(asset => asset.asset_type == 4).map(item => item.slug),
          propeller_slug: selectedAsset.filter(asset => asset.asset_type == 5).map(item => item.slug)
        }
        let payload = JSON.parse(JSON.stringify(exportObj));
        if (!payload.tech_specs.overview.export) {
          delete payload['tech_specs']['overview'];
        }
        if (!payload.tech_specs.marketing.export) {
          delete payload['tech_specs']['marketing'];
        }
        if (!payload.contracts.utilization_trends.export) {
          delete payload['contracts']['utilization_trends'];
        }
        if (!payload.contracts.utilization_history.export) {
          delete payload['contracts']['utilization_history'];
        } else {
          payload = {
            ...payload,
            contracts: {
              ...payload.contracts,
              cashflow: {
                ...payload.contracts.utilization_history,
                filter: removeEmptyKey(payload.contracts.utilization_history.filter)
              }
            }
          }
        }
        if (!payload.contracts.invoice.export) {
          delete payload['contracts']['invoice'];
        } else {
          payload = {
            ...payload,
            contracts: {
              ...payload.contracts,
              invoice: {
                ...payload.contracts.invoice,
                filter: removeEmptyKey(payload.contracts.invoice.filter)
              }
            }
          }
        }
        if (!payload.contracts.cashflow.export) {
          delete payload['contracts']['cashflow'];
        } else {
          payload = {
            ...payload,
            contracts: {
              ...payload.contracts,
              cashflow: {
                ...payload.contracts.cashflow,
                filter: removeEmptyKey(payload.contracts.cashflow.filter)
              }
            }
          }
        }
        if (!payload.contracts.hla.export) {
          delete payload['contracts']['hla'];
        }
        if (!payload.maintenance.upcoming_events.export) {
          delete payload['maintenance']['upcoming_events'];
        }
        if (!payload.records.inventory_report.export) {
          delete payload['records']['inventory_report'];
        }
        if (!payload.records.lessor_template_sheet.export) {
          delete payload['records']['lessor_template_sheet'];
        }
        if (!payload.records.action_dock.export) {
          delete payload['records']['action_dock'];
        }
        if (!Object.keys(payload.tech_specs).length) {
          delete payload['tech_specs'];
        }
        if (!Object.keys(payload.contracts).length) {
          delete payload['contracts'];
        }
        if (!Object.keys(payload.records).length) {
          delete payload['records'];
        }
        if (!Object.keys(payload.maintenance).length) {
          delete payload['maintenance'];
        }
        payload = {
          ...payload,
          asset_info: assetSlugInfo
        }
        if (Object.keys(payload).length > 1) {
            setLoading(true)
          globalPostService('reports/bulk-download-background/', payload)
            .then(response => {
                setLoading(false);
              if (response.data.statusCode == 200) {
                enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { vertical: 'top', horizontal: 'right' } })
              }
              trackActivity('Report Exported', {
                application_module: 'Reports',
                page_title: 'Report',
                file_name: 'Custom Report'
              })
              setOpen(false);
              setExportObj(bulkExportObj);
              setSelectedAsset([])
            })
        } else {
          enqueueSnackbar('Please select report type', { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
        }
      }

    let filterOptions = Object.assign({}, smbcInstance ? reportsFilterOptionsSMBC : reportsFilterOptions);
    filterOptions = {
        ...filterOptions,
        aircraft_type:{
            ...filterOptions.aircraft_type,
            options:aircraftTypes
        },
        engine_type:{
            ...filterOptions.engine_type,
            options:engineTypes
        },
        lessee:{
            ...filterOptions.lessee,
            options:lessee
        }
    }
    const checkTabPermission = () => {
      if(checkPermission('reports','bulk_download','R')){
          return 'bulk_download'
      }else if(checkPermission('reports','custom_reports','R')){
          return 'custom_reports'
      }else{
          return null
      }
    }
    const [tabIndex, setTabIndex] = useState(checkTabPermission());
    console.log('')
    return(
        <Paper className='reports-management' style={{margin:'24px 0 50px 80px',height: `${window.innerHeight - 220}px`}}>
          <DeploymentMessage />
            <Alert severity="info" style={{padding:'2px',height:'40px'}}>All assets including archive assets are shown here.</Alert>
            <Paper square style={{marginBottom:'10px'}}>
                <Tabs value={tabIndex} onChange={(event, newValue) => setTabIndex(newValue)} indicatorColor="primary">
                  {checkPermission('reports','bulk_download','R') ? <Tab label="Export Report" value='bulk_download'/> : null}
                    {[442, 242, 321, 322, 13, 484, 483, 497].includes(getLocalStorageInfo().defaultLessor.id) ? checkPermission('reports','custom_reports','R') ? <Tab label="Custom Report" value='custom_reports'/> : null : null}
                </Tabs>
            </Paper>
            {tabIndex === 'bulk_download' && checkPermission('reports','bulk_download','R') ?
            <>
            <div>
                <ExportReportSidebar 
                    isOpen={isOpen} setOpen={setOpen} 
                    selectedAsset={selectedAsset} 
                    exportObj={exportObj} 
                    onChangeExportTechSpec={(e, featureType) => onChangeExportFn(e, featureType, 'tech_specs')} 
                    onChangeExportUtils={(e, featureType) => onChangeExportFn(e, featureType, 'contracts')} 
                    onChangeExtensionFn={(e, featureType) => onChangeExtensionFn(e, featureType, 'contracts')}
                    onChangeDateFn={(value, keyParam, featureType) => onChangeDateFn(value, keyParam, featureType, 'contracts')}
                    onChangeDropdownFn={(value, keyParam, featureType) => onChangeDropdownFn(value, keyParam, featureType, 'contracts')}
                    generateBulkReportFn={generateBulkReportFn}
                
                />
            </div>
            <div>
                { skeletonLoader ? <STableLoader count={5} />:
                    <>  
                        <Paper square style={{padding:'5px 10px', position:'relative'}}>
                            { checkPermission('reports','bulk_download','EXP') && selectedAsset.length ?
                              <div className='export-btn'>
                                  <ul className='list-inline'>
                                    <li className='list-inline-item'>
                                      <span className='selected-asset'>(Selected Asset {selectedAsset.length}/10)</span>
                                    </li>
                                    <li className='list-inline-item'>
                                      <Button variant='contained' color='primary' size='small' onClick={() => setOpen({ isOpen: true })} >Export Report</Button> 
                                    </li>
                                  </ul>
                              </div> : null 
                            }
                            <Grid container spacing={1} alignItems='center'>
                                <Grid item md={12}>
                                    <FilterComponent 
                                        filter={filter} 
                                        filterMenu={filterOptions}
                                        getResponseBack={(applyFilter) => getAssets({...applyFilter, page:1, per_page:assetsInfo.pagination.per_page}, 'pageLoader')}
                                    />
                                </Grid>
                            </Grid>
                        </Paper>
                        <Paper className='major-assemblies-list'>
                            <div style={{height: !assetsInfo.asset.length ? 'auto':`${window.innerHeight-280}px`,overflow:'auto'}}>
                                <Table className='mui-table-format' stickyHeader>
                                    <ReportsHD/>
                                    <TableBody>
                                        {assetsInfo.asset.map((item,index) =>
                                            <ReportsList 
                                                item={item}
                                                key={index}
                                                selectedAsset={selectedAsset}
                                                onSelectAsset={onSelectAsset}
                                            />
                                        )}
                                    </TableBody>
                                </Table>
                            </div>
                            <Pagination 
                                pagination={assetsInfo.pagination}
                                onChangePage={(event, newPage) => getAssets({...filter, page:newPage+1, per_page: assetsInfo.pagination.per_page}, 'pageLoader')}
                                onChangeRowsPerPage={(event) => getAssets({...filter, page:1, per_page: event.target.value}, 'pageLoader')}
                            />
                            {!assetsInfo.asset.length ? <div style={{textAlign:'center'}}><EmptyCollection title={'No records found'} /></div>:null}
                        </Paper>
                    </>
                }
            </div>
            </> : null}
            {tabIndex === 'custom_reports' && checkPermission('reports','custom_reports','R') ? 
            <Paper className='custom-reports' style={{height:`${window.innerHeight-190}px`}}>
                <Grid container spacing={1}>
                    <Grid item md={6} xs={12}>
                        <AircraftCustomReport ownerList={ownerList}/>
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <AssetLogReport ownerList={ownerList}/>
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <CustAcStatement ownerList={ownerList}/>
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <CustomerAcCashflow ownerList={ownerList}/>
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <EngineCustomReport ownerList={ownerList}/>
                    </Grid>
                    {[442].includes(getLocalStorageInfo().defaultLessor.id) ? null : 
                    <Grid item md={6} xs={12}>
                        <PortfolioReport ownerList={ownerList}/>
                    </Grid>}
                    <Grid item md={6} xs={12}>
                        <LLPLimiterReport ownerList={ownerList}/>
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <EngineSummaryReport ownerList={ownerList}/>
                    </Grid>
                    {[442].includes(getLocalStorageInfo().defaultLessor.id) ? null :
                    <Grid item md={6} xs={12}>
                        <ValuationPortfolio ownerList={ownerList}/>
                    </Grid>}
                    <Grid item md={6} xs={12}>
                         <EngineSwapReport/>
                    </Grid>
                    {[14, 242, 271, 13].includes(getLocalStorageInfo().defaultLessor.id) ? 
                    <Grid item md={6} xs={12}>
                        <UtilsInvReport ownerList={ownerList}/>
                    </Grid> : null }
                </Grid>
            </Paper> : null }
            { isLoading ? <PageLoader/>:null}
        </Paper>
    )
}
export default Reports;