import React, { useEffect, useState } from "react";
import { useSnackbar } from 'notistack';
import { browserHistory, withRouter } from 'react-router'
import moment from "moment";
import { Button, Grid, Paper, Table, TableBody, TableCell, TableHead, TableRow, Menu, MenuItem, Chip, Tooltip } from "@material-ui/core";
import { STableLoader, PageLoader, EmptyCollection, FilterComponent, DeletePopUp } from '../../shared_elements';
import VisibilityIcon from '@material-ui/icons/Visibility';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import EditIcon from '@material-ui/icons/Edit';
import  AddAssembly from './AddAssembly';
import  LinkAssembly from './LinkAssembly';
import UnlinkAssembly from './UnlinkAssembly';
import { globalGetService, globalPostService } from '../../../globalServices';
import { displayDateFormatShort, imgStoragePath } from "../../../constants";
import { checkApiStatus, checkPermission, getLocalStorageInfo } from '../../utils_v2';
import { assemblyTypeObj, maFilterOps } from '../'
import appConfig from "../../../config";
import ActiveLeasePopUp from "./ActiveLeasePopUp";
const AssemblyMenu = ({assetInfoData, onClickAssembly, btnLabel}) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    return(
        <>
            <Button color="primary" size="small" variant="contained" aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick} endIcon={<ArrowDropDownIcon fontSize="small" />}>
                {btnLabel}
            </Button>
            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                style={{marginTop:'42px'}}
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                <MenuItem onClick={() => {handleClose(); onClickAssembly({type:2, label:'Engine'})}}>Engine</MenuItem>
                {assetInfoData?.aircraft_type?.has_propeller ?
                    <MenuItem onClick={() => {handleClose(); onClickAssembly({type:5, label:'Propeller'})}}>Propeller</MenuItem>:null
                }
                { assetInfoData?.aircraft_type?.has_apu ?
                    <MenuItem onClick={() => {handleClose(); onClickAssembly({type:3, label:'APU'})}}>APU</MenuItem>:null
                }
                <MenuItem onClick={() => {handleClose(); onClickAssembly({type:4, label:'Landing Gear'})}}>Landing Gear</MenuItem>
            </Menu>
        </>
    )
}
const MajorAssemblies = ({params, assetInfoData}) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [isLoading, setLoading] = useState(false);
    const [skeletonLoader, setSkeletonLoader] = useState(false);
    const [majorAssemblyInfo, setMajorAssemblyInfo] = useState({list:[], pagination:null});
    const [addEditAssembly, setAddEditAssembly] = useState({modal:false, data:null});
    const [linkAssembly, setLinkAssembly] = useState({modal:false, data:null});
    const [unlinkAssembly, setUnlinkAssembly] = useState({modal:false, data:null})
    const [activeLeaseModal, setActiveLeaseModal] = useState({modal:false, data:null})
    const [filter, setFilter]= useState({})
    useEffect(() => {
        getMajorAssemblies('skeletonLoader');
    }, []);
    const getMajorAssemblies = (loaderType, query) => {
        loaderType === 'skeletonLoader' ? setSkeletonLoader(true):setLoading(true);
        globalGetService(`console/v2/${params.type}/${params.aircraft_slug}/major-assemblies/`,query)
        .then(response => {
            if(checkApiStatus(response)){
                setMajorAssemblyInfo(response.data.data);
                if( query){
                    setFilter(query)
                }
            }
            loaderType === 'skeletonLoader' ? setSkeletonLoader(false):setLoading(false);
        })
    }
    const onEditAssembly = (assembly) => {
        let formData = {
            asset: {id:assembly.id, slug:assembly.slug, asset_type:assemblyTypeObj[assembly.major_assembly_type], asset_name:assembly?.major_assembly_name||''},
            is_assigned:assembly?.is_assigned,
            is_fitted:assembly?.is_fitted,
            is_billable:assembly?.is_billable,
            is_titled:assembly?.is_titled,
            on_wing_aircraft:assembly?.on_wing_aircraft,
            off_wing_status:assembly?.off_wing_status,
            on_wing_status:assembly?.on_wing_status,
            sub_assembly:assembly?.sub_assembly,
            installation_date:assembly?.installation_date,
            removal_date:assembly?.removal_date,
            csn_at_movement:assembly?.csn_at_movement,
            tsn_at_movement:assembly?.tsn_at_movement,
            records_movement:false
        }
        if(assembly.major_assembly_type !== 'APU'){
            formData = {
                ...formData,
                position:assembly.position
            }
        }
        setLinkAssembly({modal:true, data:{type:assemblyTypeObj[assembly.major_assembly_type], label:assembly.major_assembly_type}, editObj:formData, type:'edit'})
    }
    let filterOption = Object.assign({},maFilterOps)
    filterOption={
        ...filterOption,
        asset_type: {
            ...filterOption.asset_type,
            options: assetInfoData?.aircraft_type?.has_propeller && assetInfoData?.aircraft_type?.has_apu ?
                filterOption.asset_type.options :
                assetInfoData?.aircraft_type?.has_propeller && !assetInfoData?.aircraft_type?.has_apu ?
                    filterOption.asset_type.options.filter(item => item?.value !== 3) :
                    filterOption.asset_type.options.filter(item => item?.value !== 5)
        }
    }
    const checkUnlinkPermission =(majorAssembly )=>{
        setLoading(true)
        globalGetService(`console/aircraft/${assetInfoData?.slug}/billable-lease/`,{asset_id:majorAssembly?.id ,asset_type:assemblyTypeObj[majorAssembly.major_assembly_type]})
        .then(response=>{
            setLoading(false)
            // if(response.data.statusCode == '200' && getLocalStorageInfo()?.user?.permission?.contracts){
            if(response.data.statusCode == '428' && getLocalStorageInfo()?.user?.permission?.contracts){
                setActiveLeaseModal({modal:true, data:majorAssembly, response:response.data.message})
            }
            else{
                setUnlinkAssembly({modal:true, data:majorAssembly})
            }
        })
    }
    return(
        <div style={{padding:'10px 10px 0px 10px', height:`${window.innerHeight-210}px`}}>
            { skeletonLoader ? <STableLoader count={7} />:
                <>
                    <Paper style={{padding:'5px 10px'}} square>
                        <Grid container spacing={1}>
                            <Grid item md={8}>
                                <FilterComponent
                                    filter={filter}
                                    filterMenu={filterOption}
                                    getResponseBack={(applyFilter) => getMajorAssemblies('pageLoader', { ...applyFilter })}
                                />
                            </Grid>
                            <Grid item md={4}>
                                <ul className="list-inline" style={{float:'right'}}>
                                {checkPermission('technical_specs', 'aircraft', 'U') ?
                                    <li className="list-inline-item">
                                        <AssemblyMenu btnLabel='Link Assembly' assetInfoData={assetInfoData} onClickAssembly={(assembly) => setLinkAssembly({modal:true, data:assembly})} />
                                    </li> : null}
                                    {checkPermission('technical_specs', 'aircraft', 'U') ?
                                    <li className="list-inline-item">
                                        <AssemblyMenu btnLabel='Add Assembly' assetInfoData={assetInfoData} onClickAssembly={(assembly) => setAddEditAssembly({modal:true, data:assembly})} />
                                    </li>
                                    : null}
                                </ul>
                            </Grid>
                        </Grid>
                    </Paper>
                    <Paper style={{height:!majorAssemblyInfo?.list?.length ?'auto' : `${window.innerHeight-270}px`, overflow:'scroll'}}>
                        <Table className='mui-table-format'>
                            <TableHead style={{position:'sticky', top:'0px'}}>
                                <TableRow>
                                    <TableCell>Serial Number</TableCell>
                                    <TableCell>Position</TableCell>
                                    <TableCell>Titled MSN</TableCell>
                                    <TableCell>Assigned MSN</TableCell>
                                    <TableCell>On Wing MSN</TableCell>
                                    <TableCell>Is Billable</TableCell>
                                    <TableCell align="right" style={{width:'100px'}}>Action(s)</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {majorAssemblyInfo?.list.map((majorAssembly, index) => 
                                    <TableRow>
                                        <TableCell>
                                            { majorAssembly?.asset_type?.name ?<span style={{fontSize:'11px'}}>{majorAssembly.asset_type.name}</span>:null}
                                            <p>{majorAssembly?.major_assembly_name||''}</p>
                                        </TableCell>
                                        <TableCell >{majorAssembly?.position?.label||'--'}
                                        {
                                            majorAssembly?.sub_assembly?.name ?<>
                                            <br/>
                                            <span className="chip-sub-asmbly"> {majorAssembly?.sub_assembly?.name } </span>
                                            </>
                                            : null
                                        }
                                        </TableCell>
                                        <TableCell>
                                            <span style={{ fontSize: '11px' }}>{majorAssembly?.titled_aircraft?.aircraft_type?.name || null}</span>
                                            <p> {
                                                majorAssembly?.titled_aircraft?.name ?
                                                    assetInfoData?.slug !== majorAssembly?.titled_aircraft?.slug ?
                                                        <span className="link-cls" style={{ color: 'blue' }} onClick={() => window.open(`/technical-specification/aircraft/${majorAssembly?.titled_aircraft?.slug}/basic-details`, '_blank')}>  {majorAssembly?.titled_aircraft?.name} </span> :
                                                        majorAssembly?.titled_aircraft?.name
                                                    : '--'}</p>
                                        </TableCell>
                                        <TableCell>
                                            <span style={{ fontSize: '11px' }}>{majorAssembly?.assigned_aircraft?.aircraft_type?.name || null}</span>
                                            <p> {
                                                majorAssembly?.assigned_aircraft?.name ?
                                                    assetInfoData?.slug !== majorAssembly?.assigned_aircraft?.slug ?
                                                        <span className="link-cls" style={{ color: 'blue' }} onClick={() => window.open(`/technical-specification/aircraft/${majorAssembly?.assigned_aircraft?.slug}/basic-details`, '_blank')}>  {majorAssembly?.assigned_aircraft?.name} </span> :
                                                        majorAssembly?.assigned_aircraft?.name
                                                    : '--'}</p>
                                        </TableCell>
                                        <TableCell>
                                            <span style={{ fontSize: '11px' }}>{majorAssembly?.on_wing_aircraft?.aircraft_type?.name || null}</span>
                                            <p> {
                                                majorAssembly?.on_wing_aircraft?.name ?
                                                    assetInfoData?.slug !== majorAssembly?.on_wing_aircraft?.slug ?
                                                        <span className="link-cls" style={{ color: 'blue' }} onClick={() => window.open(`/technical-specification/aircraft/${majorAssembly?.on_wing_aircraft?.slug}/basic-details`, '_blank')}>  {majorAssembly?.on_wing_aircraft?.name} </span> :
                                                        majorAssembly?.on_wing_aircraft?.name
                                                    : '--'}</p>
                                        </TableCell>
                                        <TableCell>
                                            <p>{majorAssembly?.is_billable ? 'Yes':'No'}</p>
                                        </TableCell>
                                        <TableCell align="right">
                                            <ul className="list-inline" style={{width:'100px',  float:'right'}}>
                                                {getLocalStorageInfo()?.is_records ?
                                                <li style={{cursor:'pointer', marginRight:'9px'}} className="list-inline-item" onClick={() => window.open(`${appConfig.domain.records}${majorAssembly.major_assembly_type.toLowerCase()}/${majorAssembly.slug}/data-room/technical/${majorAssembly?.records_details?.technical_records?.uuid }`, '_blank')}>
                                                   <Tooltip title='Stand-alone Records DataRoom' arrow>
                                                     <img style={{ width: '15px', marginBottom:'10px' }} src={imgStoragePath + 'phase-2/RecordsIcon.svg'} alt="Records DataRoom" />
                                                  </Tooltip>
                                                </li> : null}
                                                {checkPermission('technical_specs', 'aircraft', 'R') ?
                                                <li style={{cursor:'pointer'}} className="list-inline-item" onClick={() => window.open(`/technical-specification/${majorAssembly.major_assembly_type.toLowerCase()}/${majorAssembly.slug}/basic-details`, '_blank')}>
                                                    <Tooltip title="View Details" arrow>
                                                        <VisibilityIcon color="primary" fontSize="small" />
                                                    </Tooltip>
                                                </li> : null}
                                                {checkPermission('technical_specs', 'aircraft', 'U') ?
                                                <li style={{cursor:'pointer'}} className="list-inline-item" onClick={() => onEditAssembly(majorAssembly)}>
                                                    <Tooltip title="Edit Linking Flow" arrow>
                                                        <EditIcon color="primary" fontSize="small" />
                                                    </Tooltip>
                                                </li> : null}
                                                {checkPermission('technical_specs', 'aircraft', 'U') ?
                                                <li style={{cursor:'pointer'}} className="list-inline-item" onClick={() => checkUnlinkPermission(majorAssembly)}>
                                                    <Tooltip title='Unlink Assembly' arrow>
                                                        <DeleteOutlineIcon color="secondary" fontSize="small" />
                                                    </Tooltip>
                                                </li>: null}
                                            </ul>
                                        </TableCell>
                                    </TableRow>    
                                )}
                            </TableBody>
                        </Table>
                        { !majorAssemblyInfo?.list?.length ?<div className="text-center"><EmptyCollection title="No records found" /></div>:null}
                    </Paper>
                </>
            }
            { addEditAssembly.modal ?
                <AddAssembly 
                recordsPermission={majorAssemblyInfo?.permission}
                    assetInfoData={assetInfoData}
                    addEditAssembly={addEditAssembly}
                    toggleModalFn={() => setAddEditAssembly({modal:false, data:null})}
                    getResponseBack={() => getMajorAssemblies('pageLoader')}
                />:null
            }
            { linkAssembly.modal ?
                <LinkAssembly 
                recordsPermission={majorAssemblyInfo?.permission}
                    showOffWingStatus={linkAssembly?.editObj?.on_wing_aircraft?.slug === assetInfoData?.slug}
                    assetInfoData={assetInfoData}
                    linkAssembly={linkAssembly}
                    toggleModalFn={() => setLinkAssembly({modal:false, data:null})}
                    getResponseBack={() => {setLinkAssembly({modal:false, data:null}) ;getMajorAssemblies('pageLoader')}}
                />:null
            }
            { unlinkAssembly.modal ?
                <UnlinkAssembly 
                recordsPermission={majorAssemblyInfo?.permission}
                    showOffWingStatus={unlinkAssembly?.data?.on_wing_aircraft?.slug === assetInfoData?.slug}
                    assetInfoData={assetInfoData}
                    unlinkAssembly={unlinkAssembly}
                    toggleModalFn={() => setUnlinkAssembly({modal:false, data:null})}
                    getResponseBack={() => getMajorAssemblies('pageLoader')}
                />:null
            }
            {
                activeLeaseModal?.modal ?
                <ActiveLeasePopUp
                modal={activeLeaseModal.modal}
                title='Active Lease Alert'
                confirmText='Continue'
                content={activeLeaseModal?.response || "This Major assembly has an active lease."}
                toggleModalFn={() => setActiveLeaseModal({ modal: false, data: null })}
                submitFn={(reason)=>{setActiveLeaseModal({modal:false}); setUnlinkAssembly({modal:true, data:activeLeaseModal.data, emptyReason:reason}) }}
            /> : null
            }
            { isLoading ? <PageLoader/>:null}
        </div>
    )
}
export default withRouter(MajorAssemblies);