import React from 'react';
import { Grid } from '@material-ui/core';
import { Link } from 'react-router';
import { getGlobalCookie } from '../../utils';
const PageNotFound = (props) => {
  return(
    <Grid container spacing={1}>
      <Grid item xs="12" style={{minHeight: '90vh', position: 'relative'}}>
        <div className="page-not-found">
          <h1>404</h1>
          <h4>Page Not Found :(</h4>
          {getGlobalCookie('lessorAccess') && 
            <Link className="start-over btn btn-default" exact to="/assets-listing">start over here! </Link>  
          }
          { !getGlobalCookie('lessorAccess') &&
            <Link className="start-over btn btn-default" exact to="/login">Start over here! </Link> 
          }
        </div>
      </Grid>
    </Grid>
  )
}
export default PageNotFound
