import React, { useState } from "react";
import { Paper, Tab, Tabs } from "@material-ui/core";
import AcquisitionDetails from './AcquisitionDetails';
import ComponentDetails from "./ComponentDetails";
const Component = ({getResponseBack ,assetInfoData}) => {
    const [isEdit, setEdit] = useState(false);
    const [tabIndex, setTabIndex] = useState('basic-info');
    return (
        <div>
            <div>
                <Paper>
                    <Tabs indicatorColor='primary' value={tabIndex} onChange={(event, newValue) => setTabIndex(newValue)} variant='fullWidth'>
                        <Tab label='Basic Details' value='basic-info' />
                        <Tab label='Acquisition & Other Details' value='acquisition-info' />
                    </Tabs>
                </Paper>
                { tabIndex === 'basic-info' ? <ComponentDetails assetInfoData={assetInfoData} getResponseBack={getResponseBack} />:null}
                { tabIndex === 'acquisition-info' ? <AcquisitionDetails assetInfoData={assetInfoData}  />: null }
            </div>
        </div>
    )
}
export default Component;