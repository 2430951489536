import React, { useState, useEffect } from 'react';
import { browserHistory, Link } from 'react-router';
import { Dialog, DialogActions, DialogContent, DialogTitle, Paper, Tooltip, Button, Chip, Table, TableBody, TableRow, TableCell } from '@material-ui/core'
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import { checkPermission, checkApiStatus } from '../../utils_v2';
import { globalGetService, globalExportService } from '../../../globalServices';
import { PageLoader } from '../../shared_elements';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import 'react-vertical-timeline-component/style.min.css';
import { downloadFileType } from '../../../utils';
const AssetTimeline = ({params, location}) => {
    const [isLoading, setLoading] = useState(false);
    const [assetTimeline, setAssetTimeline] = useState([]);
    const [assetInfo, setAssetInfo] = useState({});
    const [assetDetailTimeline, setAssetDetailTimeline] = useState({modal:false, showDetailedInfo:null, data:null, year:''})
    useEffect(() => {
        getAssetTimeline()
    },[]);
    const getAssetTimeline = () => {
        setLoading(true);
        globalGetService(`console/${params?.type}/${params?.aircraft_slug}/asset-timeline-insights/`)
        .then(response => {
            if(checkApiStatus(response)){
                setAssetTimeline(response.data.data);
                setAssetInfo(response.data.asset_metadata);
            }
            setLoading(false);
        })
    }
    const getAssetTimelineDetail = (data, year) => {
        globalGetService(`console/${params?.type}/${params?.aircraft_slug}/asset-timeline-insights/`,{year:year , eventType:data?.eventType, query: data?.query, asset_id: data?.asset_id , asset_type: data?.asset_type })
        .then(response => {
            if(checkApiStatus(response)){
                setAssetDetailTimeline({showDetailedInfo:response.data.data, modal:true, data, year})
            }
        })
    }
    const onExportAssetTimeline = () => {
        setLoading(true);
        globalExportService(`console/${params?.type}/${params?.aircraft_slug}/asset-timeline-insights/?download=xlsx`) 
        .then(response => {
            downloadFileType(response, `${assetInfo?.unique_name}_Asset_Timeline.`, 'xlsx');
            setLoading(false);
        })
    }
    return(
        <section className='asset-timeline-section' style={{margin:'24px 0px 0px 80px'}}>
            <Paper square className='timeline-title'>
                <h4>
                    <Link to={`/technical-specification/${params.type}/${params.aircraft_slug}/${params.type !== 'aircraft'?'basic-details':''}${location.search?.includes('archived-assets') ? '?archived-assets' : ''}`}>
                        <ArrowBackIcon color='primary' />
                        <span>Asset Timeline Tracker</span>
                    </Link>
                </h4>
                <ul className='list-inline ' style={{paddingLeft:'30px'}}>
                    <li className='list-inline-item'>
                        {assetInfo?.unique_name ? <span>{assetInfo.unique_name}</span>:null}
                        {assetInfo?.model ? <sub className='flex-centered'>({assetInfo.model})</sub>:null}
                    </li>
                    { checkPermission('technical_specs', 'asset_timeline', 'EXP') && assetTimeline?.length ?
                        <li className='list-inline-item' >
                            <Button style={{marginBottom:'10px'}} size='small' variant='outlined' color='primary' onClick={onExportAssetTimeline}>Export</Button>
                        </li>:null
                    }
                </ul>
            </Paper>
            <Paper style={{height: window.innerHeight - 180 + 'px', overflow: "auto"}}>
                <VerticalTimeline>
                    {assetTimeline?.map((item, index) =>
                        <VerticalTimelineElement
                            className="vertical-timeline-element--work"
                            contentStyle={{ background: 'linear-gradient(90deg,#6542c1,#6189ff)', borderTop: '3px solid #cfafea', color: '#fff' }}
                            contentArrowStyle={{ borderRight: '7px solid  #729cf1' }}
                            iconStyle={{ background: 'linear-gradient(269deg,#a7c4ff,#729cf1)', color: '#fff' }}
                            icon={<div style={{fontSize:'19px', textAlign:'center', marginTop: "15px" }}> {item?.year}</div>}
                        >
                            { item?.events ? item?.events.map((childItem, index) =>
                                <div style={{ margin: '2px 8px' }}>
                                    <span style={{ textDecoration: 'underline', color: '#f0f2f3', cursor: 'pointer' }} onClick={() => { getAssetTimelineDetail(childItem, item?.year) }}>
                                        {childItem?.title}
                                    </span>
                                </div>):
                                <div style={{ fontSize: '13px', color: 'grey' }}> No Events Found</div>
                            }
                        </VerticalTimelineElement>
                    )}
                </VerticalTimeline>
            </Paper>
            <Dialog
                open={assetDetailTimeline.modal}
                onClose={() => setAssetDetailTimeline({modal:false, showDetailedInfo:null, data:null, year:''})}
                className='console-management-modal'
                maxWidth="md"
            >
                <DialogTitle style={{padding:'8px 11px'}} id="scroll-dialog-title">
                    {assetDetailTimeline?.data?.title||''} {assetDetailTimeline?.year||''}
                </DialogTitle>
                <DialogContent style={{padding:'11px'}} dividers={true}>
                    { assetDetailTimeline?.showDetailedInfo?.values?.map((entry, index) => (
                        <Paper style={{ width: '480px', padding: '10px', marginTop: '' }}>
                            <Table className='mui-table-format'>
                                <TableBody>
                                    {assetDetailTimeline?.showDetailedInfo.header.map((pitem, childIndex) =>
                                        <TableRow>
                                            <TableCell>{pitem}</TableCell>
                                            <TableCell style={{maxWidth:'330px'}}>{assetDetailTimeline?.showDetailedInfo.values[index][childIndex]}</TableCell>
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                        </Paper>
                    ))}
                </DialogContent>
                <DialogActions style={{padding:'8px 11px'}}>
                    <Button onClick={() => setAssetDetailTimeline({modal:false, showDetailedInfo:null, data:null, year:''})} color='primary' size='small' variant='outlined'>Close</Button>
                </DialogActions>
            </Dialog>
            { isLoading ? <PageLoader/>:null }
        </section>

    )
}
export default AssetTimeline;