import React, { useState, useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Button, Tabs, Paper, Avatar, IconButton, TextField, FormControl, FormLabel, FormControlLabel, Switch, Dialog, DialogContent, DialogTitle, DialogActions, CircularProgress, Tab } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import InfoIcon from '@material-ui/icons/Info';
import PhotoCamera from '@material-ui/icons/PhotoCamera';
import { PageLoader } from '../../shared_elements'
import { globalGetService, globalPostService, globalPutService } from '../../../globalServices';
import { checkApiStatus, checkPermission, getLocalStorageInfo } from '../../utils_v2';
import ConfigSideNav from './ConfigSideNav';
import DistributionEmails from './DistributionEmails';
import MaintenaceProgram from './MaintenaceProgram';
import { ArrowBack } from '@material-ui/icons';
import { browserHistory } from 'react-router';
import AddNewLessee from '../../../shared/components/AddNewLessee';
export default function LesseeConfig(props) {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [isLoading, setLoading] = useState(false);
    const [lesseeInfo, setLesseeInfo] = useState({})
    const [tabIndex, setTabIndex] = useState('distribution')
    const [editLessee, setAddEditLessee] = useState(false);

    const [editProfile, setEditProfile] = useState({ modal: false, data: null })
    useEffect(() => {
        setLoading(true)
        globalGetService(`console/lessor-lessees/${props?.params?.slug}/`)
            .then(response => {
                setLoading(false)
                setLesseeInfo(response.data.data)
            })
    }, [])
    const onUploadLessorProfile = (files) => {
        if (files.length) {
            let formData = new FormData();
            formData.append('logo', files[0]);
            setLoading(true)
            globalPutService(`console/update-lessee/`, formData)
                .then(response => {
                    if (checkApiStatus(response)) {
                        enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { horizontal: 'right', vertical: 'top' } });
                        window.location.reload();
                    }
                    setLoading(false)
                })
        }
    }
    console.log('lesseeInfo', lesseeInfo);
    return (
        <section className='config-container' style={{ margin: '24px 0 0 75px', }}>
            {isLoading ? <PageLoader /> : null}
            <Paper className="tech-specs-card" style={{ minHeight: `${window.innerHeight - 92}px` }}>
                <ConfigSideNav />
                <div className="tech-specs-content config-lessee-section">
                    <Paper>
                        <Grid container spacing={1}>
                            <Grid item md={6}>
                                <ArrowBack onClick={() => browserHistory.goBack()} style={{ cursor: 'pointer', marginLeft: "10px" }} color='primary' fontSize='small' />
                            </Grid>
                            <Grid item md={6}>
                                <div style={{ float: 'right' }}>
                                    {
                                        checkPermission('console', 'add_lessee', 'U') ?
                                            <EditIcon onClick={() => setAddEditLessee({ modal: true, mode: 'edit', data: lesseeInfo })} style={{ cursor: 'pointer' }} color='primary' fontSize='small' />
                                            : null}  
                                </div>
                            </Grid>
                            <Grid item md={2}>
                                <div style={{ textAlign: 'center' }}>
                                    <span style={{ position: 'relative' }}>
                                        <Avatar src={lesseeInfo?.logo || null} alt={''} style={{ width: '100px', height: '100px', margin: '0 auto' }} />
                                        {/* {checkPermission('console', 'lessee_configuration', 'U') ? */}
                                        {/* <span style={{ position: 'absolute', bottom: '0', textAlign: 'center', cursor: 'pointer' }}>
                                                <input onChange={(e) => onUploadLessorProfile(e.target.files)} style={{display:'none'}} accept="image/*" id="icon-button-file" type="file" />
                                                <label htmlFor="icon-button-file">
                                                    <IconButton color="primary" aria-label="upload picture" component="span">
                                                        <PhotoCamera color='primary' fontSize="small" />
                                                    </IconButton>
                                                </label>
                                            </span>  */}
                                        {/* : null} */}
                                    </span>
                                </div>
                            </Grid>
                            <Grid item md={10}>
                                <Grid container spacing={1}>
                                    <Grid item md={6}>
                                        <TextField
                                            disabled
                                            className='lessee-profile-input'
                                            id='name'
                                            label='Name'
                                            value={lesseeInfo?.name}
                                            fullWidth
                                            margin="normal"
                                            InputLabelProps={{ shrink: true }}
                                            variant='outlined'
                                        />
                                    </Grid>

                                    <Grid item md={6}>
                                        <TextField
                                            disabled
                                            id='country'
                                            className='lessee-profile-input'
                                            label='Country'
                                            value={lesseeInfo?.country}
                                            fullWidth
                                            margin="normal"
                                            InputLabelProps={{ shrink: true }}
                                            variant='outlined'
                                        />
                                    </Grid>
                                    <Grid item md={12}>
                                        <TextField
                                            disabled
                                            className='lessee-profile-input'
                                            id='contact_address'
                                            label='Contact Address'
                                            value={lesseeInfo?.contact_address}
                                            fullWidth
                                            margin="normal"
                                            InputLabelProps={{ shrink: true }}
                                            variant='outlined'
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Paper>
                    <Paper style={{ marginTop: "10px" }}>
                        <Tabs
                            variant="fullWidth"
                            value={tabIndex}
                            onChange={(event, newValue) => setTabIndex(newValue)}
                            indicatorColor="primary"
                            textColor="primary"
                            scrollButtons="auto"
                        >
                            <Tab style={{ textTransform: 'none' }} label="Distribution Email(s)" value='distribution' />
                            <Tab style={{ textTransform: 'none' }} label="Maintenance Program" value='maintenace' />
                        </Tabs>
                        <Paper>
                            {
                                tabIndex === 'distribution' ?
                                    <DistributionEmails
                                        lesseeId={props?.location?.search?.split('?')[1]}
                                    />
                                    :
                                    <MaintenaceProgram
                                    lesseeId={props?.location?.search?.split('?')[1]}
                                    lesseeSlug={props?.params?.slug}
                                    />
                            }
                        </Paper>
                    </Paper>
                </div>
            </Paper>
            {
                editLessee?.modal ?
                    <AddNewLessee
                        addEditLessee={editLessee}
                        getResponseBack={() => { window.location.reload() }}
                        toggleModalFn={() => setAddEditLessee({ modal: false, data: {} })}
                    />
                    : null
            }
        </section>
    )
}
