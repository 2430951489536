import React, {useState} from 'react';
import { withRouter } from 'react-router';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import moment from 'moment';
import { backendDateFormat, fieldDateFormat } from '../../../constants';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { FormControlLabel, Checkbox, Grid, Radio, TextField } from "@material-ui/core";

const CashflowReport = ({onChangeExport, exportObj, onChangeExtensionFn, onChangeDateFn, onChangeDropdownFn}) => {
    return(
        <div>
            <Grid container spacing={1} alignItems='center'>
                <Grid item md={12}>
                    <div className='export-module-card'>
                        <h4 className='rpt-card-header'>Cashflow</h4>
                        <div className='rpt-card-body'>
                            <Grid container spacing={3} alignItems='center'>
                                <Grid item md={6}>
                                    <FormControlLabel control={
                                        <Checkbox 
                                            checked={exportObj.contracts && exportObj.contracts.cashflow && exportObj.contracts.cashflow.export ? true:false} 
                                            onChange={(e) => onChangeExport(e.target.checked, 'cashflow')} 
                                            color="primary" 
                                            size="small" />} 
                                            label='Cashflow Summary' 
                                        />
                                </Grid>
                                <Grid item md={6}>
                                    <FormControlLabel value={1} control={<Radio checked={(exportObj.contracts && exportObj.contracts.cashflow && exportObj.contracts.cashflow.extension == 'pdf')} onChange={() => onChangeExtensionFn('pdf', 'cashflow')} color="primary" size="small" />} label="PDF" />
                                    <FormControlLabel value={2} control={<Radio checked={(exportObj.contracts && exportObj.contracts.cashflow && exportObj.contracts.cashflow.extension == 'xls')} onChange={() => onChangeExtensionFn('xls', 'cashflow')} color="primary" size="small" />} label="XLS" />
                                </Grid>
                            </Grid>
                            <Grid container spacing={3} alignItems='center'>
                                <Grid item md={6}>
                                    <MuiPickersUtilsProvider utils={MomentUtils}>
                                        <KeyboardDatePicker
                                            margin="normal"
                                            id="start_date"
                                            label="Start Date"
                                            format={fieldDateFormat}
                                            fullWidth
                                            InputLabelProps={{ shrink: true }}
                                            maxDate={moment()}
                                            value={exportObj.contracts.cashflow.filter.startDate ? moment(exportObj.contracts.cashflow.filter.startDate): null}
                                            onChange={(data) => {onChangeDateFn((moment(data).format(backendDateFormat), 'startDate', 'cashflow'))}}
                                            inputVariant='outlined'
                                        />
                                    </MuiPickersUtilsProvider>
                                </Grid>
                                <Grid item md={6}>
                                    <MuiPickersUtilsProvider utils={MomentUtils}>
                                        <KeyboardDatePicker
                                            margin="normal"
                                            id="end_date"
                                            label="End Date"
                                            format={fieldDateFormat}
                                            fullWidth
                                            InputLabelProps={{ shrink: true }}
                                            maxDate={moment()}
                                            value={exportObj.contracts.cashflow.filter.endDate ? moment(exportObj.contracts.cashflow.filter.endDate):null}
                                            onChange={(data) => {onChangeDateFn((moment(data).format(backendDateFormat), 'endDate', 'cashflow'))}}
                                            inputVariant='outlined'
                                        />
                                    </MuiPickersUtilsProvider>
                                </Grid>
                                <Grid item md={6}>
                                    <Autocomplete
                                        options={[{label: 'Reserve Invoice', id: '1'}, {label: 'Rental Invoice', id: '2' }, {label: 'Ad Hoc Invoice', id: '9'}, {label: 'Penalty Invoice', id: '3'}, {label: 'Debit Note Invoice', id: '4'},
                                                    {label: 'Credit Note Invoice', id: '5'}]}
                                        getOptionLabel={option => option.label}
                                        id="invoice_type"
                                        value={exportObj.contracts.cashflow.filter.transaction_type}
                                        onChange={(e, data) => onChangeDropdownFn(data, 'transaction_type', 'cashflow')}
                                        renderInput={params => <TextField  {...params} label="Transaction Invoice Type" margin="normal" placeholder='Select Invoice Type' fullWidth InputLabelProps={{ shrink: true }} variant='outlined' />}
                                    />
                                </Grid>
                            </Grid>
                        </div>
                    </div>
                </Grid>
            </Grid>
        </div>
    )
}
export default withRouter(CashflowReport);