import React, { Component } from 'react';
import { connect } from 'react-redux';
import { browserHistory, withRouter } from 'react-router';
import { getGlobalCookie, getLocalStorageInfo, authorizeAutoLogin } from './index'
import config from '../config';
window.addEventListener('visibilitychange', checkLogin)
function checkLogin () {
  if(document.visibilityState || document.hasFocus()){
    let location = browserHistory.getCurrentLocation().pathname;
    let locationQuery = browserHistory.getCurrentLocation()
    let lessorAccess = '';
    if(getGlobalCookie('lessorAccess')){
      lessorAccess = JSON.parse(getGlobalCookie('lessorAccess'));
    }
    setTimeout(() =>{
      if(!lessorAccess){
        localStorage.clear();
        if(!location.includes('login') && !location.includes('/') && !location.includes('solutions') && !location.includes('password/create')  && !location.includes('password/reset') && !location.includes('termscondition') && !location.includes('data-policy') && !location.includes('privacy-policy') &&  !location.includes('forgot')){
          locationQuery = {
            ...locationQuery,
            pathname:'/login'
          }
          browserHistory.push(locationQuery);
          // window.location.reload()
        }
      }else{
        if(config.api.networkInterface.includes(lessorAccess.environment)){
          let userId = getLocalStorageInfo().defaultLessor.id
          if(lessorAccess.id){
            if(!getLocalStorageInfo() || (getLocalStorageInfo() && !getLocalStorageInfo().defaultLessor) || userId !==lessorAccess?.id){
               authorizeAutoLogin(lessorAccess)
             }    
          }else if(getLocalStorageInfo() && !getLocalStorageInfo().defaultLessor  && getLocalStorageInfo().user.two_f_auth_enabled==false){
            locationQuery = {
              ...locationQuery,
              pathname:'/login'
            }
            browserHistory.push(locationQuery)
          }
        }
      }
    })
  }
}
function Authenticate(ComposedComponent, extraInfo) {
  class authenticateApp extends Component {
    constructor(props) {
      super(props);
    }
    componentWillMount(){
      checkLogin()
    }
    render() {
      let userDetail = JSON.parse(localStorage.getItem('userInfo'));
      if(getGlobalCookie('lessorAccess') && userDetail){
        return(
          <React.Fragment>
            <ComposedComponent {...this.props}/>
          </React.Fragment>
        )
      }else{
        return null
      }
    }
  }
  return withRouter(connect(null, null)(authenticateApp));
}
export default Authenticate;
