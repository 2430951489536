import * as actions from '../actions';
import { globalGetService } from '../../globalServices';

export const getNotificationBarDataAc = () =>{
  return(dispatch =>{
    globalGetService('/console/cart/count/')
    .then(response => {
      dispatch({
        type:actions.NOTIFICATIONS_COUNT,
        payload:response.data.data
      })
    })
  })
}


