import React, { useState, useEffect } from 'react'
import { useSnackbar } from 'notistack';
import { InputAdornment, Paper, Table, TableBody, TableCell, TableHead, TableRow, TextField, Switch } from '@material-ui/core';
import { PageLoader } from '../../shared_elements';
import { globalGetService, globalPostService, globalPutService } from '../../../globalServices';
import { checkApiStatus } from '../../utils_v2';
import { regexConstants } from '../../../constants/regEx'
const AlertsNotifications = ({}) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [isLoading, setLoading] = useState(false);
    const [notifications, setNotifications] = useState([])
    useEffect(() => {
        getAlertNotification();
    },[]);
    const onFieldChange = (key, value, index) => {
        setNotifications(notifications.map((item, itemIndex) => itemIndex !== index ? item :{
            ...item,
            [key]: value
        }));
    }
    const getAlertNotification = () => {
        setLoading(true)
        globalGetService(`console/user-alerts/`)
        .then(response => {            
            if(checkApiStatus(response)){
                setNotifications(response.data.list.data);
            }
            setLoading(false)
        })
    }
    const enableAlertNotification = (item, flag) => {
        let payload = {
            flag:flag,
            frequency: item.created_by?.id ? item.frequency :30,
            duration_months: item.created_by?.id ? item.duration_months : 3,
            slug:item.slug
        }
        setLoading(true)
        if(item?.created_by?.id){
            globalPutService(`console/user-alerts/${item.slug}/`, payload)
            .then(response => {
                if(checkApiStatus(response)){
                    getAlertNotification();
                    enqueueSnackbar(response.data.message, { variant: 'success',anchorOrigin:{horizontal:'right', vertical:'top'}});
                }else{
                    enqueueSnackbar(response.data.message, { variant: 'error',anchorOrigin:{horizontal:'right', vertical:'top'}});
                }
                setLoading(false)
            })
        }else{
            globalPostService(`console/user-alerts/`, payload)
            .then(response => {
                if(checkApiStatus(response)){
                    getAlertNotification();
                    enqueueSnackbar(response?.data?.message, { variant: 'success',anchorOrigin:{horizontal:'right', vertical:'top'}});
                }else{
                    enqueueSnackbar(response?.data?.message, { variant: 'error',anchorOrigin:{horizontal:'right', vertical:'top'}});
                }
                setLoading(false)
            })
        }
     
    }
    const updateAlertNotification = (item, value) => {
        if(value && parseInt(value) > 0){
            let payload = {
                frequency: item.frequency,
                flag: true,
                duration_months: item.duration_months
            }
            setLoading(true);
            globalPutService(`console/user-alerts/${item.slug}/`,payload)
            .then(response => {
                if(checkApiStatus(response)){
                    getAlertNotification();
                    enqueueSnackbar(response.data.message, { variant: 'success',anchorOrigin:{horizontal:'right', vertical:'top'}});
                }else{
                    enqueueSnackbar(response.data.message, { variant: 'error',anchorOrigin:{horizontal:'right', vertical:'top'}});
                }   
                setLoading(false);
            })
        }else{
            enqueueSnackbar('Cannot be zero or empty value', { variant: 'error',anchorOrigin:{horizontal:'right', vertical:'top'}});   
            getAlertNotification();
        }
    }
    return(
        <Paper className="console-forms-fields">
            <Table className='mui-table-format'>
                <TableHead>
                    <TableRow>
                        <TableCell>Title</TableCell>
                        <TableCell>Disable/Enable</TableCell>
                        <TableCell>Expiry Duration</TableCell>
                        <TableCell>Email Frequency</TableCell>
                        <TableCell>Description</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {notifications.map((notification, index) => 
                        <TableRow>
                            <TableCell style={{verticalAlign:'top'}}>
                                {notification?.alert_title||'--'}
                            </TableCell>
                            <TableCell style={{verticalAlign:'top'}}>
                                <Switch
                                    checked={notification.flag}
                                    onChange={(e) => enableAlertNotification(notification, e.target.checked)}
                                    color="primary"
                                    name="checkedB"
                                />
                            </TableCell>
                            <TableCell style={{width:'160px', verticalAlign:'top'}}>
                                {  notification?.alert_title === 'Low Utilization' ? 'NA':
                                    <TextField 
                                        disabled={!notification.flag}
                                        id={`duration-months_${index}`}
                                        value={notification.flag ? notification.duration_months : ''}
                                        fullWidth
                                        margin="none"
                                        InputLabelProps={{shrink: true}}
                                        variant='outlined'
                                        onChange={(e) => {regexConstants.onlyNumeric.test(e.target.value) ? onFieldChange('duration_months', e.target.value, index):e.preventDefault()}}
                                        onBlur={(e) => updateAlertNotification(notification, e.target.value)}
                                        InputProps={{endAdornment:<InputAdornment position="start">Month(s)</InputAdornment>}}
                                    />
                                }
                            </TableCell>
                            <TableCell style={{width:'200px', verticalAlign:'top'}}>
                                {notification?.alert_title === 'Low Utilization' ? 'NA':
                                    <>
                                        <TextField 
                                            disabled={!notification.flag}
                                            id={`frequency_${index}`}
                                            value={notification.flag ? notification.frequency:''}
                                            fullWidth
                                            margin="none"
                                            InputLabelProps={{shrink: true}}
                                            variant='outlined'
                                            onChange={(e) => {regexConstants.onlyNumeric.test(e.target.value) ? onFieldChange('frequency', e.target.value, index): e.preventDefault()}}
                                            onBlur={(e) => updateAlertNotification(notification, e.target.value)}
                                            InputProps={{endAdornment:<InputAdornment position="start">Day(s)</InputAdornment>}}
                                        />
                                    </>
                                }
                                {notification.flag && <p style={notification.alert_title === "Low Utilization" ? {marginTop:'8px'}:{ fontSize: '13px', fontFamily: 'Conv_IBMPlexSans-Regular', width:'100%' }}>Next Alert:-  <span style={{ color: 'red', fontSize: '13px', position:'absolute' }}>{notification.scheduled_alert}</span></p>}
                            </TableCell>
                            <TableCell style={{verticalAlign:'top'}}>
                                {notification?.description||'--'}
                            </TableCell>
                        </TableRow>
                    )}
                </TableBody>
            </Table>
            { isLoading ? <PageLoader/>:null}
        </Paper>
    )
}
export default AlertsNotifications;