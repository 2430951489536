import React from 'react';
import { Grid, Container } from '@material-ui/core';
 
const WhatsSparta = ({}) => {
  return(
    <section className="solutions-lifecycle whats-sparta-section">
      <Container maxWidth="xl">
        <Grid container spacing={5} alignItems="center">
          <Grid item lg={7} md={7} sm={6} xs={12}>
              <div className="content-wrapper">
                <h2>Simplify Aviation Asset Lifecycle Management</h2>
                <p>
                  Through SPARTA, enterprises can effortlessly manage leases, mitigate commercial risk, execute projects and maintain digitised records.
                </p>
                <h4>All on ONE Platform</h4>
              </div>
          </Grid>
        </Grid>
      </Container>
    </section>
  )
}
export default WhatsSparta;
