import React, { useState, Fragment } from 'react';
import { useSnackbar } from 'notistack';
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField, FormLabel } from '@material-ui/core';
import { DropzoneArea } from 'material-ui-dropzone';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { getLocalStorageInfo } from '../../../utils'; 
import { globalPostService } from '../../../globalServices';
import { checkApiStatus } from '../../utils_v2';
import { checkPermission } from '../../../console_management/utils_v2';
const ImportAsset = ({addAsset, toggleModalFn, getResponseBack}) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [isLoading, setLoading] = useState(false);
    const [asset, setAsset] = useState('');
    const [error, setError] = useState({});
    const [files, setFiles] = useState([]);

    const onRestErrorKey = (keyParam) => {
        setError({...error, [keyParam]:''})
    }

    const onCreateAsset = (e) => {
        e.preventDefault();
        let validationInputs = {};
        validationInputs = {
            ...validationInputs,
            asset: asset ? '' : 'Asset Type is required'
        }
        if(!files.length){
            validationInputs={
                ...validationInputs,
                files:'Asset Transfer Key is required.'
            }
        }
        if (Object.keys(validationInputs).every((k) => { return validationInputs[k] === '' })) {
            setLoading(true)
            let formData = new FormData();
            formData.append('file', files[0]);
            globalPostService(`/console/import-data-transfer-file/`, formData)
            .then(response => {
                if(checkApiStatus(response)){
                    enqueueSnackbar('Asset import initiated successfully.', { variant: 'success', anchorOrigin: { vertical: 'top', horizontal: 'right' } })
                    toggleModalFn();getResponseBack();
                }
                setLoading(false)
            })
        } else {
            setError(validationInputs)
        }
    }

    let importOptions = [];
    if(checkPermission('technical_specs','aircraft','C')){
        importOptions = [...importOptions,{value: 'aircraft', label: 'Aircraft'}]
    }
    if(checkPermission('technical_specs','engine','C')){
        importOptions = [...importOptions,{value: 'engine', label: 'Engine'}]
    }
    return(
        <Fragment>
            <Dialog
                open={addAsset.modal}
                onClose={() => toggleModalFn()}
                className='console-management-modal'
                fullWidth={true}
                maxWidth={'sm'}
            >
            <DialogTitle id="scroll-dialog-title">
                Import Asset
            </DialogTitle>
            <DialogContent dividers={true} className="data-upload-modal">
                <form>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <Autocomplete
                                options={importOptions}
                                getOptionLabel={option => option.label}
                                id="asset"
                                value={[{value: 'aircraft', label: 'Aircraft'},{value: 'engine', label: 'Engine'}].filter(item => item.value == asset).length ? [{value: 'aircraft', label: 'Aircraft'},{value: 'engine', label: 'Engine'}].filter(item => item.value == asset)[0] : {}}
                                onChange={(e, value) => setAsset(value?.value)}
                                renderInput={params => <TextField required error={error.asset ? true : false} helperText={error.asset ? error.asset : ''} onFocus={() => onRestErrorKey('asset')} {...params} label="Asset Type" margin="normal" fullWidth InputLabelProps={{ shrink: true }} variant='outlined' />}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <div>
                                <FormLabel style={{ fontSize: '13px', marginTop: '8px' }} error={error.files ? true : false} > Asset Transfer Key File *</FormLabel>
                                <p className='error-msg'> {error.files}</p>
                                <DropzoneArea
                                    filesLimit={1}
                                    showPreviewsInDropzone={false}
                                    useChipsForPreview={true}
                                    showPreviews={ true }
                                    acceptedFiles={['.txt']}
                                    dropzoneText={<p>Drag & Drop Documents OR Click Here </p>}
                                    dropzoneClass="full-w-drag-drop-zone"
                                    maxWidth="md"
                                    alertSnackbarProps={{anchorOrigin: {vertical:'top', horizontal:'right'}}}
                                    showAlerts={['error', 'info']}
                                    onDrop={(files) => setFiles(files)}
                                    clearOnUnmount={true}
                                />
                                <p><span style={{background:'#ffe160', fontSize:'11px', padding:'2px 3px'}}>Only .txt files should be allowed to upload.</span></p>
                            </div>
                        </Grid>
                        <Grid item xs={12}>
                            <p style={{margin: '0px 10px'}} className="warning-info-text">
                                Don't have access to Transfer Key? Please consult asset owner to provide you the same. Asset Transfer key is a security file which allow you to transfer asset from one owner to another owner within SPARTA. You are required to have asset transfer license to do so.
                            </p>
                        </Grid>
                    </Grid>
                </form>
            </DialogContent>
            <DialogActions>
                <Button color="primary" size='small' onClick={() => { toggleModalFn() }}>Cancel</Button>
                <Button color="primary" size='small' onClick={(e) => { onCreateAsset(e) }} variant="contained">
                    {isLoading ? <CircularProgress color="#ffffff" size={24} /> : 'Import'}
                </Button>
            </DialogActions>
        </Dialog>
    </Fragment>
    )
}
export default ImportAsset;