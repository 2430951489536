import React, { useState, useEffect } from 'react';
import { useSnackbar } from 'notistack';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {DropzoneArea} from 'material-ui-dropzone';
import CKeditor from '../../shared/CKEditor';
import { Button, Dialog, DialogContent, DialogTitle, DialogActions, Grid, TextField, CircularProgress } from '@material-ui/core';
import { globalPostService } from '../../globalServices';
import { checkApiStatus } from '../utils_v2';
 
const ReportAnIssue = ({reportAnIssueInfo, toggleModalFn}) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [isLoading, setLoading] = useState(false);
    const [issueInfo, setIssueInfo] = useState({description:''});
    const [error, setError] = useState({});
    const [pltConstants, setPltConstants] = useState([]);
    useEffect(() => {
        getPlatformConstants();
    },[]);
    const getPlatformConstants = () => {
        globalPostService('console/get-constants/',{constant_types:['bug_module', 'bug_severity', 'bug_classification']})
        .then(response => {
            if(checkApiStatus(response)){
                setPltConstants(response.data.data)
            }
        })
    }
    const onFieldChange = (key, value) => {
        setIssueInfo({...issueInfo, [key]:value});
    }
    const updateCKEditor = (evt) =>{
        var newContent = evt.editor.getData();
        setIssueInfo({...issueInfo, 'description':newContent});
    }
    const onReportAnIssue = () => {
        let validationInputs = {
            title:issueInfo?.title?.trim()?.length ? '':'Please enter Title',
            module:issueInfo?.module ? '':'Please select Module',
            severity:issueInfo?.severity ? '':'Please select Severity',
            classification:issueInfo?.classification ? '':'Please select Classification',
        };
        if(Object.keys(validationInputs).every((k) => { return validationInputs[k] === ''})){
            setLoading(true);
            const formData= new  FormData();
            formData.append('title', issueInfo.title);
            formData.append('description', issueInfo.description);
            formData.append('severity', issueInfo.severity.value);
            formData.append('module', issueInfo.module.value);
            formData.append('classification', issueInfo.classification.value);
            formData.append('current_page_url',window.location.href);
            if(issueInfo?.attachments?.length){
                issueInfo.attachments.map((file, index) => {
                    formData.append('attachments', file);
                });
            }
            globalPostService(`console/report-bug/`, formData)
            .then(response => {
                if(checkApiStatus(response)){
                    toggleModalFn();
                    enqueueSnackbar(response.data.message, { variant: 'success',anchorOrigin:{horizontal:'right', vertical:'top'}});
                }
                setLoading(false);
            })
        }else{
            setError(validationInputs);
        }
    }
    return(
        <>
            <Dialog
                open={reportAnIssueInfo.modal}
                onClose={toggleModalFn}
                className='console-management-modal'
                maxWidth="md"
            >
                <DialogTitle id="scroll-dialog-title">
                    <div>
                        <h4>Report An Issue</h4>
                        <p style={{fontSize:'15px', fontFamily:'ibmplexRegular'}} >Please report only technical issues / bugs encountered while operating the SPARTA system. Issue will be reported to SPARTA IT team for action.</p>
                    </div>
                </DialogTitle>
                <DialogContent dividers={true}>
                    <div>
                        <Grid spacing={1} container>
                            <Grid item md={12}>
                                <TextField 
                                    required
                                    name='title'
                                    label='Title'
                                    value={issueInfo?.title||''}
                                    fullWidth
                                    margin="normal"
                                    onChange={(e) => onFieldChange('title', e.target.value)}
                                    error={error?.title}
                                    helperText={error?.title||''}
                                    onFocus={() => setError({...error, 'title':''})}
                                    InputLabelProps={{shrink: true}}
                                    inputProps={{ maxLength: 150 }}
                                    variant='outlined'
                                />
                            </Grid>
                            <Grid item md={4}>
                                <Autocomplete
                                    filterSelectedOptions={true}
                                    options = {pltConstants.filter((optionItem)=>optionItem.type === 'bug_module')}
                                    getOptionLabel={option => option.label}
                                    id="module"
                                    value={issueInfo?.module||null}
                                    onChange={(e, value) => {onFieldChange('module', value); setError({...error,'module':''})}}
                                    renderInput={params => <TextField required error={error?.module} helperText={error?.module||''} {...params} label="Module" margin="normal" fullWidth InputLabelProps={{shrink: true}} variant='outlined' />}
                                />
                            </Grid>
                            <Grid item md={4}>
                                <Autocomplete
                                    filterSelectedOptions={true}
                                    options = {pltConstants.filter((optionItem)=>optionItem.type === 'bug_severity')}
                                    getOptionLabel={option => option.label}
                                    id="severity"
                                    value={issueInfo?.severity||null}
                                    onChange={(e, value) => {onFieldChange('severity', value); setError({...error,'severity':''})}}
                                    renderInput={params => <TextField required error={error?.severity} helperText={error?.severity||''} {...params} label="Severity" margin="normal" fullWidth InputLabelProps={{shrink: true}} variant='outlined' />}
                                />
                            </Grid>
                            <Grid item md={4}>
                                <Autocomplete
                                    filterSelectedOptions={true}
                                    options = {pltConstants.filter((optionItem)=>optionItem.type === 'bug_classification')}
                                    getOptionLabel={option => option.label}
                                    id="classification"
                                    value={issueInfo?.classification||null}
                                    onChange={(e, value) => {onFieldChange('classification', value); setError({...error,'classification':''})}}
                                    renderInput={params => <TextField required error={error?.classification} helperText={error?.classification||''} {...params} label="Classification" margin="normal" fullWidth InputLabelProps={{shrink: true}} variant='outlined' />}
                                />
                            </Grid>
                            <Grid item md={12}>
                                <TextField 
                                    name='description'
                                    label='Description'
                                    value={issueInfo?.description||''}
                                    fullWidth
                                    margin="normal"
                                    multiline
                                    rows={5}
                                    onChange={(e) => onFieldChange('description', e.target.value)}
                                    InputLabelProps={{shrink: true}}
                                    inputProps={{ maxLength: 250 }}
                                    variant='outlined'
                                />
                            </Grid>
                            <Grid item md={12}>
                                <div>
                                    <DropzoneArea
                                        acceptedFiles={['.png, .jpg, .jpeg, .pdf, .xls, .xlsx, .doc, .docx']}
                                        filesLimit={10}
                                        maxFileSize={104857600}
                                        showPreviewsInDropzone={false}
                                        useChipsForPreview={true}
                                        showPreviews={ true }
                                        dropzoneText={<p style={{fontSize:'13px'}}>Drag & Drop Documents<br/> OR <br/> Click Here<br/> </p>}
                                        dropzoneClass="drag-drop-cnt"
                                        maxWidth="sm"
                                        showAlerts={['info','error']}
                                        alertSnackbarProps={{anchorOrigin: {vertical:'top', horizontal:'right'}}}
                                        clearOnUnmount={true}
                                        onDrop={(files) => onFieldChange('attachments',files)} 
                                    />
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button disabled={isLoading} onClick={toggleModalFn} color='primary' size='small' variant='outlined'>Cancel</Button>
                    <Button disabled={isLoading} onClick={onReportAnIssue} color='primary' size='small' variant='contained'>
                        {isLoading ? <CircularProgress size={24} />:'Save'}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}
export default ReportAnIssue;