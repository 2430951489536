import React, { Component } from 'react';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';
import { getGlobalCookie, getLocalStorageInfo, authorizeAutoLogin } from './'
import config from '../config';
window.addEventListener('visibilitychange', checkLogin)
function checkLogin () {
    if(document.visibilityState || document.hasFocus()) {
      let userDetail = JSON.parse(localStorage.getItem('userInfo'));
      let location = browserHistory.getCurrentLocation().pathname
      let lessorAccess = ''
      if(getGlobalCookie('lessorAccess')){
        lessorAccess = JSON.parse(getGlobalCookie('lessorAccess'));
      }
      if(!lessorAccess){
        localStorage.clear();
        if(!location.includes('login') && !location.includes('password/create')&& !location.includes('password/reset') && !location.includes('termscondition') && !location.includes('data-policy') && !location.includes('privacy-policy') && !location.includes('forgot')){
          browserHistory.push('/solutions');
          window.location.reload()
        }
        
      }else{
        if(config.api.networkInterface.includes(lessorAccess.environment)){
          if(lessorAccess.id){
            if(!getLocalStorageInfo()){
               authorizeAutoLogin(lessorAccess)
            }else{
              if(location.includes('login') || location.includes('password/create') || location.includes('password/reset') || location.includes('termscondition') || location.includes('data-policy') || location.includes('privacy-policy') || location.includes('forgot')){
                browserHistory.push('/assets-listing');
              }
            }
          }else {
            if((userDetail && userDetail.defaultLessor == undefined) || (!userDetail && lessorAccess)){
              let location = browserHistory.getCurrentLocation();
              location = {
                ...location,
                pathname: '/login'
              }
              browserHistory.push(location);
            }
          }
        }
      }
    }
}


function LoggedUserRed(ComposedComponent, extraInfo) {
 class LoginAccessCrtl extends Component {
   constructor(props) {
     super(props);
   }
   
   componentDidMount(){
     checkLogin();
   }
   render() {
     if(!localStorage.getItem('userInfo')) {
       return(
        <React.Fragment>
          <ComposedComponent {...this.props}/>
        </React.Fragment>
       )
     }else {
       return null
     }
   }
 }
  return connect(null, null)(LoginAccessCrtl);
}
export default LoggedUserRed;
